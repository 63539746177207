import React, { useState, useEffect } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AsyncSelect from 'react-select/async';
import { currency } from 'resources/utilities';
import { Link } from 'react-router-dom';
import { useGlobalState } from 'resources/GlobalStateContext';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Swal from 'sweetalert2';

const Asignacion = ({ match }) => {
    const { path } = match;
    const { id } = match.params;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [isOpenModal, openModal, closeModal] = useModal(false);
    let [articulo, setArticulo] = useState({});
    let [articuloPadre, setArticuloPadre] = useState({});
    let [variantes, setVariantes] = useState([]);
    let [articuloTiendaAsignar, setArticuloTiendaAsignar] = useState(0);
    let [varianteTiendaAsignar, setVarianteTiendaAsignar] = useState(0);
    let [articuloAsignar, setArticuloAsignar] = useState({});

    function getArticulo() {
        fetchConToken(
            `articulos?id=${id}&sucursal_Id=${getDataSession('sucursal')}&conDetalle=false`
        ).then((art) => {
            setArticulo(art);
            if (art && art.variante_Padre_Id > 0) {
                fetchConToken(`articulos/VarianteById?variante_id=${art.variante_Padre_Id}`).then(
                    (artPadre) => {
                        setArticuloPadre(artPadre);
                    }
                );
            }
            else
                if (art && art.articulo_Padre_Id > 0) {
                    fetchConToken(
                        `articulos?id=${art.articulo_Padre_Id}&sucursal_Id=${getDataSession(
                            'sucursalPrincipal'
                        )}&conDetalle=false`
                    ).then((artPadre) => {
                        setArticuloPadre(artPadre);
                    });
                }


        });
    }

    function getVariantes() {
        fetchConToken(`articulos/Variantes?articulo_id=${id}`).then((variants) => {
            Promise.all(
                variants.map(async (v) => {
                    if (v.articulo_Padre_Id > 0) {
                        v.padre = await fetchConToken(
                            `articulos?id=${v.articulo_Padre_Id}&sucursal_Id=${getDataSession(
                                'sucursalPrincipal'
                            )}&conDetalle=false`
                        );
                    }
                    if (v.variante_Padre_Id > 0) {
                        v.padre = await fetchConToken(
                            `articulos/VarianteById?variante_id=${v.variante_Padre_Id}`
                        );
                    }
                    return v;
                })
            ).then((updatedVariants) => {
                setVariantes(updatedVariants);
            });
        });
    }

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Asignacion de articulos',
            subtitulo: 'Aqui puede vincular los articulos de su tienda con la conexion principal'
        });
    };

    useEffect(() => {
        actualizarTitulo();
        getArticulo();
        getVariantes();
    }, []);

    const loadArticulos = async (query) => {
        if (getDataSession('sucursalPrincipal') === '0') {
            Swal.fire('Info', 'Configure la conexion principal.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `Articulos/ListArtYVar?busqueda=${query}&sucursal_Id=${getDataSession(
                'sucursalPrincipal'
            )}`
        );
        return resp;
    };

    async function getArticuloSeleccionado(articuloSeleccionado) {
        console.log(articuloSeleccionado);
        setArticuloAsignar(articuloSeleccionado);
    }

    const seleccionArticuloTienda = (id) => {
        setArticuloAsignar({});
        setArticuloTiendaAsignar(id);
        setVarianteTiendaAsignar(0);
        openModal();
    };

    const seleccionVarianteTienda = (id) => {
        setArticuloAsignar({});
        setArticuloTiendaAsignar(0);
        setVarianteTiendaAsignar(id);
        openModal();
    };

    function AsignarArticulo() {
        if (articuloTiendaAsignar > 0) {
            if (articuloAsignar.variante == 'N') articulo.articulo_Padre_Id = articuloAsignar.id;
            else articulo.variante_Padre_Id = articuloAsignar.id;

            var artVarAsignar = {
                articulo_Id: articulo.id,
                variante_Id: null,
                articulo_Principal_Id: articulo.articulo_Padre_Id,
                variante_Principal_Id: articulo.variante_Padre_Id
            };

            fetchConToken(`articulos/AsignarArticuloVariante`, artVarAsignar, 'PUT').then(
                (response) => {
                    if (response.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }
                    Swal.fire('Asignación completa', 'Articulo Asignado', 'success');
                    getArticulo();
                    getVariantes();
                    setArticuloAsignar({});
                }
            );
        } else if (varianteTiendaAsignar > 0) {
            const varianteAAsignar = variantes.filter((item) => item.id == varianteTiendaAsignar);

            if (varianteAAsignar) {
                if (articuloAsignar.variante == 'N') {
                    varianteAAsignar[0].articulo_Padre_Id = articuloAsignar.id;
                    varianteAAsignar[0].variante_Padre_Id = null;
                } else varianteAAsignar[0].variante_Padre_Id = articuloAsignar.id;
            }
            articulo.articulo_Variantes = variantes;

            var artVarAsignar = {
                articulo_Id: varianteAAsignar[0].articulo_Id,
                variante_Id: varianteAAsignar[0].id,
                articulo_Principal_Id: varianteAAsignar[0].articulo_Padre_Id,
                variante_Principal_Id: varianteAAsignar[0].variante_Padre_Id
            };

            fetchConToken(`articulos/AsignarArticuloVariante`, artVarAsignar, 'PUT').then(
                (response) => {
                    if (response.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }
                    Swal.fire('Asignación completa', 'Articulo Asignado', 'success');
                    getArticulo();
                    getVariantes();
                    setArticuloAsignar({});
                }
            );
        }
    }

    return (<>
        <div className='container-fluid'>
            <div className='col-md-12 text-right'>
                <Link to={'..'} className='btn btn-outline-warning'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='15'
                        height='15'
                        fill='currentColor'
                        className='bi bi-reply'
                        viewBox='0 0 16 16'
                    >
                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                    </svg>
                    &nbsp; Regresar
                </Link>
            </div>
            <div className='col-md-12'>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center' colSpan={5}><h3>Articulo Conexión</h3></TableCell>
                                <TableCell align='center' colSpan={6}><h3>Articulo Conexión Principal</h3></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>SKU</TableCell>
                                <TableCell align='center'>Id Publicacion</TableCell>
                                <TableCell align='center'>Nombre</TableCell>
                                <TableCell align='center'>Precio Publicado</TableCell>
                                <TableCell align='center'>Existencia Publicado</TableCell>
                                <TableCell align='center'>SKU</TableCell>
                                <TableCell align='center'>Id Publicacion</TableCell>
                                <TableCell align='center'>Nombre</TableCell>
                                <TableCell align='center'>Precio Publicado</TableCell>
                                <TableCell align='center'>Existencia Publicado</TableCell>
                                <TableCell align='center'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align='center'>{articulo?.sku}</TableCell>
                                <TableCell align='center'>{articulo?.clave}</TableCell>
                                <TableCell align='left'>{articulo?.nombre}</TableCell>
                                <TableCell align='right'>{currency(articulo?.precio_Base)}</TableCell>
                                <TableCell align='center'>{articulo?.existencias}</TableCell>
                                <TableCell>{articuloPadre?.sku}</TableCell>
                                <TableCell align='center'>{articuloPadre?.clave}</TableCell>
                                <TableCell align='left'>{articuloPadre?.nombre}</TableCell>
                                <TableCell align='right'>
                                    {articuloPadre
                                        ? currency(
                                            articuloPadre?.precio ||
                                            articuloPadre?.precio_Base
                                        )
                                        : ''}
                                </TableCell>
                                <TableCell align='center'>{articuloPadre?.existencias}</TableCell>
                                <TableCell align='right'>
                                    <Button
                                        variant='contained'
                                        onClick={() => seleccionArticuloTienda(articulo?.id)}
                                    >
                                        Asignar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {variantes?.length === 0 ? (
                ''
            ) : (
                <div className='col-md-12'>
                    <br></br>
                    <h4>Variantes</h4>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' colSpan={5}>
                                        Variante Conexión
                                    </TableCell>
                                    <TableCell align='center' colSpan={6}>
                                        Variante Conexión Principal
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>SKU</TableCell>
                                    <TableCell align='center'>Id Publicacion</TableCell>
                                    <TableCell align='center'>Nombre</TableCell>
                                    <TableCell align='center'>Precio Publicado</TableCell>
                                    <TableCell align='center'>Existencia Publicado</TableCell>
                                    <TableCell align='center'>SKU</TableCell>
                                    <TableCell align='center'>Id Publicacion</TableCell>
                                    <TableCell align='center'>Nombre</TableCell>
                                    <TableCell align='center'>Precio</TableCell>
                                    <TableCell align='center'>Existencia</TableCell>
                                    <TableCell align='center'></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {variantes?.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 }
                                        }}
                                    >
                                        <TableCell component='th' scope='row'>
                                            {row.sku}
                                        </TableCell>
                                        <TableCell align='center'>{row.clave}</TableCell>
                                        <TableCell align='left'>{row.nombre}</TableCell>
                                        <TableCell align='right'>
                                            {currency(row.precio)}
                                        </TableCell>
                                        <TableCell align='center'>{row.existencias}</TableCell>
                                        <TableCell align='center'>
                                            {row.padre?.sku || ''}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {row.padre?.clave || ''}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {row.padre?.nombre || ''}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.padre
                                                ? currency(
                                                    row.padre?.precio ||
                                                    row.padre?.precio_Base
                                                )
                                                : ''}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {row.padre?.existencias || ''}
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Button
                                                variant='contained'
                                                onClick={() => seleccionVarianteTienda(row.id)}
                                            >
                                                Asignar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </div>
        <Modal isOpen={isOpenModal} closeModal={closeModal}>
            <div className='card text-left' style={{ padding: 10 }}>
                <div className='container'>
                    <div>
                        <div className='espacio15px'></div>
                        <div className='card-title row  '>
                            <div className='col-md-6 text-left'>
                                <h3><label>&nbsp;&nbsp;Seleccione el articulo a ligar</label></h3>
                            </div>
                            <div className='col-md-6 text-right'>
                                <Button variant='contained' onClick={() => AsignarArticulo()}>
                                    Asignar
                                </Button>&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>
                        <div className='espacio15px'></div>
                        <div className='card-body ' style={{ height: '50vh' }}>
                            <AsyncSelect
                                isClearable
                                className='react-select-placeholder'
                                getOptionLabel={(option) => option.nombre}
                                getOptionValue={(option) => option.id}
                                loadOptions={loadArticulos}
                                onChange={(selectedItem) => { getArticuloSeleccionado(selectedItem); }}
                                value={articuloAsignar}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>);
};

export { Asignacion };
