import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getDataSession } from 'views/Utils/Common';
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';

import { useGlobalState } from 'resources/GlobalStateContext';
import Swal from 'sweetalert2';

const AddEdit = ({ history, match }) => {
    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;
    const { handleSubmit } = useForm();
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
       

    const [model, setModel] = useState({
        id: 0,
        nombre: "",
        sucursal_Id: getDataSession('sucursal'),
        tipo: 'I'
    });

    function onSubmit() {
        return isAddMode ? create(model) : update(id, model);
    }

    function create(data) {
        return fetchConToken('VxRazonVist', data, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }
                Swal.fire('Exito', 'Guardado', 'success');
                history.push('.');
            })
            .catch(alertService.error);
    }

    function update(id, data) {
        return fetchConToken('VxRazonVist', data, 'PUT')
        .then((response) => {
            if (response.hasOwnProperty('status')) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Guardado', 'success');
                history.push('..');
            
            })
            .catch(alertService.error);
    }

    useEffect(() => {
        if (!isAddMode) {
            fetchConToken(`VxRazonVist?id=${id}`).then(
                (clavel) => {
                    setModel(clavel);
                }
            );
        }
    }, []);

    const handleInputChange = ({ target }) => {
        setModel({
            ...model,
            [target.name]: target.type === 'checkbox' ? target.checked === 'true' : target.value
        });
    }
    
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo:isAddMode ? 'Agregar Razon de Visita' : 'Editar Razon de Visita',
          subtitulo: ''
        });
      };
      React.useEffect(() => {actualizarTitulo();},[]);
    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className='mb-3'>
                        <div className='row' style={{ textAlign: 'left' }}>
                           
                        <div className='col-md-12' style={{ textAlign: 'left' }}>
                                <button className='btn btn-outline-success' type='submit'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-check-lg'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                    </svg>
                                    &nbsp; Guardar
                                </button>
                                &nbsp;
                                <Link
                                    to={isAddMode ? '.' : '..'}
                                    className='btn btn-outline-warning'
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                    &nbsp; Regresar &nbsp;
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
  
                                    <label htmlFor='nombre' className='form-label'>
                                        Nombre
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        required={true}
                                        maxLength={100}
                                        value={model.nombre}
                                        onChange={handleInputChange}
                                    />              
                                   

                                   <label htmlFor='tipo' className='form-label'>
                                        Tipo
                                    </label>
                                    <select className='form-control' name='tipo' onChange={handleInputChange}>
                                        {model.tipo == 'I' ? <option value="I" selected>Inefectiva</option> : <option value="I" >Inefectiva</option>}
                                        {model.tipo == 'E' ? <option value="E" selected>Efectiva</option> : <option value="E" >Efectiva</option>}

                                    </select>        
                                   

                                </div>
                            </div>
                        </div>
                       
                    </div>
                </form>
            </div>
        </>
    );
};

export { AddEdit };
