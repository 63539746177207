import React from 'react';
import { any, arrayOf, func, string } from 'prop-types';
import { Column, Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import CollapsibleContent from 'components/collapsible/CollapsibleContent';
import { useSidebar } from 'hooks/useSidebar';
import TopProveedores from 'images/panel/menu/menu/ic_top_proveedores.svg';

const useStyles = createUseStyles({
    activeContainer: {
        backgroundColor: ({ theme }) => 'rgb(69 60 125)',/*theme.color.paleBlueTransparent*/
        opacity: ({ theme, isActive }) => (isActive ? '1' : '0.6'),
    },
    container: {
        display: 'flex',
        height: 30,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: ({ theme }) => 'rgb(74 94 207 / 20%)'/*theme.color.paleBlueTransparent*/
        },
        //paddingLeft: 70 ,//({ level }) => 10 * level,
        transition: 'all 0.2s ease-in-out',
        //borderLeft: '1px solid rgba(208, 213, 221, 1)',
        marginLeft: 35,
        opacity: ({ theme, isActive }) => (isActive ? '1' : '0.6'),
    },
    /*leftBar: {
        borderLeft: ({ theme, level }) =>
            level > 1 ? 'none' : `3px solid ${theme.color.darkGrayishBlue}`
    },*/
    title: {
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '20px',
        letterSpacing: '1.2px',
        color: ({ theme, isActive }) => (isActive ? '#01295F;'/*'black'*/ : 'rgba(1, 41, 95, 1)'/* theme.color.grayishBlue*/),
        marginLeft: 10
    }
});

function SubMenuItemComponent({ children, icon: Icon, id, items = [], level = 1, onClick, title }) {
    const theme = useTheme();
    const isCollapsible = children && children.length > 0;
    const { isExpanded, isActive, onItemClick } = useSidebar({
        isCollapsible,
        item: id,
        items
    });
    const classes = useStyles({ theme, level, isActive });
    const classNameColumn = isActive ? classes.leftBar : '';
    const classNameContainer = [classes.container, isActive && classes.activeContainer].join(' ');
    const iconColor = isActive ? '#2ED28A' : '#44C2FA';

    function onItemClicked(e) {
        if (onClick) {
            onClick(e);
            console.log(e);
        }
        onItemClick();
    }

    return (
        <Column key={id} className={classNameColumn} >
            <Row vertical='center' onClick={onItemClicked} className={classNameContainer}>
                <Icon fill={iconColor} opacity={!isActive && '1'} />
                <span className={classes.title} style={{ marginLeft: "20px" }}>{title}</span>
            </Row>
            {isCollapsible && (
                <CollapsibleContent expanded={isExpanded}>
                    {children.map((child) => child.type({ ...child.props }))}
                </CollapsibleContent>
            )}
        </Column>
    );
}

SubMenuItemComponent.defaultProps = {};

SubMenuItemComponent.propTypes = {
    children: any,
    icon: func,
    id: string,
    onClick: func,
    items: arrayOf(string),
    title: string
};

export default SubMenuItemComponent;
