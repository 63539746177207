/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import WarningIcon from '@mui/icons-material/Warning';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import RateReviewIcon from '@mui/icons-material/RateReview';
import TaskIcon from '@mui/icons-material/Task';
import DescriptionIcon from '@mui/icons-material/Description';
import BallotIcon from '@mui/icons-material/Ballot';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InfoIcon from '@mui/icons-material/Info';
import TerminalIcon from '@mui/icons-material/Terminal';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import { GaleriaImg, AddImg } from '../ListImg';
import { GeneralForm } from './GeneralForm';
import { FormCategoria } from '../CategoriaList';
import { FormFichhaTecnica } from '../FichaTecnica';
import { Costos } from '../Costos';
import { FormPublicador } from './FormPublicador';

const TablePublicador = (props) => {
    return (
        <>
            {props.loading && (
                <div className='card row' style={{ height: '10vw', background: '#F5F5F5' }}>
                    <div style={{ height: '4vw' }} />
                    <div className='col-md-12 row'>
                        <div className='col-sm-5' />
                        <div className='col-sm-1' style={{ display: 'grid' }}><div className='card-body text-start'><CircularProgress /></div></div>
                        <div className='col-sm-5' />
                    </div>
                </div>
            )}
            {!props.loading && props.items?.map((item) => { return (<RowPublicador key={item.id} item={item} tienda={props.tienda} checked={props.check} onCountCheckdChange={props.onCountCheckdChange} onDeleteItem={props.onDeleteItem} />); })}
            {props.pagination && (
                <TablePagination
                    rowsPerPageOptions={props.rowsPerPageOptions ?? [5, 10, 25, 50, 100]}
                    component="div"
                    count={props.count ?? 0}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onPageChange={props.handleChangePage}
                    onRowsPerPageChange={props.handleChangeRowsPerPage}
                />
            )}
        </>
    );
}

const RowPublicador = (props) => {
    const [chekcd, setCheckd] = useState(false);
    const handleChange = (event) => {
        setCheckd(event.target.checked);
        props.onCountCheckdChange(event);
        props.item.checked = event.target.checked;
    }

    useEffect(() => {
        props.item.checked = props.checked !== 'T' ? props.checked === 'A' : false;
        if (props.checked !== 'T') setCheckd(props.checked === 'A');
        else setCheckd(false);
    }, [props.checked])

    return (
        <>
            <div className='card' style={{ background: '#F5F5F5' }}>
                <div className='col-md-12 row'>
                    <div className='col-md-2 row'>
                        <div className='col-md-1' />
                        <div className='col-md-3 container-center'><Checkbox checked={chekcd} onChange={handleChange} /></div>
                        <div className='col-md-7 container-center'><Img item={props.item} /></div>
                    </div>
                    <div className='col-md-7' style={{ display: 'grid' }}><Info item={props.item} /></div>
                    <div className='col-md-2' style={{ display: 'grid' }}><LogBasic item={props.item} /></div>
                    <div className='col-md-1 container-center'><MenuRow item={props.item} tienda={props.tienda} onDeleteItem={props.onDeleteItem} /></div>
                </div>
            </ div>
            <div className='espacio10px' />
        </>
    );
}

const Img = ({ item }) => {
    const [portada, setPortada] = useState(null);
    useEffect(() => { if (item.imagenes && item.imagenes.length > 0) setPortada(item.imagenes[0]); }, [])

    return (
        <div className='img_art_card'>
            <div className='espacio_img' />
            {portada ? (<img src={portada?.urlImagen ?? 'http://http2.mlstatic.com/D_830187-MLM52064542416_102022-O.jpg'} alt='http://http2.mlstatic.com/D_830187-MLM52064542416_102022-O.jpg' className='card' />) : (<AddImg />)}
        </div>
    );
}

const Info = ({ item }) => {
    const { t } = useTranslation("common");
    const [categoria, setCategoria] = useState('');
    const loadDataCategoria = async (query) => await fetchConToken(`Categorias/GetCategoriByHijo?categoria=${query}`).then((resp) => {
        setCategoria(GetNombreCategoria(resp));
    }).catch(() => { setCategoria('Sin categoria') });

    const GetNombreCategoria = (categoria) => {
        let aux = categoria.nombre;
        if (categoria.sub_Categoria && categoria.sub_Categoria.length > 0) aux = `${aux} > ${GetNombreCategoria(categoria.sub_Categoria[0])}`;

        return aux;
    }

    const GetTipoPublicacion = () => {
        switch (item.tipo_Publicacion) {
            case 'gold_pro': return t('app.Articulos.form.tipoPublicacionGoldPro');
            case 'gold_special': return t('app.Articulos.form.tipoPublicacionGoldSp');
            case 'free': return t('app.Articulos.form.tipoPublicacionFree');
            default: return item.tipo_Publicacion;
        }
    }

    useEffect(() => { loadDataCategoria(item.clave_Categoria); }, [])

    return (
        <div style={{ display: 'grid' }}>
            <label className='form-label'>{item.nombre}</label>
            <label style={{ fontSize: 'small' }}>{categoria}</label>
            <div className='row'>
                <div className='col-sm-3 text-center'><label className='text-card'>SKU:{item.sku}</label></div>
                <div className='col-sm-3 text-center'><label className='text-card'>{item.existencias} en stock</label></div>
                <div className='col-sm-3 text-center'><label className='text-card'>${item.costo_Unidad}</label></div>
                <div className='col-sm-3 text-center'><label className='text-card'>{GetTipoPublicacion()}</label></div>
            </div>
            <div className='espacio5px' />
        </div>
    );
}

const LogBasic = ({ item }) => {
    return (
        <>
            <LogBasicRow title='Imagenes' status='fail' />
            <LogBasicRow title='Descripcion' status='success' />
            <LogBasicRow title='Categorizacion' status='fail' />
            <LogBasicRow title='Atributos' status='warning' />
            <div className='espacio5px' />
        </>
    );
}

const LogBasicRow = ({ title, status }) => {
    const StatusIcon = () => {
        switch (status?.toUpperCase()) {
            case 'FAIL': return (<ClearIcon style={{ color: 'red' }} />);
            case 'SUCCESS': return (<DoneIcon style={{ color: 'green' }} />);
            case 'WARNING': return (<WarningIcon style={{ color: 'gold' }} />);
            default: return (<LinkOffIcon />);
        }
    }

    return (
        <div className='row'>
            <div className='col-md-12 row'>
                <div className='col-sm-6'><label className='text-card'>{title}</label></div>
                <div className='col-sm-6 text-right'  style={{ display: 'none' }}><label className='text-card'>{StatusIcon()}</label></div>
            </div>
        </div>
    );
}

const MenuRow = ({ item, onDeleteItem, tienda }) => {
    const { t } = useTranslation("common");
    const [keyModal, setKeyModal] = useState('general');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const open = Boolean(anchorEl);
    const GetModalByMenu = () => {
        switch (keyModal) {
            case 'detalles': return (<FormPublicador id={item.id} tienda={tienda} sucursalId={item.sucursal_Id} onClose={closeModal} />);
            case 'imagenes': return (<GaleriaImg data={item.imagenes} isAdd={true} isEdit={true} handleChangeAddImg={onclick} />);
            case 'descripcion': return (<FormDescripcion item={item} />);
            case 'categoria': return (<FormCategoria data={item} />);
            case 'atributos': return (<FormFichhaTecnica data={item} />);
            case 'costos': return (<Costos data={item} imagenes={item.imagenes} />);
            case 'variantes':
            default: return (<GeneralForm data={item} />);
        }
    }

    function onclick(id) {
        setAnchorEl(null);
        if (id !== 'eliminar') {
            setKeyModal(id);
            openModal();
        }
        else onDeleteItem(item.id);
    }

    return (
        <>
            <IconButton onClick={((e) => setAnchorEl(e.currentTarget))}><MoreVertIcon /></IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={(() => setAnchorEl(null))}
                MenuListProps={{ 'aria-labelledby': 'basic-button', }}
            >
                <MenuItem id='detalles' onClick={((e) => onclick('detalles'))}><ListItemIcon><InfoIcon fontSize="small" /></ListItemIcon>Ver Detalles</MenuItem>
                <MenuItem id='general' onClick={((e) => onclick('general'))}><ListItemIcon><RateReviewIcon fontSize="small" /></ListItemIcon>Edicion General</MenuItem>
                <MenuItem id='imagenes' onClick={((e) => onclick('imagenes'))}><ListItemIcon><BurstModeIcon fontSize="small" /></ListItemIcon>Editar Imagenes</MenuItem>
                <MenuItem id='descripcion' onClick={((e) => onclick('descripcion'))}><ListItemIcon><DescriptionIcon fontSize="small" /></ListItemIcon>Editar Descripcion</MenuItem>
                <MenuItem id='categoria' onClick={((e) => onclick('categoria'))}><ListItemIcon><TaskIcon fontSize="small" /></ListItemIcon>Editar Categoria</MenuItem>
                <MenuItem id='atributos' onClick={((e) => onclick('atributos'))}><ListItemIcon><BallotIcon fontSize="small" /></ListItemIcon>Editar Atributos</MenuItem>
                <MenuItem id='costos' onClick={((e) => onclick('costos'))}><ListItemIcon><AttachMoneyIcon fontSize="small" /></ListItemIcon>Editar Costos</MenuItem>
                <MenuItem id='variantes' onClick={((e) => onclick('variantes'))}><ListItemIcon><ViewQuiltIcon fontSize="small" /></ListItemIcon>Editar Variantes</MenuItem>
                <MenuItem id='envio_gratis' onClick={((e) => onclick('envio_gratis'))}><ListItemIcon><LocalShippingIcon fontSize="small" /></ListItemIcon>Establecer envio gratis</MenuItem>
                <MenuItem id='eliminar' onClick={((e) => onclick('eliminar'))}><ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon>Eliminar </MenuItem>
                <MenuItem id='log' onClick={((e) => onclick('log'))}><ListItemIcon><TerminalIcon fontSize="small" /></ListItemIcon>Ver Log</MenuItem>
            </Menu>
            <Modal isOpen={isOpenModal} closeModal={closeModal} max>{GetModalByMenu()}</Modal>
        </>
    );
}

const FormDescripcion = ({ item }) => {
    const { t } = useTranslation("common");

    return (<>
        <label htmlFor='descripcion' className='form-label'>{t('app.Articulos.form.descripcionArt')}*</label>
        <textarea className='form-control' name='descripcion' required={true} maxLength={100} rows="8" value={item.descripcion || ''} />
    </>);
}

export { TablePublicador };