import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getDataSession } from 'views/Utils/Common';
import TituloPagina from 'components/header/TituloPagina';
import { useGlobalState } from 'resources/GlobalStateContext';
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';
    const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();

    const { compras } = match.params;
    let TipoDocto =  useState('C');
    let Docto =  useState('C');

    switch(compras)
    {
        case 'ordenesCompras':
            TipoDocto = 'O';
            Docto = 'Ordenes compras';
            break;
        case 'recepcionCompra':
            TipoDocto = 'R';
            Docto = 'Recepciones de compras';
            break;
        default:
            TipoDocto = 'C';
            Docto = 'Compras';
            break;
    }


    const columns = [
        {renderCell: RenderButtons ,headerName: '', maxWidth: 20 , path: path.replace(':compras',compras),opciones: "editar",width: 5 },
        { field: 'id', headerName: 'Id', width: 70 },
        { field: 'folio', headerName: 'Folio', flex: 1},
        { field: 'fecha', headerName: 'Fecha', flex: 1, renderCell: ({ value }) => value?.substring(0,10)},
        { field: 'proveedor_Nombre', headerName: 'Proveedor', flex: 1},
        { field: 'folio_Prov', headerName: 'Folio Prov.', flex: 1}
        
    ];
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: Docto,
          subtitulo: `Listado de ${Docto} por sucursal.`
        });
      };
      React.useEffect(() => actualizarTitulo(),[Docto]);
    return (
        <>
            <div className='container-fluid'>
                <div className='col-md-12'>
                    <div className='col-md-12'>
                    <Link to={`${ compras }/add`} className='btn btn-outline-primary'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; Nuevo
                        </Link>
                        &nbsp; &nbsp;
                    </div>
                </div>

                <div style={{ height: 400, width: '100%' }}>
                    <ListTable
                        columns = {columns}
                        path = {path}
                        url={`VxCompras/GetList?sucursal_Id=${getDataSession('sucursal')}&tipo_docto=${TipoDocto}`}
                    />
                </div>

            </div>
        </>
    );
};



export { List };
