import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getDataSession } from 'views/Utils/Common';
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import AsyncSelect from 'react-select/async';
import { useGlobalState } from 'resources/GlobalStateContext';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import moment from 'moment';

import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import { currency } from 'resources/utilities';

const AddEdit = ({ history, match }) => {
    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;
    const { handleSubmit } = useForm();
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [isOpenModal, openModal, closeModal] = useModal(false);

    //select2 Agente
    const [initialAgente, setInitialAgente] = useState(true);
    //select2 Grupo Rutas
    const [initialGrupoRutas, setInitialGrupoRutas] = useState(true);
    //select2 Clientes
    const [initialClientes, setInitialClientes] = useState(true);

    const [conceptoPago, setConceptoPago] = useState([]);
    const [condicionPago, setCondicionPago] = useState([]);
    const [documentosAdeudo, setDocumentosAdeudo] = useState([]);
    const [formasCobro, setFormasCobro] = useState([]);
    


    let [deudaAux, setDeudaAux] = useState(0);
    let [vxImporteCP, setVxImporteCP] = useState([]);
    let [vxImporteCPDet, setVxImporteCPDet] = useState({
         id: 0,
         documento_CP_Id: 0,
         fecha:null,
         cancelado:'N',
         aplicado: 'S',
         estatus:'T',
         tipo_Importe: 'P',
         documento_CP_Acr_Id: 0,
         importe: 0,
         impuesto:0,
         iva_Retenido: 0,
         isr_Retenido: 0,
         dscto_Pago:0,
         doc_Deuda: "",
         liquidado: 'S'
    });

    let [model, setModel] = useState({
        id: 0,
        sucursal_Id: getDataSession('sucursal'),
        conceptos_Id: 0,
        condicion_Pago_Id: 0,
        proveedor_Id: 0,
        compra_Id: 0,
        folio_Prov: "",
        concepto: "PAGO",
        naturaleza_Concepto: "R",
        descripcion: "",
        pctje_Dscto_Ppag: 0.000000,
        importe: 0.00,
        cancelado: "N",
        aplicado: "S",
        liquidado: "S",
        fecha: "",
        folio_Concepto: null,
        concepto_Cobro: "CONTADO"
    });

    
    useEffect(() => {

        getConceptoPago();
        getFormasCobro();

        setModel({
            ...model,
            ["fecha"]: GetFecha(true)
        });
        setVxImporteCPDet({
            ...vxImporteCPDet,
            ["fecha"]: GetFecha(true)
        });

    }, []);

    async function getConceptoPago() {
        const response = await fetchConToken(`VxConceptosPagos/GetList?sucursal_Id=${getDataSession('sucursal')}&paginas=0&totalXpagina=200`);
        setConceptoPago(response.data);
    }
    async function getFormasCobro() {
        const response = await fetchConToken(`VxFormaCobroCc/GetList?paginas=0&totalXpagina=1000&sucursal_Id=${getDataSession('sucursal')}`);
        setFormasCobro(response.data);
    }

    function GetFecha(inicio) {
        var curr = new Date();
        return curr.toISOString().substring(0, 10);
    }
    

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Registrar Abono',
          subtitulo: ''
        });
      };
    
    React.useEffect(() => {actualizarTitulo();},[]);

   
    const loadProveedor = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `VxProveedor/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`
        );
        return resp.data;
    };
    
    function BuscarDocumentos(){
        if(!(model.proveedor_Id > 0) )
            {
                Swal.fire("Info","Seleccione un proveedor valido","info");
                return;
            }
            if(model.fecha == null )
                {
                    Swal.fire("Info","Seleccione una fecha valida","info");
                    return;
                }


        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        fetchConToken(
            `VxDocumentosCP/GetList?paginas=0&totalXpagina=1000&sucursal_Id=${getDataSession('sucursal')}&liquidado=N&naturaleza_Concepto=C&proveedor_Id=${model.proveedor_Id}`
        ).then(resp=>{
            setDocumentosAdeudo(resp.data);
        });
        
        openModal();
    };


    const loadDocumentosAdeudo = async (query) => {
        if(!(model.proveedor_Id > 0) )
            {
                Swal.fire("Info","Seleccione un proveedor valido","info");
                return;
            }
            if(model.fecha == null )
                {
                    Swal.fire("Info","Seleccione una fecha valida","info");
                    return;
                }


        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `VxDocumentosCP/GetList?paginas=0&totalXpagina=500&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}&liquidado=N&naturaleza_Concepto=C&proveedor_Id=${model.proveedor_Id}`
        );
        return resp.data;
    };


    function onSubmit() {
        if(!(model.proveedor_Id > 0))
            {
                Swal.fire('Info', "Seleccione un agente valido", 'info');
                return;
            }
          
            if(vxImporteCP.length == 0)
                {
                        Swal.fire('Info', "Agrege al menos un detalle", 'info');
                        return;
                }
    
                model.vx_Importe_CP = vxImporteCP;

        return create(model);
    }
    function create(data) {
        return fetchConToken('VxDocumentosCP', data, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }
                Swal.fire('Exito', 'Guardado', 'success');
                history.push('.');
            })
            .catch(alertService.error);
    }




    const handleInputChange = ({ target }) => {
        setModel({
            ...model,
            [target.name]: target.type === 'checkbox' ? target.checked === true : target.value
        });
    }

    const handleInputChangeDetalle = ({ target }) => {

        setVxImporteCPDet({
            ...vxImporteCPDet,
            [target.name]: target.type === 'checkbox' ? target.checked === 'true' : target.value
        });
    }

    
    function seleccionar(item) {
        setVxImporteCPDet({
            ...vxImporteCPDet,
            ["doc_Deuda"]:  (item.folio + ' ~ ' + item.concepto + ' ~ ' + item.fecha.substring(0,10) + ' ~ ' + currency(item.importe-item.acreditado)),
            ["documento_CP_Id"]: item?.id
        });
           
            setDeudaAux(item.importe-item.acreditado);
            closeModal();
     }

    function guardarDetalle() {

        if(!(model.proveedor_Id > 0) )
            {
                Swal.fire("Info","Seleccione un cliente valido","info");
                return;
            }
            if(model.fecha == null )
                {
                    Swal.fire("Info","Seleccione una fecha valida","info");
                    return;
                }

                if(vxImporteCPDet.importe <= 0)
                {
                    Swal.fire("Info","Escriba un importe valido","info");
                    return;
                }

                console.log(vxImporteCPDet.importe);
                console.log(deudaAux);
                if(vxImporteCPDet.importe > deudaAux)
                    {
                        Swal.fire("Revisar","El importe es mayor al adeudo","info");
                        return;
                    }

        if(vxImporteCP.filter(x=>x.fecha == vxImporteCPDet.fecha && x.documento_CP_Id == vxImporteCPDet.documento_CP_Id).length > 0)
        {
            Swal.fire("Info","Ya existe registrado el cobro del documento","info");
            return;
        }

        setVxImporteCP([...vxImporteCP,vxImporteCPDet]);
     }
     
    
     function eliminar(item) {
        const detallesNuevos = vxImporteCP.filter(x => !(parseInt(x.id) === parseInt(item.id) &&  parseInt(x.documento_CP_Id) === parseInt(item.documento_CP_Id)));
        setVxImporteCP(detallesNuevos);
     }

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className='mb-3'>
                        <div className='row' style={{ textAlign: 'left' }}>
                           
                        <div className='col-md-12' style={{ textAlign: 'left' }}>
                                <button className='btn btn-outline-success' type='submit'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-check-lg'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                    </svg>
                                    &nbsp; Guardar
                                </button>
                                &nbsp;
                                <Link
                                    to={isAddMode ? '.' : '..'}
                                    className='btn btn-outline-warning'
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                    &nbsp; Regresar &nbsp;
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>

                                    
                                    <label htmlFor='proveedor' className='form-label'>
                                        Proveedor
                                    </label>
                                    <AsyncSelect
                                    className='react-select-placeholder'
                                        isClearable
                                        getOptionLabel={(option) =>
                                            option.rfc + '-' + option.nombre
                                        }
                                        getOptionValue={(option) => option.id}
                                        //defaultValue={initialObject.clave}
                                        loadOptions={loadProveedor}
                                        onChange={(selectedItem) => {
                                                setModel({
                                                    ...model,
                                                    ["proveedor_Id"]: selectedItem === 0 ? '' : selectedItem?.id
                                                });
                                                setVxImporteCP([]);
                                        }}
                                    />
                                      
                                      
                                        <label htmlFor='fecha' className='form-label'>
                                                Fecha
                                        </label>
                                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                                id="filled-number"
                                                type="date"
                                                name="fecha"
                                                className='form-control'
                                                value={model?.fecha?.substring(0, 10)}
                                                onChange={handleInputChange}
                                                InputLabelProps={{ shrink: true, }}
                                            />
                                

                                        <label htmlFor='Descripcion' className='form-label'>
                                                Descripcion
                                        </label>
                                        <textarea 
                                            className='form-control' 
                                            name='descripcion'  
                                            onChange={handleInputChange} 
                                            value={model.descripcion}/>

                                
                                        <label htmlFor='folio_Prov' className='form-label'>
                                        Folio Pago Proveedor
                                    </label>
                                <input
                                        type='text'
                                        className='form-control'
                                        name='folio_Prov'
                                        required={true}
                                        maxLength={9}
                                        value={model.folio_Prov}
                                        onChange={handleInputChange}
                                    />    
                                    </div>
                               
                            </div>
                        </div>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>

                   
                                                                      
                                    <label htmlFor='agente' className='form-label'>
                                        Concepto Pago
                                    </label>
                                    <select
                                                className='form-control'
                                                name='conceptos_Id'
                                                value={model.conceptos_Id}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                {conceptoPago?.map(c=>{
                                                    return  ( model.conceptos_Id === c.id ?  <option value={c.id} selected>{c.nombre}</option> :  <option value={c.id}>{c.nombre}</option> );
                                                })}
                                     </select> 

                                       <label htmlFor='forma_Cobro_Cc_Id' className='form-label'>
                                        Forma Cobro
                                        </label>  
                                        <select
                                                className='form-control'
                                                name='forma_Cobro_Cc_Id'
                                                value={model.forma_Cobro_Cc_Id}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                {formasCobro?.map(c=>{
                                                    return  ( model.forma_Cobro_Cc_Id === c.id ?  <option value={c.id} selected>{c.nombre}</option> :  <option value={c.id}>{c.nombre}</option>);
                                                })}
                                        </select> 

                                        
                                    <label htmlFor='num_Cta_Pago' className='form-label'>
                                    Numero de Cuenta de Pago
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='num_Cta_Pago'
                                        value={model.num_Cta_Pago}
                                        onChange={handleInputChange}
                                    />  

                                    
                                <label htmlFor='referencia' className='form-label'>
                                    Referencia
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='referencia'
                                        value={model.referencia}
                                        onChange={handleInputChange}
                                    />  
                   
                                
  
                                    </div>
                               
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='card mb-3'>
                                <div className='col-md-12 row'>
                                <div className='col-md-2'>
                                        <label htmlFor='fecha' className='form-label'>
                                                Fecha
                                        </label>
                                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                                id="filled-number"
                                                type="date"
                                                name="fecha"
                                                value={vxImporteCPDet.fecha?.substring(0, 10) || GetFecha(true)}
                                                onChange={handleInputChangeDetalle}
                                                InputLabelProps={{ shrink: true, }}
                                            />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='importe' className='form-label'>
                                         Importe
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        name='importe'
                                        required={true}
                                        maxLength={100}
                                        value={vxImporteCPDet.importe}
                                        onChange={handleInputChangeDetalle}
                                    />     
                                </div>
                                <div className='col-md-4'>
                                <label>Documento:</label>
                                    <label>{vxImporteCPDet.doc_Deuda}</label>
                                </div>

                              
                                <div className='col-md-2'>
                                    <div className='espacio30px' />
                                    <button
                                                    className='btn btn-outline-success'
                                                    onClick={(e) => {
                                                        BuscarDocumentos()
                                                    }}
                                                    type='button'
                                                >
                                                    &nbsp; Buscar Documentos
                                        </button>
                                </div>
                                <div className='col-md-2 '>
                                    <div className='espacio30px' />
                                        <button
                                                    className='btn btn-outline-success'
                                                    onClick={(e) => {
                                                        guardarDetalle()
                                                    }}
                                                    type='button'
                                                >
                                                    &nbsp; Agregar Abono
                                        </button>
                                </div>
                                </div>
                            </div>
               
                            <div className='card-body text-start'>
                            <div style={{ overflowX: 'auto' }}>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-text'>Fecha Pago</th>
                                                        <th className='text-left'>Doc. Adeudo</th>
                                                        <th className='text-left'>Importe Abono</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {vxImporteCP?.map((item) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td className='text-left'>
                                                                    {item.fecha?.substring(0, 10)}
                                                                </td>
                                                                <td className='text-left'>
                                                                    {item.doc_Deuda}
                                                                </td>
                                                                <td className='text-left'>
                                                                    {currency(item.importe)}
                                                                </td>
                                                                <td style={{ width: "50px"}} >
                                                                    <button
                                                                        type='button'
                                                                        className='btn  mr-1'
                                                                        onClick={() =>
                                                                            eliminar(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='25'
                                                                            height='25'
                                                                            fill='currentColor'
                                                                            className='bi bi-x-circle-fill'
                                                                            viewBox='0 0 16 16'
                                                                        >
                                                                            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z' />
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                
                                                </tbody>
                                            </table>
                                        </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal} >
                <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-text'>Seleccionar</th>
                                                        <th className='text-text'>Folio</th>
                                                        <th className='text-left'>Concepto</th>
                                                        <th className='text-left'>Fecha</th>
                                                        <th className='text-left'>Adeudo</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {documentosAdeudo?.map((item) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                 <td className='text-left'>
                                                                    <button
                                                                                className='btn btn-outline-success'
                                                                                onClick={(e) => {
                                                                                    seleccionar(item)
                                                                                }}
                                                                                type='button'
                                                                            >
                                                                                &nbsp;Seleccionar
                                                                    </button>
                                                                </td>
                                                                <td className='text-left'>
                                                                    {item.folio}
                                                                </td>
                                                                <td className='text-left'>
                                                                    {item.concepto}
                                                                </td>
                                                                <td className='text-left'>
                                                                    {item.fecha?.substring(0, 10)}
                                                                </td>
                                                                <td className='text-left'>
                                                                    {currency(item.importe-item.acreditado)}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                
                                                </tbody>
                                            </table>
            </Modal>
        </>
    );
};

export { AddEdit };
