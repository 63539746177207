/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession, setDataSession } from 'views/Utils/Common';
import { descargarFactura } from 'actions/archivos';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Swal from 'sweetalert2';
import { HubConnection, HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import { useTranslation } from "react-i18next";
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import shopify from 'images/panel/conexiones/shopify.png';
import tiendanube from 'images/panel/conexiones/tiendanube.png';
import wix from 'images/panel/conexiones/wix.png';
import woocommerce from 'images/panel/conexiones/woocommerce.png';
import amazon from 'images/panel/conexiones/amazon.png';
import mercadolibre from 'images/panel/conexiones/mercadolibre.png';
import AsyncSelect from 'react-select/async';
import SLUGS from 'resources/slugs';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListItemIcon from '@mui/material/ListItemIcon';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { ListVariante, Cargando } from './Variante';
import { currency } from 'resources/utilities';
import { useGlobalState } from 'resources/GlobalStateContext';
import { SelectorConexion } from './SelectorConexion';

const $ = require('jquery')
$.DataTable = require('datatables.net-select')

const List = ({ history, match, saludo, subtitulo }) => {
    const { path } = match;
    const { t } = useTranslation("common");
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [noDisponible,] = useState('NoDisponible');
    const [totalArticulos, setTotalArticulos] = useState([]);
    const [totalxPagina, setTotalxPagina] = useState(10);
    const [busqueda, setbusqueda] = useState('');
    let [descargaActiva, setDescargaActiva] = useState(false);
    let [articuloId, setArticuloId] = useState(0);
    let [dataArt, setDataArt] = useState(null);
    let [movimiento, setMovimiento] = useState(null);
    let [mensajeAsignacion, setMensajeAsignacion] = useState('');
    let [mensajeAsignacionVariante, setMensajeAsignacionVariante] = useState('');
    let [mensajeAsignacionMensaje, setMensajeAsignacionMensaje] = useState('');
    let [data, setData] = useState([]);
    let [error, setError] = useState(['']);
    let [pagina, setPagina] = useState('');
    let [actualizarTabla, setActualizarTabla] = useState(false);
    let [CodigoArticulo, setCodigoArticulo] = useState('');
    let [sucursalesList, setSucursalesList] = useState(['']);
    let [archivo, setArchivo] = useState('');
    let [sucursalIdOri, setSucursalIdOri] = useState(getDataSession('sucursal'));
    let [sucursalIdDes, setSucursalIdDes] = useState(getDataSession('sucursal'));
    let [mensajeArticulosDescargados, setMensajeArticulosDescargados] = useState('');
    const [isOpenModalMarketPlace, openModalMarketPlace, closeModalMarketPlace] = useModal(false);
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState(false);
    const baseUrl = process.env.REACT_APP_API_URL;
    const [connection, setConnection] = useState(null);
    var [mensajeNotificacion, setMensajeNotificacion] = useState('');
    var [toast, setToast] = useState(false);
    let [sucursalPrincipal, setSucursalPrincipal] = useState({});
    const [sucursales, setSucursales] = useState([]);
    const [tienda, setTienda] = useState('');
    const [rolClavesTienda, setRolClavesTienda] = useState([]);
    const [rolClavesPrincipal, setRolClavesPrincipal] = useState([]);
    let [asignacion, setAsignacion] = useState({
        lista_clave_tienda_id: 0,
        lista_clave_principal_id: 0,
        articulo_tienda_id: 0,
        articulo_principal_id: 0,
        variante: false,
        automatica: true
    });
    let [filtro, setFiltro] = useState('T');
    let [status, setStatus] = useState('ACTIVOS');
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElAsignacion, setAnchorElAsignacion] = useState(null);
    const [anchorElHerramientas, setAnchorElHerramientas] = useState(null);
    const open = Boolean(anchorEl);
    const openAsignacion = Boolean(anchorElAsignacion);
    const openHerramientas = Boolean(anchorElHerramientas);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClickAsignacion = (event) => { setAnchorElAsignacion(event.currentTarget); };
    const handleClickHerramientas = (event) => { setAnchorElHerramientas(event.currentTarget); };
    const handleClose = () => {
        setAnchorEl(null);
        setAnchorElHerramientas(null);
        setAnchorElAsignacion(null);
    };

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: t('app.Articulos.lista.titulo'),
            subtitulo: t('app.Articulos.lista.descripcion')
        });
    };

    async function getData(page) {
        setPagina(page);
        const response = await fetchConToken(
            `Articulos/GetList?empresa_id=${getDataSession('empresa')}&sucursal_id=${getDataSession(
                'sucursal'
            )}&paginas=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda}`
        );
        setData(response);
    }

    const handleInputChange = ({ target }) => {
        setCodigoArticulo(target.value);
    };

    const DescargarTienda = () => {
        Swal.fire({
            text: t('app.Articulos.descargarArticulos?'),
            showCancelButton: true,
            confirmButtonText: t('app.botones.descargar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                setDescargaActiva(true);
                fetchConToken(
                    `Articulos/DescargarArticulos?empresa_id=${getDataSession('empresa')}&sucursal_id=${getDataSession('sucursal')}`,
                    null,
                    'POST'
                ).then(function (response) {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                        });
                        return;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return;
                    }
                    Swal.fire(t('app.Articulos.SwalFire.descargando'), t('app.Articulos.SwalFire.seEstaDescargando'), 'info');
                });
            }
        });
    };

    async function eliminar(id) {
        Swal.fire({
            text: "De donde desea eliminar el articulo?",
            showCancelButton: true,
            confirmButtonText: 'Microzync',
            showDenyButton: true,
            denyButtonText: 'Ecommerce y Microzync',
            cancelButtonText: "Cancelar"
        }).then((result) => {
            var eliminar = false;
            var eliminarMk = false;
            if (result.isConfirmed) {
                eliminar = true;
                eliminarMk = false;
            }
            else if (result.isDenied) {
                eliminar = true;
                eliminarMk = true;
            }
            if (eliminar) {
                fetchConToken(`Articulos?articulo_id=${id}${eliminarMk ? "&eliminarmk=true" : ''}`, null, 'DELETE').then(function (response) {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                        });
                        return;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return;
                    }
                    setActualizarTabla(true);
                    Swal.fire(t('app.SwalFire.eliminado'), t('app.Articulos.SwalFire.eliminado'), 'success');
                    setActualizarTabla(false);
                });
            }
        })
    }

    async function editar(id) {
        Window.location.href = `${path}/edit/${id}`;
    }

    async function TotalArticulosFactuxin() {
        const response = await fetchConToken(`Articulos/total?sucursal_id=${getDataSession('sucursal')}`);
        setMensajeArticulosDescargados(t("app.Articulos.articulosDescargados") + ":   " + (response.total ?? 0) + "/ " + (response.total_Ecommerce ?? 0));
    }

    function getArt(articulo_id) {
        if (articulo_id && articulo_id > 0) {
            setDataArt(null);
            fetchConToken(`articulos?id=${articulo_id}&sucursal_Id=${getDataSession('sucursal')}&conDetalle=true`).then(
                (art) => {
                    if (art.hasOwnProperty('status')) {
                        Object.entries(art?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (art.hasOwnProperty('codigo')) {
                        Swal.fire('Error', art.mensajes[0], 'error');
                        return;
                    }

                    setDataArt(art);
                }
            );
        }
    }

    React.useEffect(() => {
        const connect = new HubConnectionBuilder()
            .withUrl(baseUrl + "/notificacion", {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .build();
        setConnection(connect);
    }, []);

    React.useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(async () => {
                    await connection.invoke("Add", getDataSession('empresa') + "-" + getDataSession('sucursal'));
                    connection.on("ReceiveNotificacionDescarga", function (message) {
                        setMensajeArticulosDescargados(message);
                        setDescargaActiva(true);
                        setActualizarTabla(true);
                        setActualizarTabla(false);
                    });

                    connection.on("ReceiveNotificacionFinalizacion", function (message) {
                        setDescargaActiva(false);
                        TotalArticulosFactuxin();
                        setActualizarTabla(true);
                        setActualizarTabla(false);
                    });

                    connection.on("ReceiveNotificacionAsignacion", function (message) {
                        setMensajeAsignacion(message);
                        setActualizarTabla(true);
                        setActualizarTabla(false);
                    });
                    connection.on("ReceiveNotificacionAsignacionVariante", function (message) {
                        setMensajeAsignacionVariante(message);
                        setActualizarTabla(true);
                        setActualizarTabla(false);
                    });
                    connection.on("ReceiveNotificacionAsignacionTerminada", function (message) {
                        setMensajeAsignacionMensaje(message);
                        setActualizarTabla(true);
                        setActualizarTabla(false);
                    });

                })
                .catch((error) => console.log(error));
        }

    }, [connection]);

    React.useEffect(() => {
        getPrincipal();
        TotalArticulosFactuxin();
        getData(1);
        getSucursales();
        $('.dataTables_length').append("&nbsp; &nbsp;<label>Estatus:" +
            "<select class='select-filter-status' >" +
            "   <option value='Activos'>" + t('app.Articulos.filtros.activos') + "</option>" +
            "    <option value='Inactivos'>" + t('app.Articulos.filtros.inactivos') + "</option>" +
            "    <option value='Pendientes'>" + t('app.Articulos.filtros.pendientes') + "</option>" +
            "</select></label>");
        $('.dataTables_length').append("&nbsp; &nbsp;<label>" + t('app.Articulos.filtros.filtro') + ":" +
            "<select class='select-filter-filtro' >" +
            "    <option value='vacio'></option>" +
            "    <option value='SinClaveProdServ'>" + t('app.Articulos.filtros.sinClaveSat') + "</option>" +
            "    <option value='SinNoIdentificacion'>" + t('app.Articulos.filtros.sinNumIdenti') + "</option>" +
            "    <option value='Sinclaveunidad'>" + t('app.Articulos.filtros.sinClaveUnidad') + "</option>" +
            "    <option value='SinSKU'>" + t('app.Articulos.filtros.sinSku') + "</option>" +
            "    <option value=>" + t('app.Articulos.filtros.requiereRevision') + "</option>" +
            "</select></label>");
        $("body").on("change", ".select-filter-status", function () {
            var table = $('#table-js').DataTable();
            table.columns(1).search(this.value).draw();
        });

        $("body").on("change", ".select-filter-filtro", function () {
            var table = $('#table-js').DataTable();
            table.columns(2).search(this.value).draw();
        });
    }, []);

    React.useEffect(() => {
        actualizarTitulo();
    }, []);

    const getSucursales = async () => {
        const responseSucursal = await fetchConToken(`Sucursales/List`);
        setSucursalesList(responseSucursal);
    };

    const handleChangeBusqueda = ({ target }) => {
        setbusqueda(target.value);
    };

    const DescargarArticulo = () => {
        fetchConToken(`Articulos/DescargarArticulos?empresa_id=${getDataSession('empresa')}&sucursal_id=${getDataSession('sucursal')}&clave_articulo=${CodigoArticulo}`,
            null, 'POST').then(function (response) {
                getData(1);
                setError('');
                if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                    });
                    return;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return;
                }

                Swal.fire(t('app.SwalFire.exitoso'), t('app.Articulos.SwalFire.articuloDescargado'), 'success');
                setActualizarTabla(true);
                setActualizarTabla(false);
            });
    };

    const AsignacionAutomatica = () => {
        if (asignacion.lista_clave_tienda_id == 0) {
            Swal.fire('Info', 'Seleccione el rol de clave de la tienda', 'info');
            return;
        }
        if (asignacion.lista_clave_principal_id == 0) {
            Swal.fire('Info', 'Seleccione el rol de clave de la principal', 'info');
            return;
        }

        fetchConToken(`Articulos/AsignacionAutArticulos?sucursal_id=${getDataSession('sucursal')}&lista_clave_tienda_id=${asignacion.lista_clave_tienda_id}&lista_clave_principal_id=${asignacion.lista_clave_principal_id}`, null, 'PUT');
        Swal.fire('Sincronizacion Iniciada', '', 'success');
        setActualizarTabla(true);
        setActualizarTabla(false);
    };

    const getPrincipal = () => {
        fetchConToken(`Sucursales/Principal`).then(response => {
            setSucursalPrincipal(response);
        });
    }

    const AsignacionArticulo = () => {
        if (asignacion.automatica !== true)
            asignacion.automatica = asignacion.automatica == "true";

        fetchConToken(`Articulos/AsignarArticulo`, asignacion, 'PUT').then(response => {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }

            setActualizarTabla(true);
            Swal.fire('Asignación completa', response.articulo, 'success');
            setActualizarTabla(false);
        });
    };

    const actualizarCodigosXSucursal = () => {
        if (sucursalIdOri === sucursalIdDes) {
            Swal.fire(t('app.botones.atencion'), t('app.botones.seleccioneConexionesDif'), 'info');
            return;
        }

        Swal.fire({
            text: t('app.Articulos.actualizarCodigos?'),
            showCancelButton: true,
            confirmButtonText: t('app.botones.actualizar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                fetchConToken(
                    `articulos/ActualizarCodigosSucursal?empresa_id=${getDataSession(
                        'empresa'
                    )}&sucursal_ori=${sucursalIdOri}&sucursal_des=${sucursalIdDes}`,
                    true
                )
                    .then((response) => {
                        getData(1);
                        Swal.fire(t('app.SwalFire.procesando'), t('app.Articulos.actualizandoCodigos'), 'success');
                    })
                //.catch(alertService.error);
            }
        });
    };

    const actualizarCodigos = () => {
        const formData = new FormData();
        formData.append('empresa_id', getDataSession('empresa'));
        formData.append('sucursal_id', sucursalIdDes);
        formData.append('tipo', 'excel');
        formData.append('FileToUpload', archivo[0], archivo[0].name);
        fetchConToken('articulos/ActualizarCodigos', formData, 'POST', true);
        Swal.fire(t('app.SwalFire.procesando'), t('app.Articulos.actualizandoCodigos'), 'success');
        closeModal();
    };

    function handleChangeFiltros(e) {
        setFiltro(e.target.value);
    }

    function handleChangeStatus(e) {
        setStatus(e.target.value);
    }

    function handleChangeOri(e) {
        setSucursalIdOri(e.target.value);
    }

    function handleChangeDes(e) {
        setSucursalIdDes(e.target.value);
    }

    const handleInputChangeFileConfig = ({ target }) => {
        setArchivo(target.files);
    };

    function opcionModal(opcion) {
        if (opcion == 'AsignacionAutomatica') cargarRolesClaves();

        setModalValor(opcion);
        openModal();
    }

    const cargarRolesClaves = async () => {
        const responseSucursal = await fetchConToken(`RolesClaves/GetList?sucursal_Id=${getDataSession('sucursal')}`);
        setRolClavesTienda(responseSucursal);

        const responsePrincipal = await fetchConToken(`RolesClaves/GetListPrincipal`);
        setRolClavesPrincipal(responsePrincipal);
    };

    function descargarPlantilla() {
        descargarFactura(0, 'articulos/PlantillaActualizacion', "plantilla_carga_codigos_articulos.xlsx");
    }

    function descargarArticulos() {
        descargarFactura(0, `articulos/DescargaExcel?sucursal_id=${getDataSession('sucursal')}`, "articulos.xlsx");
    }

    function PublicadorMasivo(eCommerce) {
        if (noDisponible === eCommerce) {
            Swal.fire('Tienda no disponible');
            return;
        }

        history.push(`${SLUGS.articulos}/publicador/${eCommerce}`);
    }

    const duplicarArticulo = (articulo_id) => {
        setSucursales(null);
        getTiendaBySucursal();
        setArticuloId(articulo_id);
        setModalValor('duplicarArticulo');
        openModalMarketPlace();
    }

    const duplicarArticuloOnClick = () => {
        let sucursales_ids = sucursales?.filter(x => x.checked);
        if (!sucursales_ids || sucursales_ids.length === 0) {
            Swal.fire('Error', 'Debe tener seleccionado por lo menos una conexion', 'error');
            return;
        }

        genArticulos(sucursales_ids);
    }

    const handleInputAsignacion = ({ target }) => {
        setAsignacion({
            ...asignacion,
            [target.name]: target.value
        });
    };

    function asignarArticulo(articulo_id) {
        cargarRolesClaves();
        setAsignacion({
            ...asignacion,
            ['articulo_tienda_id']: articulo_id
        });

        setModalValor('AsignacionArticulo');
        openModal();
    }

    function verVentasArticulo(articulo_id) {
        if (articulo_id !== "0") setArticuloId(articulo_id);

        setModalValor('verVentasArticulo');
        setMovimiento('VENTA');
        openModal();
    }

    function verMovimientoArticulo(articulo_id) {
        setArticuloId(articulo_id);
        setModalValor('verVentasArticulo');
        setMovimiento(null);
        openModal();
    }

    function verVariantesArticulo(articulo_id) {
        setDataArt(null);
        setArticuloId(articulo_id);
        getArt(articulo_id);
        setModalValor('verVariantesArticulo');
        openModal();
    }

    function getArticulo(id) {
        setAsignacion({
            ...asignacion,
            ['articulo_principal_id']: id
        });
    }

    const loadArticulos = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `Articulos/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${sucursalPrincipal.id}`
        );
        return resp?.data;
    };

    const asignarImpuestos16 = () => {
        Swal.fire({
            text: "¿Desea asignar el impuesto IVA 16% a todos los articulos?",
            showCancelButton: true,
            confirmButtonText: "Si, asignalo",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                fetchConToken(`articulos/AsignarImpuestosXsucursal?sucursal_id=${getDataSession('sucursal')}`, null, 'PUT')
                    .then((response) => {
                        if (response.hasOwnProperty('status') && response.status === 400) {
                            Object.entries(response.errors).map(([key, value]) => {
                                Swal.fire('Error', value[0], 'error');
                            });
                            return;
                        } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                            return;
                        }

                        Swal.fire("Exito", "Impuestos Asignados", 'success');
                    })
            }
        });
    }

    const ActualizarArticulo = (articulo_id) => {
        Swal.fire({
            title: t('app.Articulos.SwalFire.actualizarArt?'),
            text: t('app.Articulos.SwalFire.infoActArt'),
            showCancelButton: true,
            confirmButtonText: t('app.botones.actualizar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                fetchConToken(
                    `Articulos/DescargarArticulos?empresa_id=${getDataSession('empresa')}&sucursal_id=${getDataSession('sucursal')}&articulo_id=${articulo_id}`,
                    null,
                    'POST'
                ).then(function (response) {
                    getArticulo();
                    if (response.hasOwnProperty('status') && response.status === 400) Object.entries(response.errors).map(([key, value]) => Swal.fire('Error', value[0], 'error'));
                    else if (response.hasOwnProperty('codigo') && response.codigo !== 200) Swal.fire('Error', response.mensajes[0], 'error');
                    else {
                        Swal.fire('Exito', t('app.Articulos.SwalFire.artActualizado'), 'success');
                        setActualizarTabla(true);
                        setActualizarTabla(false);
                    }

                });
            }
        })

    };

    const getTiendaBySucursal = () => fetchConToken(`ConfiguracionesSucursales?sucursal_id=${getDataSession('sucursal')}&nombre=TIENDA`).then(response => {
        if (response && response.valor) getSucursalesTienda(response.valor);
    });

    const getSucursal = (tienda, parametros) => fetchConToken(`Sucursales/GetListByTienda?empresa_id=${getDataSession('empresa')}&tienda=${tienda}&${parametros}`);

    async function getSucursalesTienda(tiendaParam) {
        setTienda(tiendaParam);
        const response = await getSucursal(tiendaParam, 'includeTienda=true&includeMatriz=false');
        setSucursales(response);
    }

    const getArticuloPublicador = (conexiones) => {
        var sucursales_ids = [];
        if (conexiones?.length > 0) conexiones.forEach(element => sucursales_ids.push(element.id));

        return { tienda: tienda, sucursal_id: getDataSession('sucursal'), articulos_ids: [articuloId], sucursales_ids: sucursales_ids };
    }

    const genArticulos = async (conexiones) => await fetchConToken('Articulos/GenerarArtiulos', getArticuloPublicador(conexiones), 'POST').then((resp) => {
        if (resp.codigo === 200) history.push(`${SLUGS.articulos}/edit/${resp.data[0].id}`);
        else Swal.fire('Error', resp.mensajes[0] ?? 'Error al generar los articulos', 'error');
    }).catch((e) => { Swal.fire('Error', e, 'error'); });

    const desvincularArticulo = (articulo_id) => {
        if (getDataSession('sucursal') !== getDataSession('sucursalPrincipal')) {
            Swal.fire({
                title: `¿Desea desvincular la publicacion?`,
                showCancelButton: true,
                confirmButtonText: 'Desvincular',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    fetchConToken(`Articulos/DesvincularArticulo`, { articulo_id: articulo_id }, 'PUT')
                        .then((response) => {
                            if (response.hasOwnProperty('status')) {
                                Object.entries(response?.errors).map(([key, value]) => {
                                    Swal.fire('Error', value[0], 'error');
                                    return;
                                });
                                return false;
                            } else if (response.hasOwnProperty('codigo')) {
                                Swal.fire('Error', response.mensajes[0], 'error');
                                return false;
                            }

                            Swal.fire('Exito', t('app.Articulos.SwalFire.artActualizado'), 'success');
                            setActualizarTabla(true);
                            setActualizarTabla(false);
                        })
                }
            });
        }
        else Swal.fire('Atencion', 'No se pueden desvincular articulos de la conexion principal', 'warning');
    }

    const handleClickPadres = (c) => {
        setDataSession("sucursal", c.id);
        history.push(path + '/referencias/' + c.articulo_Id);
    };

    //{ field: 'clave_Prod_Serv', headerName: 'Clave Prod. Serv.', flex: 1 },
    //{ field: 'no_Identificacion', headerName: 'No. Identificacion', flex: 1 },
    //{ field: 'clave_Unidad', headerName: 'Clave Unidad', flex: 1 },
    //{ field: 'requiere_Revision', headerName: 'Requiere Revision', flex: 1, type: 'boolean' },
    const columns = [
        {
            renderCell: RenderButtons, headerName: '', maxWidth: 20, path: path, 
            opciones: "editar," + (getDataSession('sucursal') !== getDataSession('sucursalPrincipal') ? "asignar,asignarmanual" : "") + ",verVentasArticulo,kardex,verVariantesArticulo,actualizararticulo,duplicarArticulo" + (getDataSession('sucursal') !== getDataSession('sucursalPrincipal') ? ",desvincular" : "") + ",eliminar",
            asignarAutomatico: asignarArticulo, verVentasArticulo: verVentasArticulo, verMovimientoArticulo: verMovimientoArticulo, verVariantesArticulo: verVariantesArticulo, actualizararticulo: ActualizarArticulo, duplicarArticulo: duplicarArticulo, desvincular: desvincularArticulo, eliminar: eliminar
        },
        {
            field: 'id', headerName: 'Id', maxWidth: 20, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'clave', headerName: 'Clave', width: 100, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'sku', headerName: 'SKU', width: 100, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'nombre', headerName: 'Nombre', width: 100, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'url', headerName: 'URL', width: 100, renderCell: (params) => (
                <a href={`${params.value}`}>{params.value}</a>)
        },
        { field: 'existencias', headerName: 'Exist. Pub' },
        { field: 'costo_Unidad', headerName: 'Precio Pub', type: 'number', renderCell: ({ value }) => currency(value || 0) },
        { field: 'articulo_Padre_Id', headerName: 'Asignado', type: 'boolean', renderCell: ({ value }) => value !== null },
        { field: 'actStockAut', headerName: 'Act. Exist. Aut.' },
        {
            field: 'conexiones', headerName: 'Canales', renderCell: ({ value }) => (
                <FormControl fullWidth sx={{ m: 1, minWidth: 50 }} size="small">
                    <InputLabel id="almacen-multiple-checkbox-label">{value?.length}</InputLabel>
                    <Select
                        labelId="almacen-multiple-checkbox-label"
                        id="almacen-multiple-checkbox"
                        onChange={handleChangeStatus}
                        label="Estatus"
                    >
                        {value?.map((c) => {
                            return (
                                <MenuItem key={c.id} value={c.id} >
                                    <ListItemButton onClick={() => { handleClickPadres(c) }} primary={c.nombre}  >
                                        {c.nombre}
                                    </ListItemButton>
                                </MenuItem>
                            );
                        })}

                    </Select>
                </FormControl>
            )
        },
        { field: 'tiene_Variante', headerName: 'Variantes' },
        { field: 'es_Full', headerName: 'EsFull' },
        { field: 'estatus', headerName: 'Estatus' },
        { field: 'envio_Gratis', headerName: 'Env.Gratis', type: 'boolean' },
        { field: 'costo_Envio', headerName: 'C.Envio', type: 'number', renderCell: ({ value }) => currency(value || 0) },
        { field: 'costo_Comision', headerName: 'Comisión', type: 'number', renderCell: ({ value }) => currency(value || 0) },
        { field: 'metodo_Envio', headerName: 'Metodo env.' },
        { field: 'tipo_Publicacion', headerName: 'Tipo pub.' },
    ];


    if (getDataSession('sucursal') !== getDataSession('sucursalPrincipal')) {
        const indexToRemove = columns.findIndex(column => column.headerName === 'Canales');
        if (indexToRemove !== -1) columns.splice(indexToRemove, 1);

        columns.splice(1, 0, {
            field: 'padre.cveOrig', headerName: 'Cve. Orig.', width: 100, cellClassName: (params) => { return 'columna-art-padre' }, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.padre?.idOrigen}`}>{params.row.padre ? params.row.padre.cveOrig : ''}</a>)
        });
        columns.splice(2, 0, {
            field: 'padre.nomOrig', headerName: 'Nom. Orig.', width: 100, cellClassName: (params) => { return 'columna-art-padre' }, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.padre?.idOrigen}`}>{params.row.padre ? params.row.padre.nomOrig : ''}</a>)
        });
        columns.splice(3, 0, {
            field: 'padre.exiOrig', headerName: 'Exi. Orig.', width: 100, cellClassName: (params) => { return 'columna-art-padre' }, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.padre?.idOrigen}`}>{params.row.padre ? params.row.padre.exiOrig : ''}</a>)
        });
        columns.splice(4, 0, {
            field: 'padre.preOrig', headerName: 'Pre. Orig.', width: 100, cellClassName: (params) => { return 'columna-art-padre' }, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.padre?.idOrigen}`}>{params.row.padre ? currency(params.row.padre.preOrig) : ''}</a>)
        });
    }

    const columnsVentaArt = [
        { field: 'id', headerName: 'Id', flex: 1, key: 'id' },
        { field: 'articulo', headerName: 'Articulo', flex: 1 },
        { field: 'movimiento', headerName: 'Movimiento', flex: 1 },
        { field: 'fecha', headerName: 'Fecha', flex: 1 },
        { field: 'no_Documento', headerName: 'No. Venta', flex: 1 },
        { field: 'folio', headerName: 'Folio', flex: 1 },
        { field: 'unidades', headerName: 'Unidades', flex: 1 },
        { field: 'existencia_Anterior', headerName: 'Existencia Anterior', flex: 1 },
        { field: 'existencia_Nueva', headerName: 'Existencias Nueva', flex: 1 },
        { field: 'tipo_Movimiento', headerName: 'Movimiento', flex: 1 },
        { field: 'almacen', headerName: 'Almacen', flex: 1 },
        /*{ field: 'variante', headerName: 'Variante', flex: 1 ,type: 'boolean'}*/
    ];

    return (
        <>
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-5' >
                        <Link to={`${path}/add`} className='btn btn-outline-primary m-1'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; {t('app.SwalFire.nuevo')}
                        </Link>
                        &nbsp;
                        <button
                            className='btn btn-outline-primary m-1'
                            style={{ width: 'auto' }}
                            onClick={handleClick}
                        >
                            Descargar <ArrowDropDownIcon />
                        </button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button', }}
                        >
                            <MenuItem onClick={(e) => { DescargarTienda(); handleClose(); }}><ListItemIcon><svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-cloud-arrow-down-fill'
                                viewBox='0 0 16 16'
                            >
                                <path d='M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z' />
                            </svg></ListItemIcon>{t('app.Articulos.lista.descargarArticulos')}</MenuItem>
                            <MenuItem onClick={(e) => { opcionModal('descargarArticulo'); handleClose(); }}><ListItemIcon><svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-cloud-arrow-down'
                                viewBox='0 0 16 16'
                            >
                                <path fill-rule='evenodd' d='M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z' />
                                <path d='M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z' />
                            </svg></ListItemIcon>{t('app.Articulos.lista.descargarArticulo')}</MenuItem>
                        </Menu>
                        <button
                            className='btn btn-outline-primary m-1'
                            style={{ width: 'auto' }}
                            onClick={handleClickAsignacion}
                        >
                            Asignación <ArrowDropDownIcon />
                        </button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElAsignacion}
                            open={openAsignacion}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button', }}
                        >
                            {getDataSession('sucursal') !== getDataSession('sucursalPrincipal') ?
                                <>
                                    <MenuItem onClick={(e) => { opcionModal('AsignacionAutomatica'); handleClose(); }}><ListItemIcon><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse-vertical" viewBox="0 0 16 16">
                                        <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M0 8a.5.5 0 0 1 .5-.5h3.793L3.146 6.354a.5.5 0 1 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8.5H.5A.5.5 0 0 1 0 8m11.707.5 1.147 1.146a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2a.5.5 0 0 1 .708.708L11.707 7.5H15.5a.5.5 0 0 1 0 1z" />
                                    </svg></ListItemIcon>Asignación Automatica</MenuItem>
                                    {getDataSession('rol') === 'EMPRESA' || getDataSession('rol') === 'ADMINISTRADOR' ? (
                                        <MenuItem onClick={(e) => { opcionModal('actualizacionCodigosSucursal'); handleClose(); }}><ListItemIcon><svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-shop'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z' />
                                        </svg></ListItemIcon>{t('app.Articulos.lista.asignarPorConexion')}</MenuItem>
                                    ) : ('')}
                                </> : ''}
                            <MenuItem onClick={(e) => { opcionModal('actualizacionCodigosExcel'); handleClose(); }}><ListItemIcon><svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-file-excel'
                                viewBox='0 0 16 16'
                            >
                                <path d='M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z' />
                                <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                            </svg></ListItemIcon>{t('app.Articulos.lista.asignarPorExcel')}</MenuItem>
                            <MenuItem onClick={(e) => { asignarImpuestos16(); handleClose(); }}><ListItemIcon><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-wallet-fill" viewBox="0 0 16 16">
                                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542s.987-.254 1.194-.542C9.42 6.644 9.5 6.253 9.5 6a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z" />
                                <path d="M16 6.5h-5.551a2.7 2.7 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5s-1.613-.412-2.006-.958A2.7 2.7 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z" />
                            </svg>
                            </ListItemIcon>Asignar Impuesto 16%</MenuItem>
                        </Menu>
                    </div>
                    <div className='col-sm-2 text-right' >
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="almacen-multiple-checkbox-label">Filtros</InputLabel>
                            <Select
                                labelId="almacen-multiple-checkbox-label"
                                id="almacen-multiple-checkbox"
                                onChange={handleChangeFiltros}
                                value={filtro}
                                label="Filtros"
                            >
                                <MenuItem key="TodosFiltros" value="T">
                                    <ListItemText primary="Todos" />
                                </MenuItem>
                                <MenuItem key="Asignados" value="ASIGNADO">
                                    <ListItemText primary="Asignados" />
                                </MenuItem>
                                <MenuItem key="NoAsignados" value="N_ASIGNADO" va>
                                    <ListItemText primary="No Asignados" />
                                </MenuItem>
                                <MenuItem key="ConVariantes" value="VARIANTE">
                                    <ListItemText primary="Con Variantes" />
                                </MenuItem>
                                <MenuItem key="Sin Variantes" value="N_VARIANTE">
                                    <ListItemText primary="Sin Variantes" />
                                </MenuItem>
                                <MenuItem key="ArticulosFull" value="FULL">
                                    <ListItemText primary="Articulos Full" />
                                </MenuItem>
                                <MenuItem key="SinSKU" value="N_SKU">
                                    <ListItemText primary="Sin SKU" />
                                </MenuItem>
                                <MenuItem key="SinImagenes" value="S_IMAGEN" >
                                    <ListItemText primary="Sin Imagenes" />
                                </MenuItem>
                                {getDataSession('tienda') === 'MERCADOLIBRE' && (
                                    <MenuItem key="SinImagenes" value="CATALOGO" >
                                        <ListItemText primary="Articulos de Catalogo" />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-sm-2 text-right' >
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="almacen-multiple-checkbox-label">Estatus</InputLabel>
                            <Select
                                labelId="almacen-multiple-checkbox-label"
                                id="almacen-multiple-checkbox"
                                onChange={handleChangeStatus}
                                value={status}
                                label="Estatus"
                            >
                                {/*<MenuItem key="TodosEstatus" value="">
                                    <ListItemText primary="Todos" />
                                </MenuItem>*/}
                                <MenuItem key="Activos" value="ACTIVOS">
                                    <ListItemText primary="Activos" />
                                </MenuItem>
                                <MenuItem key="Pausados" value="PAUSADOS" va>
                                    <ListItemText primary="Pausados" />
                                </MenuItem>
                                <MenuItem key="Inactivos" value="INACTIVOS">
                                    <ListItemText primary="Inactivos" />
                                </MenuItem>
                                <MenuItem key="Archivados" value="ARCHIVADOS">
                                    <ListItemText primary="Archivados" />
                                </MenuItem>
                                <MenuItem key="Borrador" value="BORRADOR">
                                    <ListItemText primary="En Borrador" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-sm-3 text-right'>
                        <button
                            className='btn btn-outline-primary m-1'
                            style={{ width: 'auto' }}
                            onClick={handleClickHerramientas}
                        >
                            {t('app.botones.herramientas')} <ArrowDropDownIcon />
                        </button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElHerramientas}
                            open={openHerramientas}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button', }}
                        >
                            <MenuItem onClick={() => { descargarArticulos(); handleClose(); }}><ListItemIcon><svg xmlns='http://www.w3.org/2000/svg' width='20' eight='20' fill='currentColor' className='bi bi-file-excel' viewBox='0 0 16 16'>
                                <path d='M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z' />
                                <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                            </svg></ListItemIcon>{t('app.Articulos.lista.exportarArticulo')}</MenuItem>
                            {getDataSession('sucursalPrincipal') === getDataSession('sucursal') ? '' :
                                <MenuItem onClick={() => { history.push(`${SLUGS.diferencias}`); handleClose(); }}><ListItemIcon><CompareArrowsIcon /></ListItemIcon>Comparativa</MenuItem>
                            }
                            <MenuItem onClick={() => { setModalValor('pulicadorMasivo'); openModalMarketPlace(); handleClose(); }}><ListItemIcon>
                                <WebStoriesIcon />
                            </ListItemIcon>{t('app.Articulos.pulicadorMasivo')}
                            </MenuItem>

                        </Menu>
                        <p>{mensajeArticulosDescargados}</p>
                    </div>
                </div>
                <div className='col-md-12'>
                    <ListTable
                        columns={columns}
                        path={path}
                        url={`Articulos/GetList?sucursal_Id=${getDataSession('sucursal')}&filtro=${filtro}${status ? "&status=" + status : ''}`}
                        isTreeData={true}
                        reload={actualizarTabla}
                    />
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                {modalValor === 'verVentasArticulo' ? (
                    <>
                        <div className='card text-left' style={{ padding: 10, height: "80vh" }}>
                            <ListTable
                                columns={columnsVentaArt}
                                path={path}
                                url={`Existencias/HistorialExistencias/GetList?articulo_Id=${articuloId}&movimiento=${movimiento == null ? '' : movimiento}`}
                                hideConexion={true}
                            />
                        </div>
                    </>
                ) : ('')}
                {modalValor === 'AsignacionArticulo' ? (
                    <>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <h6>Asignacion de articulos</h6>
                            <div className='container'>
                                <div className=' row'>
                                    <h7>
                                        La asignacion automatica ligara el articulo de su conexión actual con la conexión principal, se utilizaran las claves del articulo asigandas al rol proporcionado.
                                    </h7>
                                    {asignacion?.automatica == "false" ?
                                        <>
                                            <div className='col-md-12'>
                                                <div className='card mb-2 '>
                                                    <div className='card-title text-start'>
                                                        <label><b>Seleccione el articulo de la <b>conexión principal</b> para ligar los articulos</b></label>
                                                    </div>
                                                    <div className='card-body text-start'>
                                                        <AsyncSelect
                                                            className='react-select-placeholder'
                                                            isClearable
                                                            getOptionLabel={(option) =>
                                                                option.clave +
                                                                '-' +
                                                                option.nombre
                                                            }
                                                            getOptionValue={(option) => option.id}
                                                            loadOptions={loadArticulos}
                                                            onChange={(selectedItem) => {
                                                                const val = selectedItem === null ? '' : selectedItem?.id;
                                                                getArticulo(val);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </> :
                                        <>
                                            <div className='col-md-6'>
                                                <div className='card mb-2 '>
                                                    <div className='card-title text-start'>
                                                        <label>Utilizar el siguiente rol de clave de la <b>conexión actual</b> para ligar los articulos</label>
                                                    </div>
                                                    <div className='card-body text-start'>
                                                        <select
                                                            className='form-control'
                                                            name='lista_clave_tienda_id'
                                                            value={asignacion?.lista_clave_tienda_id}
                                                            onChange={handleInputAsignacion}
                                                            required
                                                        >
                                                            <option >Seleccione un rol de clave</option>
                                                            {rolClavesTienda.map((rol) => {
                                                                return (
                                                                    <option key={rol.id} value={rol.id}>
                                                                        {rol.nombre}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='card mb-2 '>
                                                    <div className='card-title text-start'>
                                                        <label><b>Utilizar el siguiente rol de clave de la <b>conexión principal</b> para ligar los articulos</b></label>
                                                    </div>
                                                    <div className='card-body text-start'>
                                                        <select
                                                            className='form-control'
                                                            name='lista_clave_principal_id'
                                                            value={asignacion?.lista_clave_principal_id}
                                                            onChange={handleInputAsignacion}
                                                            required
                                                        >
                                                            <option >Seleccione un rol de clave</option>
                                                            {rolClavesPrincipal.map((rol) => {
                                                                return (
                                                                    <option key={rol.id} value={rol.id}>
                                                                        {rol.nombre}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                    &nbsp;&nbsp;
                                    <button
                                        className='btn btn-outline-primary'
                                        onClick={(e) => AsignacionArticulo()}
                                        style={{ maxWidth: '300px' }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse-vertical" viewBox="0 0 16 16">
                                            <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M0 8a.5.5 0 0 1 .5-.5h3.793L3.146 6.354a.5.5 0 1 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8.5H.5A.5.5 0 0 1 0 8m11.707.5 1.147 1.146a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2a.5.5 0 0 1 .708.708L11.707 7.5H15.5a.5.5 0 0 1 0 1z" />
                                        </svg>
                                        &nbsp; Iniciar la asignación
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : ('')}
                {modalValor === 'AsignacionAutomatica' ? (
                    <>
                        <br></br>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <p>{mensajeAsignacion}</p>
                            <p>{mensajeAsignacionVariante}</p>
                            <p>{mensajeAsignacionMensaje}</p>
                            <br></br>
                            <hr></hr>
                            <h6>Asignacion automatica</h6>
                            <div className='container'>
                                <div className=' row'>
                                    <h7>
                                        La asignacion automatica ligara los articulos de su conexión actual con la conexión principal, se utilizaran las claves del articulo asigandas al rol proporcionado.
                                    </h7>
                                    <div className='col-md-6'>
                                        <div className='card mb-2 '>
                                            <div className='card-title text-start'>
                                                <label>Utilizar el siguiente rol de clave de la <b>conexión actual</b> para ligar los articulos</label>
                                            </div>
                                            <div className='card-body text-start'>
                                                <select
                                                    className='form-control'
                                                    name='lista_clave_tienda_id'
                                                    value={asignacion?.lista_clave_tienda_id}
                                                    onChange={handleInputAsignacion}
                                                    required
                                                >
                                                    <option ></option>
                                                    {rolClavesTienda.map((rol) => {
                                                        return (
                                                            <option key={rol.id} value={rol.id}>
                                                                {rol.nombre}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='card mb-2 '>
                                            <div className='card-title text-start'>
                                                <label><b>Utilizar el siguiente rol de clave de la <b>conexión principal</b> para ligar los articulos</b></label>
                                            </div>
                                            <div className='card-body text-start'>
                                                <select
                                                    className='form-control'
                                                    name='lista_clave_principal_id'
                                                    value={asignacion?.lista_clave_principal_id}
                                                    onChange={handleInputAsignacion}
                                                    required
                                                >
                                                    <option ></option>
                                                    {rolClavesPrincipal.map((rol) => {
                                                        return (
                                                            <option key={rol.id} value={rol.id}>
                                                                {rol.nombre}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;&nbsp;
                                    <button
                                        className='btn btn-outline-primary'
                                        onClick={(e) => AsignacionAutomatica()}
                                        style={{ maxWidth: '200px' }}
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-plus-square'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                                        </svg>
                                        &nbsp; Iniciar la asignación automatica
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : ('')}
                {modalValor === 'descargarArticulo' ? (
                    <>
                        <br></br>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <h6>E-Commerce</h6>
                            <div className='container'>
                                <div className=' row'>
                                    <h7>
                                        Descargar un articulo de E-Commerce, mediante la clave del
                                        articulo
                                    </h7>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        style={{ width: '320px' }}
                                        placeholder='Clave del articulo de Marketplace'
                                        value={CodigoArticulo || ''}
                                        onChange={handleInputChange}
                                    />
                                    &nbsp;&nbsp;
                                    <button
                                        className='btn btn-outline-primary'
                                        onClick={(e) => DescargarArticulo()}
                                        style={{ maxWidth: '200px' }}
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-plus-square'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                                        </svg>
                                        &nbsp; {t('app.Articulos.lista.descargarArticulo')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : ('')}
                {modalValor === 'actualizacionCodigosExcel' ? (
                    <>
                        <br></br>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <h6>{t('app.Articulos.actualizacionCodigos')}</h6>
                            <div className='container'>
                                <div className='container text-right'>
                                    <button className='btn btn-outline-success' type='button' onClick={() => descargarPlantilla()}>Descargar Plantilla</button>
                                    &nbsp;
                                    &nbsp;
                                    <button
                                        className='btn btn-outline-primary '
                                        type='button'
                                        onClick={() => actualizarCodigos()}
                                        style={{ maxWidth: '200px' }}
                                    >
                                        Cargar codigos
                                    </button>
                                    <br></br>
                                </div>
                                <br></br>
                                <div className='row'>

                                    <div className='col-md-6'>
                                        <h7>Configurar codigos mediante excel:</h7>
                                        <input
                                            type='file'
                                            onChange={handleInputChangeFileConfig}
                                            style={{ maxWidth: '320px' }}
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <h7>
                                            Conexión de destino: &nbsp;
                                            &nbsp;
                                        </h7>
                                        <select
                                            className='form-control'
                                            onChange={handleChangeDes}
                                            value={sucursalIdDes}
                                            style={{ with: '350px' }}
                                        >
                                            {sucursalesList?.map((item) => {
                                                return (
                                                    <option value={item.id} key={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </>
                ) : ('')}
                {modalValor === 'actualizacionCodigosSucursal' ? (
                    <>
                        <br></br>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 row'>
                                        <h6>Actualización de códigos respecto a las conexiones</h6>
                                        <small>Asignara los códigos: Clave Prod. Serv.,No. Identificacion,Clave Unidad. Mediante la comparación de SKU </small>
                                    </div>

                                    <div className='col-md-6'>
                                        <h7>
                                            Conexión de origen: &nbsp;
                                            &nbsp;
                                        </h7>
                                        <select
                                            className='form-control'
                                            onChange={handleChangeOri}
                                            value={sucursalIdOri}
                                            style={{ with: '350px' }}
                                        >
                                            {sucursalesList?.map((item) => {
                                                return (
                                                    <option value={item.id} key={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-md-6'>
                                        <h7>
                                            Conexión de destino: &nbsp;
                                            &nbsp;
                                        </h7>
                                        <select
                                            className='form-control'
                                            onChange={handleChangeDes}
                                            value={sucursalIdDes}
                                            style={{ with: '350px' }}
                                        >
                                            {sucursalesList?.map((item) => {
                                                return (
                                                    <option value={item.id} key={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-md-12 text-right'>
                                        <br></br>
                                        <button
                                            className='btn btn-outline-primary'
                                            type='button'
                                            onClick={() => actualizarCodigosXSucursal()}
                                        >
                                            Actualizar codigos
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : ('')}
                {modalValor === 'verVariantesArticulo' ? (<>{dataArt ? <ListVariante data={dataArt} variantes={dataArt.articulo_Variantes} editar={false} /> : <Cargando />}</>) : ('')}
            </Modal>
            <Modal isOpen={isOpenModalMarketPlace} closeModal={closeModalMarketPlace}>
                {modalValor === 'duplicarArticulo' ? (<SelectorConexion sucursalesTienda={sucursales} onClickButton={duplicarArticuloOnClick} />) : ('')}
                {modalValor === 'pulicadorMasivo' ? (
                    <>
                        <div className='espacio15px' />
                        <h4 className='text-center'>Seleccione su e-Commerce</h4>
                        <div className='col-md-12 row'>
                            <div className='col-sm-1' />
                            <div className='col-md-11 row'>
                                <button type='button' className='btn col-md-4 img_E-Commerce' onClick={() => { PublicadorMasivo('MercadoLibre') }}>
                                    <img src={mercadolibre} />
                                </button>
                                <button type='button' className='btn col-md-4 img_E-Commerce' onClick={() => { PublicadorMasivo('WooCommerce') }}>
                                    <img src={woocommerce} />
                                </button>
                                <button type='button' className='btn col-md-4 img_E-Commerce' onClick={() => { PublicadorMasivo('Shopify') }}>
                                    <img src={shopify} />
                                </button>
                                <button type='button' className='btn col-md-4 img_E-Commerce' onClick={() => { PublicadorMasivo('TiendaNube') }}>
                                    <img src={tiendanube} />
                                </button>
                                <button type='button' className='btn col-md-4 img_E-Commerce' onClick={() => { PublicadorMasivo(noDisponible) }}>
                                    <img src={amazon} />
                                </button>
                                <button type='button' className='btn col-md-4 img_E-Commerce' onClick={() => { PublicadorMasivo(noDisponible) }}>
                                    <img src={wix} />
                                </button>
                            </div>
                        </div>
                    </>
                ) : ('')}
            </Modal>
        </>
    );
};

export { List };