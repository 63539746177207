import React, { useState, useEffect } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';

const AuthTienda = ({ tienda, code, sucursal_id }) => {
    let [error, setError] = useState(['']);
    let [success, setSuccess] = useState(['']);

    const saveCode = () => {
        if(!(sucursal_id && code)) {
            setError((!code ? 'Error al obtener el código de acceso a tu tienda' : 'Error al obtener el identificador de la sucursal') + "\r\n Intentelo mas tarde");
            return;
        }

        fetchConToken(`ConfiguracionesSucursales/ObtenerToken?Code=${code}&sucursal_id=${sucursal_id}&tienda=${tienda}`)
            .then((response) => {
                setError('');
                if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    setError(response.mensajes[0] + "\r\n Intentelo mas tarde");
                    return;
                }

                setSuccess('La cambios se han guardado correctamente. \r\n Puede cerrar la ventana');
            })
            .catch(alertService.error);
    }

    const GetTienda = () => {
        switch (tienda) {
            case 'MERCADOLIBRE': return 'Mercado Libre';
            case 'TIENDANUBE':
            default: return 'Tienda Nube';
        }
    }

    useEffect(() => { saveCode(); }, []);

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12' style={{ display: 'grid' }}>
                        <div className='card mb-3 '>
                            <div className='card-body text-start'>
                                <div className='card-title text-center'>
                                    <h5>Confirmando su autorizacion de</h5>
                                    <h2>{GetTienda()}</h2>
                                </div>
                                <div className='row'><div className='col-md-12 text-center'>{code}</div></div>
                            </div>
                            <div className='text-center'>
                                <h3 style={{ color: 'red' }}>{error}</h3>
                                <h3 style={{ color: 'blue' }}>{success}</h3>
                                {error || success ? (<br />) : ('')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { AuthTienda };