import React, { useState, useEffect } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { useGlobalState } from 'resources/GlobalStateContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Swal from 'sweetalert2';

const Asignacion = ({ match }) => {
    const { path } = match;
    const { id } = match.params;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [isOpenModal, openModal, closeModal] = useModal(false);
    let [etiqueta, setEtiqueta] = useState({});
    let [etiquetaPadre, setEtiquetaPadre] = useState(undefined);
    let [etiquetaAsignar, setEtiquetaAsignar] = useState({});

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Asignacion de etiquetas',
            subtitulo: 'Aqui puede vincular las etiquetas de su tienda con la conexion principal'
        });
    };

    const loadEtiquetas = async (query) => {
        if (getDataSession('sucursalPrincipal') === '0') {
            Swal.fire('Info', 'Configure la conexion principal.', 'info');
            return;
        }

        return await fetchConToken(`categorias/EtiquetaList?busqueda=${query}&sucursal_Id=${getDataSession('sucursalPrincipal')}`);
    };

    function getEtiqueta() {
        fetchConToken(`categorias/Etiqueta?id=${id}&sucursal_Id=${getDataSession('sucursal')}`).then((etq) => {
            setEtiqueta(etq);
            fetchConToken(`categorias/GetEtiquetasAsignados?id=${id}&sucursal_Id=${getDataSession('sucursal')}`).then((etiquetas) => {
                setEtiquetaPadre(undefined);
                if (etiquetas && etiquetas?.length > 0) {
                    var temp = etiquetas.find(x => x.sucursal_Id == getDataSession('sucursalPrincipal'));
                    if (temp) setEtiquetaPadre(temp);
                }
            });
        });
    }

    function asignar() {
        setEtiquetaAsignar({});
        openModal();
    }

    function asignarEtiqueta() {
        if (etiquetaAsignar && etiquetaAsignar?.id > 0) Swal.fire({
            title: `¿Desea relacionar la etiqueta ${etiqueta?.nombre} con la etiqueta ${etiquetaAsignar?.nombre}?`,
            showCancelButton: true,
            confirmButtonText: 'Relacionar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                var asignacion = {
                    sucursalOrg: getDataSession('sucursal'),
                    sucursalDes: getDataSession('sucursalPrincipal'),
                    catalogoOrg: etiqueta.id,
                    catalogoDes: etiquetaAsignar.id
                };
                fetchConToken(`categorias/AsignarEtiqueta`, asignacion, 'PUT').then((response) => {
                    if (response.hasOwnProperty('status')) {
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }

                    Swal.fire('Asignación completa', 'Etiqueta Relacionado', 'success');
                    getEtiqueta();
                    setEtiquetaAsignar({});
                    closeModal();
                });
            }
        });
    }

    function desvincular() {
        if (etiquetaPadre) {
            Swal.fire({
                title: `¿Desea desvincular la etiqueta ${etiqueta?.nombre} de la etiqueta ${etiquetaPadre?.nombre}?`,
                showCancelButton: true,
                confirmButtonText: 'Desvincular',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    var desvincular = {
                        sucursalOrg: getDataSession('sucursal'),
                        sucursalDes: getDataSession('sucursalPrincipal'),
                        catalogoOrg: etiqueta.id,
                        catalogoDes: etiquetaPadre.id
                    };
                    fetchConToken(`categorias/DesvincularEtiqueta`, desvincular, 'PUT')
                        .then((response) => {
                            if (response.hasOwnProperty('status')) {
                                Object.entries(response?.errors).map(([key, value]) => {
                                    Swal.fire('Error', value[0], 'error');
                                    return;
                                });
                                return false;
                            } else if (response.hasOwnProperty('codigo')) {
                                Swal.fire('Error', response.mensajes[0], 'error');
                                return false;
                            }

                            Swal.fire('Exito', 'Etiqueta Desvinculado', 'success');
                            getEtiqueta();
                        })
                }
            });
        }
    }

    useEffect(() => {
        actualizarTitulo();
        getEtiqueta();
    }, []);

    return (<>
        <div className='container-fluid'>
            <div className='col-md-12 text-right'>
                <Link to={'..'} className='btn btn-outline-warning'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='15'
                        height='15'
                        fill='currentColor'
                        className='bi bi-reply'
                        viewBox='0 0 16 16'
                    >
                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                    </svg>
                    &nbsp; Regresar
                </Link>
            </div>
            <div className='col-md-12'>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center' colSpan={2}><h3>Etiqueta Conexión</h3></TableCell>
                                <TableCell align='center' colSpan={1} />
                                <TableCell align='center' colSpan={2}><h3>Etiqueta Conexión Principal</h3></TableCell>
                                <TableCell align='center' colSpan={1} />
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>Clave Etiqueta</TableCell>
                                <TableCell align='center'>Nombre</TableCell>
                                <TableCell align='center' />
                                <TableCell align='center'>Clave Etiqueta</TableCell>
                                <TableCell align='center'>Nombre</TableCell>
                                <TableCell align='center' />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align='center'>{etiqueta.clave_Etiqueta}</TableCell>
                                <TableCell align='left'>{etiqueta?.nombre}</TableCell>
                                <TableCell align='center' />
                                <TableCell align='center'>{etiquetaPadre?.clave_Etiqueta}</TableCell>
                                <TableCell align='left'>{etiquetaPadre?.nombre}</TableCell>
                                <TableCell align='right'>
                                    <Button variant='contained' onClick={() => asignar()}>Asignar</Button>&nbsp;
                                    <Button variant='contained' onClick={() => desvincular()} disabled={!etiquetaPadre}>Desvincular</Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
        <Modal isOpen={isOpenModal} closeModal={closeModal}>
            <div className='card text-left' style={{ padding: 10 }}>
                <div className='container'>
                    <div>
                        <div className='espacio15px'></div>
                        <div className='card-title row  '>
                            <div className='col-md-6 text-left'><h3><label>&nbsp;Seleccione la etiqueta a relacionar</label></h3></div>
                            <div className='col-md-6 text-right'>
                                <Button variant='contained' onClick={() => asignarEtiqueta()}>
                                    Asignar
                                </Button>&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>
                        <div className='espacio15px'></div>
                        <div className='card-body ' style={{ height: '50vh' }}>
                            <AsyncSelect
                                isClearable
                                className='react-select-placeholder'
                                getOptionLabel={(option) => option.nombre}
                                getOptionValue={(option) => option.id}
                                loadOptions={loadEtiquetas}
                                onChange={(selectedItem) => { setEtiquetaAsignar(selectedItem); }}
                                value={etiquetaAsignar}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>);
};

export { Asignacion };