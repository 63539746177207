import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';
//signalr  notificaciones
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { currency } from 'resources/utilities';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/Visibility';


import { useGlobalState } from 'resources/GlobalStateContext';
import QRCode from 'qrcode.react';
import { descargarArchivo } from 'actions/archivos';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { alertService } from '_services';

import AsyncSelect from 'react-select/async';

const $ = require('jquery')
const List = ({history, match, saludo, subtitulo }) => {
    const { path } = match;
    const { document } = match.params;
    let [documento, setDocumento] = useState(document);
    let [statusFiltro, setStatusFiltro] = useState("");
    let [totales, setTotales] = useState([]);
    let [actualizarTabla, setActualizarTabla] = useState(false);
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let [mensajeVentaDescargados, setMensajeVentaDescargados] = useState('');
    let [descargaActiva, setDescargaActiva] = useState(false);
    const [fechaDescarga, setFechaDescarga] = useState(GetFecha(true));
    let [CodigoVenta, setCodigoVenta] = useState('');
    let [fechaInicio, setFechaInicio] = useState(GetFecha(true));
    let [fechaFin, setFechaFin] = useState(GetFecha(false));
    let [años, setAños] = useState([]);
    const [busqueda, setBusqueda] = useState('');
    const [conFiltros, setConFiltros] = useState(true);
    const [cabecera, setCabecera] = useState(true);
    const [conDetalle, setConDetalle] = useState(false);
    const [detalleAgrupado, setDetalleAgrupado] = useState(false);
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState(false);
    const baseUrl = process.env.REACT_APP_API_URL;
    const [connection, setConnection] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };
    let [TipoDocto, setTipoDocto]  = useState('x');
    let [Docto, setDocto]  = useState('C');
    const [agentes, setAgentes] = useState([]);
    let [vxEntrega, setVxEntrega] = useState([]);
    let [vxEntregaDetalle, setVxEntregaDetalle] = useState([]);
    let [mostrar, setMostrar ] = useModal(false);
    let [clienteId, setClienteId ] = useState(0);
    

    let [ventaAgente, setVentaAgente] = useState({
        Id: 0,
        agente_Id: 0,
        venta_Id: 0,
        Tipo: 'E',
        sucursal_Id: getDataSession('sucursal')
    });


    React.useEffect(() => {
        switch (match.params.documento) {
            case 'cotizaciones':
                setTipoDocto('C');
                setDocto('Cotizaciones');
                break;
            case 'pedidos':
                setTipoDocto('P');
                setDocto('Pedidos');
                break;
            case 'remisiones':
                setTipoDocto('R');
                setDocto('Remisiones');
                break;            
            default:
                setTipoDocto('P');
                setDocto('Pedidos');
                break;
        }
        
        loadDataAgentes();
    },[match.params]);




    const loadDataAgentes = async (query) =>
        await fetchConToken(
            `Usuarios/GetList?empresa_id=${getDataSession('empresa')}&paginas=0&totalXpagina=1000&tipoUsuario=AGENTE&estatus=ACTIVOS&sucursal_Id=${getDataSession('sucursal')}`
        ).then((resp) => {
                setAgentes(resp.data);
            })
            .catch((e) => {
                Swal.fire(
                    'Error',
                    `Ocurrio un error al obtener agentes ${query}`,
                    'error'
                );
            });
    async function TotalVentasFactuxin() {
        const response = await fetchConToken(`ventas/total?sucursal_id=${getDataSession('sucursal')}`);
        setMensajeVentaDescargados("Ventas descargadas: " + (response.total ?? 0) + "/ " + (response.total_Ecommerce ?? 0));
    }

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: Docto,
            subtitulo: 'Listado de ' + Docto + ' realizadas.'
        });
    };

    React.useEffect(() => {

        if(TipoDocto == "R")
        {
            const connect = new HubConnectionBuilder()
                .withUrl(baseUrl + "/notificacion", {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets
                })
                .withAutomaticReconnect()
                .build();
            setConnection(connect);
        }
    }, [TipoDocto]);

    React.useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(async () => {
                    await connection.invoke("Add", getDataSession('empresa') + "-" + getDataSession('sucursal'));
                    connection.on("ReceiveNotificacionDescarga", function (message) {
                        setMensajeVentaDescargados(message);
                        setDescargaActiva(true);
                        getactualizarTabla();
                    });

                    connection.on("ReceiveNotificacionFinalizacion", function (message) {
                        setDescargaActiva(false);
                        TotalVentasFactuxin();
                        getactualizarTabla();
                    });
                })
                .catch((error) => console.log(error));
        }
    }, [connection]);

    React.useEffect(() => {

        setDocumento(match.params.documento);
        setMensajeVentaDescargados('');

        let year = moment().format('Y')
        const beforeYear = year - 10
        for (let x = year; x >= beforeYear; x--) {
            años.push(x);
        }
        actualizarTitulo();
        getactualizarTabla();
    }, [TipoDocto]);

    React.useEffect(() => { getTotales() }, [TipoDocto,fechaInicio,fechaFin,clienteId]);

    function getTotales() {
        fetchConToken(`Ventas/CantidadXEstatus?sucursal_Id=${getDataSession('sucursal')}&tipo_documento=${TipoDocto}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&cliente_id=${clienteId}`).then(function (response) { setTotales(response) });
    }

    async function eliminar(id) {
        const response = await fetchConToken(
            `ventas?id=${id}&sucursal_Id=${getDataSession('sucursal')}`,
            null,
            'DELETE'
        );
        Swal.fire('Exitoso', 'venta eliminada', 'success');
    }

    function getactualizarTabla() {
        setActualizarTabla(true);
        setActualizarTabla(false);
    }

    const DescargarTienda = () => {
        Swal.fire({
            text: '¿Desea descargar las ventas de su E-Commerce con la fecha asignada?',
            showCancelButton: true,
            confirmButtonText: 'Descargar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            closeModal();
            if (result.isConfirmed) {
                setDescargaActiva(true);
                fetchConToken(
                    `Ventas/DescargarVentas?empresa_id=${getDataSession(
                        'empresa'
                    )}&sucursal_id=${getDataSession('sucursal')}&fecha_Inicial=${fechaDescarga}`,
                    null,
                    'POST'
                ).then(function (response) {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                        });
                        getactualizarTabla();
                        return;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error'); //setError(response.mensajes[0]);
                        getactualizarTabla()
                        return;
                    }
                    Swal.fire(
                        'Procesando',
                        'Se estan descargando las ventas. Esto puede tomar un momento',
                        'success'
                    );
                    getactualizarTabla()
                });
            }
        });
    };

    const DescargarVenta = () => {
        closeModal();
        fetchConToken(
            `Ventas/DescargarVentaById?empresa_id=${getDataSession(
                'empresa'
            )}&sucursal_id=${getDataSession('sucursal')}&id=${CodigoVenta}`,
            null,
            'POST'
        ).then(function (response) {
            if (response.hasOwnProperty('status') && response.status === 400) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                });
                getactualizarTabla()
                return;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                getactualizarTabla()
                return;
            }
            getactualizarTabla();
            Swal.fire('Exitoso', 'Venta Descargada', 'success');
        });
    };

    const handleInputChange = ({ target }) => {
        setCodigoVenta(target.value);
    };

    const handleInputChangeFechaDescarga = ({ target }) => {
        setFechaDescarga(target.value);
    };

    async function opcionModal(opcion) {
        if (opcion === 'exportarDocumentos') {
            setConFiltros(true);
            setConfiguracionExportar(true, false, false);
        }

        setModalValor(opcion);
        openModal();
    }



    const handleChangeFiltroStatus = (event, newValue) => {
        setStatusFiltro(newValue);
    };

    const handleInputChangeFechaInicio = ({ target }) => {
        setFechaInicio(target.value);
    };

    const handleInputChangeFechaFin = ({ target }) => {
        setFechaFin(target.value);
    }

    const handelChangeExportarDocumentos = ({ target }) => {
        switch(target.name) {
            case 'conFiltros': setConFiltros(target.checked); break;
            case 'conDetalle': setConfiguracionExportar(false, true, false); break;
            case 'detalleAgrupado': setConfiguracionExportar(false, false, true); break;
            default: setConfiguracionExportar(true, false, false); break;
        }
    }

    const setConfiguracionExportar = (cabecera, detalle, agrupado) => {
        setCabecera(cabecera);
        setConDetalle(detalle);
        setDetalleAgrupado(agrupado);
    }

    const generarCopia = (venta_id) => {
        fetchConToken(`Ventas/GeneraCopia?venta_id=${venta_id}`, null, 'post').then(async (resp) => {
            try {
                if (resp.hasOwnProperty('status') && resp.status === 400) {
                    var erroresVenta = {};
                    Object.entries(resp.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (resp.hasOwnProperty('codigo') && resp.codigo !== 200) {
                    Swal.fire('Error', resp.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Copia de la venta generada en la principal', 'success');
            } catch (error) {
                console.error('Error al descargar el archivo:', error);
            }
        });
    }

    const convertirPedido = (venta_id) => {
        fetchConToken(`Cotizaciones/ConvertirAPedido?documento_id=${venta_id}&sucursal_id=${getDataSession('sucursal')}`, null, 'post').then(async (resp) => {
            try {
                if (resp.hasOwnProperty('status') && resp.status === 400) {
                    var erroresVenta = {};
                    Object.entries(resp.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (resp.hasOwnProperty('codigo') && resp.codigo !== 200) {
                    Swal.fire('Error', resp.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Se Convirtio a Pedido', 'success');
            } catch (error) {
                console.error('Error al convertir el pedido:', error);
            }
        });
    }

    const convertirRemision = (venta_id) => {
        fetchConToken(`Pedidos/ConvertirARemision?documento_id=${venta_id}&sucursal_id=${getDataSession('sucursal')}`, null, 'post').then(async (resp) => {
            try {
                if (resp.hasOwnProperty('status') && resp.status === 400) {
                    var erroresVenta = {};
                    Object.entries(resp.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (resp.hasOwnProperty('codigo') && resp.codigo !== 200) {
                    Swal.fire('Error', resp.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Se Convirtio a Remision', 'success');
            } catch (error) {
                console.error('Error al convertir a remision:', error);
            }
        });
    }

    const verEntregasPedido = (venta_id) => {
        fetchConToken(`VxEntregas/Entregas?venta_id=${venta_id}`).then(resp=>{
            setVxEntrega(resp);
        });        
        setModalValor("VerEntregas");
        openModal();
    }
    

    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path.replace(':documento', documento), 
            opciones: "editar,vista,descargarguia" + 
            ((getDataSession('sucursal') !== getDataSession('sucursalPrincipal')) ? ',generarCopia' : '')+
            (TipoDocto === 'C' ? ',convertirPedido' : (TipoDocto === 'P' ? ',convertirRemision' : '') )+
            (TipoDocto === 'P' ? ',asignarAgente,verEntregasPedido' :  '' ), 
            generarCopia: generarCopia, convertirPedido: convertirPedido,convertirRemision: convertirRemision, asignarAgente: asignarAgente,verEntregasPedido: verEntregasPedido },
        {
            field: 'id', headerName: 'Id', maxWidth: 20, renderCell: (params) => (
                <a href={`${path.replace(':documento', documento) + '/view/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'qr', headerName: 'QR', maxWidth: 75, renderCell: (params) => (
                <QRCode
                    value={params.row.id}
                    size={55}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="Q"
                    includeMargin={true}
                />)
        },
        {
            field: 'no_Orden', headerName: 'Orden', minWidth: 200, renderCell: (params) => (
                <a href={`${path.replace(':documento', documento) + '/view/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'folio', headerName: 'Folio', minWidth: 100, renderCell: (params) => (
                <a href={`${path.replace(':documento', documento) + '/view/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'folio_Venta', headerName: 'Folio Venta', minWidth: 200, renderCell: (params) => (
                <a href={`${path.replace(':documento', documento) + '/view/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'sucursal_Origen', headerName: 'Canal', minWidth: 130, renderCell: (params) => (
                <a href={`${path.replace(':documento', documento) + '/view/' + params.row.documento_Origen_Id}`}>{params.value}</a>)
        },
        {
            field: 'documento_FoliosOrigen', headerName: 'Folios ERP', minWidth: 130, renderCell: (params) => (
                <p>{params.row.documento_FoliosOrigen.map(objeto => objeto.erp_Folio)?.join(', ')}</p>)
        },
        { field: 'estatus', headerName: 'Estatus', minWidth: 130 },
        { field: 'fecha_Orden', headerName: 'Fecha', minWidth: 160 }, 
        { field: 'fecha_Registro', headerName: 'Fecha Registro', minWidth: 160 },
        { field: 'nombre_Cliente', headerName: 'Cliente', minWidth: 300 },
        { field: 'rfc_Cliente', headerName: 'RFC', minWidth: 150 },
        { field: 'importe_Total', headerName: 'SubTotal', type: 'number', renderCell: ({ value }) => currency(value) },
        { field: 'impuestos', headerName: 'Impuestos', type: 'number', renderCell: ({ value }) => currency(value) },
        { field: 'importe_Total_Impuestos', headerName: 'Total', type: 'number', renderCell: ({ value }) => currency(value) }

    ];

    if (getDataSession('sucursal') !== getDataSession('sucursalPrincipal')) {
        const indexToRemove = columns.findIndex(column => column.headerName === 'Canal');
        if (indexToRemove !== -1) {
            columns.splice(indexToRemove, 1);
        }
    }

    const CustomTab = ({ value, label, count, color }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{label}</span>
            <span style={{
                marginLeft: 5,
                backgroundColor: color,
                color: 'white',
                padding: '2px 5px',
                borderRadius: 4,
            }}>
                {count}
            </span>
        </div>
    );

    function GetFecha(inicio) {
      const fechaActual = new Date();
      let anio = fechaActual.getFullYear();
      let mes = String(fechaActual.getMonth() + (inicio ? 0 : 1)).padStart(2, '0'); // Los meses comienzan en 0
      
      const dia = String(fechaActual.getDate()).padStart(2, '0');
      const horas = String(fechaActual.getHours()).padStart(2, '0');
      const minutos = String(fechaActual.getMinutes()).padStart(2, '0');

      console.log(mes);
      if(mes === '00')
      {
        mes = 12;
        anio = anio -1;
      }
      
      return `${anio}-${mes}-${dia}T${horas}:${minutos}`;
    }

    function descargarDocumentos() {
        var url = `Ventas/DescargaExcel?sucursal_id=${getDataSession('sucursal')}&tipo_documento=${TipoDocto}&conDetalle=${conDetalle}&detalleAgrupado=${detalleAgrupado}`;
        if (conFiltros) url = url + `&status=${statusFiltro}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&busqueda=${busqueda}`;

        descargarArchivo(url, `${Docto}.xlsx`);
    }

    function asignarAgente(ventaId){
        setVentaAgente({
            ...ventaAgente,
            ['venta_Id']: ventaId,
            ['agente_Id']: 0
        });

        console.log(ventaAgente);
        fetchConToken('VxAgenteVenta?venta_id='+ventaId)
        .then((response) => {
            if (response.hasOwnProperty('status')) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                
                if (!response)
                    return;
            setVentaAgente(response);
        })
        console.log(ventaAgente);
        setModalValor('seleccionarAgente');
        openModal();
    }

    function AsignarAgenteVenta(){

        if(ventaAgente.ventaId == 0)
        {
            Swal.fire('Info', "seleccione una venta valida", 'info');
            return;
        }
        if(ventaAgente.agente_Id == 0)
        {
            Swal.fire('Info', "seleccione un agente valido", 'info');
            return;
        }


        fetchConToken('VxAgenteVenta', ventaAgente, 'POST')
        .then((response) => {
            if (response.hasOwnProperty('status')) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Agente Asignado', 'success');            
            })
            .catch(alertService.error);           
    }

    const loadClientes = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        var resp = await fetchConToken(`Clientes/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`);
        return resp.data;
    };


    return (
        <>
            <div className='container-fluid'>
                <div className='col-md-12 d-flex justify-content-between'>
                    <div className='col-md-3'>
                    <div className='espacio20px'/>
                        <Link to={`${documento}/add`} className='btn btn-outline-primary'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; Nuevo
                        </Link>
                        &nbsp;
                        <button
                            className='btn btn-outline-primary m-1'
                            style={{ width: 'auto' }}
                            onClick={(e) => { opcionModal('exportarDocumentos'); }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-excel" viewBox="0 0 16 16">
  <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
</svg> &nbsp; Exportar
                        </button>
                        &nbsp;
                        {TipoDocto === 'R' ?
                            <>
                                <button
                                    className='btn btn-outline-primary m-1'
                                    style={{ width: 'auto' }}
                                    onClick={handleClick}
                                >
                                    Descargar <ArrowDropDownIcon />
                                </button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{ 'aria-labelledby': 'basic-button', }}
                                >
                                    <MenuItem onClick={(e) => { opcionModal('descargarVentas'); handleClose(); }}><ListItemIcon><svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-cloud-arrow-down-fill'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z' />
                                    </svg></ListItemIcon>Descargar ventas</MenuItem>
                                    <MenuItem onClick={(e) => { opcionModal('descargarVenta'); handleClose(); }}><ListItemIcon><svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-cloud-arrow-down'
                                        viewBox='0 0 16 16'
                                    >
                                        <path fill-rule='evenodd' d='M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z' />
                                        <path d='M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z' />
                                    </svg></ListItemIcon>Descargar venta</MenuItem>
                                </Menu>
                            </> : ''
                        }
                    </div>
                    <div className='col-md-3'>
                        <label>Cliente</label>
                        <AsyncSelect
                                    placeholder='&nbsp; Cliente'
                                    aria-label='Cliente'
                                    isClearable
                                    getOptionLabel={(option) => 'Cliente:' + option.nombre + ' - ' + option.rfc }
                                    getOptionValue={(option) => option.id}
                                    loadOptions={loadClientes}
                                    onChange={(selectedItem) => { 
                                        console.log(selectedItem);
                                        if(selectedItem !== undefined && selectedItem !== null)
                                        setClienteId(selectedItem?.id.toString());
                                    else
                                        setClienteId(0);
                                     }}
                            />
                    </div>
                    <div className='col-md-6 row'>
                    <div className='espacio20px'/>
                        <div className='col-md-6'>
                        <TextField fullWidth sx={{ m: 1 }}
                            id="filled-number"
                            label="Fecha Inicio"
                            type="datetime-local"
                            value={fechaInicio}
                            onChange={handleInputChangeFechaInicio}
                            InputLabelProps={{ shrink: true, }}
                        />
                        </div>
                        <div className='col-md-6'>
                        <TextField fullWidth sx={{ m: 1}} 
                            id="filled-number"
                            label="Fecha Fin"
                            type="datetime-local"
                            value={fechaFin}
                            onChange={handleInputChangeFechaFin}
                            InputLabelProps={{ shrink: true, }}
                        />
                        </div>
                    </div>
                  
                </div>
                <div className='col-md-12'>
                    <Tabs value={statusFiltro} onChange={handleChangeFiltroStatus} textColor="secondary" indicatorColor="secondary">
                        <Tab value="" label={<CustomTab label="Todos" count={totales?.todos} color='black' />} />
                        <Tab value="P" label={<CustomTab label="Pendientes" count={totales?.pendientes} color='blue' />} />
                        <Tab value="T" label={<CustomTab label="Terminados" count={totales?.terminados} color='yellowgreen' />} />
                        <Tab value="C" label={<CustomTab label="Cancelados" count={totales?.cancelados} color='grey' />} />
                        <Tab value="D" label={<CustomTab label="Devueltos" count={totales?.devueltos} color='pink' />} />
                        <Tab value="d" label={<CustomTab label="Devoluciones parciales" count={totales?.devueltosparcial} color='green' />} />
                    </Tabs>
                    <ListTable
                        columns={columns}
                        path={path}
                        reload={actualizarTabla}
                        setBusqueda={setBusqueda}
                        url={`Ventas/List?sucursal_Id=${getDataSession('sucursal')}&tipo_documento=${TipoDocto}&status=${statusFiltro}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&cliente_id=${clienteId}`}
                    />
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal} min={modalValor === 'exportarDocumentos'}>
                {modalValor === 'descargarVenta' ? (
                    <>
                        <br></br>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <div className='container'>
                                <div className=' row'>
                                    <h7>Descargar una venta de E-Commerce</h7>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        style={{ width: '250px' }}
                                        placeholder='Codigo de venta'
                                        value={CodigoVenta || ''}
                                        onChange={handleInputChange}
                                    />
                                    &nbsp;&nbsp;
                                    <button
                                        className='btn btn-outline-primary '
                                        onClick={(e) => DescargarVenta()}
                                        style={{ maxWidth: '200px' }}
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-plus-square'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                                        </svg>
                                        &nbsp; Descargar venta
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : ('')}
                {modalValor === 'descargarVentas' ? (
                    <>
                        <br></br>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <div className='container'>
                                <div className='row'>
                                    <h7>Descargar las ventas de E-Commerce a partir de la fecha:</h7>
                                    <input
                                        type='date'
                                        className='form-control'
                                        name='text'
                                        style={{ width: '250px' }}
                                        value={fechaDescarga || ''}
                                        onChange={handleInputChangeFechaDescarga}
                                    />
                                    &nbsp;&nbsp;
                                    <br></br>
                                    <button
                                        className='btn btn-outline-primary '
                                        onClick={(e) => DescargarTienda()}
                                        style={{ maxWidth: '200px' }}
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-plus-square'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                                        </svg>
                                        &nbsp; Descargar ventas
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : ('')}
                {modalValor === 'exportarDocumentos' ? (
                    <>
                        <div className='card' style={{ padding: 10 }}>
                            <div className='container'>
                                <div className='espacio10px' />
                                <div className='col-12 col-sm-12 col-md-12 row'>
                                    <div className='col-sm-2' />
                                    <div className='col-sm-8'>
                                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}><h3>Exportar {Docto}</h3></Stack>
                                        <div className='espacio10px' />
                                        <h5>Configuracion:</h5>
                                        <FormControlLabel control={<Checkbox name='conFiltros' checked={conFiltros} onChange={handelChangeExportarDocumentos} />} label="Usar los filtros de la tabla" />
                                        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.5}>
                                            <FormControlLabel control={<Radio name='cabecera' checked={cabecera} onChange={handelChangeExportarDocumentos} />} label="Informacion general" />
                                            <FormControlLabel control={<Radio name='conDetalle' checked={conDetalle} onChange={handelChangeExportarDocumentos} />} label="Desglosar el detalle de articulos" />
                                            <FormControlLabel control={<Radio name='detalleAgrupado' checked={detalleAgrupado} onChange={handelChangeExportarDocumentos} />} label="Agrupar detalle de articulos" />
                                        </Stack>
                                        <div className='espacio15px' />
                                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                            <button
                                                className='btn btn-outline-primary '
                                                onClick={(e) => descargarDocumentos()}
                                                style={{ maxWidth: '200px' }}
                                            ><svg xmlns='http://www.w3.org/2000/svg' width='20' eight='20' fill='currentColor' className='bi bi-file-excel' viewBox='0 0 16 16'>
                                                    <path d='M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z' />
                                                    <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                                                </svg>
                                                &nbsp; Descargar {Docto}
                                            </button>
                                        </Stack>
                                    </div>
                                    <div className='col-sm-2' />
                                </div>
                                <div className='espacio10px' />
                            </div>
                        </div>
                    </>
                ) : ('')}
                { modalValor === 'seleccionarAgente' ? (
                    <> 
                       <div className='card text-left'>
                        <div className='card-header'>
                            <div className='espacio5px' />
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}><h4>Asignar Agente</h4></Stack>
                            <div className='espacio10px' />
                        </div>
                        <div className='espacio10px' />
                        <div className='row'>
                            <div className='col-md-2'>
                            <label>&nbsp; Agente:</label>
                            </div>
                            <div className='col-md-8'>
                            <select
                                                    className='form-select'
                                                    name='agente_Id'
                                                    value={ventaAgente.agente_Id}
                                                    onChange={({ target })=>{ setVentaAgente({
                                                        ...ventaAgente,
                                                        ['agente_Id']: target?.value
                                                    });
                                                }}
                                                >
                                                    <option key={0} value={0} selected>Seleccione un agente</option> 
                                                    {agentes?.map((item) => {
                                                        return (
                                                            ventaAgente.agente_Id == item.id ?
                                                            <option key={item.id} value={item.id} selected>{item.nombre}</option> 
                                                            :  
                                                            <option key={item.id} value={item.id}>{item.nombre}</option>
                                                        );
                                                    })}
                             </select>

                                             </div>
                            <div className='col-md-2'>
                                   <button
                                                    className='btn btn-outline-success'
                                                    onClick={(e) => {
                                                        AsignarAgenteVenta()
                                                    }}
                                                    type='button'
                                                >
                                                    &nbsp; Seleccionar Agente
                                    </button>
                            </div>
                        </div>
                       
                       
                        <div className='espacio10px' />
                    </div>
                    </>) : ('')
                }
                 { modalValor === 'VerEntregas' ? (
                    <> 
                    <h4>Entregas</h4>
                    <div className='row' style={{border: "1px solid black"}}>
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Agente</th>
                                    <th>Recibe</th>
                                    <th>Folio</th>
                                    <th>Fecha Entrega</th>
                                    <th>Detalle</th>
                                </tr>
                                </thead>
                                <tbody>
                                {vxEntrega?.map(x=>{
                          return  ( 
                                    <tr>
                                    <td>{x?.nombre_Agente}</td>
                                    <td>{x?.recibe}</td>
                                    <td>{x?.folio}</td>
                                    <td>{x?.fecha_Entrega}</td>
                                    <td>
                                        <button
                                            className='btn btn-outline-primary m-1'
                                            style={{ width: 'auto' }}
                                            onClick={()=>{
                                                setVxEntregaDetalle(x.vx_Entregas_Detalles);
                                                setMostrar(mostrar ? false : true);
                                            }}
                                        >
                                            <VisibilityIcon/>
                                        </button>
                                    </td>
                                    </tr>)})}
                                </tbody>
                            </table>
                            </div>
                            { mostrar ? 
                            <div className='row' >
                               <h4>Detalle de entrega</h4>
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>Clave Articulo</th>
                                        <th>Articulo</th>
                                        <th>Cant. a Entregar</th>
                                        <th>Cant. Entregada</th>
                                        <th>Diferencia</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {vxEntregaDetalle?.map(x=>{
                                   return  ( 
                                        <tr>
                                        <td>{x?.clave}</td>
                                        <td>{x?.articulo}</td>
                                        <td>{x?.cant_Entregar}</td>
                                        <td>{x?.cant_Entregada}</td>
                                        <td>{x?.cant_Entregar - x?.cant_Entregada}</td>
                                        </tr>)})}
                                    </tbody>
                                </table> 
                            </div> : '' }
                       
                    </>) : ('')
                }
            </Modal>
        </>
    );
};

List.propTypes = { saludo: PropTypes.string };

List.defaultProps = { subtitulo: 'Soy subtitulo' };

export { List };