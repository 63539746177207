import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { useGlobalState } from 'resources/GlobalStateContext';
import { currency } from 'resources/utilities';
import { Link } from 'react-router-dom';
import ListTable from 'components/table/ListTable';
import TextField from '@mui/material/TextField';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';


const List = ({ history, match }) => {
    const { path } = match;
    const { t } = useTranslation("common");
    const [fechaInicio, setFechaInicio] = useState(GetFecha(true));
    const [fechaFin, setFechaFin] = useState(GetFecha(false));
    const [actualizarTabla, setActualizarTabla] = useState(false);
    const { globalTitulo, setGlobalTitulo } = useGlobalState();

    let [xvCobro, setVxCobro] = useState({});
    const [isOpenModal, openModal, closeModal] = useModal(false);

    const verDetallePago = (pago_id) => {
        fetchConToken(`VxCobros?id=${pago_id}`).then(resp=>{
            setVxCobro(resp);
        });        
        openModal();
    }

    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "verDetallePago",verDetallePago: verDetallePago },
        { field: 'folio', headerName: 'Folio', minWidth: 100 },
        { field: 'nombre_Cliente', headerName: 'Cliente', minWidth: 100},
        { field: 'importe', headerName: 'Importe', minWidth: 100, type: 'number', renderCell: ({ value }) => currency(value)  },
        { field: 'cancelado', headerName: 'Cancelado', minWidth: 100 },
        { field: 'aplicado', headerName: 'Aplicado', minWidth: 100 },
        { field: 'fecha', headerName: 'Fecha', minWidth: 80 , renderCell: ({ value }) => value?.substring(0,10)},
        { field: 'concepto_Cobro', headerName: 'Concepto Cobro', minWidth: 100 },
        { field: 'folio_Concepto', headerName: 'Folio Concepto', minWidth: 100 },
        
    ];

    
 

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Creditos',
            subtitulo: 'Listado de Creditos'
        });
    };

    const onClickButtonMenu = (slug) => {

    }

    const handleInputChangeFechaInicio = ({ target }) => {
        setFechaInicio(target.value);
    };

    const handleInputChangeFechaFin = ({ target }) => {
        setFechaFin(target.value);
    }

    function GetFecha(inicio) {
        var curr = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        if (!inicio) curr = new Date(curr.getFullYear(), curr.getMonth(), new Date(curr.getFullYear(), curr.getMonth() + 1, 0).getDate());

        curr.setDate(curr.getDate());
        return curr.toISOString().substring(0, 10);
    }


    useEffect(() => actualizarTitulo(), []);


    return (
        <>
            <div className='espacio10px' />
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-2'>
                        <Link to={`${path}/add`} className='btn btn-outline-primary m-1' style={{ width: 'auto' }}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; {t('app.SwalFire.nuevo')}
                        </Link>
                    </div>
                    <div className='col-sm-5' />
                    <div className='col-sm-5' style={{ display: 'flex' }}>
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={handleInputChangeFechaInicio}
                            InputLabelProps={{ shrink: true, }}
                        />
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Fin"
                            type="date"
                            value={fechaFin}
                            onChange={handleInputChangeFechaFin}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>
                </div>
                <div className='col-md-12'>
                    <ListTable 
                        columns={columns} 
                        path={path} 
                        reload={actualizarTabla} 
                        url={`VxCobros/GetList?sucursal_Id=${getDataSession('sucursal')}&naturaleza=R&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`} />
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal} >
                <div className='col-md-12 row'>
                    <div className='col-md-3'>
                        <h4>Cliente</h4>
                        <label>Cliente:</label><p><label>{xvCobro?.cliente?.nombre}</label></p>
                        <label>Telefono 1:</label><p>{xvCobro?.cliente?.telefono1}</p>
                        <label>Correo:</label><p>{xvCobro?.cliente?.correo}</p>
                        <label>RFC:</label><p>{xvCobro?.cliente?.rfc}</p>
                    </div>
                    <div className='col-md-3'>
                         <h4>Cobro</h4>
                         <label>Concepto Cobro:</label><p>{xvCobro?.concepto_Cobro}</p>
                         <label>Forma Cobro:</label><p>{xvCobro?.forma_Cobro_Cc}</p>
                         <label>Descripcion:</label><p>{xvCobro?.descripcion}</p>

                    </div>
                    <div className='col-md-3'>
                        <h4>Cobro</h4>
                        <label>Num. Cta. Pago:</label><p>{xvCobro?.num_Cta_Pago}</p>
                        <label>Referencia:</label><p>{xvCobro?.referencia}</p>
                        <label>Fecha Registro:</label><p>{xvCobro?.fecha_Registro}</p>
                    </div>
                    <div className='col-md-3'> 
                        <h4>Datos</h4>
                        <label>Cancelado:</label><p>{xvCobro?.cancelado}</p>
                        <label>Aplicado:</label><p>{xvCobro?.aplicado}</p>
                        <label>Folio:</label><p>{xvCobro?.folio}</p>
                    </div>
                </div>
                <h4>Detalle Cobro</h4>
                <div className='row' style={{border: "1px solid black"}}>
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Folio Adeudo:</th>
                                    <th>Importe:</th>
                                    <th>Adeudo:</th>
                                    <th>Acreditado:</th>
                                    <th>Cancelado:</th>
                                    <th>Aplicado:</th>
                                </tr>
                                </thead>
                                <tbody>
                                {xvCobro?.vx_Importe_CC?.map(x=>{
                          return  ( 
                                    <tr>
                                    <td>{x?.folio_Concepto}</td>
                                    <td>{currency(x?.importe)}</td>
                                    <td>{currency(x?.adeudo)}</td>
                                    <td>{currency(x?.acreditado)}</td>
                                    <td>{x?.cancelado}</td>
                                    <td>{x?.aplicado}</td>
                                    </tr>)})}
                                </tbody>
                            </table>
                </div>
           </Modal>
        </>
    );
}

export { List };