import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';
import { Clientes } from 'views/clientes/Index';
import { Users } from 'views/users/Index';
import { Articulos } from 'views/articulos/Index';
import { Documentos } from 'views/documentos/Index';
import { Configempresa } from 'views/configuraciones/Index';
import { Configtienda } from 'views/tienda/Index';
import { Impuestos } from 'views/impuestos/Index';
import { Facturas } from 'views/facturas/Index';
import { Usuarios } from 'views/usuarios/Index';
import { SeriesEmpresa } from 'views/configuraciones/SeriesEmpresa';
import { CamposEmpresa } from 'views/camposempresa/Index';
import { Paquetes } from 'views/paquetes/Index';
import { Carrito } from 'views/carrito/Index';
import { PlanesYPrecios } from 'views/paquetes/PlanesYPrecios';
import { Facturar } from 'views/documentos/Facturar';
import { Movimientos } from 'views/movimientos/Index';
import { BolsaTimbres } from 'views/bolsatimbres/Index';
import { FacturacionGlobal } from 'views/facturacion/FacturacionGlobal'
import { ListRecibidos } from 'views/facturas/ListRecibidos';
import { DescargaSAT } from 'views/descargasat/Index';
import { SellosFis } from 'views/configuraciones/SellosFiscales';
import { Sucursales } from 'views/configuraciones/Sucursales';
import { Ayuda } from 'views/configuraciones/Ayuda';
import { Soporte } from 'views/configuraciones/Soporte';
import { MiPerfil } from 'views/usuarios/MiPerfil';
import { MercadoLibre } from 'views/configuraciones/MercadoLibre';
import { TiendaNube } from 'views/configuraciones/TiendaNube';
import { File } from 'views/archivos/File';
import { LogNotificaciones } from 'views/LogNotificaciones/Index';
import { Pagos } from 'views/pagos/Index';
import { IngresosMensuales } from 'views/estadisticas/IngresosMensuales';
import { IngresosDiarios } from 'views/estadisticas/IngresosDiarios';
import { EgresosMensuales } from 'views/estadisticas/EgresosMensuales';
import { IngresosVsEgresosMensuales } from 'views/estadisticas/IngresosVsEgresosMensuales';
import { TopClientes } from 'views/estadisticas/TopClientes';
import { TopProveedores } from 'views/estadisticas/TopProveedores';
import { PagoMercadoPago } from 'views/carrito/PagoMercadoPago';
import { Procesos } from 'views/Procesos/Mensajes/Index';
import { TiposMensajes } from 'views/Procesos/TiposMensajes/TiposMensajes';
import { RespuestasMensajes } from 'views/Procesos/RespuestasMensajes/RespuestasMensajes';
import { Empresas } from 'views/empresas/Index';
import { Categorias } from 'views/categorias/Index';
import { Etiquetas } from 'views/etiquetas/Index';
import { Atributos } from 'views/atributos/Index';
import { Almacenes } from 'views/almacenes/Index';
import { Compras } from 'views/compras/Index';
import { Proveedores } from 'views/proveedores/Index';
import { CondicionPago } from 'views/condicionpago/Index';
import { ListaPrecios } from 'views/listaprecios/Index';
import { RolesClaves } from 'views/rolesclaves/Index';
import { GrupoRutas } from 'views/VxRuta/Catalogos/GrupoRutas/Index';
import { VxRutas } from 'views/VxRuta/Catalogos/Rutas/Index';
import { VxPedidos } from 'views/VxRuta/Movimiento/Pedido/Index';
import { VxFacturas } from 'views/VxRuta/Movimiento/Facturas/Index';
import { VxSaldosProveedores } from 'views/VxRuta/Movimiento/SaldosProveedores/Index';
import { VxVentas } from 'views/VxRuta/Movimiento/Venta/Index';
import { VxOtrosDocumentos } from 'views/VxRuta/Movimiento/OtrosDocumentos/Index';
import { VxPlanVisita } from 'views/VxRuta/Catalogos/PlanVisita/Index';
import { VxCobros } from 'views/VxRuta/Movimiento/Cobros/Index';
import { VxCreditos } from 'views/VxRuta/Movimiento/Creditos/Index';
import { VxCargos } from 'views/VxRuta/Movimiento/Cargos/Index';
import { VxCreditosProv } from 'views/VxRuta/Movimiento/CreditosProv/Index';
import { ConfigAppMaxventas } from 'views/VxRuta/ConfigAppMaxventas/Index';
import { VxVisitas } from 'views/VxRuta/Movimiento/Visitas/Index';
import { VxRazonVisita } from 'views/VxRuta/Catalogos/RazonVisita/Index';
import { VxAgentes } from 'views/VxRuta/Catalogos/Agentes/Index';
import { VxEntregas } from 'views/VxRuta/Movimiento/Entregas/Index';
import { CreditoCliente } from 'views/VxRuta/Movimiento/CreditoCliente/Index';
import { VxCuentasBancos } from 'views/VxRuta/Catalogos/CuentasBancos/Index';
import { Altiria } from 'views/VxRuta/Altiria/Config/Index';
import { SmsCobranza } from 'views/VxRuta/Altiria/SmsCobranza/Index';
import { VxFormasCobrosCc } from 'views/VxRuta/Catalogos/FormasCobroCC/Index';
import { ListSMS } from 'views/VxRuta/Altiria/SmsCobranza/List';
import { VxRecorrido } from 'views/VxRuta/Monitoreo/Recorrido/Recorrido';
import { VxUbicacion } from 'views/VxRuta/Monitoreo/Ubicacion/Ubicacion';




let DashboardComponent = lazy(() => import('./dashboard'));

//const Programa = lazy(() => import('views/puntos'));
//<Route exact path={SLUGS.overview} component={Programa} />
//sucursaladdedit: '/branch/addedit',
////<Route exact path={SLUGS.sucursaladdedit} component={AddEdit} />

function PrivateRoutes() {
    const { pathname } = useLocation();
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                <Route path={SLUGS.dashboard} component={DashboardComponent} />

                <Route path={SLUGS.facturar} component={Facturar} />
                <Route path={SLUGS.facturas} component={Facturas} />
                <Route path={SLUGS.facturasRecibidas} component={ListRecibidos} />
                <Route path={SLUGS.descargasat} component={DescargaSAT} />
                <Route path={SLUGS.impuestos} component={Impuestos} />
                <Route path={SLUGS.articulos} component={Articulos} />
                <Route path={SLUGS.clientes} component={Clientes} />
                <Route path={SLUGS.pagos} component={Pagos} />
                <Route path={SLUGS.documentos} component={Documentos} />
                <Route path={SLUGS.usuarios} component={Usuarios} />

                <Route path={SLUGS.categorias} component={Categorias} />
                <Route path={SLUGS.etiquetas} component={Etiquetas} />
                <Route path={SLUGS.atributos} component={Atributos} />
                <Route path={SLUGS.almacenes} component={Almacenes} />
                <Route path={SLUGS.listaprecios} component={ListaPrecios} />
                <Route path={SLUGS.rolesclaves} component={RolesClaves} />
                <Route path={SLUGS.compras} component={Compras} />

                <Route path={SLUGS.mensajes} component={Procesos} />
                <Route path={SLUGS.tiposMensajes} component={TiposMensajes} />
                <Route path={SLUGS.respuestasMensajes} component={RespuestasMensajes} />
                <Route path={SLUGS.empresas} component={Empresas} />
                <Route path={SLUGS.proveedores} component={Proveedores} />
                <Route path={SLUGS.condicionpago} component={CondicionPago} />

                <Route path={SLUGS.movimientos} component={Movimientos} />
                <Route path={SLUGS.bolsatimbres} component={BolsaTimbres} />
                <Route path={SLUGS.paquetes} component={Paquetes} />
                <Route path={SLUGS.lognotificaciones} component={LogNotificaciones} />
                <Route path={SLUGS.planesyprecios} component={PlanesYPrecios} />
                <Route path={SLUGS.miperfil} component={MiPerfil} />
                <Route path={SLUGS.empresa} component={Configempresa} />
                <Route path={SLUGS.sellosfiscales} component={SellosFis} />
                <Route path={SLUGS.sucursales} component={Sucursales} />
                <Route path={SLUGS.ayuda} component={Ayuda} />
                <Route path={SLUGS.soporte} component={Soporte} />
                <Route path={SLUGS.carrito} component={Carrito} />

                <Route path={SLUGS.mercadolibre} component={MercadoLibre} />
                <Route path={SLUGS.tiendanube} component={TiendaNube} />
                <Route path={SLUGS.file} component={File} />
                <Route path={SLUGS.est_ingresos_diarios} component={IngresosDiarios} />
                <Route path={SLUGS.est_ingresos_mensuales} component={IngresosMensuales} />
                <Route path={SLUGS.est_egresos_mensuales} component={EgresosMensuales} />
                <Route path={SLUGS.est_ingresos_vs_egresos_mensuales} component={IngresosVsEgresosMensuales} />
                <Route path={SLUGS.est_top_proveedores} component={TopProveedores} />
                <Route path={SLUGS.est_top_clientes} component={TopClientes} />

                <Route path={SLUGS.pagomercadopago} component={PagoMercadoPago} />

                <Route path={SLUGS.vxcuentasbancos} component={VxCuentasBancos} />
                <Route path={SLUGS.vxgruporutas} component={GrupoRutas} />
                <Route path={SLUGS.vxrutas} component={VxRutas} />
                <Route path={SLUGS.vxCobros} component={VxCobros} />
                <Route path={SLUGS.vxCreditos} component={VxCreditos} />
                <Route path={SLUGS.vxCargos} component={VxCargos} />
                <Route path={SLUGS.vxvisitas} component={VxVisitas} />
                <Route path={SLUGS.vxrazonvisitas} component={VxRazonVisita} />
                <Route path={SLUGS.vxagentes} component={VxAgentes} />
                <Route path={SLUGS.vxentregas} component={VxEntregas} />
                <Route path={SLUGS.creditocliente} component={CreditoCliente} />
                <Route path={SLUGS.vxformascobroscc} component={VxFormasCobrosCc} />
                
                  
                <Route path={SLUGS.vxcreditosProveedores} component={VxCreditosProv} />
                <Route path={SLUGS.ConfigAppMaxventas} component={ConfigAppMaxventas} />
                <Route path={SLUGS.altiria} component={Altiria} />
                <Route path={SLUGS.smscobranza} component={SmsCobranza} />
                <Route path={SLUGS.listaSMS} component={ListSMS} />
                <Route path={SLUGS.vxrecorrido} component={VxRecorrido} />
                <Route path={SLUGS.vxubicacion} component={VxUbicacion} />
                 
                
                

                <Route path={SLUGS.vxpedidos} component={VxPedidos} />
                <Route path={SLUGS.vxfacturas} component={VxFacturas} />
                <Route path={SLUGS.vxsaldosproveedores} component={VxSaldosProveedores} />
                <Route path={SLUGS.vxventas} component={VxVentas} />
                <Route path={SLUGS.vxotrosdocumentos} component={VxOtrosDocumentos} />
                <Route path={SLUGS.vxplanvisita} component={VxPlanVisita} />

                <Route path={SLUGS.FacturacionGlobal} component={FacturacionGlobal} />
                <Route path={SLUGS.seriesEmpresa} component={SeriesEmpresa} />
                <Route path={SLUGS.seriesEmpresaEdit} component={SeriesEmpresa} />
                {/*              
                
                <Route path={SLUGS.tienda} component={Configtienda} />
                <Route path={SLUGS.camposempresa} component={CamposEmpresa} />
                <Route path={SLUGS.FacturacionGlobal} component={FacturacionGlobal} />
                
                 */}

                {/*<Route path={SLUGS.ideasThree} render={() => <div>ideasThree</div>} />
                <Route path={SLUGS.subscription} render={() => <div>subscription</div>} />*/}
                <Redirect to={SLUGS.articulos} />
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;