import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { useGlobalState } from 'resources/GlobalStateContext';
import { currency } from 'resources/utilities';
import { Link } from 'react-router-dom';
import ListTable from 'components/table/ListTable';
import TextField from '@mui/material/TextField';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';


import logo from 'images/venta/SIN-IMAGEN.jpg'


const Config = ({ history, match }) => {
    const { path } = match;
    const { t } = useTranslation("common");
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const baseUrl = process.env.REACT_APP_SERVER_URL;

    let [logocolor, setLogocolor] = useState({
        empresa_Id: 1,
        nombre: 'app_logo_color',
        valor: '',
        tipo:'imagen',
        es_Visible: false,
        descripcion: 'Logo a color de la app',
        categoria: 'app_maxventa'
    });
    let [logoblanco, setLogoblanco] = useState({
        empresa_Id: 1,
        nombre: 'app_logo_blanco',
        valor: '',
        tipo:'imagen',
        es_Visible: false,
        descripcion: 'Logo blanco de la app',
        categoria: 'app_maxventa'
    });
    let [imagenfondologin, setImagenfondologin] = useState({
        empresa_Id: 1,
        nombre: 'app_fondo_login',
        valor: '',
        tipo:'imagen',
        es_Visible: false,
        descripcion: 'Fondo del login de la app',
        categoria: 'app_maxventa'
    });
    let [imagenbarra, setImagenbarra] = useState({
        empresa_Id: 1,
        nombre: 'app_imagen_barra',
        valor: '',
        tipo:'imagen',
        es_Visible: false,
        descripcion: 'Imagen de la barra de la app',
        categoria: 'app_maxventa'
    });
    let [colorprimario, setColorprimario] = useState({
        empresa_Id: 1,
        nombre: 'app_color_primario',
        valor: '',
        tipo:'texto',
        es_Visible: false,
        descripcion: 'Color primario de la app',
        categoria: 'app_maxventa'
    });
    let [colorsecundario, setColorsecundario] = useState({
        empresa_Id: 1,
        nombre: 'app_color_secundario',
        valor: '',
        tipo:'texto',
        es_Visible: false,
        descripcion: 'Color secundario de la app',
        categoria: 'app_maxventa'
    });
    let [icon, setIcon] = useState({
        empresa_Id: 1,
        nombre: 'app_icon',
        valor: '',
        tipo:'imagen',
        es_Visible: false,
        descripcion: 'Icono principal de la app',
        categoria: 'app_maxventa'
    });

/*
    let [logocolor, setLogocolor] = useState('');
    let [logoblanco, setLogoblanco] = useState('');
    let [imagenfondologin, setImagenfondologin] = useState('');
    let [imagenbarra, setImagenbarra] = useState('');
    let [colorprimario, setColorprimario] = useState('');
    let [colorsecundario, setColorsecundario] = useState('');

    let [icon, setIcon] = useState('');*/

    const [isOpenModal, openModal, closeModal] = useModal(false);

    
 

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'APP MaxVentas',
            subtitulo: 'Configuracion de MaxVentas'
        });
    };

    function cargaDatos(){
        fetchConToken(`ConfiguracionesEmpresas?empresa_id=1&nombre=app_logo_color`)
        .then((response) => {
            if (response.hasOwnProperty('id') && response.id > 0) {
                setLogocolor({...logocolor, ["valor"]:response.valor});
            }
        });
        fetchConToken(`ConfiguracionesEmpresas?empresa_id=1&nombre=app_logo_blanco`)
        .then((response) => {
            if (response.hasOwnProperty('id') && response.id > 0) {
                setLogoblanco({...logoblanco, ["valor"]:response.valor});
                console.log(logoblanco);
            }
        });
        fetchConToken(`ConfiguracionesEmpresas?empresa_id=1&nombre=app_fondo_login`)
        .then((response) => {
            if (response.hasOwnProperty('id') && response.id > 0) {
                setImagenfondologin({...imagenfondologin, ["valor"]:response.valor});
            }
        });
        fetchConToken(`ConfiguracionesEmpresas?empresa_id=1&nombre=app_imagen_barra`)
        .then((response) => {
            if (response.hasOwnProperty('id') && response.id > 0) {
                setImagenbarra({...imagenbarra, ["valor"]:response.valor});
            }
        });
        fetchConToken(`ConfiguracionesEmpresas?empresa_id=1&nombre=app_color_primario`)
        .then((response) => {
            if (response.hasOwnProperty('id') && response.id > 0) {
                setColorprimario({...colorprimario, ["valor"]:response.valor});
            }
        });
        fetchConToken(`ConfiguracionesEmpresas?empresa_id=1&nombre=app_color_secundario`)
        .then((response) => {
            if (response.hasOwnProperty('id') && response.id > 0) {
                setColorsecundario({...colorsecundario, ["valor"]:response.valor});
            }
        });
        fetchConToken(`ConfiguracionesEmpresas?empresa_id=1&nombre=app_icon`)
        .then((response) => {
            if (response.hasOwnProperty('id') && response.id > 0) {
                setIcon({...icon, ["valor"]:response.valor});
            }
        });



    }
  


    useEffect(() => {actualizarTitulo(); cargaDatos(); }, []);

    const handleInputChangeFileConfiglogoblanco = ({ target }) => {
        handleInputChangeFileConfig(target.files[0], target.files[0].name, 'logoblanco');
    }
    const handleInputChangeFileConfigimagenfondologin = ({ target }) => {
        handleInputChangeFileConfig(target.files[0], target.files[0].name, 'fondologin');
    }
    const handleInputChangeFileConfiglogocolor = ({ target }) => {
        handleInputChangeFileConfig(target.files[0], target.files[0].name, 'logocolor');
    }
    const handleInputChangeFileConfigimagenbarra = ({ target }) => {
        handleInputChangeFileConfig(target.files[0], target.files[0].name, 'imagenbarra');
    }
    const handleInputChangeFileConfigicon = ({ target }) => {
        handleInputChangeFileConfig(target.files[0], target.files[0].name, 'icon');
    }
    
    

    const handleInputChangeFileConfig = (file,name,opcion) => {
        const formData = new FormData();

        formData.append('empresa_id', 1);
        formData.append('sucursal_id', getDataSession('sucursal'));
        formData.append('tipo', 'imagenes');

        formData.append('FileToUpload', file, name);

        fetchConToken('Archivos', formData, 'POST', true)
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }

                switch(opcion){
                    case 'logoblanco':
                        setLogoblanco({...logoblanco, ["valor"]:response.url});
                    break;
                    case 'fondologin':
                        setImagenfondologin({...imagenfondologin,["valor"]:response.url});
                    break;
                    case 'logocolor':
                        setLogocolor({...logocolor,["valor"]:response.url});
                    break;
                    case 'imagenbarra':
                        setImagenbarra({...imagenbarra,["valor"]:response.url});
                    break;
                    case 'icon':
                        setIcon({...icon,["valor"]:response.url});
                    break;
                    
                }          
            })
            //.catch(alertService.error);
    };


    const handleColorChangePrimary = (e) => {
        setColorprimario({...colorprimario,["valor"]: e.target.value});
      };
      const handleColorChangeSecundary = (e) => {
        setColorsecundario({...colorsecundario,["valor"]: e.target.value});
      };


      const guardar = () =>
      {
         fetchConToken('ConfiguracionesEmpresas', logocolor, 'POST')
                .then((response) => {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        var erroresC = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return false;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }             
                });
        
                fetchConToken('ConfiguracionesEmpresas', logoblanco, 'POST')
                .then((response) => {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        var erroresC = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return false;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }             
                });
        
                fetchConToken('ConfiguracionesEmpresas', imagenfondologin, 'POST')
                .then((response) => {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        var erroresC = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return false;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }             
                });
        
                fetchConToken('ConfiguracionesEmpresas', imagenbarra, 'POST')
                .then((response) => {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        var erroresC = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return false;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }             
                });
        
                fetchConToken('ConfiguracionesEmpresas', colorprimario, 'POST')
                .then((response) => {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        var erroresC = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return false;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }             
                });
        
                fetchConToken('ConfiguracionesEmpresas', colorsecundario, 'POST')
                .then((response) => {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        var erroresC = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return false;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }             
                });
        

                fetchConToken('ConfiguracionesEmpresas', icon, 'POST')
                .then((response) => {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        var erroresC = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return false;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }             
                });
        
                Swal.fire("Correcto","Guardado","success");
      }
        

    return (
        <>
         <div className='espacio10px' />
            <div className='container-fluid'>
            <div className='col-md-12 text-right'>
                                    <button className='btn btn-outline-success' type='button' onClick={() => { guardar() }}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-check-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                        </svg>
                                        &nbsp; Guardar
                                    </button>
                                  
                                </div>
                <div className='col-12 col-sm-12 col-md-12 row'>
                <div className='col-md-12 row'>
                <div className='col-md-6'>
                        <label
                            className='form-label'
                        >
                        Logo blanco <small>({logoblanco.valor?.substring(logoblanco.valor?.lastIndexOf('/') + 1)})</small>
                        </label>
                        <input
                            type='file'
                            className='form-control'
                            name={logoblanco.valor}
                            onChange={
                            handleInputChangeFileConfiglogoblanco
                            }
                        />

<label
                            className='form-label'
                        >
                        Logo color <small>({logocolor.valor?.substring(logocolor.valor?.lastIndexOf('/') + 1)}) </small>
                        </label>
                        <input
                            type='file'
                            className='form-control'
                            name={logocolor.valor}
                            onChange={
                            handleInputChangeFileConfiglogocolor
                            }
                        />

                       <label
                            className='form-label'
                        >
                        Imagen fondo login <small>({imagenfondologin.valor?.substring(imagenfondologin.valor?.lastIndexOf('/') + 1)})</small>
                        </label>
                        <input
                            type='file'
                            className='form-control'
                            name={imagenfondologin.valor}
                            onChange={
                            handleInputChangeFileConfigimagenfondologin
                            }
                        />
                </div>
                <div className='col-md-6'>
                    
                <label
                                            className='form-label'
                                        >
                                        Imagen barra <small>({imagenbarra.valor?.substring(imagenbarra.valor?.lastIndexOf('/') + 1)})</small>
                                        </label>
                                        <input
                                            type='file'
                                            className='form-control'
                                            name={imagenbarra.valor}
                                            onChange={
                                            handleInputChangeFileConfigimagenbarra
                                            }
                                        />

                <label
                                            className='form-label'
                                        >
                                        Icon APP <small>({icon.valor?.substring(icon.valor?.lastIndexOf('/') + 1)})</small>
                                        </label>
                                        <input
                                            type='file'
                                            className='form-control'
                                            name={icon.valor}
                                            onChange={
                                            handleInputChangeFileConfigicon
                                            }
                                        />


                <label htmlFor="colorPicker">Color Primario:</label>
                    <input
                        type="color"
                        id="colorPicker"
                        value={colorprimario.valor}
                        onChange={handleColorChangePrimary}
                    />

                <br/>
                <label htmlFor="colorPicker">Color Secundario:</label>
                    <input
                        type="color"
                        id="colorPicker"
                        value={colorsecundario.valor}
                        onChange={handleColorChangeSecundary}
                    />

                </div>
                
                </div>
                <div className='espacio45px' />
                <div className='espacio45px' />
                <div className='row' style={{backgroundColor: "gainsboro"}}>
                    <div className='col-md-6' style={{paddingLeft: "100px"}}>
                        <h3>Pantalla Login</h3>
                    <div class="login-container-app" style={{backgroundImage: "url("+baseUrl +imagenfondologin.valor+")"}}>
                        <form class="login-form-app">
                            <div class="logo-app">
                                <img alt="Campori Logo" src={logoblanco.valor?.length > 0 ?  baseUrl + logoblanco.valor : logo }/>
                            </div>
                            <div class="input-group-app">
                                <input type="email" placeholder="Correo" required/>
                            </div>
                            <div class="input-group-app">
                                <input type="password" placeholder="Contraseña" required/>
                            </div>
                            <div className='espacio45px' />
                            <button type="submit" class="btn-app login-btn-app">Iniciar sesión</button>
                            <button type="button" class="btn-app create-account-btn-app">Crear una cuenta</button>
                            <a href="#" class="forgot-password-app">Olvidé mi contraseña</a>
                            <div className='espacio45px' />

                        </form>
                    </div>
                    </div>
                    <div className='col-md-6' style={{padding: "0"}}>
                    <h3>Pantalla Principal</h3>
                        <div class="top-bar-app" style={{backgroundImage: "url("+baseUrl +imagenbarra.valor+")"}}>
                            <button class="menu-button-app">&#9776;</button>
                            <span class="title-app">Acerca De</span>
                        </div>

                        <div class="content-app" style={{background: "white",    padding: "0"}}>
                        <div className='espacio45px' />
                        <div className='espacio45px' />
                      
                        <img alt="Campori Logo" src={logocolor.valor?.length > 0 ?  baseUrl + logocolor.valor : logo } style={{width: "80%"}}/>
                            <p>App para generar pedidos<br/>versión 1.0.0<br/>Developed by Atti Solutions<br/>"Diseñamos para acelerar tus ventas"</p>
                            <div className='espacio45px' />
                        </div>
                        
                        <div class="bottom-nav-app">
                            <div class="nav-item-app">
                                <i class="icon-app">&#128722;</i>
                                <span>Comprar</span>
                            </div>
                            <div class="nav-item-app">
                                <i class="icon-app">&#128100;</i>
                                <span>Pedidos</span>
                            </div>
                            <div class="nav-item">
                                <i class="icon-app">&#128179;</i>
                                <span>Cartera</span>
                            </div>
                            <div class="nav-item-app">
                                <i class="icon-app">&#128203;</i>
                                <span>Facturas</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6' style={{backgroundColor: "gainsboro" ,paddingLeft: "100px"}}>
                    <h3>Pantalla Menu</h3>
                    <div class="sidebar-app">
                            <div class="sidebar-header-app" style={{backgroundColor: colorprimario.valor}}>
                                <div class="logo-app">
                                    <img alt="Campori Logo" src={logoblanco.valor?.length > 0 ?  baseUrl + logoblanco.valor : logo }/>
                                </div>
                                <p>Frutas, verduras y abarrotes</p>
                            </div>

                            <ul class="nav-list-app" style={{backgroundColor: colorsecundario.valor}}>
                                <li class="nav-item-app">
                                    <i class="icon-app">&#128100;
                                    <span>Perfil</span></i>
                                </li>
                                <li class="nav-item-app active">
                                    <i class="icon-app">&#128722;
                                    <span>Comprar</span></i>
                                </li>
                                <li class="nav-item-app">
                                    <i class="icon">&#128221;
                                    <span>Pedidos</span></i>
                                </li>
                                <li class="nav-item-app">
                                    <i class="icon">&#128179;
                                    <span>Cartera</span></i>
                                </li>
                                <li class="nav-item-app">
                                    <i class="icon">&#128203;
                                    <span>Facturas</span></i>
                                </li>
                                <li class="nav-item-app">
                                    <i class="icon-app">&#128276;
                                    <span>Notificaciones</span></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-6' style={{backgroundColor: "gainsboro"}}>
                            <h3>Icono APP.</h3>
                            <div class="card">
                                <div className='row'>
                                <div className='col-md-6'>
                                <div class="menu-item-app" style={{    backgroundColor: "#cfcfcf"}}>
                                    <img  src={icon.valor?.length > 0 ?  baseUrl + icon.valor : logo} style={{ maxWidth: "50px", maxHeight: "50px", marginLeft: "96px"}}/>
                                    <b><span class="menu-text-app" style={{color:"red"}}>Max Ventas</span></b>
                                </div>
                                <div class="menu-item-app">
                                    <i class="icon-app">&#x1F4C5;</i> 
                                    <span class="menu-text-app">Calendario</span>
                                </div>
                                <div class="menu-item-app">
                                    <i class="icon-app">&#x1F50D;</i> 
                                    <span class="menu-text-app">Buscar</span>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                <div class="menu-item-app">
                                    <i class="icon-app">&#x1F4F7;</i> 
                                    <span class="menu-text-app">Cámara</span>
                                </div>
                                <div class="menu-item-app">
                                    <i class="icon-app">&#x1F464;</i> 
                                    <span class="menu-text-app">Perfil</span>
                                </div>
                                <div class="menu-item-app">
                                    <i class="icon-app">&#x1F3E0;</i> 
                                    <span class="menu-text-app">Configuracion</span>
                                </div>
                                </div>
                                </div>
                            </div>
                    </div>
                    <div className='espacio45px' />
                    <div className='espacio45px' />
                    </div>
                   
                </div>
                <div className='espacio45px' />
            </div>
        </>
    );
}

export { Config };