import React, { useEffect } from 'react';
import { getDataSession } from 'views/Utils/Common';
import { useLocation } from 'react-router';
import { useGlobalState } from 'resources/GlobalStateContext';
import { AuthTienda } from './AuthTienda';

const MercadoLibre = ({ history, match, saludo, subtitulo }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let { search } = useLocation();
    let query = new URLSearchParams(search);
    let code = query.get('code');
    const id = getDataSession('sucursalMercadoLibre');
    //console.log(id)
    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Empresa',
            subtitulo: ''
        });
    };

    useEffect(() => { actualizarTitulo(); }, []);

    return (<AuthTienda tienda='MERCADOLIBRE' code={code} sucursal_id={id}></AuthTienda>);
};

export { MercadoLibre };
