import React, { useState, useEffect, useRef } from 'react';
import { fetchConToken,fetchConTokenFile,handleResponse } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { MenuDescargas } from '../MenuDescargas';
import { useGlobalState } from 'resources/GlobalStateContext';
import { currency } from 'resources/utilities';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import ListTable from 'components/table/ListTable';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';
import Modal from 'components/modal/Modal';
import { alertService } from '_services';
import { useModal } from 'hooks/useModal';
import { useTranslation } from 'react-i18next';
import Add_File from 'images/panel/add-image.png'
import AsyncSelect from 'react-select/async';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { descargarArchivo } from 'actions/archivos';


const List = ({ history, match }) => {
    const { path } = match;
    const { t } = useTranslation('common');
    const seleccionarArchivo = useRef(null);
    const [fechaPago, setFechaPago] = useState(GetFecha(true));
    const [fechaDeposito, setFechaDeposito] = useState(GetFecha(true));
    const [fechaInicio, setFechaInicio] = useState(GetFecha(true));
    const [fechaFin, setFechaFin] = useState(GetFecha(false));
    const [estatus, setEstatus] = useState('00');
    const [estatusExtra, setEstatusExtra] = useState('00');
    const [tipoFecha, setTipoFecha] = useState('Registro');
    const [actualizarTabla, setActualizarTabla] = useState(false);
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [asignacion, setAsignacion] = useState('');
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState(false);
    const [cuentasBanco, setCuentasBanco] = useState([]);
    const [cuentaBanco, setCuentaBanco] = useState('');
    const [factura, setFactura] = useState(null);
    const [nombreArchivo, setNombreArchivo] = useState('Ningun archivo seleccionado');
    const [archivo, setArchivo] = useState(null);
    const [dataStatus, setDataStatus] = useState([]);
    const [dataStatusExtra, setDataStatusExtra] = useState([]);
    const [pagado, setPagado] = useState(false);
    const [PDF,setPDF] = useState(['']);
    const [initialAgente, setInitialAgente] = useState(true);
    let [totales, setTotales] = useState([]);
    let [statusFiltro, setStatusFiltro] = useState("T");
    

   


    const [dataMenu] = useState([
        {
            Titulo: 'Facturas Pagadas',
            Data: [
                { Titulo: 'Descargar Excel', Slug: 'EXCEL' },
                { Titulo: 'Descargar PDF', Slug: 'PDF' }
            ]
        }, {
            Titulo: 'Facturas Faltantes de Pago',
            Data: [
                { Titulo: 'Descargar Excel', Slug: 'EXCEL' },
                { Titulo: 'Descargar PDF', Slug: 'PDF' }
            ]
        }, {
            Titulo: 'Ficha de Pago',
            Data: [
                { Titulo: 'Descargar Excel', Slug: 'EXCEL' },
                { Titulo: 'Descargar PDF', Slug: 'PDF' }
            ]
        }]);

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Facturas',
            subtitulo: 'Listado de Facturas'
        });
    };

    const handleClick = () => seleccionarArchivo.current.click();

    const handleInputChangeFileConfig = ({ target }) => {
        if (target && target.files[0]) {
            setNombreArchivo(target.files[0].name);
            setArchivo(target.files[0]);
        }
    };

    const handleClickSubirExcel = () => {
        if (archivo) {
            const formData = new FormData();
            formData.append('empresa_id', getDataSession('empresa'));
            formData.append('sucursal_id', getDataSession('sucursal'));
            formData.append('tipo', 'excel');
            formData.append('FileToUpload', archivo, nombreArchivo);
            fetchConToken('Archivos', formData, 'POST', true).then((response) => { console.log(response) }).catch(alertService.error);
        }
        else Swal.fire('Error', 'Seleccione el archivo', 'error');
    }

    const asignarDatosExtraFactura = (factura_id) => {
        setFechaPago(GetFecha(true));
        setFactura(null);
        setEstatusExtra('00');
        setAsignacion(factura_id);
        loadFactura(factura_id);
        setModalValor('AsignarDatosExtraFactura');
        openModal();
    }

    const verNotasCredito = (factura_id) => {
        setFactura(null);
        setAsignacion(factura_id);
        loadFactura(factura_id);
        setModalValor('VerNotasCredito');
        openModal();
    }

    const validarFormaPagoDatosExtra = (formaPago) => {
        if (factura?.estatus_Pago) return factura?.estatus_Pago.toUpperCase().includes("EFECTIVO") || factura?.estatus_Pago.toUpperCase().includes("TARJETA");

        return false;
    }

    const onClickButtonMenu = (slug) => {

    }

    const onClickButtonSaveDatosExtra = () => {
        factura.fecha_Pago = pagado ? fechaPago : null;
        factura.fecha_Deposito = factura.depositado ? fechaDeposito : null;
        fetchConToken('Facturacion/Facturas/ActualizarDatosExtra', factura, 'PUT').then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                Object.entries(response.errors).map(([key, value]) => { Swal.fire('Error', value[0], 'error'); });
                return;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return;
            }

            Swal.fire('Exito', '', 'success');
            closeModal();
        }).catch((e) => Swal.fire('Error', e, 'error'));
    }

    const handleInputChangeFechaPago = ({ target }) => {
        setFechaPago(target.value);
        setFactura({ ...factura, fecha_Pago: target.value });
    };

    const handleInputChangeFechaDeposito = ({ target }) => {
        setFechaDeposito(target.value);
        setFactura({ ...factura, fecha_Deposito: factura.depositado ? target.value : null });
    };

    const handleInputChangeFechaInicio = ({ target }) => {
        setFechaInicio(target.value);
    };

    const handleInputChangeFechaFin = ({ target }) => {
        setFechaFin(target.value);
    }

    const handleChange = ({ target }) => {
        setEstatus(target.value);
    }

    const handleChangeBanco = ({ target }) => {
        setCuentaBanco(target.value);
    }
    
    const handleChangeExtra = ({ target }) => {
        setEstatusExtra(target.value);
        setFactura({ ...factura, estatus_Pago: target.value });
    }

    const handleChangeTipoFecha = ({ target }) => {
        setTipoFecha(target.value);
    }

    const handleChangeObservaciones = ({ target }) => {
        setFactura({ ...factura, observaciones: target.value });
    }

    const handelChangeDepositado = ({ target }) => {
        setFactura({ ...factura, depositado: target.checked });
    }

    const handelChangePagado = ({ target }) => {
        setPagado(target.checked);
    }

    const handleCargarExcel = (e) => {
        setNombreArchivo('Ningun archivo seleccionado');
        setArchivo(null);
        setModalValor('CargarExcel');
        openModal();
    }

    const loadCuentasBanco = () => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        fetchConToken(`VxCuentaBanco/GetList?paginas=0&totalXpagina=500&sucursal_Id=${getDataSession('sucursal')}`).then((response) => {
            if (response.hasOwnProperty('codigo')) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return;
            }

            setCuentasBanco(response.data)
        });
    };

    const loadFormasPago = () => {
        fetchConToken(`CatalogosSat/ListFormaPago?pagina=1&totalXpagina=50`).then((response) => {
            if (response.hasOwnProperty('codigo')) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return;
            }

            setDataStatusExtra([...response]);
            response.push({ id: 0, clave: '00', nombre: 'Todos' });
            response.sort((a, b) => a.clave.localeCompare(b.clave));
            response.push({ id: -0, clave: 'CC', nombre: 'Cancelado' });
            setDataStatus(response)
        });
    }

    const loadFactura = (factura_id) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        fetchConToken(`Facturacion/Facturas/?id=${factura_id}`).then((response) => {
            if (response.hasOwnProperty('codigo')) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return;
            }

            setFactura(response);
            setEstatusExtra(response.forma_Pago);
            setFechaPago(ConverDate(response?.fecha_Pago));
            setFechaDeposito(ConverDate(response?.fecha_Deposito));
            setPagado(response?.fecha_Pago?.length > 0 );
        });
    }

    function GetFecha(inicio) {
        var curr = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        if (!inicio) curr = new Date(curr.getFullYear(), curr.getMonth(), new Date(curr.getFullYear(), curr.getMonth() + 1, 0).getDate());

        curr.setDate(curr.getDate());
        return curr.toISOString().substring(0, 10);
    }

    function ConverDate(fecha) {
        var date = fecha ? new Date(fecha) : new Date();
        date.setDate(date.getDate());
        return date.toISOString().substring(0, 10);
    }

    function verFactura(url){

        console.log(url)
        fetchConTokenFile(url)
        .then(response => {
        
            console.log(response.status)
            if(response.status == 200)
            {
                return response.blob()
            }
            else{
                handleResponse(response).then(data=>{
                    if (data.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(data?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (data.hasOwnProperty('codigo')) {
                        Swal.fire('Error', data.mensajes[0], 'error');
                        return;
                    }
                })
            }
        })
        .then(function(blob) {
            const fileURL = URL.createObjectURL(blob);
            setPDF(fileURL);
            setModalValor('viewPdf');
            openModal()
          })
    };

    






    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "viewPdf,pdf,xml,cancelacionCfdi,asignarDatosExtraFactura,verNotasCredito",viewPdf : 'Facturacion/DescargarPDF', viewPdfOnClick: verFactura, xml: 'Facturacion/DescargarXML', marcarpagadoUrl: "Facturacion/MarcarPagado", asignarDatosExtraFactura: asignarDatosExtraFactura, verNotasCredito: verNotasCredito },
        { field: 'id', headerName: 'Id', maxWidth: 10 },
        { field: 'uuid', headerName: 'UUID', minWidth: 50 },
        { field: 'folio', headerName: 'Folio', minWidth: 50 },
        { field: 'fecha_Timbrado', headerName: 'Fecha Timbrado', minWidth: 100, renderCell: ({ value }) => value?.substring(0,10) },
        { field: 'fecha_Registro', headerName: 'Fecha Registro', minWidth: 100, renderCell: ({ value }) => value?.substring(0,10) },
        { field: 'nombre_Receptor', headerName: 'Cliente', minWidth: 275 },
        { field: 'rfc_Receptor', headerName: 'RFC', minWidth: 100 },
        { field: 'subtotal', headerName: 'SubTotal', type: 'number', minWidth: 100, renderCell: ({ value }) => currency(value) },
        { field: 'total_Impuestos', headerName: 'Impuestos', type: 'number', minWidth: 100, renderCell: ({ value }) => currency(value) },
        { field: 'total', headerName: 'Total', type: 'number', minWidth: 100, renderCell: ({ value }) => currency(value) },
        { field: 'estatus_Pago', headerName: 'Estatus Pago', minWidth: 150, flex: 1 },
        { field: 'fecha_Pago', headerName: 'Fecha Pago', minWidth: 150, flex: 1 , renderCell: ({ value }) => value?.substring(0,10) },
    ];

    const columnsNotaCredito = [
        { field: 'id', headerName: 'Id', maxWidth: 10 },
        { field: 'folio', headerName: 'Folio', minWidth: 50 },
        { field: 'fecha_Registro', headerName: 'Fecha Registro', minWidth: 200 },
        { field: 'nombre_Cliente', headerName: 'Cliente', minWidth: 300 },
        { field: 'rfc_Cliente', headerName: 'RFC', minWidth: 120 },
        { field: 'importe', headerName: 'Total', type: 'number', renderCell: ({ value }) => currency(value) }
    ];

    useEffect(() => {
        actualizarTitulo();
        loadCuentasBanco();
        loadFormasPago();
        getTotales();
    }, []);

    const handleChangeFiltroStatus = (event, newValue) => {
        setStatusFiltro(newValue);
    };

    const CustomTab = ({ value, label, count, color }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{label}</span>
            <span style={{
                marginLeft: 5,
                backgroundColor: color,
                color: 'white',
                padding: '2px 5px',
                borderRadius: 4,
            }}>
                {count}
            </span>
        </div>
    );

    useEffect(() => {
        getTotales();
    }, [fechaInicio,fechaFin,tipoFecha,estatus]);

    function getTotales() {
        fetchConToken(`Facturacion/Facturas/CfdisGetList/CantidadXEstatus?sucursal_Id=${getDataSession('sucursal')}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&tipoFecha=${tipoFecha}&estatusPago=${statusFiltro}`).then(function (response) { setTotales(response) });
    }

    function descargarExcel() {

        if(statusFiltro == "P")
        {
            var url =`Facturacion/Facturas/CfdisGetList/Excel/pendientes?sucursal_Id=${getDataSession('sucursal')}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&tipoFecha=${tipoFecha}&estatusPago=${statusFiltro}`;
            descargarArchivo(url, `Facturas Pagadas.xlsx`);    
        }
        
        if(statusFiltro == "F")
        {
            var url =`Facturacion/Facturas/CfdisGetList/Excel/pagadas?sucursal_Id=${getDataSession('sucursal')}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&tipoFecha=${tipoFecha}&estatusPago=${statusFiltro}`;
            descargarArchivo(url, `Facturas Pagadas.xlsx`);
        }
        
        if(statusFiltro != "F" && statusFiltro != "P")
        {
            Swal.fire("Info","Seleccione la opcion de factura pagadas o pendientes de pago","info");
        }
       
    }
    
    function descargarPdf() {

        if(statusFiltro == "P")
        {
            var url = `Facturacion/Facturas/CfdisGetList/pdf/pendientes?sucursal_Id=${getDataSession('sucursal')}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&tipoFecha=${tipoFecha}&estatusPago=${statusFiltro}`;
            descargarArchivo(url, `Facturas Pagadas.pdf`);    
        }
        
        if(statusFiltro == "F")
        {
            var url = `Facturacion/Facturas/CfdisGetList/pdf/pagadas?sucursal_Id=${getDataSession('sucursal')}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&tipoFecha=${tipoFecha}&estatusPago=${statusFiltro}`;
            descargarArchivo(url, `Facturas Pagadas.pdf`);
        }
        
        if(statusFiltro != "F" && statusFiltro != "P")
        {
            Swal.fire("Info","Seleccione la opcion de factura pagadas o pendientes de pago","info");
        }
       
    }


    function descargarPdfFichaPago() {

        if(!(cuentaBanco > 0))
        {
            Swal.fire("Info","Seleccione una cuenta de banco valido","info");
            return;
        }

            var url = `Facturacion/Facturas/CfdisGetList/Pdf/fichapago?cuenta_banco_id=${cuentaBanco}&sucursal_Id=${getDataSession('sucursal')}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`;
            descargarArchivo(url, `Facturas Pagadas.pdf`);    

        
        if(statusFiltro != "F" && statusFiltro != "P")
        {
            Swal.fire("Info","Seleccione la opcion de factura pagadas o pendientes de pago","info");
        }
       
    }

    function descargarExcelFichaPago() {

        if(!(cuentaBanco > 0))
            {
                Swal.fire("Info","Seleccione una cuenta de banco valido","info");
                return;
            }

        var url = `Facturacion/Facturas/CfdisGetList/excel/fichapago?cuenta_banco_id=${cuentaBanco}&sucursal_Id=${getDataSession('sucursal')}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`;
        descargarArchivo(url, `Facturas Pagadas.xlsx`);    

    
    if(statusFiltro != "F" && statusFiltro != "P")
    {
        Swal.fire("Info","Seleccione la opcion de factura pagadas o pendientes de pago","info");
    }
   
}



    return (
        <>
            
            <div className='container-fluid'>
                <div className='col-md-12 d-flex justify-content-between'>
                    <div className='col-md-12'>
                    <button
                            className='btn btn-outline-primary m-1'
                            style={{ width: 'auto' }}
                            onClick={() => { descargarExcel(9) }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                                <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                            </svg>
                            &nbsp; Descargar Facturas Excel
                        </button>
                        &nbsp;
                        <button
                            className='btn btn-outline-primary m-1'
                            style={{ width: 'auto' }}
                            onClick={() => {  descargarPdf() }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                                <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z"/>
                            </svg>
                            &nbsp; Descargar Facturas PDF
                        </button>
                        &nbsp;
                        <button
                            className='btn btn-outline-primary m-1'
                            style={{ width: 'auto' }}
                            onClick={(e) => { descargarExcelFichaPago()}}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                                <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                            </svg>
                            &nbsp; Descargar Ficha Excel
                        </button>
                        &nbsp;
                        <button
                            className='btn btn-outline-primary m-1'
                            style={{ width: 'auto' }}
                            onClick={(e) => { descargarPdfFichaPago() }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                                <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z"/>
                            </svg>
                            &nbsp; Descargar Ficha PDF
                        </button>
                    </div>
                   
                 </div>
                 <div className='espacio10px' />
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-2 container-center'>
                        <button className='btn btn-outline-primary m-1' style={{ width: 'auto' }} onClick={handleCargarExcel}><UploadFileIcon />&nbsp; Cargar Excel</button>
                    </div>
                    <div className='col-sm-3' style={{ display: 'flex' }}>
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={handleInputChangeFechaInicio}
                            InputLabelProps={{ shrink: true, }}
                        />
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Fin"
                            type="date"
                            value={fechaFin}
                            onChange={handleInputChangeFechaFin}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="cuenta-banco-label">Cuenta de Banco</InputLabel>
                            <Select label="Cuenta de Banco" value={cuentaBanco} onChange={handleChangeBanco}>
                                {cuentasBanco?.map((item) => {
                                    return <MenuItem key={item.id} value={item.id}><ListItemText primary={(item.banco + ': ' + item.cuenta)} /></MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-sm-2'>
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="estatus-label">Estatus Pago</InputLabel>
                            <Select label="Estatus Pago" value={estatus} onChange={handleChange}>
                                {dataStatus?.map((item) => {
                                    return <MenuItem key={item.clave} value={item.clave}><ListItemText primary={(item.nombre)} /></MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div> 
                    <div className='col-sm-2'>
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="fecha-label">Tipo Fecha</InputLabel>
                            <Select label="Tipo Fecha" value={tipoFecha} onChange={handleChangeTipoFecha}>
                                <MenuItem key='Registro' value='Registro'><ListItemText primary='Fecha Registro' /></MenuItem>
                                <MenuItem key='Factura' value='Factura'><ListItemText primary='Fecha Factura' /></MenuItem>
                                <MenuItem key='Pago' value='Pago'><ListItemText primary='Fecha Pago' /></MenuItem>
                            </Select>
                        </FormControl>
                    </div> 
                </div>
                <div className='col-md-12'>
                    <Tabs value={statusFiltro} onChange={handleChangeFiltroStatus} textColor="secondary" indicatorColor="secondary">
                        <Tab value="" label={<CustomTab label="Todos" count={totales?.todos} color='black' />} />
                        <Tab value="P" label={<CustomTab label="Pendiente Pago" count={totales?.pendientes} color='blue' />} />
                        <Tab value="F" label={<CustomTab label="Pagadas" count={totales?.pagados} color='yellowgreen' />} />
                    </Tabs>
                    <ListTable
                        columns={columns}
                        path={path}
                        reload={actualizarTabla}
                        url={`Facturacion/Facturas/CfdisGetList?sucursal_Id=${getDataSession('sucursal')}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&tipoFecha=${tipoFecha}&estatusPago=${statusFiltro}`} />
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal} /*min={modalValor !== 'VerNotasCredito'}*/>
                {modalValor === 'AsignarDatosExtraFactura' ? (
                    <div className='card text-left'>
                        <div className='card-header'>
                            <div className='espacio5px' />
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}><h4>Asignar Datos Extra</h4></Stack>
                            <div className='espacio10px' />
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}><h5>FOLIO: {factura?.folio} - UUID: {factura?.uuid}</h5></Stack>
                        </div>
                        <div className='espacio15px' />
                        <div className='espacio10px' />
                        <div className='container'>
                            <div className='col-12 col-sm-12 col-md-12 row'>
                                <div className='col-sm-1' />
                                <div className='col-sm-3'>
                                    <Stack direction="row" alignItems="center" spacing={1}><FormControlLabel control={<Checkbox checked={pagado} onChange={handelChangePagado} />} label="Fue Pagado" /></Stack>
                                </div>
                                <div className='col-sm-3' style={{ display: 'flex' }}>
                                    <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                        id="filled-number"
                                        label="Fecha Pago"
                                        type="date"
                                        value={fechaPago}
                                        onChange={handleInputChangeFechaPago}
                                        InputLabelProps={{ shrink: true, }}
                                    />
                                </div>
                                <div className='col-sm-4' style={{ display: 'flex' }}>
                                    <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                                        <InputLabel id="estatus-label">Forma Pago</InputLabel>
                                        {console.log(factura?.estatus_Pago)}
                                        <Select label="Forma Pago" value={factura?.estatus_Pago || ""} onChange={handleChangeExtra}>
                                            <MenuItem key="PAGADO TRANSFERENCIA" value="PAGADO TRANSFERENCIA"><ListItemText primary={"PAGADO TRANSFERENCIA"} /></MenuItem>
                                            <MenuItem key="PAGADO CHEQUE" value="PAGADO CHEQUE"><ListItemText primary={"PAGADO CHEQUE"} /></MenuItem>
                                            <MenuItem key="PAGADO EFECTIVO" value="PAGADO EFECTIVO"><ListItemText primary={"PAGADO EFECTIVO"} /></MenuItem>
                                            <MenuItem key="PAGADO DEPOSITO" value="PAGADO DEPOSITO"><ListItemText primary={"PAGADO DEPOSITO"} /></MenuItem>
                                            <MenuItem key="PAGADO TARJETA CREDITO" value="PAGADO TARJETA CREDITO"><ListItemText primary={"PAGADO TARJETA CREDITO"} /></MenuItem>
                                            <MenuItem key="PAGADO TARJETA DEBITO" value="PAGADO TARJETA DEBITO"><ListItemText primary={"PAGADO TARJETA DEBITO"} /></MenuItem>
                                            <MenuItem key="CANCELADA" value="CANCELADA"><ListItemText primary={"CANCELADA"} /></MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='col-sm-1' />
                            </div>
                            <hr/>
                            {(factura?.depositado || validarFormaPagoDatosExtra(estatusExtra)) &&
                                <div className='col-12 col-sm-12 col-md-12 row'>
                                    <div className='col-sm-1' />
                                    <div className='col-sm-5'>
                                        <Stack direction="row" alignItems="center" spacing={1}><FormControlLabel control={<Checkbox checked={factura?.depositado} onChange={handelChangeDepositado} />} label="Fue Depositado" /></Stack>
                                    </div>
                                    <div className='col-sm-5' style={{ display: 'flex' }}>
                                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                            id="filled-number"
                                            label="Fecha Deposito"
                                            type="date"
                                            value={fechaDeposito}
                                            onChange={handleInputChangeFechaDeposito}
                                            InputLabelProps={{ shrink: true, }}
                                        />
                                    </div>
                                    <div className='col-sm-1' />
                                </div>}
                                <hr/>
                            <div className='col-12 col-sm-12 col-md-12 row'>
                                <div className='col-sm-1' />
                                <div className='col-sm-10' style={{ display: 'flex' }}>
                                    <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                        id="filled-Observaciones"
                                        label="Observaciones"
                                        value={factura?.observaciones}
                                        onChange={handleChangeObservaciones}
                                        multiline
                                        rows={4}
                                        InputLabelProps={{ shrink: true, }}
                                    />
                                </div>
                                <div className='col-sm-1' />
                            </div>
                            <div className='espacio10px' />
                            <div className='col-12 col-sm-12 col-md-12 row'>
                                <div className='col-sm-8' />
                                <div className='col-sm-3' style={{ display: 'flex' }}>
                                    <button type='button' className='btn btn-outline-primary' onClick={onClickButtonSaveDatosExtra} style={{ width: '100%' }}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-check-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                        </svg>
                                        &nbsp; {t('app.botones.guardar')}
                                    </button>
                                </div>
                                <div className='col-sm-1' />
                            </div>
                            <div className='espacio15px' />
                        </div>
                    </div>
                ) : ('')}
                {modalValor === 'CargarExcel' ? (
                    <div className='card text-left'>
                        <div className='card-header'>
                            <div className='espacio5px' />
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}><h4>Cargar Excel</h4></Stack>
                            <div className='espacio10px' />
                        </div>
                        <div className='espacio10px' />
                        <div className='container'>
                            <div className='col-12 col-sm-12 col-md-12 row'>
                                <div className='col-sm-6 container-center'>
                                    <div style={{ justifyContent: 'flex-start' }}>
                                        <h5>&nbsp; Excel de facturas</h5>
                                        <div className='espacio5px' />
                                        <input type='file' className='input_file_hidden' name='imagen_nueva' onChange={handleInputChangeFileConfig} ref={seleccionarArchivo}
                                            accept=".csv, .xls, .xlsx, text/csv, application/csv,
                                            text/comma-separated-values, application/csv, application/excel,
                                            application/vnd.msexcel, text/anytext, application/vnd. ms-excel,
                                            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                        <Tooltip title="Seleccionar archivo" className='col-12 col-sm-12 col-md-12 row cursor-pointer-hover' onClick={handleClick}>
                                            <div className='col-sm-4'><IconButton className='img_art_add'><img src={Add_File} className='img_art_add' alt='Add' /></IconButton></div>
                                            <div className='col-sm-8 container-center'><h6>{nombreArchivo}</h6></div>
                                        </Tooltip>
                                        <div className='espacio30px' />
                                    </div>
                                </div>
                                <div className='col-sm-6' style={{ display: 'flex' }}>
                                    <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                        id="filled-number"
                                        label="Resultado"
                                        multiline
                                        rows={8}
                                    />
                                </div>
                            </div>
                            <div className='espacio15px' />
                            <div className='col-12 col-sm-12 col-md-12 row'>
                                <div className='col-sm-5' />
                                <div className='col-sm-3' style={{ display: 'flex' }}>
                                    <button type='button' className='btn btn-outline-primary' onClick={handleClickSubirExcel} style={{ width: '100%' }}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-check-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                        </svg>
                                        &nbsp; {t('app.botones.guardar')}
                                    </button>
                                </div>
                                <div className='col-sm-4' />
                            </div>
                            <div className='espacio15px' />
                        </div>
                    </div>
                ) : ('')}
                {modalValor === 'VerNotasCredito' ? (
                    <div className='card text-left'>
                        <div className='card-header'>
                            <div className='espacio5px' />
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}><h4>Notas de crédito</h4></Stack>
                            <div className='espacio10px' />
                        </div>
                        <div className='espacio10px' />
                        <h5>&nbsp; Lista de notas de crédito</h5>
                        <div className='card-body col-md-12 text-right' style={{ padding: 10, height: "50vh" }}>
                            <ListTable
                                columns={columnsNotaCredito}
                                path={path}
                                reload={actualizarTabla}
                                hideSearch
                                url={`VxNotasCredito/GetList?sucursal_Id=${getDataSession('sucursal')}&venta_Id=${factura?.venta_Id ?? 0}`} />
                        </div>
                        <div className='espacio10px' />
                    </div>
                ) : ('')}
                { modalValor === 'viewPdf' ? (
                    <> 
                    <div className="row" style={{padding: '21px'}}>
                    <embed
                        src={PDF}
                        type="application/pdf"
                        height={800}
                        width={500}
                    />
                    </div>
                </>) : ('')
                }
                


            </Modal>
        </>
    );
}

export { List };