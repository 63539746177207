import React, { useState, useEffect, useRef } from 'react';
import { useGlobalState } from 'resources/GlobalStateContext';
import { getDataSession } from 'views/Utils/Common';
import { useTranslation } from 'react-i18next';
import { fetchConToken } from 'helpers/fetch';
import AsyncSelect from 'react-select/async';
import { useForm } from 'react-hook-form';
import SLUGS from 'resources/slugs';
import Swal from 'sweetalert2';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const AddEdit = ({ history, match }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const { id } = match.params;
    const path = match.url;
    const { t } = useTranslation('common');
    const isAddMode = !id;
    const button_submit = useRef(null);
    const { register, handleSubmit, reset } = useForm();
    const [initialObject, setInitialObject] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    let [categoria, setCategoria] = useState({
        sucursal_Id: getDataSession('sucursal'),
        categoria_Padre_Id: 0,
        clave_Categoria: '',
        clave_Categoria_Padre: '',
        nombre: '',
        estatus: 'A',
        tipo_Categoria: 'P',
        tienda: '',
        clave_Sat: null,
        publicado: false
    });

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: isAddMode ? 'Agregar Categoria' : 'Editar Categoria',
            subtitulo: ''
        });
    };

    const loadData = async (query) => await fetchConToken(`Categorias/GetList?pagina=${0}&totalXpagina=${50}&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickButtonSubmit = () => {
        if (validar()) button_submit.current.click();
    };

    const validar = () => {
        if (!categoria.nombre || categoria.nombre === '') {
            Swal.fire('', 'Complete el campo de Nombre', 'warning');
            return false;
        }

        return true;
    };

    function regresar() {
        history.push(`${SLUGS.categorias}`);
    }

    function obtenerCategoria() {
        fetchConToken('Categorias?id=' + id + '&sucursal_id=' + getDataSession('sucursal')).then(res => {
            if (res.hasOwnProperty('status')) {
                Object.entries(res?.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (res.hasOwnProperty('codigo')) {
                Swal.fire('Error', res.mensajes[0], 'error');
                return;
            }

            setCategoria(res);
            if (res.clave_Categoria_Padre) fetchConToken('Categorias/GetByClave?clave=' + res.clave_Categoria_Padre + '&sucursal_id=' + getDataSession('sucursal')).then(x => {
                if (x.hasOwnProperty('status')) {
                    Object.entries(x?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (x.hasOwnProperty('codigo')) {
                    Swal.fire('Error', x.mensajes[0], 'error');
                    return;
                }

                setInitialObject(x);
            });
        });
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setCategoria({
            ...categoria,
            [name]: value
        });
    }

    function postCategoria() {
        if (getDataSession('tienda') === 'TIENDANUBE') {
            Swal.fire({
                title: `Desea publicar o solo guardar la categoria`,
                showCancelButton: true,
                confirmButtonText: 'Guardar y Publicar',
                cancelButtonText: 'Guardar'
            }).then((result) => PostCategoria(result.isConfirmed));
        }
        else PostCategoria(false);
    }

    function PostCategoria(isConfirmed) {
        var url = !isConfirmed ? `Categorias?empresa_id=${getDataSession('empresa')}` :
            `Categorias/Publicar?empresa_id=${getDataSession('empresa')}&sucursal_id=${getDataSession('sucursal')}&tienda=${getDataSession('tienda')}`;
        fetchConToken(url, categoria, 'POST').then((response) => {
            if (response.hasOwnProperty('status')) {
                Object.entries(response?.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo')) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }

            Swal.fire('Exito', !isAddMode ? 'Categoria actualizado con exito' : 'Categoria agregada con exito', 'success');
            history.push(isAddMode ? '.' : '..');
        })
    }

    function eliminar() {
        if (getDataSession('tienda') !== 'TIENDANUBE' || !categoria.publicado) {
            eliminarItem(false);
            return;
        }

        Swal.fire({
            title: '¿Desea eliminar esta categoria de la Ecommerce?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.botones.eliminar') + ' de la Ecommerce',
            cancelButtonText: t('app.botones.eliminar') + ' del sistema'
        }).then((r) => eliminarItem(r.isConfirmed));
    }

    function eliminarItem(isConfirmed) {
        Swal.fire({
            title: '¿Desea eliminar esta categoria?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.botones.eliminar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                var url = `Categorias?id=${id}&emrpesa_id=${getDataSession('empresa')}&sucursal_id=${getDataSession('sucursal')}&eliminarmk=${isConfirmed}`;
                fetchConToken(url, null, 'DELETE').then((response) => {
                    if (response.hasOwnProperty('status')) {
                        Object.entries(response?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return;
                    }

                    Swal.fire('Exito', 'Categoria eliminada correctamente', 'success');
                    history.push('..');
                });
            }
        });
    }

    function actualizar() {
        if (!(!isAddMode && categoria.publicado)) return;

        Swal.fire({
            title: '¿Desea actualizar la información de la categoria desde la tienda?',
            showCancelButton: true,
            confirmButtonText: t('app.botones.actualizar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                fetchConToken(
                    `Categorias/DescargarCategoria?claveCategoria=${categoria.clave_Categoria}&sucursal_id=${getDataSession('sucursal')}&emrpesa_id=${getDataSession('empresa')}&tienda=${getDataSession('tienda')}`,
                    null,
                    'GET'
                ).then(function (response) {
                    obtenerCategoria();
                    if (response.hasOwnProperty('status') && response.status === 400) Object.entries(response.errors).map(([key, value]) => Swal.fire('Error', value[0], 'error') );
                    else if (response.hasOwnProperty('codigo') && response.codigo !== 200) Swal.fire('Error', response.mensajes[0], 'error');
                    else Swal.fire('Exito', t('app.Articulos.SwalFire.artActualizado'), 'success');
                });
            }
        });
    }

    function onSubmit() {
        if (isAddMode && getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        if (isAddMode) {
            fetchConToken('Categorias/GetByClave?clave=' + categoria.clave_Categoria + '&sucursal_id=' + getDataSession('sucursal')).then(x => {
                if (x.hasOwnProperty('mensajes')) {
                    var aux = x?.mensajes?.find((e) => e === 'No se encontro la categoria');
                    if (aux) return postCategoria();

                    Object.entries(x?.mensajes).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return;
                } else if (x.hasOwnProperty('status')) {
                    Object.entries(x?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return;
                } else if (x.hasOwnProperty('codigo')) {
                    Swal.fire('Error', x.mensajes[0], 'error');
                    return;
                }

                if (x?.clave_Categoria === categoria.clave_Categoria) {
                    Swal.fire('Error', 'Ya existe una categoria con la clave ' + x.clave_Categoria, 'error');
                    return;
                }
            });
        }
        else return postCategoria();
    }

    function ClaveDisable() {
        if (getDataSession('tienda') === 'TIENDANUBE') return true;

        return !isAddMode;
    }

    useEffect(() => {
        actualizarTitulo();
        if (!isAddMode) obtenerCategoria();
        else {
            if (getDataSession('sucursal') === '0') {
                Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
                return;
            }

            const fecha = new Date();
            setCategoria({
                ...categoria,
                clave_Categoria: `${fecha.getFullYear()}${fecha.getMonth() + 1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}${fecha.getMilliseconds()}`,
                tienda: getDataSession('tienda') && getDataSession('tienda') !== undefined ? getDataSession('tienda') : '',
                publicado: getDataSession('tienda') === 'TIENDANUBE' ? false : true
            });
        }
    }, []);

    return (
        <div className='container-fluid'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-6  col-md-6'>
                        <div className='espacio5px' />
                    </div>
                    <div className='col-sm-2' />
                    <div className='col-sm-4 text-right'>
                        <button
                            className='input_file_hidden'
                            type='submit'
                            ref={button_submit}
                        >
                            {t('app.botones.guardar')}
                        </button>
                        <button
                            type='button'
                            className='btn btn-outline-primary m-1'
                            style={{ width: 'auto' }}
                            onClick={handleClick}
                        >
                            <TuneIcon />&nbsp; Acciones <ArrowDropDownIcon />
                        </button>
                        <Menu
                            id='basic-menu'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                        >
                            {!isAddMode && categoria.publicado ? (
                                <MenuItem
                                    onClick={() => {
                                        actualizar();
                                        handleClose();
                                    }}
                                >
                                    <ListItemIcon>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='16'
                                            height='16'
                                            fill='currentColor'
                                            className='bi bi-arrow-clockwise'
                                            viewBox='0 0 16 16'
                                        >
                                            <path
                                                fill-rule='evenodd'
                                                d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z'
                                            />
                                            <path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z' />
                                        </svg>
                                    </ListItemIcon>
                                    {t('app.botones.actualizar')}
                                </MenuItem>
                            ) : ('')}
                            {getDataSession('tienda') !== 'MERCADOLIBRE' ? (<MenuItem
                                onClick={() => {
                                    handleClickButtonSubmit();
                                    handleClose();
                                }}
                            >
                                <ListItemIcon>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-check-lg'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                    </svg>
                                </ListItemIcon>
                                {t('app.botones.guardar')}
                            </MenuItem>) : ('')}
                            {getDataSession('tienda') !== 'MERCADOLIBRE' && !isAddMode ? (
                                <MenuItem
                                    onClick={() => {
                                        eliminar();
                                        handleClose();
                                    }}
                                >
                                    <ListItemIcon>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-x-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
                                        </svg>
                                    </ListItemIcon>
                                    {t('app.botones.eliminar')}
                                </MenuItem>
                            ) : ('')}
                            <MenuItem
                                onClick={() => {
                                    regresar();
                                    handleClose();
                                }}
                            >
                                <ListItemIcon>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                </ListItemIcon>
                                {t('app.botones.regresar')}
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                <div className='espacio10px' />
                <div className='card'>
                    <div className='col-12 col-sm-12 col-md-12 row'>
                        <div className='col-sm-1' />
                        <div className='col-sm-10' style={{ display: 'grid' }}>
                            <div className='card-body text-start row'>
                                <label htmlFor='clave_Categoria_Padre' className='form-label'>Categoria Padre</label>
                                <AsyncSelect
                                    isClearable
                                    className='react-select-placeholder'
                                    getOptionLabel={(option) => option.clave_Categoria + ' - ' + option.nombre + ' (' + option.tipo_Categoria + ')'}
                                    getOptionValue={(option) => option.clave_Categoria}
                                    defaultOptions
                                    value={initialObject}
                                    loadOptions={loadData}
                                    onChange={(selectedItem) => {
                                        const val = selectedItem === null ? '' : selectedItem?.clave_Categoria;
                                        categoria.clave_Categoria_Padre = val;
                                        categoria.tipo_Categoria = selectedItem ? 'C' : 'P';
                                        setInitialObject(selectedItem);
                                    }}
                                />
                                <label htmlFor='clave' className='form-label'>Clave</label>
                                <input
                                    type='text'
                                    className={ClaveDisable() ? 'form-control form-control-disabled' : 'form-control'}
                                    name='clave_Categoria'
                                    maxLength={100}
                                    value={categoria.clave_Categoria || ''}
                                    disabled={ClaveDisable()}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor='nombre' className='form-label'>Nombre</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='nombre'
                                    maxLength={100}
                                    value={categoria.nombre || ''}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor='clave' className='form-label'>Clave SAT</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='clave_Sat'
                                    maxLength={100}
                                    value={categoria.clave_Sat || ''}
                                    onChange={handleInputChange}
                                />
                                <div className='espacio10px' />
                            </div>
                        </div>
                        <div className='col-sm-1' />
                    </div>
                </div>
            </form>
        </div>
    );
}

export { AddEdit };