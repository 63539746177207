/*import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const MapComponent = ({ locations }) => {
  const mapStyles = { height: "100vh", width: "100%" };
  const [defaultCenter, setDefaultCenter ] = useState({ lat: 19.4288343, lng: -99.1473432 });

  const [selectedLocation, setSelectedLocation] = useState(null);

  React.useEffect(() => {console.log(locations); if(locations?.length > 0 && mapRef.current) mapRef.current.panTo(locations[locations.length - 1].position);} , [locations]);

  const mapRef = React.useRef(null);


  const onLoad = map => {
    mapRef.current = map;
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyDIH1DqOnDVWgMuLtd_2nfoEMusjH0OPVY">
      <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={defaultCenter} onLoad={onLoad}>
        {locations?.map((location) => (
          <Marker
            key={location.id}
            position={location.position}
            onClick={() => setSelectedLocation(location)}
          />
        ))}

        {selectedLocation && (
          <InfoWindow
            position={selectedLocation.position}
            onCloseClick={() => setSelectedLocation(null)}
          >
            <div>
              <h4>{selectedLocation.name}</h4>
              <p>{selectedLocation.date}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;

*/
import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap  } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Asegúrate de que el icono de Leaflet se muestre correctamente
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: 'https://unpkg.com/leaflet/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet/dist/images/marker-shadow.png',
});

const CenterMap = ({ position }) => {
  //console.log(position.position);
  console.log(position);
  //if(!map)
  //    map = useMap();
  
    const map = useMap(); 
 // useEffect(() => {
    console.log("entro use efect");
    if (position) {
      console.log(map);
      var auxPos = position.position === undefined ? position : position.position;
      console.log(auxPos);
      map.flyTo( auxPos, 13); // Centrar el mapa en la nueva posición
    }
  //}, [position, map]);
  return null;
};

const MapComponent = ({ locations }) => {
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState([19.4288343, -99.1473432]); 
  const mapRef = useRef(null); 


  useEffect(() => {
    if (locations?.length > 0 ) {
      const lastLocation = locations[locations.length - 1];
      setCenter(lastLocation.position); 
      //centrarmap(lastLocation.position);
      //CenterMap(lastLocation.position);
    }
  }, [locations]);



  return (
    <div>
      <MapContainer
        center={center} 
        zoom={13}
        style={{ height: '100vh', width: '100%', zIndex: '0' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <CenterMap position={center} />
        {locations?.map((marker) => (
          <Marker key={marker.id} position={marker.position}>
            <Popup>
              <h6>{marker.name}</h6>
              <p>{marker.date}</p>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
