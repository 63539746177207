import React, { useState, useEffect } from 'react';
import MapComponent from 'components/maps/MapComponent';
import { useGlobalState } from 'resources/GlobalStateContext';
import AsyncSelect from 'react-select/async';
import { fetchConToken } from 'helpers/fetch';
import Swal from 'sweetalert2';
import { getDataSession } from 'views/Utils/Common';

const VxUbicacion = ({ history, match }) => {

    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let [agente, setAgente] = useState(null);
    let [cliente, setCliente] = useState(null);
    const [locations, setLocations] = useState([]);
    


  const actualizarTitulo = () => {
    setGlobalTitulo({
        ...globalTitulo,
        titulo: 'Ubicación'
    });
};

const loadDataClientes = async (query) =>
    await fetchConToken(
        `Clientes/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`
    ).then((resp) => {
            return resp.data;
        })
        .catch((e) => {
            Swal.fire(
                'Error',
                `Ocurrio un error al obtener clientes ${query}`,
                'error'
            );
 });

        
 const loadDataAgentes = async (query) =>
        await fetchConToken(
            `Usuarios/GetList?empresa_id=${getDataSession('empresa')}&paginas=0&totalXpagina=50&busqueda=${query}&tipoUsuario=AGENTE&estatus=ACTIVOS&sucursal_Id=${getDataSession('sucursal')}`
        ).then((resp) => {
                return resp.data;
            })
            .catch((e) => {
                Swal.fire(
                    'Error',
                    `Ocurrio un error al obtener agentes ${query}`,
                    'error'
                );
 });

  useEffect(() => actualizarTitulo(), []);

  useEffect(() => ubicacionCliente(), [cliente]);
  useEffect(() => ubicacionAgente(), [agente]);



  const  ubicacionCliente = () => {
    if(!cliente)
        return;

    if(cliente?.latitud !== undefined && cliente?.longitud !== undefined && cliente?.latitud !== null && cliente?.longitud !== null)
    {
        let location = {
            id: 1,
            name: "Cliente:" + cliente?.nombre,
            date: "Telefono:" + cliente?.telefono1,
            position: [ Number(cliente?.latitud), Number(cliente?.longitud) ],
            };

            setLocations([...locations,location]);
    }

  }

  function ubicacionAgente(){
        if(!agente)
            return;

        var ubicacion = fetchConToken(
                `VxLogUbicacion/GetList?paginas=0&totalXpagina=1&sucursal_Id=${getDataSession('sucursal')}&agente_id=`+agente.id
            ).then((resp) => {

                    if(resp.data[0]?.lat !== undefined && resp.data[0]?.lon !== undefined)
                    {
                        const location = 
                        {
                            id: 1,
                            name: "Agente:" + agente?.nombre,
                            date: "Telefono:" + agente?.telefono,
                            position: [ Number(resp.data[0]?.lat), Number(resp.data[0]?.lon) ],
                        }
            
                        
                        setLocations([...locations,location])
                    }
                    else
                    {
                        Swal.fire('Info',`No se encontro registro de ultima ubicación`,'info'
                    )
                    }
                })
                .catch((e) => {
                    Swal.fire(
                    'Error',
                    `Ocurrio un error al obtener la ubicacion`,
                    'error'
            )})          
            
}

 
function limpiarMapa(){
    setLocations([]);
}

  return (
    <>
        <div className='container-fluid'>
            <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-md-8 '>
                                <label htmlFor='cliente' className='form-label'>
                                                    Cliente
                                </label>
                                <AsyncSelect
                                                        isClearable
                                                        className='react-select-placeholder'
                                                        getOptionLabel={(option) =>
                                                            option.nombre + ( option.latitud === null ||  option.longitud === null ? " - Sin ubicación":"")
                                                        }
                                                        getOptionValue={(option) => option.id}
                                                        defaultOptions
                                                        //value={initialClientes}
                                                        loadOptions={loadDataClientes}
                                                        onChange={(selectedItem) => {
                                                            setCliente(selectedItem);
                                                        }}
                                />     

                                <label htmlFor='cliente' className='form-label'>
                                                    Agente
                                </label>
                                <AsyncSelect
                                                isClearable
                                                className='react-select-placeholder'
                                                getOptionLabel={(option) =>
                                                    option.nombre
                                                }
                                                getOptionValue={(option) => option.id}
                                                defaultOptions
                                                //value={initialAgente}
                                                loadOptions={loadDataAgentes}
                                                onChange={(selectedItem) => {
                                                    setAgente(selectedItem);
                                                }}
                                            />
                    </div>
                    <div className='col-md-4'>
                        <div className='espacio40px'/>
                        <button className='btn btn-outline-success' type='button' onClick={()=>limpiarMapa()}>
                                        &nbsp; Limpiar Mapa
                                    </button>
                        </div>
                <div className='col-md-12'>
                    <div className='espacio10px'/>
                    <MapComponent locations={locations} />
                </div>
            </div>
        </div>
    </>
  );
};

export {VxUbicacion};
