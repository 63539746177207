import React, { useEffect } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import {
    IconAgents,
    IconArticles,
    IconContacts,
    IconIdeas,
    IconLogout,
    IconOverview,
    IconSettings,
    IconSubscription,
    IconTickets,
    IconEstadisticas
} from 'assets/icons';
import { convertSlugToUrl } from 'resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';
import { getDataSession, removeUserSession } from 'views/Utils/Common';
import { setDataSession } from 'views/Utils/Common';
import SidebarOptionsComponent from './SidebarOptionsComponent';

import AnimatedIcon from "./AnimatedIcon";
/*
import Home from 'images/panel/menu/menu/home.svg';
import Facturacion from 'images/panel/menu/menu/facturacion.svg';
import Catalogos from 'images/panel/menu/menu/catalogos.svg';
import Reportes from 'images/panel/menu/menu/reportes.svg';
import Estadisticas from 'images/panel/menu/menu/estadisticas.svg';
import Ayuda from 'images/panel/menu/menu/ayuda.svg';
import Soporte from 'images/panel/menu/menu/soporte.svg';
import Salir from 'images/panel/menu/menu/salir.svg';
*/
import Cotizaciones from 'images/panel/menu/menu_blanco/Cotizaciones_blanco.svg';
import Fiscal from 'images/panel/menu/menu_blanco/Fiscal_blanco.svg';


import Configuraciones from 'images/panel/menu/menu_blanco/Configuraciones_blanco.svg';
import ic_estadisticas from 'images/panel/menu/menu_color/Estadisticas.svg';

import Swal from 'sweetalert2';

const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    }
});

const subMenu = [];

const baseUrl = process.env.REACT_APP_TIPO_SISTEMA;
let lista = [
    {
        id: SLUGS.dashboard,
        imagen: IconArticles,
        onClick: SLUGS.dashboard,
        menu: 'Principal',
        title: 'Principal',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        id: SLUGS.vxubicacion,
        imagen: IconArticles,
        items: [1],
        onClick: SLUGS.vxubicacion,
        menu: 'Monitoreo',
        title: 'Monitoreo',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        id: SLUGS.sucursal,
        imagen: IconArticles,
        onClick: SLUGS.sucursal,
        menu: 'Conexiones',
        title: 'Canales de venta',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        id: SLUGS.articulos,
        imagen: IconContacts,
        onClick: SLUGS.articulos,
        menu: 'Articulos',
        title: 'Artículos',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        id: SLUGS.mensajes,
        imagen: IconContacts,
        items: [1],
        onClick: SLUGS.mensajes,
        menu: 'Mensajes',
        title: 'Mensajes',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        id: SLUGS.cotizaciones,
        imagen: IconArticles,
        items: [1],
        onClick: SLUGS.cotizaciones,
        menu: 'Ventas',
        title: 'Ventas',
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        id: SLUGS.ordencompra,
        imagen: IconArticles,
        items: [1],
        onClick: SLUGS.ordencompra,
        menu: 'Compras',
        title: 'Compras',
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        id: SLUGS.creditocliente,
        imagen: IconArticles,
        items: [1],
        onClick: SLUGS.creditocliente,
        menu: 'Cuentas_X_cobrar',
        title: 'Cuentas por cobrar',
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        id: SLUGS.vxCargos,
        imagen: IconArticles,
        items: [1],
        onClick: SLUGS.vxCargos,
        menu: 'Cuentas_X_pagar',
        title: 'Cuentas por pagar',
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        id: SLUGS.vxagentes,
        imagen: IconArticles,
        items: [1],
        onClick: SLUGS.vxagentes,
        menu: 'Venta_en_Ruta',
        title: 'Venta en ruta',
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        id: SLUGS.categorias,
        imagen: IconArticles,
        items: [1],
        onClick: SLUGS.categorias,
        menu: 'Catalogos',
        title: 'Catalogos',
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        id: SLUGS.soporte,
        imagen: IconArticles,
        //items: { items },
        onClick: SLUGS.soporte,
        menu: 'Soporte',
        title: 'Soporte',
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },








        /*
        {
            //id: SLUGS.vxmovimientos,
            imagen: IconArticles,
            //items: { items },
            //onClick: SLUGS.vxmovimientos,
            menu: 'Movimientos',
            title: 'Movimientos',
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            //id: SLUGS.vxmovimientos,
            imagen: IconArticles,
            //items: { items },
            //onClick: SLUGS.vxmovimientos,
            menu: 'ConfigAppMaxventas',
            title: 'App MaxVentas',
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
    {
        id: SLUGS.remisiones,
        imagen: IconArticles,
        //items: { items },
        onClick: SLUGS.remisiones,
        menu: 'Ordenes',
        title: 'Ventas',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },
    {
        id: SLUGS.procesos,
        imagen: IconArticles,
        //items: { items },
        //onClick: SLUGS.procesos,
        menu: 'Mensajes',
        title: 'Notificaciones',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },*//*   
        {
            id: SLUGS.compras,
            imagen: IconArticles,
            //items: { items },
            //onClick: SLUGS.ordenes,
            menu: 'Compras',
            title: 'Compras',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },*/
    /*{
        id: SLUGS.categorias,
        imagen: IconArticles,
        //items: { items },
        onClick: SLUGS.categorias,
        menu: 'Catalogos',
        title: 'Catalogos',
        ADMINISTRADOR: 'ADMINISTRADOR',
        EMPRESA: 'EMPRESA',
        SUCURSAL: 'SUCURSAL'
    },*/

    
        /*{
            id: SLUGS.articulos,
            imagen: Reportes,
            items: { items },
            onClick: SLUGS.articulos,
            menu: 'Reportes',
            title: 'Reportes',
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            id: SLUGS.empresa,
            imagen: Configuraciones,
            items: { items },
            onClick: SLUGS.empresa,
            menu: 'Configuraciones',
            title: 'Configuraciones',
            ADMINISTRADOR: 'ADMINISTRADOR',
        }*/];


function SidebarComponent() {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1300;
    var menu = getDataSession('menu');
    const items = subMenu.map((item) => {
        return item.menu === menu ? item.id : '';
    });
    let [version, setVersion] = React.useState('');

    useEffect(() => {
        fetchConToken(`empresas/GetVersion`)
            .then((response) => { setVersion(response); })
            .catch(alertService.error)
    }, []);

    var menua = getDataSession('menu');
    
    function renderSwitch() {
        var rol = getDataSession('rol');
        return lista.map((item) => {
            return item.hasOwnProperty(rol) ? (
                <>
                    <MenuItem
                        key={item.id + "princ"}
                        id={item.menu}
                        icon={IconArticles}
                        title={item.title}
                        items={item.items}
                        onClick={() => onClick(item.id, item.menu)}
                    />
                    <SidebarOptionsComponent menu={item.menu} />
                </>
            ) : (
                ''
            );
        });
    }

    async function logout() {
        Swal.fire({
            text: 'Esta apunto de salir del panel',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Salir',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                removeUserSession();
                push('..');
            }
        })
    }

    function onClick(slug, submenu) {
        setDataSession('menu', submenu);
        push(slug);
    }

    return (
        <>
            <Menu isMobile={isMobile} key={"menu00-1"}>
                <div key={"menu000"} >
                    <div key={"menu001"} style={{ paddingTop: 15, paddingBottom: 0 }}>
                        <LogoComponent key={"menu005"} />
                        <div key={"menu01"} className='text-center' style={{ paddingTop: 10 }}>
                            {/*<h6 key={"menu02"} style={{color: '#fff'}}>{getDataSession('rol')}</h6>*/}
                            <p style={{ color: "#fff", fontSize: ".55em" }}>{version.version}</p>
                        </div>
                    </div>
                    <div key={"menu03"} style={{ overflow: 'auto', height: '100%' }}>
                        {renderSwitch()}
                        <MenuItem key={"salir00"} id='logout' title='Salir' onClick={logout} icon={IconArticles} />
                    </div>
                    <div key={"menu04"} className='submenu-sidebar-scroll'>
                        <SidebarOptionsComponent key={"menu05"} />
                    </div>
                </div>
            </Menu>
        </>
    );
}

export default SidebarComponent;

//<MenuItem
//                id=SLUGS.subscription}
//                title='Newsletter'
//                icon={IconSubscription}
//                onClick={() => onClick(SLUGS.subscription)}
//            />
