import React, { useState, useEffect } from 'react';
import { getDataSession } from 'views/Utils/Common';
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';
import { currency } from 'resources/utilities';
import { ClavesArticulos } from './ClavesArticulos';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Swal from 'sweetalert2';
import { fetchConToken } from 'helpers/fetch';
import { ListImg } from './ListImg';
import AsyncSelect from 'react-select/async';
import sinIimagen from 'images/venta/SIN-IMAGEN.jpg';

const ListVariante = ({ data, viewClaves, clavesArt, eliminarClave, setModalValor, openModal, setVarianteId, variantes, setVariantes, clavesArticuloVariante, editar = { editar } }) => {
    //console.log("ListVariante");
    return <AgregarVarianteButton data={data} viewClaves={viewClaves} clavesArt={clavesArt} eliminarClave={eliminarClave} setModalValor={setModalValor} openModalPrincipal={openModal} setVarianteId={setVarianteId} variantes={variantes} setVariantes={setVariantes} clavesArticuloVariante={clavesArticuloVariante} editar={editar} />;
}

const AgregarVarianteButton = ({ data, viewClaves, clavesArt, eliminarClave, setModalValor, openModalPrincipal, setVarianteId, variantes, setVariantes, clavesArticuloVariante, editar = { editar } }) => {
    const { t } = useTranslation('common');
    const [isOpenModal, openModal, closeModal] = useModal(false);
    let [initialObject, setInitialObject] = useState();
    let [initialObject2, setInitialObject2] = useState([]);
    let [atributos, setAtributos] = useState([]);
    let [atributo, setAtributo] = useState({
        clave_Atributo: '',
        nombre: '',
        valor: ''
    });
    let [variant, setVariant] = useState({
        nombre: data.nombre || "Variante",
        actStockAut: "N",
        altura: 0,
        ancho: 0,
        articulo_Id: data.id,
        articulo_Padre_Id: 0,
        atributos: [],
        atributos_Combinacion: [],
        clave: '',
        clave_Prod_Serv: "",
        clave_Unidad: "",
        clavesArticulo: null,
        codigo_Barras: null,
        estatus: "A",
        existenciaPublicaciones: null,
        existencias: 0,
        imagenes: [],
        longitud: 0,
        nivelesArticulos: [],
        no_Identificacion: "",
        padre: null,
        peso: 0,
        precio: 0,
        sku: data.sku,
        sucursal_Id: 0,
        ultimo_Costo: null,
        variante_Padre_Id: null,
        id: 0
    });
    let [i, setI] = useState(-1);

    function AddVariante() {
        setAtributos([]);
        setAtributo({
            clave_Atributo: '',
            nombre: '',
            valor: ''
        });
        openModal();
    }

    function handleInputChange(event) {
        setAtributo({
            ...atributo,
            [event.target.name]: event.target.value
        });
    }

    const agregarAtributo = () => {
        if (getDataSession('tienda') === 'MERCADOLIBRE') {
            if (atributo.clave_Atributo.length === 0 || atributo.valor.length === 0) {
                Swal.fire('info', "Debe agregar un atributo y valor", 'info');
                return;
            }

            if (atributos.filter(x => x.clave_Atributo === atributo.clave_Atributo && x.valor === atributo.valor).length > 0) {
                Swal.fire('info', "El atributo y valor ya se enecuntran en la tabla", 'info');
                return;
            }

            setAtributos([...atributos, atributo]);
            return;
        }

        if (!initialObject || !initialObject.clave_Atributo || initialObject.clave_Atributo.length === 0) {
            Swal.fire('info', "Debe agregar un atributo", 'info');
            return;
        }

        if (!initialObject2 || !initialObject2.nombre || initialObject2.nombre.length === 0) {
            Swal.fire('info', "Debe agregar el valor del atributo", 'info');
            return;
        }

        if (atributos.filter(x => x.clave_Atributo === initialObject.clave_Atributo && x.valor === initialObject2.nombre).length > 0) {
            Swal.fire('info', "El atributo y valor ya se enecuntran en la tabla", 'info');
            return;
        }

        setAtributos([...atributos, { clave_Atributo: initialObject.clave_Atributo, nombre: initialObject.nombre, valor: initialObject2.nombre}]);
    }

    const agregarVariante = () => {
        if (atributos.length == 0) {
            Swal.fire('info', "Debe tener minimo un atributo, para agregar una variante", 'info');
            return;
        }

        variant.id = i;
        setI((i - 1));
        variant.atributos_Combinacion = atributos;
        setVariantes([...variantes, variant]);
        if (data.id > 0) ActualizarAgregarVariantes(variant);

        setAtributos([]);
        setAtributo({
            clave_Atributo: '',
            nombre: '',
            valor: ''
        });
        setVariant({
            nombre: data.nombre || "Variante",
            actStockAut: "N",
            altura: 0,
            ancho: 0,
            articulo_Id: data.id,
            articulo_Padre_Id: 0,
            atributos: [],
            atributos_Combinacion: [],
            clave: '',
            clave_Prod_Serv: "",
            clave_Unidad: "",
            clavesArticulo: null,
            codigo_Barras: null,
            estatus: "A",
            existenciaPublicaciones: null,
            existencias: 0,
            imagenes: [],
            longitud: 0,
            nivelesArticulos: [],
            no_Identificacion: "",
            padre: null,
            peso: 0,
            precio: 0,
            sku: data.sku,
            sucursal_Id: 0,
            ultimo_Costo: null,
            variante_Padre_Id: null,
            id: 0
        });
    }

    function ActualizarAgregarVariantes(artVariante) {
        fetchConToken(`Articulos/Variante?articulo_id=` + data.id, artVariante, 'PUT').then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }

            Swal.fire('Exito', 'Actualizacion de variante terminado', 'success');
            clavesArticuloVariante(data.id, response.id);
            if (artVariante.id > 0) {
                const variantesActualizar = variantes.map((item) => { return (item.id === response.id) ? response : item; });
                setVariantes(variantesActualizar);
            }
            else setVariantes([...variantes, response]);

            setAtributos([]);
        });
    }

    async function eliminar(varianteid) {
        if (data.id > 0) {
            Swal.fire({
                text: "De donde desea eliminar la variante?",
                showCancelButton: true,
                confirmButtonText: 'Microzync',
                showDenyButton: true,
                denyButtonText: 'Ecommerce y Microzync',
                cancelButtonText: "Cancelar"
            }).then((result) => {
                var eliminar = false;
                var eliminarMk = false;
                if (result.isConfirmed) {
                    eliminar = true;
                    eliminarMk = false;
                }
                else if (result.isDenied) {
                    eliminar = true;
                    eliminarMk = true;
                }

                if (eliminar) {
                    fetchConToken(`Articulos?articulo_id=${data.id}${eliminarMk ? "&eliminarmk=true" : ''}&varianteId=${varianteid}`, null, 'DELETE').then(function (response) {
                        if (response.hasOwnProperty('status') && response.status === 400) {
                            Object.entries(response.errors).map(([key, value]) => {
                                Swal.fire('Error', value[0], 'error');
                            });
                            return;
                        } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                            return;
                        }

                        Swal.fire(t('app.SwalFire.eliminado'), t('app.Articulos.SwalFire.eliminado'), 'success');
                        var variantesAux = variantes.filter(x => x.id != varianteid);
                        setVariantes(variantesAux);
                    });
                }
            })
        }
        else {
            var variantesAux = variantes.filter(x => x.id != varianteid);
            setVariantes(variantesAux);
        }
    }

    function eliminarAtributo(item) {
        setAtributos(atributos.filter(x => x.valor !== item.valor));
    }

    const loadDataAtributos = async (query) => await fetchConToken(`Categorias/AtributosListBySucursal?pagina=${1}&totalXpagina=${50}&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`);

    const loadDataAtributoValores = async (query) => await fetchConToken(`Categorias/AtributosValoresList?pagina=${1}&totalXpagina=${50}&clave_atributo=${initialObject.clave_Atributo}&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`);

    return (
        <>
            <div className='col-12 col-sm-12 col-md-12'>
                <div className='col-md-13 row'>
                    <div className='col-sm-11'>
                        <div className='card-body text-right'>
                            {editar ? <button type='button' className='btn btn-outline-success' onClick={() => AddVariante()}>Agregar variante</button> : ''}
                        </div>
                    </div>
                </div>
            </div>
            {(variantes && variantes.length > 0) ?
                (<div>{variantes?.map((item) => {
                    return <RowVariante key={item.id} item={item} viewClaves={viewClaves} clavesArt={clavesArt} eliminarClave={eliminarClave} setModalValor={setModalValor} openModal={openModalPrincipal} setVarianteId={setVarianteId} ActualizarAgregarVariantes={ActualizarAgregarVariantes} eliminar={eliminar} editar={editar} />
                })}</div>) : (<SinVariante data={data} />)}
            <div className='espacio5px' />
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                <div className='espacio20px' />
                <div className='col-md-12 row'>
                    <div className='col-md-1' />
                    <div className='col-md-5'>
                        <label htmlFor='atributo'>Atributo</label>
                        {getDataSession('tienda') === 'MERCADOLIBRE' ? (<input type='text' className={'form-control'}
                            name='clave_Atributo'
                            onChange={handleInputChange}
                            placeholder='Color'
                            value={atributo.clave_Atributo}
                        />) : (<AsyncSelect
                            isClearable
                            className='react-select-placeholder'
                            getOptionLabel={(option) => option.clave_Atributo + ' - ' + option.nombre}
                            getOptionValue={(option) => option.clave_Atributo}
                            defaultOptions
                            value={initialObject}
                            loadOptions={loadDataAtributos}
                            onChange={(selectedItem) => { setInitialObject(selectedItem); }}
                        />)}
                    </div>
                    <div className='col-md-4'>
                        <label htmlFor='valor'>Valor</label>
                        {getDataSession('tienda') === 'MERCADOLIBRE' ? (<input type='text' className={'form-control'}
                            name='valor'
                            onChange={handleInputChange}
                            placeholder='rojo'
                            value={atributo.valor}
                        />) : (<AsyncSelect
                            isClearable
                            className='react-select-placeholder'
                            getOptionLabel={(option) => option.nombre}
                            getOptionValue={(option) => option.nombre}
                            defaultOptions
                            isDisabled={!initialObject}
                            value={initialObject2}
                            loadOptions={loadDataAtributoValores}
                            onChange={(selectedItem) => { setInitialObject2(selectedItem); }}
                        />)}
                    </div>
                    <div className='col-md-2 text-center'>
                        <div className='espacio15px' />
                        <div className='espacio10px' />
                        <button type='button' className='btn btn-outline-success' onClick={() => agregarAtributo()}>Agregar Atributo</button>
                        <div className='espacio10px' />
                    </div>
                </div>
                <div className='espacio10px' />
                <div className='col-md-12 row'>
                    <div className='col-md-1' />
                    <div className='col-md-9'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th className='text-center'>Atributo</th>
                                    <th className='text-center'>Valor</th>
                                    <th className='text-center' width='50' />
                                </tr>
                            </thead>
                            <tbody>
                                {atributos?.map(item => {
                                    return <tr>
                                        <td className='text-center'>{item.clave_Atributo}</td>
                                        <td className='text-center'>{item.valor}</td>
                                        <td className='text-center' width='50'>
                                            <button
                                                type='button'
                                                className='btn'
                                                style={{ color: 'red' }}
                                                onClick={(e) => eliminarAtributo(item)}
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='25'
                                                    height='20'
                                                    fill='currentColor'
                                                    className='bi bi-x-circle-fill'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'></path>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='col-md-2 text-center'>
                        <div className='espacio20px' />
                        {editar ? <button type='button' className='btn btn-outline-success' onClick={() => agregarVariante()}>Agregar Variante</button> : ''}
                    </div>
                </div>
                <div className='espacio20px' />
                <div className='espacio20px' />
            </Modal>
        </>
    );
}

const SinVariante = ({ data }) => {
    return (
        <>
            <div className='col-12 col-sm-12 col-md-12'>
                <div className='espacio30px' />
                <div className='col-md-13 row'>
                    <div className='col-sm-1' />
                    <div className='col-sm-10'>
                        <div className='card-body'>
                            <div className='espacio30px' />
                            <div className='col-md-13 row'>
                                <div className='col-sm-5' />
                                <div className='col-sm-4'><div className='text-justify'><label className='form-label'>Sin Variantes</label></div></div>
                                <div className='col-sm-3' />
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-1' />
                </div>
                <div className='espacio30px' />
            </div>
        </>
    );
}

const Cargando = () => {
    return (
        <div className='col-12 col-sm-12 col-md-12'>
            <div className='espacio30px' />
            <div className='col-md-13 row'>
                <div className='col-sm-1' />
                <div className='col-sm-10'>
                    <div className='card-body'>
                        <div className='espacio30px' />
                        <div className='col-md-13 row'>
                            <div className='col-sm-5' />
                            <div className='col-sm-4'>
                                <CircularProgress />
                                <div className='text-justify'><label className='form-label'>Cargando...</label></div>
                            </div>
                            <div className='col-sm-3' />
                        </div>
                    </div>
                </div>
                <div className='col-sm-1' />
            </div>
            <div className='espacio30px' />
        </div>);
}

const RowVariante = ({ item, viewClaves, clavesArt, eliminarClave, setModalValor, openModal, setVarianteId, ActualizarAgregarVariantes, eliminar, editar = { editar } }) => {
    const { t } = useTranslation("common");
    let [imagenes, setImagenes] = useState(item.imagenes);
    let [existencia, setExistencia] = useState(item.existencias);
    let [lsexistencia, setlsExistencia] = useState([{ existencia: item.existencias }]);

    function handleChangeAddImg(data) {
        if (data) {
            item.imagenes.push({ urlImagen: data });
            setImagenes([...imagenes, { urlImagen: data }]);
        }

        if (data.id > 0) ActualizarAgregarVariantes(item);
    }

    function handleChangeRemove() {
        item.imagenes = [];
        setImagenes([]);
        ActualizarAgregarVariantes(item);
    }

    const changeExistencia = ({ target }) => {
        setExistencia(target.value);
        item.existencias = target.value;
        setlsExistencia([]);
        setlsExistencia([{ existencia: target.value }]);
        item.existenciaPublicaciones = [{ existencia: target.value }];
    }

    return (
        <div className='col-12 col-sm-12 col-md-12'>
            <div className='col-md-13 row'>
                <div className='col-sm-1' />
                <div className='col-sm-10 card card-backgground'>
                    <div className='card-body text-right'>
                        {editar ? <button type='button' onClick={() => eliminar(item.id)} className='btn btn-outline-danger btn-sm'>X</button> : ''}
                        <div className='espacio5px' />
                        <div className='col-md-13 row'>
                            <div className='col-sm-2 container-center'>
                                <div className='card'>
                                    {imagenes?.length > 0 ?
                                        <>
                                            <img className='img_art_card' src={imagenes?.at(0)?.urlImagen} alt={imagenes?.at(0)?.clave_Imagen} />
                                            {editar ? <button type='button' onClick={() => handleChangeRemove()} className='btn btn-outline-danger btn-sm'>X</button> : ''}
                                        </> :
                                        (editar ?
                                            <ListImg
                                                data={imagenes}
                                                isAdd={true}
                                                isEdit={true}
                                                handleChangeAddImg={handleChangeAddImg}
                                                handleChangeRemove={handleChangeRemove}
                                            /> : <img className='img_art_card' src={sinIimagen} />)
                                    }
                                </div>
                            </div>
                            <div className={viewClaves ? 'col-sm-5 container-center' : 'col-sm-8 container-center'}>
                                <div className='text-justify'><label className='form-label'>Titulo del Articulo: {item?.nombre || ''}</label></div>
                                {!viewClaves && <div className='text-justify'><label className='form-label'>SKU: {item?.sku || ''}</label></div>}
                                {viewClaves && <>
                                    <div className='text-justify'>
                                        {item?.atributos_Combinacion?.map(x => { return <small><p>{x.clave_Atributo}: {x.valor}</p></small> })}
                                    </div>
                                    {item.id > 0 ?
                                        <div className='text-justify'><label className='form-label'>Existencias: {item?.existencias || '0'}</label></div> :
                                        <div className='text-justify'><label className='form-label'>Existencias: </label><input value={existencia} type='number' onChange={changeExistencia} className='form-control' /></div>
                                    }
                                    <div className='text-justify'><label className='form-label'>Precio: ${item?.precio || '0'}</label></div>
                                </>}
                            </div>
                            {!viewClaves && <div className='col-sm-2 container-center'>
                                <div className='text-justify'><label className='form-label'>Existencias: {item?.existencias || '0'}</label></div>
                                <div className='text-justify'><label className='form-label'>Precio: ${item?.precio || '0'}</label></div>
                            </div>}
                            {viewClaves && <div className='col-sm-5 container-center'>
                                <ClavesArticulos clavesArt={clavesArt} eliminarClave={eliminarClave} setModalValor={setModalValor} openModal={openModal} varianteId={item.id} setVarianteId={setVarianteId} />
                            </div>}
                        </div>
                        <div className='espacio5px' />
                        {item.padre ? <div className='row'>
                            <div className='col-md-3'><label>Cve. Orig.:</label><br /><small>{item.padre.cveOrig}</small> </div>
                            <div className='col-md-5'><label>Nom. Orig.:</label><br /><small>{item.padre.nomOrig}</small> </div>
                            <div className='col-md-2'><label>Exi. Orig.:</label><br /><small>{item.padre.exiOrig}</small> </div>
                            <div className='col-md-2'><label>Pre. Orig.:</label><br /><small>{currency(item.padre.preOrig)}</small> </div>
                        </div> : ''}

                    </div>
                </div>
                <div className='col-sm-1' />

            </div>
            <div className='espacio15px' />
        </div>);
}

export { ListVariante, Cargando };