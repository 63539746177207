import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { useGlobalState } from 'resources/GlobalStateContext';
import { currency } from 'resources/utilities';
import { Link } from 'react-router-dom';
import ListTable from 'components/table/ListTable';
import TextField from '@mui/material/TextField';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const List = ({ history, match }) => {
    const { path } = match;
    const { t } = useTranslation("common");
    const baseUrl = process.env.REACT_APP_SERVER_URL;
    const [fechaInicio, setFechaInicio] = useState(GetFecha(true));
    const [fechaFin, setFechaFin] = useState(GetFecha(false));
    const [actualizarTabla, setActualizarTabla] = useState(false);
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let [statusFiltro, setStatusFiltro] = useState("");
    let [totales, setTotales] = useState([]);

    let [vxEntrega, setVxEntrega] = useState({});
    const [isOpenModal, openModal, closeModal] = useModal(false);

    const verVxEntregas = (entrega_id) => {
        fetchConToken(`VxEntregas?id=${entrega_id}`).then(resp=>{
            setVxEntrega(resp);
        });        
        openModal();
    }

    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "verVxEntregas" ,verVxEntregas:verVxEntregas},
        { field: 'folio', headerName: 'Folio Entrega', minWidth: 180 },
        { field: 'nombre_Cliente', headerName: 'Cliente', minWidth: 180 },
        { field: 'nombre_Agente', headerName: 'Agente', minWidth: 180 },
        { field: 'fecha_Entrega', headerName: 'Fecha Entrega', minWidth: 180  },
        { field: 'fecha_Surtido', headerName: 'Fecha Surtido', minWidth: 80},
        { field: 'recibe', headerName: 'Recibio', minWidth: 100 },        
    ];

    
 

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Entregas',
            subtitulo: 'Listado de entregas'
        });
    };

    const handleInputChangeFechaInicio = ({ target }) => {
        setFechaInicio(target.value);
    };

    const handleInputChangeFechaFin = ({ target }) => {
        setFechaFin(target.value);
    }

    function GetFecha(inicio) {
        var curr = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        if (!inicio) curr = new Date(curr.getFullYear(), curr.getMonth(), new Date(curr.getFullYear(), curr.getMonth() + 1, 0).getDate());

        curr.setDate(curr.getDate());
        return curr.toISOString().substring(0, 10);
    }


    useEffect(() => actualizarTitulo(), []);

    const handleChangeFiltroStatus = (event, newValue) => {
        setStatusFiltro(newValue);
    };

    const CustomTab = ({ value, label, count, color }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{label}</span>
            <span style={{
                marginLeft: 5,
                backgroundColor: color,
                color: 'white',
                padding: '2px 5px',
                borderRadius: 4,
            }}>
                {count}
            </span>
        </div>
    );

    useEffect(() => getTotales(), [fechaInicio,fechaFin]);

    function getTotales() {
        fetchConToken(`VxEntregas/GetListTotales?sucursal_Id=${getDataSession('sucursal')}&fechainicio=${fechaInicio}&fechafin=${fechaFin}`).then(function (response) { setTotales(response) });
    }

    return (
        <>
            <div className='espacio10px' />
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    {/*<div className='col-sm-2'>
                        <Link to={`${path}/add`} className='btn btn-outline-primary m-1' style={{ width: 'auto' }}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; {t('app.SwalFire.nuevo')}
                        </Link>
                    </div>*/}
                    <div className='col-sm-5' />
                    <div className='col-sm-5' style={{ display: 'flex' }}>
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={handleInputChangeFechaInicio}
                            InputLabelProps={{ shrink: true, }}
                        />
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Fin"
                            type="date"
                            value={fechaFin}
                            onChange={handleInputChangeFechaFin}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>
                </div>
                <div className='col-md-12'>
                    <Tabs value={statusFiltro} onChange={handleChangeFiltroStatus} textColor="secondary" indicatorColor="secondary">
                        <Tab value="" label={<CustomTab label="Todos" count={totales?.todos} color='black' />} />
                        <Tab value="P" label={<CustomTab label="Pendiente" count={totales?.pendientes} color='blue' />} />
                        <Tab value="S" label={<CustomTab label="Surtido" count={totales?.surtidos} color='yellowgreen' />} />
                        <Tab value="C" label={<CustomTab label="Cancelado" count={totales?.cancelados} color='grey' />} />
                    </Tabs>
                    <ListTable 
                        columns={columns} 
                        path={path} 
                        reload={actualizarTabla} 
                        url={`VxEntregas/GetList?sucursal_Id=${getDataSession('sucursal')}&fechainicio=${fechaInicio}&fechafin=${fechaFin}&estatus=${statusFiltro}`} />
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal} >
                <div className='col-md-12 row'>
                    <div className='col-md-4'>
                        <h4>Cliente</h4>
                        <label>Cliente:</label><p><label>{vxEntrega?.cliente?.nombre}</label></p>
                        <label>Telefono:</label><p>{vxEntrega?.cliente?.telefono1}</p>
                        <label>Correo:</label><p>{vxEntrega?.cliente?.correo}</p>
                    </div>
                    <div className='col-md-4'>
                         <h4>Entrega</h4>
                        <label>Fecha Entrega:</label><p>{vxEntrega?.fecha_Entrega}</p>
                        <label>Fecha Surtido:</label><p>{vxEntrega?.fecha_Surtido}</p>
                        <label>Recibe:</label><p>{vxEntrega?.recibe}</p>
                    </div>
                    <div className='col-md-4'>
                        <h4>Datos</h4>
                        <label>Notas:</label><p>{vxEntrega?.notas}</p>
                        <label>firma:</label>
                        <p>
                            <img style={{width: "100px", height: "100px"}} src={baseUrl+vxEntrega?.firma}/>
                        </p>
                    </div>
                </div>
                <h4>Detalle Entrega</h4>
                <div className='row' style={{border: "1px solid black"}}>
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Clave Articulo</th>
                                    <th>Articulo</th>
                                    <th>Cant. a Entregar</th>
                                    <th>Cant. Entregada</th>
                                    <th>Diferencia</th>
                                </tr>
                                </thead>
                                <tbody>
                                {vxEntrega?.vx_Entregas_Detalles?.map(x=>{
                          return  ( 
                                    <tr>
                                    <td>{x?.clave}</td>
                                    <td>{x?.articulo}</td>
                                    <td>{x?.cant_Entregar}</td>
                                    <td>{x?.cant_Entregada}</td>
                                    <td>{x?.cant_Entregar - x?.cant_Entregada}</td>
                                    </tr>)})}
                                </tbody> 
                            </table>
                            </div>
           </Modal>
        </>
    );
}

export { List };