import React, { useState } from 'react';
import { useTheme } from 'react-jss';
import { slide as Menu } from 'react-burger-menu';

const getMenuStyles = ({ theme }) => ({
    bmBurgerButton: {
        position: 'fixed',
        width: 26,
        height: 20,
        left: 30,
        top: 40,
        zIndex: 3
    },
    bmBurgerBars: {
        background: theme.color.veryDarkGrayishBlue
    },
    bmBurgerBarsHover: {
        background: theme.color.darkRed
    },
    bmCrossButton: {
        display: 'none'
    },
    bmCross: {
        background: theme.color.grayishBlue3
    },
    bmMenuWrap: {
        height: '100%',
        zIndex: 20,
        border: '1px solid #f0f0f0'
        //boxShadow: '2px 5px 10px 5px rgb(0 0 0 / 10%)'
    },
    bmMenu: {
        background: 'rgb(238 233 242)',//theme.color.veryDarkGrayishBlue, '#fff'
        overflow: 'hidden'
    },
    bmItem: {
        outline: 'none',
        //display: "grid",
        //gridTemplateColumns: "80px 270px",
        '&:focus': {
            outline: 'none'
        },
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    bmMorphShape: {
        fill: theme.color.veryDarkGrayishBlue
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
        zIndex: 20
    },
});

function MenuComponent({ children, isMobile }) {
    const theme = useTheme();
    const menuStyles = getMenuStyles({ theme });
    const [isOpen, setIsOpen] = useState(true);
    return (
        <Menu
            isOpen={!isMobile && isOpen}
            noOverlay={!isMobile}
            disableCloseOnEsc
            styles={menuStyles}
            width={"250px"}
            onStateChange={(state) => setIsOpen(state.isOpen)}
            //customBurgerIcon = {  true  }
        >
            {children}
        </Menu>
    );
}

export default MenuComponent;
