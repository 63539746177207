import React, { useState, useEffect } from "react";
import "./AnimatedIcon.css"; // Asegúrate de crear este archivo CSS

const AnimatedIcon = ({ isTrue }) => {
  const [rotate, setRotate] = useState(isTrue);

  console.log(isTrue);
  useEffect(() => {
    setRotate(isTrue);
  }, [isTrue]);

  return (
    <div className={`icon ${rotate ? "rotate-true" : "rotate-false"}`} style={{color: 'white'}}>
      &#8250;
    </div>
  );
};

export default AnimatedIcon;