import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { EnvioMensajes } from "./EnvioMensajes";

function SmsCobranza({ match }) {
    const { path } = match;

    return (
        <Switch>
            <Route exact path={path} component={EnvioMensajes} />             
            <Redirect from="*" to={{
                                pathname: EnvioMensajes
                            }}/>
        </Switch>
    );
}

export { SmsCobranza };
