import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { useGlobalState } from 'resources/GlobalStateContext';
import { currency } from 'resources/utilities';
import { Link } from 'react-router-dom';
import ListTable from 'components/table/ListTable';
import TextField from '@mui/material/TextField';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';


const List = ({ history, match }) => {
    const { path } = match;
    const { t } = useTranslation("common");
    const [fechaInicio, setFechaInicio] = useState(GetFecha(true));
    const [fechaFin, setFechaFin] = useState(GetFecha(false));
    const [actualizarTabla, setActualizarTabla] = useState(false);
    const { globalTitulo, setGlobalTitulo } = useGlobalState();

    let [vxDoctocc, setVxDoctocc] = useState({});
    const [isOpenModal, openModal, closeModal] = useModal(false);

    const verPagos = (docto_cc_id) => {
        fetchConToken(`VxCobros?id=${docto_cc_id}`).then(resp=>{
            setVxDoctocc(resp);
        });        
        openModal();
    }

    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "editar,verPagos", verPagos: verPagos },
        { field: 'folio', headerName: 'Folio', minWidth: 100 },
        { field: 'nombre_Cliente', headerName: 'Cliente', minWidth: 100 },
        { field: 'fecha', headerName: 'Fecha', minWidth: 80 , renderCell: ({ value }) => value?.substring(0,10)},
        { field: 'cancelado', headerName: 'Cancelado', minWidth: 100 },
        { field: 'aplicado', headerName: 'Aplicado', minWidth: 100 },
        { field: 'liquidado', headerName: 'Liquidado', minWidth: 100},
        { field: 'importe', headerName: 'Importe', minWidth: 100, type: 'number', renderCell: ({ value }) => currency(value)  },
        
        
    ];

    
 

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Cargos',
            subtitulo: 'Listado de Cargos'
        });
    };

    const handleInputChangeFechaInicio = ({ target }) => {
        setFechaInicio(target.value);
    };

    const handleInputChangeFechaFin = ({ target }) => {
        setFechaFin(target.value);
    }

    function GetFecha(inicio) {
        var curr = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        if (!inicio) curr = new Date(curr.getFullYear(), curr.getMonth(), new Date(curr.getFullYear(), curr.getMonth() + 1, 0).getDate());

        curr.setDate(curr.getDate());
        return curr.toISOString().substring(0, 10);
    }


    useEffect(() => actualizarTitulo(), []);


    return (
        <>
            <div className='espacio10px' />
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-2'>
                        {/*<Link to={`${path}/add`} className='btn btn-outline-primary m-1' style={{ width: 'auto' }}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; {t('app.SwalFire.nuevo')}
                        </Link>*/}
                    </div>
                    <div className='col-sm-5' />
                    <div className='col-sm-5' style={{ display: 'flex' }}>
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={handleInputChangeFechaInicio}
                            InputLabelProps={{ shrink: true, }}
                        />
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Fin"
                            type="date"
                            value={fechaFin}
                            onChange={handleInputChangeFechaFin}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>
                </div>
                <div className='col-md-12'>
                    <ListTable 
                        columns={columns} 
                        path={path} 
                        reload={actualizarTabla} 
                        url={`VxDocumentosCc/GetList?sucursal_Id=${getDataSession('sucursal')}&naturaleza_Concepto=C&liquidado=N&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`} />
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal} >
                <div className='col-md-12 row'>
                    <div className='col-md-4'>
                        <h4>Cliente</h4>
                        <label>Cliente:</label><p><label>{vxDoctocc?.cliente?.nombre}</label></p>
                        <label>Telefono:</label><p>{vxDoctocc?.cliente?.telefono1}</p>
                        <label>Correo:</label><p>{vxDoctocc?.cliente?.correo}</p>
                    </div>
                    <div className='col-md-4'>
                         <h4></h4>
                        <label>Rfc:</label><p>{vxDoctocc?.cliente?.rfc}</p>
                        <label>Uso Cfdi:</label><p>{vxDoctocc?.cliente?.uso_Cfdi}</p>
                        <label>Regimen Fiscal Receptor:</label><p>{vxDoctocc?.cliente?.regimen_Fiscal_Receptor}</p>
                    </div>
                    <div className='col-md-4'>
                         <h4></h4>
                        <label>Total Documento:</label><p>{currency(vxDoctocc?.importe)}</p>
                        <label>Acreditado:</label><p>{currency(vxDoctocc?.acreditado)}</p>
                        <label>Adeudo:</label><p>{currency(vxDoctocc?.importe-vxDoctocc?.acreditado)}</p>
                    </div>
                </div>
                <div className='col-md-12'>
                    <h4>Pagos</h4>
                    
                      
                            <div className='row' style={{border: "1px solid black"}}>
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Folio Concepto:</th>
                                    <th>Fecha:</th>
                                    <th>Importe:</th>
                                    <th>Aplicado:</th>
                                    <th>Cancelado:</th>
                                </tr>
                                </thead>
                                <tbody>
                                {vxDoctocc?.vx_Importe_CC?.map(x=>{
                          return  ( 
                                    <tr>
                                    <td>{x?.folio_Concepto}</td>
                                    <td>{x?.fecha}</td>
                                    <td>{currency(x?.importe)}</td>
                                    <td>{x?.aplicado}</td>
                                    <td>{x?.cancelado}</td>
                                    </tr>)})};
                                </tbody>
                            </table>
                            </div>
                      
                </div>
           </Modal>
        </>
    );
}

export { List };