import React from 'react';
import { any, arrayOf, func, string } from 'prop-types';
import { Column, Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import CollapsibleContent from 'components/collapsible/CollapsibleContent';
import { useSidebar } from 'hooks/useSidebar';
import { getDataSession } from 'views/Utils/Common';

import AnimatedIcon from "./AnimatedIcon";

const useStyles = createUseStyles({
    activeContainer: {
        backgroundColor: ({ theme }) => theme.color.paleBlueTransparent
    },
    container: {
        display: 'flex',
        height: 33,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: ({ theme }) => theme.color.paleBlueTransparent
        },
        paddingLeft: 25,
        transition: 'all 0.2s ease-in-out'
    },
    leftBar: {
        borderLeft: ({ level }) =>
            level > 1 ? 'none' : `4px solid #FFFFFF`
    },
    title: {
        fontSize: 15,
        lineHeight: '20px',
        letterSpacing: '0.2px',
        color: ({ theme, isActive }) => (isActive ?  'rgb(83 142 221)'/*theme.color.paleBlue*/ :  'rgba(1, 41, 95, 1)'/*theme.color.grayishBlue*/),
        marginLeft: 5
    }
});

//rgba(1, 41, 95, 1)     rgb(131 144 169)

function MenuItemComponent({ children, icon: Icon, id, items = [], level = 1, onClick, title }) {
    const theme = useTheme();
    const isCollapsible = children && children.length > 0;
    const { isExpanded, isActive, onItemClick } = useSidebar({
        isCollapsible,
        item: id,
        items
    });
    const classes = useStyles({ theme, level, isActive });
    const classNameColumn = isActive ? classes.leftBar : '';
    const classNameContainer = [classes.container, isActive && classes.activeContainer].join(' ');
    const iconColor = isActive ? '#2ED28A' : '#44C2FA';
    function onItemClicked(e) {
        if (onClick) {
            onClick(e);
        }
        onItemClick();
    }
    var menua = getDataSession('menu');

    return (
        
        <Column key={id} className={classNameColumn}>
        <Row key={"menuItem01"} vertical='center' onClick={onItemClicked} className={classNameContainer}>
            <Icon key={"menuItem02"} fill={iconColor} opacity={!isActive && '1'} />
            <span key={"menuItem03"} className={classes.title}>{title}</span>
            
            {items.length > 0 ?
            <>&nbsp;<AnimatedIcon isTrue={menua === id} /></> : '' }
        </Row >
        {isCollapsible && (
            <CollapsibleContent key={"menuItem04"} expanded={isExpanded}>
                {children.map((child) => child.type({ ...child.props }))}
            </CollapsibleContent>
        )}
         
    </Column>
    );
}

MenuItemComponent.defaultProps = {};

MenuItemComponent.propTypes = {
    children: any,
    icon: func,
    id: string,
    onClick: func,
    items: arrayOf(string),
    title: string
};

export default MenuItemComponent;
