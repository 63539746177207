/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Pagination from 'components/pagination/Pagination';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import { CategoriaList, AtributoList } from '../CategoriaList';
import { FichaTecnica } from '../FichaTecnica';
import { ListImg } from '../ListImg';
import { Costos } from '../Costos';
import { GeneralForm } from './GeneralForm';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import Settings from '@mui/icons-material/Settings';
import ImageIcon from '@mui/icons-material/Image';
import TaskIcon from '@mui/icons-material/Task';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const FormPublicador = ({ id, tienda, sucursalId, onClose }) => {
    const { t } = useTranslation("common");
    const busqueda = useFormInput('');
    const totalxPagina = 10;
    const isAddMode = !id;
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [valueTab, setValueTab] = useState(0);
    const [categoriaFinId, setCategoriaFinID] = useState('0');
    const [modalValor, setModalValor] = useState(false);
    let [datos, setDatos] = useState([]);
    let [atributos, setAtributos] = useState([]);
    let [categorias, setCategorias] = useState([]);
    let [atributosArticulo, setAtributosArticulo] = useState([]);
    let [catalogoSat, setCatalogoSat] = useState(['']);
    let [sucursal, setSucursal] = useState([]);
    let [impuestos, setImpuestos] = useState([]);
    let [imagenes, setImagenes] = useState([]);
    let [articulo, setArticulo] = useState({});
    let [preciosArt, setPreciosArt] = useState([]);
    let [listaPrecios, setListaPrecios] = useState([]);
    let [listaImpuestosArt, setListaImpuestosArt] = useState([]);
    let [impuestoArticulo, setImpuestoArticulo] = useState({
        id: 0,
        articulo_Id: id,
        tipo_Impuestos_Id: 0,
        tipo_impuesto: {}
    });
    let [nuevoPrecioArticulo, setNuevoPrecioArticulo] = useState({
        id: 0,
        articulo_Id: id,
        lista_Precio_Id: 0,
        precio: 0
    });

    useEffect(() => {
        getSucursal(sucursalId);
        listasPrecios();
        if (!isAddMode) getArticulo();
        else if (sucursalId === '0') {
            Swal.fire('Info', t('app.SwalFire.infoConexion'), 'info');
            return;
        }

        listaImpuestos();
    }, []);

    function impuestosByArticulo(id) {
        fetchConToken(`ImpuestosArticulos/GetList?articulo_Id=${id}`).then((imp) => setImpuestos(imp));
    }

    function getSucursal(id) {
        fetchConToken(`sucursales?id=${sucursalId}`).then((suc) => setSucursal(suc));
    }

    function listaImpuestos() {
        fetchConToken(`TiposImpuestos/GetList?paginas=1&totalXpagina=100`).then((imp) => setListaImpuestosArt(imp));
    }

    function getArticulo() {
        fetchConToken(`articulos?id=${id}&sucursal_Id=${sucursalId}&conDetalle=true`).then(
            (art) => {
                if (art.hasOwnProperty('status')) {
                    Object.entries(art?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (art.hasOwnProperty('codigo')) {
                    Swal.fire('Error', art.mensajes[0], 'error');
                    return;
                }
                
                setArticulo(art);
                setImagenes(art.imagenes);
                setAtributosArticulo(art.atributos);
                setCategorias(art.categorias);
                impuestosByArticulo(id);
                precioArticulo(id);
            }
        );
    }

    const onChangeCheck = ({ target }) => {
        const xCheck = target.checked ? 'S' : 'N';
        setArticulo({
            ...articulo,
            [target.name]: xCheck
        });
    };

    const onChangeCheckActivo = ({ target }) => {
        const xCheck = target.checked ? 'A' : 'I';
        setArticulo({
            ...articulo,
            [target.name]: xCheck
        });
    };

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setArticulo({
            ...articulo,
            [name]: value
        });
    }

    const registrarImpuesto = () => {
        if (String(impuestoArticulo.tipo_Impuestos_Id) === '0') {
            Swal.fire('', t('app.Articulos.SwalFire.seleccioneImpuestos'), 'info');
            //alertService.error('Seleccione un impuesto', { keepAfterRouteChange: true });
            return;
        }

        console.log(impuestoArticulo.tipo_Impuestos_Id);
        var impuestoRepetido = impuestos.filter((i) => i.tipo_Impuestos_Id === impuestoArticulo.tipo_Impuestos_Id);
        if (impuestoRepetido.length > 0) {
            Swal.fire('', t('app.Articulos.SwalFire.impuestoAgregadoAnt'), 'info');
            return;
        }

        console.log(impuestoArticulo);
        if (isAddMode) {
            setImpuestos([...impuestos, impuestoArticulo]);
            return;
        }

        fetchConToken(`ImpuestosArticulos`, impuestoArticulo, 'POST').then(function (response) {
            if (response.hasOwnProperty('status')) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            Swal.fire(t('app.SwalFire.exitoso'), t('app.Articulos.SwalFire.impuestoAgregado'), 'success');
            //alertService.success('Impuesto agregado', { keepAfterRouteChange: true });
            setImpuestos([...impuestos, response]);
        });
    };

    const eliminarImpuesto = (id_imp, tipo_Impuestos_Id) => {
        Swal.fire({
            title: t('app.Articulos.SwalFire.eliminarImpuesto'),
            showCancelButton: true,
            confirmButtonText: t('app.botones.eliminar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                if (id_imp !== undefined) {
                    fetchConToken(`ImpuestosArticulos?id=${id_imp}`, null, 'DELETE').then(function (
                        response
                    ) {
                        if (response.hasOwnProperty('status')) {
                            Object.entries(response.errors).map(([key, value]) => {
                                Swal.fire('Error', value[0], 'error');
                                return;
                            });
                            return false;
                        } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                            return false;
                        }
                        Swal.fire(t('app.Articulos.SwalFire.impuestoEliminado'), '', 'success');
                        //alertService.success('Impuesto Eliminado', { keepAfterRouteChange: true });
                        const arrayFiltrado = impuestos.filter(
                            (impuesto) => impuesto.id !== id_imp
                        );
                        setImpuestos(arrayFiltrado);
                    });
                } else {
                    setImpuestos(
                        impuestos.filter((imp) => imp.tipo_Impuestos_Id !== tipo_Impuestos_Id)
                    );
                }
            }
        });
    };

    function handleChange(e) {
        var impuesto = listaImpuestosArt.filter((imp) => String(imp.id) === e.target.value);
        setImpuestoArticulo({
            tipo_Impuestos_Id: e.target.value,
            articulo_Id: id,
            tipo_impuesto: impuesto[0]
        });
    }

    function handleChangeCategoria(e) {
        if (getDataSession('tienda') !== 'MERCADOLIBRE') {
            if (e === '0' && articulo.clave_Categoria && articulo.clave_Categoria !== '' && articulo.clave_Categoria !== '0') {
                setCategoriaFinID(articulo.clave_Categoria);
                return;
            }

            setCategoriaFinID(e);
            setArticulo({
                ...articulo,
                clave_Categoria: e
            });
            return;
        }

        if (e === '0' && articulo.clave_Categoria && articulo.clave_Categoria !== '' && articulo.clave_Categoria !== '0') {
            if (articulo.clave_Categoria !== categoriaFinId) loadDataAtributos(articulo.clave_Categoria, 1);

            setCategoriaFinID(articulo.clave_Categoria);
            return;
        }

        setCategoriaFinID(e);
        setArticulo({
            ...articulo,
            clave_Categoria: e
        });
        if (e !== '0') loadDataAtributos(e, 1);
    }

    function handleChangeAtributo(claveAtributo, valor) {
        if (claveAtributo === 'WARRANTY_TYPE') {
            setArticulo({
                ...articulo,
                garantia: valor
            });
            return;
        }

        var res = atributosArticulo?.find(x => x.clave_Atributo === claveAtributo);
        if (res === null || res === undefined) {
            if (atributosArticulo === null || atributosArticulo === undefined) setAtributosArticulo([]);

            res = atributos.find(x => x.clave_Atributo === claveAtributo);
            res.id = 0;
            res.valor = valor;
            atributosArticulo.push(res);
        }
        else res.valor = valor;

        setArticulo({
            ...articulo,
            atributos: atributosArticulo
        });
    }

    function handlePredictCat(e) {
        setCategoriaFinID(e);
        setArticulo({
            ...articulo,
            clave_Categoria: e
        });
        handleChangeTab(undefined, 0);
        handleChangeTab(undefined, 2);
    }

    function handleChangeAddImg(data) {
        if (data) {
            imagenes.push({ urlImagen: data });
            handleChangeTab(undefined, 0);
            handleChangeTab(undefined, 1);
        }
    }

    function handleChangeRemove(data) {
        if (data) setImagenes(imagenes.filter((item) => item.urlImagen !== data.urlImagen));
    }

    function getDatos(page, catalogo = catalogoSat) {
        switch (catalogo) {
            case 'clave_Prod_Serv':
                fetchConToken(`CatalogosSat/ListClaveProd?pagina=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda.value}`)
                    .then((claves) => setDatos(claves));
                break;
            case 'clave_Unidad':
                fetchConToken(`CatalogosSat/ListClaveUnidad?pagina=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda.value}`)
                    .then((claves) => setDatos(claves));
                break;
        }

        setCatalogoSat(catalogo);
    }

    function precioArticulo(id) {
        fetchConToken(`PrecioArticulo/GetList?articulo_id=${id}`).then((imp) => setPreciosArt(imp.data));
    }

    function listasPrecios() {
        fetchConToken(`ListaPrecios/List?sucursal_Id=${sucursalId}`).then((response) => setListaPrecios(response));
    }

    const downloadDataCategoria = async (query, index) => await fetchConToken(`Categorias/DescargarAtributo?claveCategoria=${query}`)
        .then((resp) => { if (index === 1) loadDataAtributos(query, 2); }).catch((e) => { Swal.fire('Error', `No se pudieron descargar los Atributos de la categoria ${query}`, 'error'); });

    const loadDataAtributos = async (query, index) => await fetchConToken(`Categorias/AtributosXCategoria?pagina=${1}&totalXpagina=${100}&claveCategoria=${query}`).then((resp) => {
        setAtributos(resp);
        if (resp.length === 0 && index === 1) downloadDataCategoria(query, index);
        else if (resp.length === 0 && index === 2) Swal.fire('Error', `No se encontraron Atributos de la categoria ${query}`, 'error');
    });

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    }

    const getIsAddMode = () => {
        return isAddMode || !articulo.publicado;
    }

    function updateArt() {
        return fetchConToken('Articulos', articulo, 'PUT')
            .then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => { Swal.fire('Error', value[0], 'error'); });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }

                onClose(true);
            })
    }

    function onClickGuardar(e) {
        articulo.imagenes = imagenes;
        articulo.categorias = categorias;
        setArticulo({
            ...articulo,
            imagenes: imagenes,
            categorias: categorias
        });
        Swal.fire({
            title: t('app.Articulos.SwalFire.guardarArt?'),
            showCancelButton: true,
            confirmButtonText: t('app.botones.guardar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => { if (result.isConfirmed) updateArt(); })
    }

    function handleChangePrecios(e) {
        setNuevoPrecioArticulo({
            ...nuevoPrecioArticulo,
            [e.target.name]: e.target.value
        });
    }

    const agregarPrecio = () => {
        if (String(nuevoPrecioArticulo.lista_Precio_Id) === '0') {
            Swal.fire('', 'Seleccione una lista de precio', 'info');
            return;
        }

        fetchConToken(`PrecioArticulo`, nuevoPrecioArticulo, 'POST').then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }

            if (listaPrecios.filter(x => x.id === nuevoPrecioArticulo.lista_Precio_Id && x.es_Principal).length > 0) articulo.costo_Unidad = nuevoPrecioArticulo.precio;

            Swal.fire(t('app.SwalFire.exitoso'), 'Precio agregado', 'success');
            precioArticulo(id);
            closeModal();
        });
    };

    return (
        <div style={{ marginTop: '-2.5%' }}>
            <Tabs value={valueTab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                <Tab icon={<Settings />} iconPosition="start" aria-label="phone" label={t('app.Articulos.form.tabs.tabGneral')} />
                <Tab icon={<ImageIcon />} iconPosition="start" aria-label="phone" label={t('app.Articulos.form.tabs.tabImgDesc')} />
                <Tab icon={<TaskIcon />} iconPosition="start" aria-label="phone" label={t('app.Articulos.form.tabs.tabCatAtrb')} />
                <Tab icon={<AttachMoneyIcon />} iconPosition="start" aria-label="phone" label={t('app.Articulos.form.tabs.tabCosto')} />
                <Tab icon={<ViewQuiltIcon />} iconPosition="start" aria-label="phone" label={t('app.Articulos.form.tabs.tabVariante')} />
            </Tabs>
            <div className='espacio5px' />
            {valueTab === 0 && (<GeneralForm data={articulo} onChangeCheckActivo={onChangeCheckActivo} onChangeCheck={onChangeCheck} handleInputChange={handleInputChange} />)}
            {valueTab === 1 && (
                <div className='row'>
                    <div className='col-md-12' style={{ display: 'grid' }}>
                        <div className='card mb-3 '>
                            <label className='form-label title2'>{t('app.Articulos.datosObligatorios')}</label>
                            <div className='card-body text-start'>
                                <label className='form-label'>{t('app.Articulos.form.imagenes')}*</label>
                                <div className='card mb-3 card-img'>
                                    <div className='espacio5px' />
                                    <div className='list_img'><ListImg data={imagenes} isAdd={true} isEdit={true} handleChangeAddImg={handleChangeAddImg} handleChangeRemove={handleChangeRemove} /></div>
                                    <div className='espacio5px' />
                                </div>
                                <label htmlFor='descripcion' className='form-label'>{t('app.Articulos.form.descripcionArt')}*</label>
                                <textarea
                                    className='form-control'
                                    name='descripcion'
                                    required={true}
                                    maxLength={100}
                                    rows="8"
                                    value={articulo.descripcion || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {valueTab === 2 && (
                <div className='row'>
                    <div className='col-md-12' style={{ display: 'grid' }}>
                        <div className={tienda === 'MERCADOLIBRE' ? 'card mb-3' : 'mb-3'}>
                            <div className='espacio15px' />
                            {tienda === 'MERCADOLIBRE' ? <div className='card-body text-start'>
                                <CategoriaList
                                    data={articulo}
                                    HandleChangePrincipal={handleChangeCategoria}
                                    disabled={!getIsAddMode()}
                                    HandlePredictCat={handlePredictCat}
                                    tienda={tienda}
                                />
                                {categoriaFinId && categoriaFinId !== '0' /*&& atributos && atributos.length > 0*/ && (
                                    <div>
                                        <div className='espacio15px' />
                                        <div className='espacio15px' />
                                        <Divider variant='middle' color='indigo' />
                                        <div className='espacio15px' />
                                        <FichaTecnica
                                            data={articulo}
                                            Atributos={atributos}
                                            HandleChangePrincipal={handleChangeAtributo}
                                        />
                                    </div>
                                )}
                            </div> : <>
                                <div className='card card-body text-start'>
                                    <CategoriaList
                                        data={articulo}
                                        HandleChangePrincipal={handleChangeCategoria}
                                        HandlePredictCat={handlePredictCat}
                                        categorias={categorias}
                                        SetCategorias={setCategorias}
                                        tienda={tienda}
                                    />
                                </div>
                                <div className='espacio5px' />
                                <div className='card card-body text-start'><AtributoList atributos={atributos} SetAtributos={setAtributos} /></div>
                            </>}
                        </div>
                    </div>
                </div>
            )}
            {valueTab === 3 && (<Costos
                data={articulo}
                listaImpuestosArt={listaImpuestosArt}
                impuestos={impuestos}
                sucursal={sucursal}
                imagenes={imagenes}
                handleChange={handleChange}
                tienda={tienda}
                isAddMode={isAddMode}
                preciosArt={preciosArt}
                setModalValor={setModalValor}
                openModal={openModal}
                registrarImpuesto={registrarImpuesto}
                eliminarImpuesto={eliminarImpuesto}
                handleInputChange={handleInputChange}
                onChangeCheckActivo={onChangeCheck} />

            )}
            {valueTab === 4 && (
                <div className='row'>
                    <div className='col-md-12' style={{ display: 'grid' }}>
                        <div className='card mb-3 '>
                            <div className='espacio15px'></div>
                        </div>
                    </div>
                </div>
            )}
            <div className='espacio5px' />
            <div className='row'>
                <div className='col-sm-8' />
                <div className='col-sm-2'>
                    <button className='btn btn-outline-danger' onClick={onClose}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            fill='currentColor'
                            className='bi bi-x-lg'
                            viewBox='0 0 16 16'
                        >
                            <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
                        </svg>
                        &nbsp; &nbsp; {t('app.botones.cancelar')}
                    </button>
                </div>
                <div className='col-sm-2'>
                    <button className='btn btn-outline-success' onClick={onClickGuardar}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            fill='currentColor'
                            className='bi bi-check-lg'
                            viewBox='0 0 16 16'
                        >
                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                        </svg>
                        &nbsp; {t('app.botones.guardar')}
                    </button>
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                {modalValor === 'asignarPrecio' ? (
                    <>
                        <div className='espacio30px' />
                        <div className='col-md-12 row text-right'>
                            <div className='col-sm-10'>
                                <div className='card-body text-start'>
                                    <label htmlFor='impuesto' className='form-label title2'>
                                        Agregar Precio
                                    </label>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <select
                                                className='form-select'
                                                onChange={handleChangePrecios}
                                                name='lista_Precio_Id'
                                            >
                                                <option key={0} value='0'>
                                                    Seleccione el precio
                                                </option>
                                                {listaPrecios?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>
                                                            {item.nombre}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type='number'
                                                className='form-control'
                                                name='precio'
                                                onChange={handleChangePrecios}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-2'>
                                <div className='espacio30px' />
                                <button
                                    type='button'
                                    className='btn btn-outline-primary'
                                    onClick={(e) => agregarPrecio()}
                                >
                                    Agregar Precio
                                </button>
                            </div>
                        </div>
                    </>
                ) : (<>
                    <div className='col-12 col-sm-12 col-md-12 text-right'>
                        <label htmlFor='id'>
                            <button
                                type='button'
                                className='btn btn-outline-danger'
                                onClick={function (event) { openModal(); }}
                            >
                                {t('app.botones.buscar')}
                            </button>
                        </label>
                    </div>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th width='40px'>#No</th>
                                <th>{t('app.Articulos.form.clave')}</th>
                                <th>{t('app.Articulos.form.nombreArt')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.clave}</td>
                                        <td>{item.nombre}</td>
                                        <td className='text-center'>
                                            <button
                                                className='btn btn-outline-warning'
                                                type='button'
                                                onClick={function (event) { closeModal(); }}
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='25'
                                                    height='25'
                                                    fill='currentColor'
                                                    className='bi bi-check2'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z' />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <Pagination
                        consumirPaginacion={getDatos}
                        elementos={datos.length}
                        totalxPagina={totalxPagina}
                    />
                </>)}
            </Modal>
        </div>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = (e) => { setValue(e.target.value); };
    return {
        value,
        onChange: handleChange
    };
};

export { FormPublicador };
