import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';
import { useGlobalState } from 'resources/GlobalStateContext';

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [actualizarTabla, setActualizarTabla] = useState(false);
    const columns = [
        {
            renderCell: RenderButtons, headerName: '', maxWidth: 20, path: path, opciones: "editar" + (validarSucursal() ? ",asignar,asignarmanual,desvincular" : ""),
            asignarAutomatico: asignar, desvincular: desvincular
        },
        getDataSession('tienda') === 'MERCADOLIBRE' ? { field: 'id', headerName: 'Id', maxWidth: 20 } :
        { field: 'id', headerName: 'Id', maxWidth: 20, renderCell: (params) => (<a href={`${path + '/edit/' + params.row.id}`}>{params.value}</a>) },
        getDataSession('tienda') === 'MERCADOLIBRE' ? { field: 'clave_Etiqueta', headerName: 'Clave etiqueta', flex: 1 } :
        { field: 'clave_Etiqueta', headerName: 'Clave etiqueta', flex: 1, renderCell: (params) => (<a href={`${path + '/edit/' + params.row.id}`}>{params.value}</a>) },
        { field: 'nombre', headerName: 'Nombre', flex: 1 },
        { field: 'slug', headerName: 'Slug', flex: 1 }
    ];

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Etiquetas',
            subtitulo: 'Aqui se muestran todas las etiquetas por conexion'
        });
    };

    function validarSucursal() {
        return getDataSession('sucursal') !== getDataSession('sucursalPrincipal');
    }

    function asignar(etiqueta_id) {
        Swal.fire({
            title: `¿Desea relacionar la Etiqueta?`,
            showCancelButton: true,
            confirmButtonText: 'Relacionar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                var asignacion = {
                    sucursalOrg: getDataSession('sucursal'),
                    sucursalDes: getDataSession('sucursalPrincipal'),
                    catalogoOrg: etiqueta_id,
                    catalogoDes: 0
                };
                fetchConToken(`categorias/AsignarEtiqueta`, asignacion, 'PUT').then((response) => {
                    if (response.hasOwnProperty('status')) {
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }

                    Swal.fire('Asignación completa', 'Etiqueta Relacionado', 'success');
                    setActualizarTabla(true);
                    setActualizarTabla(false);
                });
            }
        });
    }

    function desvincular(etiqueta_id) {
        Swal.fire({
            title: `¿Desea desvincular la etiqueta?`,
            showCancelButton: true,
            confirmButtonText: 'Desvincular',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                var desvincular = {
                    sucursalOrg: getDataSession('sucursal'),
                    sucursalDes: getDataSession('sucursalPrincipal'),
                    catalogoOrg: etiqueta_id,
                    catalogoDes: 0
                };
                fetchConToken(`categorias/DesvincularEtiqueta`, desvincular, 'PUT')
                    .then((response) => {
                        if (response.hasOwnProperty('status')) {
                            Object.entries(response?.errors).map(([key, value]) => {
                                Swal.fire('Error', value[0], 'error');
                                return;
                            });
                            return false;
                        } else if (response.hasOwnProperty('codigo')) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                            return false;
                        }

                        Swal.fire('Exito', 'Etiqueta Desvinculado', 'success');
                        setActualizarTabla(true);
                        setActualizarTabla(false);
                    })
            }
        });
    }

    if (getDataSession('tienda') === 'MERCADOLIBRE') {
        const indexToRemove = columns.findIndex(column => column.headerName === '');
        if (indexToRemove !== -1) columns.splice(indexToRemove, 1);
    }

    if (getDataSession('sucursal') !== getDataSession('sucursalPrincipal')) {
        columns.splice(1, 0, {
            field: 'padre.clave_Etiqueta', headerName: 'Cve. Orig.', width: 100, cellClassName: (params) => { return 'columna-art-padre' }, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.padre?.id}`}>{params.row.padre ? params.row.padre.clave_Etiqueta : ''}</a>)
        });
        columns.splice(2, 0, {
            field: 'padre.nombre', headerName: 'Nom. Orig.', width: 100, cellClassName: (params) => { return 'columna-art-padre' }, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.padre?.id}`}>{params.row.padre ? params.row.padre.nombre : ''}</a>)
        });
    }

    useEffect(() => actualizarTitulo(), []);

    return (
        <div className='container-fluid'>
            <div className='col-md-12'>
                <div className='col-md-12'>
                    {getDataSession('tienda') !== 'MERCADOLIBRE' &&
                        <Link to={`${path}/add`} className='btn btn-outline-primary'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; Nuevo
                        </Link>}
                </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12'>
                <ListTable
                    hideFiltroExtra
                    columns={columns}
                    path={path}
                    url={`Categorias/EtiquetasGetList?sucursal_Id=${getDataSession('sucursal')}`}
                    reload={actualizarTabla}
                />
            </div>
        </div>
    );
};

export { List };