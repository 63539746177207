import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { AddEdit } from "./AddEdit";

function Altiria({ match }) {
    const { path } = match;

    return (
        <Switch>
            <Route exact path={path} component={AddEdit} />             
            <Redirect from="*" to={{
                                pathname: AddEdit
                            }}/>
        </Switch>
    );
}


export { Altiria };
