import React, { useState, useEffect } from 'react';
import { fetchConToken } from 'helpers/fetch';
import AsyncSelect from 'react-select/async';
import { useTranslation } from "react-i18next";
import { getDataSession } from 'views/Utils/Common';
import Swal from 'sweetalert2';

const FormCategoria = ({ data, HandleChangePrincipal, disabled, HandlePredictCat, categorias, SetCategorias, tienda }) => {
    return (
        <div className='row'>
            <div className='col-md-12' style={{ display: 'grid' }}>
                <div className='card mb-3 '>
                    <div className='espacio15px' />
                    <div className='card-body text-start'>
                        <CategoriaList data={data} HandleChangePrincipal={HandleChangePrincipal} disabled={disabled} HandlePredictCat={HandlePredictCat} categorias={categorias} SetCategorias={SetCategorias} tienda={tienda} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const CategoriaList = ({ data, HandleChangePrincipal, disabled, HandlePredictCat, categorias, SetCategorias, tienda }) => {
    const { t } = useTranslation("common");
    const [categoriaId, setCategoriaID] = useState('');
    const [initialObject, setInitialObject] = useState();
    const [initialObject2, setInitialObject2] = useState([]);
    const [categoria, SetCategoria] = useState();
    const loadDataCategoriaPrincipal = async (query) =>
        getDataSession('tienda') !== 'MERCADOLIBRE' ? await fetchConToken(`Categorias/GetList?pagina=${0}&totalXpagina=${50}&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}&tipo_Categoria=P`)
            : await fetchConToken(`Categorias/GetListMercadoLibre?paginas=${1}&totalXpagina=${50}&busqueda=${query}&categoriaPadre`);
    const loadDataSubCategoria = async (query) => await fetchConToken(`Categorias/GetListMercadoLibre?paginas=${1}&totalXpagina=${50}&busqueda=&categoriaPadre=${query}`).then((resp) => {
        setInitialObject2(resp);
        if (resp.length > 0) HandleChangePrincipal('0');
        else HandleChangePrincipal(query);
    });
    const loadDataSubCategoria2 = async (query) => await fetchConToken(`Categorias/GetList?paginas=${0}&totalXpagina=${50}&categoriaPadre=${query}&sucursal_Id=${getDataSession('sucursal')}`).then((resp) => {
        setInitialObject2(resp);
        if (resp.length > 0) HandleChangePrincipal('0');
        else HandleChangePrincipal(query);
    });

    const downloadDataCategoria = async (query, index) => await fetchConToken(`Categorias/DescargarCategoria?claveCategoria=${query}`).then((resp) => { if (index === 1) loadDataCategoria(query, 2); }).catch(Swal.fire('Error', `No se encontro la categoria ${query}`, 'error'));

    const loadDataCategoria = async (query, index) => await fetchConToken(`Categorias/GetCategoriByHijo?categoria=${query}`).then((resp) => {
        setInitialObject(resp);
        if (resp.sub_Categoria && resp.sub_Categoria.length > 0) onChange(resp);
        else if (index === 1) downloadDataCategoria(query, index);
    });

    const PredictCategoria = async (titulo) => await fetchConToken(`Articulos/PredictCategoria?titulo=${titulo}&sucursal_Id=${getDataSession('sucursal')}`).then((resp) => {
        HandleChangePrincipal(resp.categoriaId);
        loadDataCategoria(resp.categoriaId, 1);
        HandlePredictCat(resp.categoriaId);
    });

    const subCat = () => {
        if (initialObject.sub_Categoria?.length > 0) return initialObject.sub_Categoria[0];

        return null;
    }

    function onChange(categoria) {
        setCategoriaID(categoria.clave_Categoria);
        loadDataSubCategoria(categoria.clave_Categoria);
    }

    function ActualizarCategoria() {
        PredictCategoria(data.nombre);
    }

    function AddCategoria(e) {
        var aux = categorias.find((item) => item.clave_Categoria === categoria?.clave_Categoria);
        if (aux) return;

        if (data.id && data.id > 0) {
            fetchConToken(`Articulos/ArticuloCategoria`, { clave_Categoria: categoria.clave_Categoria, articulo_Id: data.id }, 'POST').then((res) => {
                if (res.hasOwnProperty('status')) {
                    Object.entries(res?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (res.hasOwnProperty('codigo') && res.codigo != 200) {
                    Swal.fire('Error', res.mensajes[0], 'error');
                    return false;
                }

                SetCategorias([...categorias, categoria]);
            });
        }
        else SetCategorias([...categorias, categoria]);
    }

    function EliminarCategoria(cat) {
        if (cat) {
            if (data.id && data.id > 0) {
                fetchConToken(`Articulos/ArticuloCategorias?articulo_id=${data.id}&Clave_Etiqueta=${cat.clave_Categoria}`, null, 'DELETE').then((res) => {
                    if (res.hasOwnProperty('status')) {
                        Object.entries(res?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (res.hasOwnProperty('codigo')) {
                        Swal.fire('Error', res.mensajes[0], 'error');
                        return;
                    }

                    SetCategorias(categorias.filter((item) => item.clave_Categoria !== cat.clave_Categoria));
                });
            }
            else SetCategorias(categorias.filter((item) => item.clave_Categoria !== cat.clave_Categoria));
        }
    }

    const GetSelect = () => {
        if (!disabled)
            return (
                <div className='col-md-12' style={{ display: 'grid' }}>
                    <div className='row'>
                        <div className='col-sm-8' style={{ display: 'grid' }}>
                            <AsyncSelect
                                isClearable
                                className='react-select-placeholder'
                                getOptionLabel={(option) => option.clave_Categoria + ' - ' + option.nombre}
                                getOptionValue={(option) => option.clave_Categoria}
                                defaultOptions
                                value={initialObject}
                                isDisabled={disabled}
                                loadOptions={loadDataCategoriaPrincipal}
                                onChange={(selectedItem) => {
                                    const val = selectedItem === null ? '' : selectedItem?.clave_Categoria;
                                    setInitialObject(selectedItem);
                                    setCategoriaID(val);
                                    loadDataSubCategoria(val);
                                }}
                            />
                        </div>
                        <div className='col-sm-4' style={{ display: 'grid' }}>
                            <button className='btn btn-outline-info' type='button' onClick={(e) => ActualizarCategoria()}>
                                Categorizacion Automatica
                            </button>
                        </div>
                    </div>
                </div>
            );

        return (
            <AsyncSelect
                isClearable
                className='react-select-placeholder'
                getOptionLabel={(option) => option.clave_Categoria + ' - ' + option.nombre}
                getOptionValue={(option) => option.clave_Categoria}
                defaultOptions
                value={initialObject}
                isDisabled={disabled}
                loadOptions={loadDataCategoriaPrincipal}
                onChange={(selectedItem) => {
                    const val = selectedItem === null ? '' : selectedItem?.clave_Categoria;
                    setInitialObject(selectedItem);
                    setCategoriaID(val);
                    loadDataSubCategoria(val);
                }}
            />
        );
    }

    useEffect(() => {
        if (tienda === 'MERCADOLIBRE') {
            if (data.clave_Categoria && data.clave_Categoria !== '' && data.clave_Categoria !== '0') loadDataCategoria(data.clave_Categoria, 1);
        }
    }, []);

    return tienda !== 'MERCADOLIBRE' ? (
        <>
            <div className='col-md-12' style={{ display: 'grid' }}>
                <div className='row'>
                    <div className='col-sm-6' style={{ display: 'grid' }}>
                        <label htmlFor='clave_cat_principal' className='form-label'> {t('app.Articulos.form.categoriaPpal')}</label>
                        <AsyncSelect
                            isClearable
                            className='react-select-placeholder'
                            getOptionLabel={(option) => option.clave_Categoria + ' - ' + option.nombre}
                            getOptionValue={(option) => option.clave_Categoria}
                            defaultOptions
                            value={initialObject}
                            isDisabled={disabled}
                            loadOptions={loadDataCategoriaPrincipal}
                            onChange={(selectedItem) => {
                                const val = selectedItem === null ? '' : selectedItem?.clave_Categoria;
                                setInitialObject(selectedItem);
                                setCategoriaID(val);
                                loadDataSubCategoria2(val);
                                if (selectedItem) SetCategoria(selectedItem);
                            }}
                        />
                        {(categoriaId !== '' && initialObject2.length > 0) && (<CategoriaSelect index={1} Categoria={subCat} Categorias={initialObject2} HandleChangePrincipal={HandleChangePrincipal} disabled={disabled} SetCategoria={SetCategoria} />)}
                        <div className='espacio15px' />
                        <div><button className='btn btn-outline-info' type='button' onClick={AddCategoria}>Asignar categoria</button></div>
                    </div>
                    <div className='card col-sm-6' style={{ display: 'grid' }}>
                        <div className='card-body'>
                            <div style={{ overflowX: 'auto' }}>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Clave</th>
                                            <th className='text-center'>Categoria</th>
                                            <th className='text-right' width='50' />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categorias?.map(item => {
                                            return (
                                                <tr key={item.id}>
                                                    <td className='text-center'>{item.clave_Categoria}</td>
                                                    <td className='text-center'>{item.nombre}</td>
                                                    <td className='text-center' width='50'>
                                                        <button type='button' className='btn' style={{ color: 'red' }} onClick={(e) => EliminarCategoria(item)}>
                                                            <svg
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                width='25'
                                                                height='20'
                                                                fill='currentColor'
                                                                className='bi bi-x-circle-fill'
                                                                viewBox='0 0 16 16'
                                                            >
                                                                <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>);
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <>
            <label htmlFor='clave_cat_principal' className='form-label'> {t('app.Articulos.form.categoriaPpal')}</label>
            {GetSelect()}
            {(categoriaId !== '' && initialObject2.length > 0) && (<CategoriaSelect index={1} Categoria={subCat} Categorias={initialObject2} HandleChangePrincipal={HandleChangePrincipal} disabled={disabled} />)}
        </>
    );
}

const CategoriaSelect = ({ index, Categoria, Categorias, HandleChangePrincipal, disabled, SetCategoria }) => {
    const { t } = useTranslation("common");
    const [categoriaId, setCategoriaID] = useState('');
    const [initialObject, setInitialObject] = useState(Categoria);
    const [initialObject2, setInitialObject2] = useState([]);
    const loadDataSubCategoria = async (query) => await fetchConToken(`Categorias/GetListMercadoLibre?paginas=${1}&totalXpagina=${50}&busqueda=&categoriaPadre=${query}`).then((resp) => {
        setInitialObject2(resp);
        if (resp.length > 0) HandleChangePrincipal('0');
        else HandleChangePrincipal(query);
    });

    const loadDataSubCategoria2 = async (query) => await fetchConToken(`Categorias/GetList?paginas=${0}&totalXpagina=${50}&categoriaPadre=${query}&sucursal_Id=${getDataSession('sucursal')}`).then((resp) => {
        setInitialObject2(resp);
        if (resp.length > 0) HandleChangePrincipal('0');
        else HandleChangePrincipal(query);
    });

    const subCat = () => {
        if (initialObject.sub_Categoria?.length > 0) return initialObject.sub_Categoria[0];

        return null;
    }

    function handleChange(e) {
        setCategoriaID(e.target.value);
        var aux = Categorias.filter((item) => item.clave_Categoria === e.target.value);
        if (aux.length > 0) {
            var cat = aux[0];
            setInitialObject(cat);
            if (getDataSession('tienda') === 'MERCADOLIBRE') loadDataSubCategoria(e.target.value);
            else {
                loadDataSubCategoria2(e.target.value);
                if (SetCategoria) SetCategoria(cat);
            }
        }
    }

    useEffect(() => {
        if (initialObject && initialObject.clave_Categoria && initialObject.clave_Categoria !== '' && initialObject.clave_Categoria !== '0') {
            setCategoriaID(initialObject.clave_Categoria);
            if (getDataSession('tienda') === 'MERCADOLIBRE') loadDataSubCategoria(initialObject.clave_Categoria);
            else loadDataSubCategoria2(initialObject.clave_Categoria);
        }
    }, []);

    return (
        <>
            <label htmlFor='SubCategoria' className='form-label'> {t('app.Articulos.form.subCategoria') + ' ' + index}</label>
            <select className={!disabled ? 'form-select' : 'form-select form-control-disabled'} name='SubCategoria' value={initialObject?.clave_Categoria || '0'} onChange={handleChange} disabled={disabled}>
                <option key={0} value='0'>{t('app.Articulos.form.seleccioneSubCat')}</option>
                {Categorias.map((item) => { return (<option key={item.clave_Categoria} value={item.clave_Categoria}>{item.clave_Categoria + ' - ' + item.nombre}</option>); })}
            </select>
            {(categoriaId !== '' && initialObject2.length > 0) && (<CategoriaSelect index={(index + 1)} Categoria={subCat} Categorias={initialObject2} HandleChangePrincipal={HandleChangePrincipal} disabled={disabled} SetCategoria={SetCategoria} />)}
        </>
    );
}

const AtributoList = ({ disabled, atributos, SetAtributos }) => {
    const [initialObject, setInitialObject] = useState();
    const [atributo, SetAtributo] = useState();
    const loadDataAtributos = async (query) => await fetchConToken(`Categorias/AtributosListBySucursal?pagina=${1}&totalXpagina=${50}&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`);

    function AddAtributo(e) {
        var aux = atributos.find((item) => item.clave_Atributo === atributo?.clave_Atributo);
        if (aux) return;

        SetAtributos([...atributos, atributo]);
    }

    function EliminarAtributo(atributo) {
        if (atributo) SetAtributos(atributos.filter((item) => item.clave_Atributo !== atributo.clave_Atributo));
    }

    return (
        <div className='col-md-12' style={{ display: 'grid' }}>
            <div className='row'>
                <div className='col-sm-6' style={{ display: 'grid' }}>
                    <label htmlFor='clave_cat_principal' className='form-label'>Atributo</label>
                    <AsyncSelect
                        isClearable
                        className='react-select-placeholder'
                        getOptionLabel={(option) => option.clave_Atributo + ' - ' + option.nombre}
                        getOptionValue={(option) => option.clave_Atributo}
                        defaultOptions
                        value={initialObject}
                        isDisabled={disabled}
                        loadOptions={loadDataAtributos}
                        onChange={(selectedItem) => {
                            setInitialObject(selectedItem);
                            if (selectedItem) SetAtributo(selectedItem);
                        }}
                    />
                    <div className='espacio15px' />
                    <div><button className='btn btn-outline-info' type='button' onClick={AddAtributo}>Asignar atributo</button></div>
                </div>
                <div className='card col-sm-6' style={{ display: 'grid' }}>
                    <div className='card-body'>
                        <div style={{ overflowX: 'auto' }}>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th className='text-center'>Clave</th>
                                        <th className='text-center'>Atributo</th>
                                        <th className='text-right' width='50' />
                                    </tr>
                                </thead>
                                <tbody>
                                    {atributos?.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td className='text-center'>{item.clave_Atributo}</td>
                                                <td className='text-center'>{item.nombre}</td>
                                                <td className='text-center' width='50'>
                                                    <button type='button' className='btn' style={{ color: 'red' }} onClick={(e) => EliminarAtributo(item)}>
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='25'
                                                            height='20'
                                                            fill='currentColor'
                                                            className='bi bi-x-circle-fill'
                                                            viewBox='0 0 16 16'
                                                        >
                                                            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'></path>
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { FormCategoria, CategoriaList, AtributoList };