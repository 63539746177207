import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { useGlobalState } from 'resources/GlobalStateContext';
import { currency } from 'resources/utilities';
import { Link } from 'react-router-dom';
import ListTable from 'components/table/ListTable';
import TextField from '@mui/material/TextField';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';


const List = ({ history, match }) => {
    const { path } = match;
    const { t } = useTranslation("common");
    const { globalTitulo, setGlobalTitulo } = useGlobalState();

    let [cliente, setCliente] = useState({});
    let [saldoCliente, setSaldoCliente] = useState({});

    const [isOpenModal, openModal, closeModal] = useModal(false);

    const verAdeudos = (cliente_id) => {
        fetchConToken(`clientes?id=${cliente_id}&sucursal_id=${getDataSession('sucursal')}`).then(resp=>{
            setCliente(resp);
        });
        fetchConToken(`clientes/Saldos?cliente_id=${cliente_id}`).then(resp=>{
            setSaldoCliente(resp);
        });        
        openModal();
    }

    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "editar,verAdeudos",verAdeudos: verAdeudos },
        { field: 'nombre', headerName: 'Cliente', minWidth: 200 },
        { field: 'correo', headerName: 'Correo', minWidth: 100},
        { field: 'rfc', headerName: 'RFC', minWidth: 100},
        { field: 'telefono1', headerName: 'Telefono 1', minWidth: 100},
        { field: 'telefono2', headerName: 'Telefono 2', minWidth: 100},
        { field: 'limite_Credito', headerName: 'Limite de Credito', minWidth: 150, type: 'number', renderCell: ({ value }) => currency(value)  },
        { field: 'saldo_Cliente.saldo_Actual', headerName: 'Saldo Actual', minWidth: 150, type: 'number', renderCell: (params) => currency(params.row.saldo_Cliente.saldo_Actual)  },
        { field: 'saldo_Cliente.credito_Disponible', headerName: 'Credito Disponible', minWidth: 150, type: 'number', renderCell: (params) => currency(params.row.saldo_Cliente.credito_Disponible)  },

        
    ];

    
 

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            subtitulo: 'Listado de Creditos'
        });
    };




    useEffect(() => actualizarTitulo(), []);


    return (
        <>
            <div className='espacio10px' />
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-2'>
                        <Link to={`${path}/add`} className='btn btn-outline-primary m-1' style={{ width: 'auto' }}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; {t('app.SwalFire.nuevo')}
                        </Link>
                    </div>
                </div>
                <div className='col-md-12'>
                    <ListTable 
                        columns={columns} 
                        path={path} 
                        url={`Clientes/GetList?sucursal_Id=${getDataSession('sucursal')}`} />
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal} >
                <div className='col-md-12 row'>
                    <div className='col-md-4'>
                        <h4>Cliente</h4>
                        <label>Cliente:</label><label>{cliente?.nombre}</label>
                        <label>Telefono:</label><p>{cliente?.telefono1}</p>
                        <label>Correo:</label><p>{cliente?.correo}</p>
                    </div>
                    <div className='col-md-4'>
                         <h4></h4>
                        <label>Rfc:</label><p>{cliente?.rfc}</p>
                        <label>Uso Cfdi:</label><p>{cliente?.uso_Cfdi}</p>
                        <label>Regimen Fiscal Receptor:</label><p>{cliente?.regimen_Fiscal_Receptor}</p>
                    </div>
                    <div className='col-md-4'>
                         <h4>Adeudo</h4>
                        <label>Limite Credito:</label><p>{currency(cliente?.saldo_Cliente?.limite_Credito)}</p>
                        <label>Saldo Actual:</label><p>{currency(cliente?.saldo_Cliente?.saldo_Actual)}</p>
                        <label>Credito Disponible</label><p>{currency(cliente?.saldo_Cliente?.credito_Disponible)}</p>
                    </div>
                </div>
                <div className='col-md-12'>
                    <h4>Adeudos</h4>
                    {
                       saldoCliente?.facturas_Pendientes?.map(x=>{
                          return  ( <div className='row' style={{border: "1px solid black"}}>
                            <div className='col-md-3'>
                            <label>Folio Adeudo:</label><p>{x?.folio}</p>
                            </div>
                            <div className='col-md-3'>
                            <label>Fecha Vencimiento:</label><p>{x?.fecha_Vencimiento}</p>
                            </div>
                            <div className='col-md-2'>
                            <label>Importe Acreditado:</label><p>{currency(x?.importe_Acreditado)}</p>
                            </div>
                            <div className='col-md-2'>
                            <label>Saldo Cargo:</label><p>{currency(x?.saldo_Cargo)}</p>
                            </div>
                            <div className='col-md-2'>
                            <label>Forma pago:</label><p>{x?.concepto}</p>
                            </div>
                                 
                                 
                                
                                 
                            </div>);
                        })
                    }
                </div>
           </Modal>
        </>
    );
}

export { List };