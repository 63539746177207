import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getDataSession } from 'views/Utils/Common';
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import AsyncSelect from 'react-select/async';

import { useGlobalState } from 'resources/GlobalStateContext';
import Swal from 'sweetalert2';

const EnvioMensajes = ({ history, match }) => {
    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;
    const { handleSubmit } = useForm();
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let [destinatarios, setDestinatarios] = useState([]);

    let [model, setModel] = useState({
        mensaje: "",
        sucursal_Id: getDataSession('sucursal')
    });

    let [destinatarioCliente, setDestinatarioCliente] = useState({
        cliente_id: 0,
        cliente: "",
        destinatario: ""
    });



    function onSubmit() {
        return AddEdit();
    }

    function AddEdit() {
        console.log(model);
        model.destinatarios = destinatarios;
        console.log(model);
        model.sucursal_Id = getDataSession('sucursal');
        return fetchConToken('SmsMensajes/EnvioCorreo', model, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }
                Swal.fire('Exito', 'Enviado', 'success');
            })
            .catch(alertService.error);
    }

    const handleInputChange = ({ target }) => {
        setModel({
            ...model,
            [target.name]: target.type === 'checkbox' ? target.checked === 'true' : target.value
        });
    }
    
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Envio de Mensaje',
          subtitulo: ''
        });
      };

      React.useEffect(() => {actualizarTitulo();},[]);

      const loadClientes = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(`Clientes/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`);
        return resp.data;
      };

      const loadPlanVisita = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(`VxPlanesVisitas/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`);
        return resp.data;
      };

      function AgregarDestinatario()
      {
            const resultado = destinatarios.find(dest => dest.cliente_id === destinatarioCliente.cliente_id);
            if(resultado === undefined)
                setDestinatarios([...destinatarios,destinatarioCliente]);
            else
                Swal.fire('Info', 'Ya selecciono a este cliente', 'info');
      }

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className='mb-3'>
                        <div className='row' style={{ textAlign: 'left' }}>
                           
                        <div className='col-md-12' style={{ textAlign: 'left' }}>
                                <button className='btn btn-outline-success' type='submit'>
                                    Enviar &nbsp; 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                                    </svg>
                                </button>

                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start row'>
                                    <div className='col-md-4'>
  
                                    <label htmlFor='cmd' className='form-label'>
                                    Buscar Cliente:
                                    </label>
                                    <AsyncSelect
                                            placeholder='&nbsp; Cliente'
                                            aria-label='Cliente'
                                            isClearable
                                            getOptionLabel={(option) => option.nombre + ' - ' + option.telefono1 + '-'+ option.telefono2 }
                                            getOptionValue={(option) => option.id}
                                            loadOptions={loadClientes}
                                            onChange={(selectedItem) => { 
                                                if(selectedItem !== undefined && selectedItem !== null)
                                                {
                                                    setDestinatarioCliente({
                                                        ...destinatarioCliente,
                                                        cliente_id: selectedItem.id,
                                                        cliente: selectedItem.nombre,
                                                        destinatario: selectedItem.telefono1.length > 0 ? selectedItem.telefono1 : selectedItem.telefono2 
                                                    });
                                                }
                                        
                                            }}
                                    />
                                    </div>
<div className='col-md-2'>
    <div className='espacio40px'/>
                                        <button 
                                            className='btn btn-outline-success'
                                            type='button'
                                            onClick={()=>{AgregarDestinatario()}}
                                        >
                                          Agregar
                                        </button>
                                    </div>
                                    <div className='col-md-4'>

                                 {/* <label htmlFor='Plan Visita' className='form-label'>
                                    Buscar Plan Visita:
                                    </label>
                                    <AsyncSelect
                                            placeholder='&nbsp; Plan Visita'
                                            aria-label='Plan Visita'
                                            isClearable
                                            getOptionLabel={(option) =>  option.nombre }
                                            getOptionValue={(option) => option.id}
                                            loadOptions={loadPlanVisita}
                                            onChange={(selectedItem) => { 
                                            }
                                        }
                                    /> 
                                                                        </div>
<div className='col-md-2'>
    <div className='espacio40px'/>     
    <button 
                                            className='btn btn-outline-success'
                                            type='button'
                                            onClick={()=>{AgregarDestinatario()}}
                                        >
                                          Agregar
                                        </button>*/}
                                    </div>
                                    <div className='col-md-6'>
                                    <div className='espacio15px'/>
                                            <table class="table  table-bordered">
                                                <thead>
                                                    <tr>
                                                    <th scope="col">Numero Envio</th>
                                                    <th scope="col">Nombre</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {destinatarios?.map(d=>{
                                                    return (
                                                    <tr>
                                                        <td>
                                                           <input 
                                                                style={{maxWidth: "200px"}}
                                                                className='form-control'
                                                                 name='destinatario' 
                                                                 value={d.destinatario}
                                                                 onChange={({ target })=> {
                                                                    const nuevaLista = destinatarios.map(dest => 
                                                                        dest.cliente_id === d.cliente_id ? { ...dest, ...{ destinatario: target.value } } : dest
                                                                      );
                                                                      setDestinatarios(nuevaLista);

                                                                }}
                                                            />
                                                        </td>
                                                        <td>{d.cliente}</td>
                                                    </tr>)
                                                    })}
                                                   
                                                </tbody>
                                                </table>
                                    </div>
                                    <div className='col-md-6'>
                                        <label htmlFor='Plan Visita' className='form-label'>
                                        Mensaje:
                                        </label>
                                        <textarea
                                                className='form-control'
                                                name='mensaje'
                                                rows='4'
                                                maxLength={160}
                                                value={model.mensaje || ''}
                                                onChange={handleInputChange}
                                            />
                                    </div>

                                </div>

                            </div>
                        </div>
                       
                    </div>
                </form>
            </div>
        </>
    );
};

export { EnvioMensajes };
