import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { useGlobalState } from 'resources/GlobalStateContext';
import { currency } from 'resources/utilities';
import { Link } from 'react-router-dom';
import ListTable from 'components/table/ListTable';
import TextField from '@mui/material/TextField';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';


const List = ({ history, match }) => {
    const { path } = match;
    const { t } = useTranslation("common");
    const [fechaInicio, setFechaInicio] = useState(GetFecha(true));
    const [fechaFin, setFechaFin] = useState(GetFecha(false));
    const [actualizarTabla, setActualizarTabla] = useState(false);
    const { globalTitulo, setGlobalTitulo } = useGlobalState();

    let [vxVisita, setVxVisita] = useState({});
    const [isOpenModal, openModal, closeModal] = useModal(false);

    const verVisitas = (visita_id) => {
        fetchConToken(`VxVisitas?id=${visita_id}`).then(resp=>{
            setVxVisita(resp);
        });        
        openModal();
    }

    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "verVisitas" ,verVisitas:verVisitas},
        { field: 'nombre_Cliente', headerName: 'Cliente', minWidth: 180 },
        { field: 'nombre_Agente', headerName: 'Agente', minWidth: 180  },
        { field: 'fecha_Planeada', headerName: 'Fecha Planeada', minWidth: 80,renderCell: ({ value }) => value?.substring(0,10) },
        { field: 'fecha_Hora_Inicial', headerName: 'Fecha Hora Inicial', minWidth: 100 },
        { field: 'fecha_Hora_Final', headerName: 'Fecha Hora Final', minWidth: 100 },
        { field: 'razon_Vis_Infec', headerName: 'Razon Vis. Inecf.', minWidth: 100},
        { field: 'importe_Cobros', headerName: 'Importe cobros', minWidth: 100, type: 'number', renderCell: ({ value }) => currency(value)  },
        { field: 'importe_Pedidos', headerName: 'Importe Pedidos', minWidth: 100, type: 'number', renderCell: ({ value }) => currency(value)  },
        { field: 'movimientos', headerName: 'Movimientos', minWidth: 100},
        { field: 'observaciones', headerName: 'Observaciones', minWidth: 200},
        
    ];

    
 

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Visitas',
            subtitulo: 'Listado de Visitas'
        });
    };

    const handleInputChangeFechaInicio = ({ target }) => {
        setFechaInicio(target.value);
    };

    const handleInputChangeFechaFin = ({ target }) => {
        setFechaFin(target.value);
    }

    function GetFecha(inicio) {
        var curr = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        if (!inicio) curr = new Date(curr.getFullYear(), curr.getMonth(), new Date(curr.getFullYear(), curr.getMonth() + 1, 0).getDate());

        curr.setDate(curr.getDate());
        return curr.toISOString().substring(0, 10);
    }


    useEffect(() => actualizarTitulo(), []);


    return (
        <>
            <div className='espacio10px' />
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    {/*<div className='col-sm-2'>
                        <Link to={`${path}/add`} className='btn btn-outline-primary m-1' style={{ width: 'auto' }}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; {t('app.SwalFire.nuevo')}
                        </Link>
                    </div>*/}
                    <div className='col-sm-5' />
                    <div className='col-sm-5' style={{ display: 'flex' }}>
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={handleInputChangeFechaInicio}
                            InputLabelProps={{ shrink: true, }}
                        />
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Fin"
                            type="date"
                            value={fechaFin}
                            onChange={handleInputChangeFechaFin}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>
                </div>
                <div className='col-md-12'>
                    <ListTable 
                        columns={columns} 
                        path={path} 
                        reload={actualizarTabla} 
                        url={`VxVisitas/GetList?sucursal_Id=${getDataSession('sucursal')}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`} />
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal} >
                <div className='col-md-12 row'>
                    <div className='col-md-4'>
                        <h4>Cliente</h4>
                        <label>Cliente:</label><p><label>{vxVisita?.cliente?.nombre}</label></p>
                        <label>Telefono:</label><p>{vxVisita?.cliente?.telefono1}</p>
                        <label>Correo:</label><p>{vxVisita?.cliente?.correo}</p>
                    </div>
                    <div className='col-md-4'>
                         <h4>Visita</h4>
                        <label>Fecha Planeada:</label><p>{vxVisita?.fecha_Planeada?.substring(0,10)}</p>
                        <label>Fecha Hora Inicial:</label><p>{vxVisita?.fecha_Hora_Inicial}</p>
                        <label>Fecha Hora Final:</label><p>{vxVisita?.fecha_Hora_Final}</p>
                    </div>
                    <div className='col-md-4'>
                        <h4>Datos</h4>
                        <label>Razon Vis. Infec.:</label><p>{vxVisita?.razon_Vis_Infec}</p>
                        <label>Observaciones:</label><p>{vxVisita?.observaciones}</p>
                        <label>Estatus:</label><p>{vxVisita?.estatus}</p>
                    </div>
                </div>
                <div className='col-md-12'>
                    <h4>Movimientos Visitas</h4>
                    {
                        vxVisita?.movimientos?.map(x=>{
                          return  ( <div className='row' style={{border: "1px solid black"}}>
                            <div className='col-md-4'>
                            <label>Folio:</label><p>{x?.folio}</p>
                            </div>
                            <div className='col-md-4'>
                            <label>Movimiento:</label><p>{x?.movimiento}</p>
                            </div>
                            <div className='col-md-4'>
                            <label>Fecha Registro:</label><p>{x?.fecha_Registro}</p>
                            </div>
                            </div>);
                        })
                    }
                </div>
           </Modal>
        </>
    );
}

export { List };