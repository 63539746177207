/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { getDataSession } from 'views/Utils/Common';
import { fetchConToken } from 'helpers/fetch';
import TituloPagina from 'components/header/TituloPagina';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import ListTable from 'components/table/ListTable';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import PendingIcon from '@mui/icons-material/Pending';
import SLUGS from 'resources/slugs';
import { SelectorConexion } from '../SelectorConexion';
import { useGlobalState } from 'resources/GlobalStateContext';

const ListPublicador = ({ history, match, saludo, subtitulo }) => {
    const { path } = match;
    const { tienda } = match.params;
    const { t } = useTranslation("common");
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [keyAction, setKeyAction] = useState('');
    const [buscar, setBuscar] = useState('');
    const [artPublicado, setArtPblicado] = useState('N');
    const [almacen, setAlmacen] = useState(0);
    const [filtro, setFiltro] = useState('T');
    const [sucursalId, setSucursalId] = useState(0);
    const [sucursales, setSucursales] = useState([]);
    const [sucursalesTienda, setSucursalesTienda] = useState([]);
    const [almacenes, setAlmacenes] = useState([]);
    const [artsSelectd, setArtsSelectd] = useState([]);
    const [filtros, setFiltros] = useState([{ id: 'N_STOCK', nombre: 'Sin Stock' }, { id: 'STOCK', nombre: 'Con Stock' }]);
    const [reload, setReload] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [anchorElHerramientas, setAnchorElHerramientas] = useState(null);
    const openHerramientas = Boolean(anchorElHerramientas);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClickHerramientas = (event) => { setAnchorElHerramientas(event.currentTarget); };
    const [url, setURL] = useState(`Articulos/GetListFiltosExtra?tienda=${tienda}&sucursal_Id=${sucursalId}&tipo_art=${artPublicado}`);
    const [isOpenModalMarketPlace, openModalMarketPlace, closeModalMarketPlace] = useModal(false);
    const columns = [
        { field: 'id', headerName: 'Id', width: 50 },
        { field: 'nombre', headerName: 'Nombre', flex: 1 },
        { field: 'sku', headerName: 'SKU', width: 160 },
        { field: 'existencias', headerName: 'Existencias', width: 100 },
        { field: 'costo_Unidad', headerName: 'Precio', width: 100 },
        { field: 'es_Paquete', headerName: 'Paquetem', width: 100 }
    ];
    const columnsArtPublicado = [
        { field: 'id', headerName: 'Id', width: 50 },
        { field: 'nombre', headerName: 'Nombre', flex: 1 },
        { field: 'sku', headerName: 'SKU', width: 160 },
        { field: 'tiene_Variante', headerName: 'Variante', width: 80 },
        { field: 'existencias', headerName: 'Existencias', width: 100 },
        { field: 'envio_Gratis', headerName: 'Envio Gratis', width: 100 },
        { field: 'url', headerName: 'URL', flex: 1 }
    ];

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: `${t('app.Articulos.publicadorMasivo.titulo')} ${GetNombreTienda()}`,
            subtitulo: t('app.Articulos.publicadorMasivo.descripcion')
        });
    };

    const GetNombreTienda = () => {
        switch (tienda?.toUpperCase()) {
            case 'MERCADOLIBRE': return 'Mercado Libre';
            case 'TIENDANUBE': return 'Tienda Nube';
            default: return tienda ?? '';
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorElHerramientas(null);
    };

    function handleInputChange(event) {
        const { value } = event.target;
        setBuscar(value);
    };

    function handleChange(e) {
        switch (e.target.name) {
            case 'Articulos': getSucursales(e.target.value); setArtPblicado(e.target.value); break;
            case 'Conexiones': setSucursalId(e.target.value); break;
            case 'Almacenes': setAlmacen(e.target.value); break;
            case 'Filtrar': setFiltro(e.target.value); break;
            default: changeURL(); setReload(!reload); return;
        }
    }

    function onKeyPress(e) {
        if (e.charCode === 13) {
            changeURL();
            setReload(!reload);
        }
    }

    function changeURL() {
        setURL(`Articulos/GetListFiltosExtra?tienda=${tienda}&sucursal_Id=${sucursalId}&busqueda=${buscar}&tipo_art=${artPublicado}&filtro=${filtro}&almacen_id=${almacen}`);
    }

    const getSucursal = (parametros) => fetchConToken(`Sucursales/GetListByTienda?empresa_id=${getDataSession('empresa')}&tienda=${tienda}&${parametros}`);

    async function getSucursales(artPublicado) {
        let parametros = artPublicado === 'N' ? 'includeTienda=false&includeMatriz=true' : 'includeTienda=true&includeMatriz=false';
        const response = await getSucursal(parametros);
        setSucursales(response);
        getAlmacenes(artPublicado);
        setFiltros(artPublicado === 'N' ? [{ id: 'N_STOCK', nombre: 'Sin Stock' }, { id: 'STOCK', nombre: 'Con Stock' }] :
            [{ id: 'N_STOCK', nombre: 'Sin Stock' }, { id: 'STOCK', nombre: 'Con Stock' }, { id: 'VARIANTE', nombre: 'Variantes' },
            { id: 'N_VARIANTE', nombre: 'No Variantes' }, { id: 'ASIGNADO', nombre: 'Asignados' }, { id: 'N_ASIGNADO', nombre: 'No Asignados' }]);
    }

    async function getAlmacenes(artPublicado) {
        let urlAlmacen = artPublicado === 'N' ? `Almacenes/GetListByMatriz?empresa_id=${getDataSession('empresa')}` : `Almacenes/GetListByTienda?empresa_id=${getDataSession('empresa')}&tienda=${tienda}`;
        const response = await fetchConToken(urlAlmacen);
        setAlmacenes(response);
    }

    const getArticuloPublicador = (sucursales_ids) => { return { tienda: tienda, sucursal_id: getDataSession('sucursal'), articulos_ids: artsSelectd, sucursales_ids: sucursales_ids }; }

    const genArticulos = async (articuloPublicador) => await fetchConToken('Articulos/GenerarArtiulos', articuloPublicador, 'POST').then((resp) => {
        if (resp.codigo === 200) history.push(`${SLUGS.articulos}/publicador_edicion/${tienda}`);
        else Swal.fire('Error', resp.mensajes[0] ?? 'Error al generar los articulos', 'error');
    }).catch((e) => { Swal.fire('Error', e, 'error'); });

    function openModalMarket(key) {
        setKeyAction(key);
        setAnchorEl(null);
        if (!artsSelectd || artsSelectd.length === 0) {
            Swal.fire('Error', 'Debe tener seleccionado por lo menos un articulo', 'error');
            return;
        }

        openModalMarketPlace(true);
    }

    function PublicarSelecccionados() {
        if (!artsSelectd || artsSelectd.length === 0) {
            Swal.fire('Error', 'Debe tener seleccionado por lo menos un articulo', 'error');
            return;
        }

        let sucursales_ids = [];
        sucursalesTienda.filter(x => x.checked).forEach(x => sucursales_ids.push(x.id));
        if (sucursales_ids.length === 0) {
            Swal.fire('Error', 'Debe tener seleccionado por lo menos una conexion', 'error');
            return;
        }

        closeModalMarketPlace(true);
        switch (keyAction) {
            case 'publicar':
            case 'duplicar': genArticulos(getArticuloPublicador(sucursales_ids)); break;
            case 'editar': break;
            default: history.push(`${SLUGS.articulos}/publicador_edicion/${tienda}`); break;
        }
    }

    function Pendientes() {
        history.push(`${SLUGS.articulos}/publicador_edicion/${tienda}`);
    }

    function regresar() {
        history.push(SLUGS.articulos);
    }

    useEffect(() => {
        actualizarTitulo();
        getSucursales(artPublicado);
        getSucursal('includeTienda=true&includeMatriz=false').then((resp) => setSucursalesTienda(resp));
    }, []);

    useEffect(() => { changeURL(); }, [artPublicado, almacen, filtro, sucursalId]);

    const AccionesLote = () => {
        if (artPublicado === 'N') return (
            <>
                <MenuItem onClick={((e) => openModalMarket('publicar'))}><ListItemIcon><SendIcon fontSize="small" /></ListItemIcon>Publicar seleccionados</MenuItem>
                <MenuItem onClick={Pendientes}><ListItemIcon><PendingIcon fontSize="small" /></ListItemIcon>Pendientes</MenuItem>
            </>
        );

        return (
            <>
                <MenuItem onClick={((e) => openModalMarket('duplicar'))}><ListItemIcon><SendIcon fontSize="small" /></ListItemIcon>Duplicar seleccionados</MenuItem>
                <MenuItem onClick={((e) => openModalMarket('editar'))}><ListItemIcon><SendIcon fontSize="small" /></ListItemIcon>Editar seleccionados</MenuItem>
                <MenuItem onClick={Pendientes}><ListItemIcon><PendingIcon fontSize="small" /></ListItemIcon>Pendientes</MenuItem>
            </>
        );
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-3'>
                        <button className='btn btn-outline-primary m-1' style={{ width: 'auto' }} onClick={handleClick}>
                            {t('app.botones.accionesLote')}<ArrowDropDownIcon />
                        </button>
                        <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>{AccionesLote()}</Menu>
                    </div>
                    <div className='col-sm-7' />
                    <div className='col-sm-2'>
                        <button className='btn btn-outline-primary m-1' style={{ width: 'auto' }} onClick={handleClickHerramientas}>
                            <TuneIcon />&nbsp; {t('app.botones.herramientas')}<ArrowDropDownIcon />
                        </button>
                        <Menu anchorEl={anchorElHerramientas} open={openHerramientas} onClose={handleClose}>
                            <MenuItem onClick={handleClose} style={{ display: 'none' }}>Impotar por CSV</MenuItem>
                            <MenuItem
                                onClick={() => {
                                    regresar();
                                    handleClose();
                                }}
                            >
                                <ListItemIcon>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                </ListItemIcon>
                                {t('app.botones.regresar')}
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                <div className='espacio15px' />
                <div className='col-md-12'>
                    <div style={{ marginLeft: 'auto' }}>
                        <div className='col-13 col-sm-13 col-md-13 row'>
                            <div className='col-sm-2'>
                                <FormControl fullWidth>
                                    <InputLabel>Articulos</InputLabel>
                                    <Select
                                        name='Articulos'
                                        value={artPublicado}
                                        label="Articulos"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'N'}>No Publicado</MenuItem>
                                        <MenuItem value={'P'}>Publicados</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-sm-2'>
                                <FormControl fullWidth>
                                    <InputLabel>Conexiones</InputLabel>
                                    <Select
                                        name='Conexiones'
                                        value={sucursalId}
                                        label="Conexiones"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={0}>Todas las conexiones</MenuItem>
                                        {sucursales?.map((item) => { return <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-sm-2'>
                                <FormControl fullWidth>
                                    <InputLabel>Almacenes</InputLabel>
                                    <Select
                                        name='Almacenes'
                                        value={almacen}
                                        label="Almacenes"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={0}>Todos</MenuItem>
                                        {almacenes?.map((item) => { return <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-sm-2'>
                                <FormControl fullWidth>
                                    <InputLabel>Filtrar</InputLabel>
                                    <Select
                                        name='Filtrar'
                                        value={filtro}
                                        label="Filtrar"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'T'}>Todos</MenuItem>
                                        {filtros?.map((item) => { return <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-sm-4 row'>
                                <div className='col-sm-11'><TextField fullWidth label="Buscar" value={buscar} type="search" onChange={handleInputChange} onKeyPress={onKeyPress} /></div>
                                <div className='col-sm-1 container-center'><IconButton size="large" onClick={handleChange}><SearchIcon /></IconButton ></div>
                            </div>
                        </div>
                        <div className='espacio15px' />
                    </div>
                    <ListTable
                        columns={(artPublicado === 'N' ? columns : columnsArtPublicado)}
                        path={path}
                        tienda={tienda}
                        url={url}
                        reload={reload}
                        onChangeSelectdIds={setArtsSelectd}
                        checkboxSelection
                        filtrosExtra
                        hideSearch
                    />
                </div>
            </div>
            <Modal isOpen={isOpenModalMarketPlace} closeModal={closeModalMarketPlace}>
                <SelectorConexion sucursalesTienda={sucursalesTienda} onClickButton={PublicarSelecccionados} multiple={true} />
            </Modal>
        </>
    );
}

export { ListPublicador };