/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import { getDataSession, setDataSession } from 'views/Utils/Common';
import Swal from 'sweetalert2';
import SLUGS from 'resources/slugs';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TaskIcon from '@mui/icons-material/Task';
import DescriptionIcon from '@mui/icons-material/Description';
import BallotIcon from '@mui/icons-material/Ballot';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { TablePublicador } from "./TablePulicador";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import { useGlobalState } from 'resources/GlobalStateContext';

const ListEdicionPublicador = ({ history, match, saludo, subtitulo }) => {
    //const { path } = match;
    const { tienda } = match.params;
    const { t } = useTranslation("common");
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [buscar, setBuscar] = useState('');
    const [pagina, setPagina] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [anchorElHerramientas, setAnchorElHerramientas] = useState(null);
    const openHerramientas = Boolean(anchorElHerramientas);
    const [loading, setLoading] = useState(false);
    const [artPublicado, setArtPblicado] = useState('P');
    const [almacen, setAlmacen] = useState(0);
    const [almacenes, setAlmacenes] = useState([]);
    const [filtro, setFiltro] = useState('T');
    const [sucursalId, setSucursalId] = useState(0);
    const [sucursales, setSucursales] = useState(['']);
    const [articulos, setArtiulos] = useState([]);
    const [countArticulos, setCountArtiulos] = useState(0);
    const [chekcArticulos, setCheckArtiulos] = useState('T');
    const [chekcdArticulos, setCheckdArtiulos] = useState(false);
    const [countCheckdArticulos, setCountCheckdArtiulos] = useState(0);
    const [rowsPerPageOptions,] = useState([5, 10, 25, 50, 100, 150, 200]);
    const [filtros,] = useState([{ id: 'N_STOCK', nombre: 'Sin Stock' }, { id: 'STOCK', nombre: 'Con Stock' }, { id: 'VARIANTE', nombre: 'Variantes' }, { id: 'N_VARIANTE', nombre: 'No Variantes' }]);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClickHerramientas = (event) => { setAnchorElHerramientas(event.currentTarget); };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorElHerramientas(null);
    };

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: `${t('app.Articulos.publicadorMasivo.titulo')} ${GetNombreTienda()}`,
            subtitulo: ''
        });
    };

    const GetNombreTienda = () => {
        switch (tienda?.toUpperCase()) {
            case 'MERCADOLIBRE': return 'Mercado Libre';
            case 'TIENDANUBE': return 'Tienda Nube';
            default: return tienda ?? '';
        }
    }

    function handleChange(e) {
        switch (e.target.name) {
            case 'Articulos': setArtPblicado(e.target.value); break;
            case 'Conexiones': setSucursalId(e.target.value); break;
            case 'Almacenes': setAlmacen(e.target.value); break;
            case 'Filtrar': setFiltro(e.target.value); break;
            default: resetTable(rowsPerPage); break;
        }
    }

    const resetTable = (value) => {
        setLoading(true);
        setArtiulos(null);
        setPagina(0);
        setCheckArtiulos('T');
        setCountCheckdArtiulos(0);
        setCheckdArtiulos(false);
        getData(0, value);
        setRowsPerPage(value);
    }

    function handleInputChange(event) {
        const { value } = event.target;
        setBuscar(value);
    }

    function onKeyPress(e) {
        if (e.charCode === 13) resetTable(rowsPerPage);
    }

    function changeURL() {
        resetTable(rowsPerPage);
    }

    async function getSucursales() {
        const response = await fetchConToken(`Sucursales/GetListByTienda?empresa_id=${getDataSession('empresa')}&tienda=${tienda}&includeTienda=true&includeMatriz=false`);
        setSucursales(response);
    }

    async function getAlmacenes() {
        const responeAlmacen = await fetchConToken(`Almacenes/GetListByTienda?empresa_id=${getDataSession('empresa')}&tienda=${tienda}`);
        setAlmacenes(responeAlmacen);
    }

    async function getData(page, rowsPerPage) {
        const response = await fetchConToken(`Articulos/GetListFiltosExtra?status=BORRADOR&tienda=${tienda}&sucursal_Id=${sucursalId}&tipo_art=${artPublicado}&paginas=${page}&totalXpagina=${rowsPerPage}&busqueda=${buscar}&filtro=${filtro}&almacen_id=${almacen}`);
        setArtiulos(response.data);
        setCountArtiulos(response.recordsFiltered);
        setLoading(false);
    }

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        getData(newPage, rowsPerPage);
        setPagina(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        resetTable(parseInt(event.target.value, 10));
    };

    const handleChangeCheckLote = (event) => {
        setCheckdArtiulos(event.target.checked)
        setCheckArtiulos(event.target.checked ? 'A' : 'I');
        setCountCheckdArtiulos(event.target.checked ? articulos.length : 0);
    }

    const handleChangeCountCheckd = (event) => {
        setCountCheckdArtiulos(event.target.checked ? (countCheckdArticulos + 1) : (countCheckdArticulos - 1));
    }

    async function eliminar(id) {
        Swal.fire({
            title: t('app.Articulos.SwalFire.eliminar?'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.botones.eliminar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                fetchConToken(`Articulos?articulo_id=${id}`, null, 'DELETE')
                    .then((response) => {
                        if (response.hasOwnProperty('status')) {
                            Object.entries(response?.errors).map(([key, value]) => {
                                Swal.fire('Error', value[0], 'error');
                                // eslint-disable-next-line array-callback-return
                                return;
                            });
                            return false;
                        } else if (response.hasOwnProperty('codigo')) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                            return;
                        }

                        Swal.fire('Exito', t('app.Articulos.SwalFire.eliminado'), 'success');
                        resetTable(rowsPerPage);
                    })
            }
        })
    }

    const handleDeleteItem = (id) => {
        eliminar(id);
    }

    function regresar() {
        history.push(`${SLUGS.articulos}/publicador/${tienda}`);
    }

    function publicarSeleccionados() {
        var arts = articulos.filter(x => x.checked);
        if (arts.length === 0) {
            Swal.fire('', 'No hay articulos seleecionados', 'warning');
            return;
        }

        Swal.fire({
            title: `¿Desea publicar los articulos?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Publicar',
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                arts.forEach(art => publicar(art.id, art.sucursal_Id));
            }
        });
    }

    function publicar(id, sucursalId) {
        fetchConToken(`articulos?id=${id}&sucursal_Id=${sucursalId}&conDetalle=true`).then((art) => {
            if (art.hasOwnProperty('status')) {
                Object.entries(art?.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (art.hasOwnProperty('codigo')) {
                Swal.fire('Error', art.mensajes[0], 'error');
                return;
            }

            return fetchConToken(`Articulos/Publicar?sucursal_id=${sucursalId}`, art, 'POST').then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        erroresA[key] = value[0];
                        Swal.fire('Error', value[0], 'error');
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }

                articulos.filter(x => x.id === id).forEach(x => x.checked = false);
                if (articulos.filter(x => x.checked).length === 0) {
                    setLoading(false);
                    window.location.href = window.location.href;
                }
            });
        });
    }

    useEffect(() => {
        //setDataSession('tienda', tienda?.toUpperCase());
        //setDataSession('sucursal', e.target.value);
        actualizarTitulo();
        setLoading(true);
        getSucursales();
        getAlmacenes();
        getData(pagina, rowsPerPage);
    }, []);

    useEffect(() => { changeURL(); }, [almacen, filtro, sucursalId]);

    return (
        <>
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-3'>
                        <button
                            className='btn btn-outline-primary m-1'
                            style={{ width: 'auto' }}
                            onClick={handleClick}
                        >
                            {t('app.botones.accionesLote')}<ArrowDropDownIcon />
                        </button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button', }}
                        >
                            <MenuItem onClick={publicarSeleccionados}><ListItemIcon><SendIcon fontSize="small" /></ListItemIcon>Publicar seleccionados</MenuItem>
                            <MenuItem ><ListItemIcon><DescriptionIcon fontSize="small" /></ListItemIcon>Editar Descripcion</MenuItem>
                            <MenuItem ><ListItemIcon><TaskIcon fontSize="small" /></ListItemIcon>Editar Categoria</MenuItem>
                            <MenuItem ><ListItemIcon><BallotIcon fontSize="small" /></ListItemIcon>Editar Atributos</MenuItem>
                            <MenuItem ><ListItemIcon><AttachMoneyIcon fontSize="small" /></ListItemIcon>Editar Costos</MenuItem>
                            <MenuItem ><ListItemIcon><LocalShippingIcon fontSize="small" /></ListItemIcon>Establecer envio gratis</MenuItem>
                            <MenuItem ><ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon>Eliminar Seleccionados</MenuItem>
                        </Menu>
                    </div>
                    <div className='col-sm-7' />
                    <div className='col-sm-2'>
                        <button
                            className='btn btn-outline-primary m-1'
                            style={{ width: 'auto' }}
                            onClick={handleClickHerramientas}
                        >
                            <TuneIcon />&nbsp; {t('app.botones.herramientas')}<ArrowDropDownIcon />
                        </button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElHerramientas}
                            open={openHerramientas}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleClose}>Impotar por CSV</MenuItem>
                            <MenuItem
                                onClick={() => {
                                    regresar();
                                    handleClose();
                                }}
                            >
                                <ListItemIcon>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                </ListItemIcon>
                                {t('app.botones.regresar')}
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                <div className='espacio10px' />
                <div className='card'>
                    <div className='card-body'>
                        <div className='espacio30px' />
                        <div className='col-12 col-sm-12 col-md-12 row'>
                            <div className='col-sm-3'>
                                <FormControl fullWidth>
                                    <InputLabel>Conexiones</InputLabel>
                                    <Select
                                        name='Conexiones'
                                        value={sucursalId}
                                        label="Conexiones"
                                        onChange={handleChange}
                                    >
                                        <MenuItem key={0} value={0}>Todas las conexiones</MenuItem>
                                        {sucursales?.map((item) => { return <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-sm-2'>
                                <FormControl fullWidth>
                                    <InputLabel>Almacenes</InputLabel>
                                    <Select
                                        name='Almacenes'
                                        value={almacen}
                                        label="Almacenes"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={0}>Todos</MenuItem>
                                        {almacenes?.map((item) => { return <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-sm-2'>
                                <FormControl fullWidth>
                                    <InputLabel>Filtrar</InputLabel>
                                    <Select
                                        name='Filtrar'
                                        value={filtro}
                                        label="Filtrar"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'T'}>Todos</MenuItem>
                                        {filtros?.map((item) => { return <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-sm-1' />
                            <div className='col-sm-4 row'>
                                <div className='col-sm-11'><TextField fullWidth label="Buscar" value={buscar} type="search" onChange={handleInputChange} onKeyPress={onKeyPress} /></div>
                                <div className='col-sm-1 container-center'><IconButton size="large" onClick={handleChange}><SearchIcon /></IconButton ></div>
                            </div>
                        </div>
                        <div className='espacio15px' />
                        <Divider variant="middle" color='indigo' />
                        <div className='col-13 col-sm-13 col-md-13 row'>
                            <div className='col-sm-3'>
                                <div className='col-12 col-sm-12 col-md-12 row'>
                                    <div className='col-sm-1' />
                                    <div className='col-sm-10'>
                                        <FormControlLabel control={<Checkbox checked={chekcdArticulos} onChange={handleChangeCheckLote} />} label="Seleccionar lote" />
                                        <div className='espacio5px' />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6' />
                            <div className='col-sm-3 container-center'>
                                <div className='card container-center' style={{ display: 'grid', background: '#F5F5F5' }}>
                                    <label>{countCheckdArticulos} Articulos Seleccionados</label>
                                    <div className='espacio10px' />
                                </div>
                            </div>
                        </div>
                        <div className='espacio5px' />
                        <TablePublicador
                            items={articulos}
                            tienda={tienda?.toUpperCase()}
                            pagination
                            rowsPerPage={rowsPerPage}
                            page={pagina}
                            count={countArticulos}
                            check={chekcArticulos}
                            loading={loading}
                            rowsPerPageOptions={rowsPerPageOptions}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            onCountCheckdChange={handleChangeCountCheckd}
                            onDeleteItem={handleDeleteItem} />
                    </div>
                </div>
                <div className='espacio10px' />
            </div>
        </>
    );
}

export { ListEdicionPublicador };