import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken, fetchConTokenFile,handleResponse } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import { descargarFactura } from 'actions/archivos';
import TituloPagina from 'components/header/TituloPagina';
import Table from 'components/table/Table'
import Modal from "components/modal/Modal";
import { useModal } from "hooks/useModal"
import Swal from 'sweetalert2'
import moment from 'moment';
import { useGlobalState } from 'resources/GlobalStateContext';
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const $ = require('jquery')

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [PDF,setPDF] = useState(['']);
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let [data, setData] = useState([]);
    const [totalxPagina, setTotalxPagina] = useState(10);
    const [busqueda, setbusqueda] = useState('');
    const baseUrl = process.env.REACT_APP_API_URL;
    let [mes, setMes] = useState('');
    let [ejercicio, setEjercicio] = useState(moment().format('Y'));
    let [años, setAños] = useState([]);
    let [statusFiltro, setStatusFiltro] = useState("T");
    let [url,setUrl] = useState(`Facturacion/Facturas/CfdisGetList?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&estatusPago=${statusFiltro}`);
    let [estatusSat, setEstatusSat] = useState('T');
    let [estatusPago, setEstatusPago] = useState('T');
    let [totales, setTotales] = useState([]);

    let [montoTotal, setMontoTotal] = useState('');

    async function getData(page) {
        const response = await fetchConToken(
            `Facturacion/Facturas/GetList?sucursal_id=${getDataSession('sucursal')}&paginas=${page}&totalXPagina=${totalxPagina}&tipo_factura=E`
        );
        setData(response);
    }

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Facturas emitidas' ,
          subtitulo: 'Aquí se guarda el historial de facturas'
        });
      };

      const CustomTab = ({ value, label, count, color }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{label}</span>
            <span style={{
                marginLeft: 5,
                backgroundColor: color,
                color: 'white',
                padding: '2px 5px',
                borderRadius: 4,
            }}>
                {count}
            </span>
        </div>
    );

    React.useEffect(() => {
        getData(1);
        montoTotalFunc();
        $("table").on("click", ".btn-visualizar", function (event) {
            var id = $(this).attr("data-id");
            var ruta = $(this).attr("data-ruta");
            var nombre = $(this).attr("data-nombre");
            verFactura(id,ruta,nombre);
        })
        let year = moment().format('Y')
        const beforeYear = year - 10
        for(let x = year; x >= beforeYear; x--)
        {
            años.push(x);           
        }
        actualizarTitulo();
    }, []);

    function verFactura(url){

        console.log(url)
        fetchConTokenFile(url)
        .then(response => {
        
            console.log(response.status)
            if(response.status == 200)
            {
                return response.blob()
            }
            else{
                handleResponse(response).then(data=>{
                    if (data.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(data?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (data.hasOwnProperty('codigo')) {
                        Swal.fire('Error', data.mensajes[0], 'error');
                        return;
                    }
                })
            }
        })
        .then(function(blob) {
            const fileURL = URL.createObjectURL(blob);
            setPDF(fileURL);
            openModal()
          })
    };

    function getTotales() {
        fetchConToken(`Facturas/CfdisGetList/CantidadXEstatus?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}`).then(function (response) { setTotales(response) });
    }

    const filtrarPorFecha = () => {
        montoTotalFunc();
        setUrl(`Facturacion/Facturas/CfdisGetList?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}`).load();
    }

    const onChangeEjercicio = (e) =>{
        ejercicio = e.target.value
        setEjercicio(ejercicio)
        filtrarPorFecha()
    }

    const onChangeMes = (e) =>{
        mes = e.target.value
        setMes(mes)
        filtrarPorFecha()
    }

    const filtrarEstatusSat = (e) => {
        estatusSat = e.target.value;
        setEstatusSat(e.target.value);
        filtrarPorFecha();
        
    }

    const filtrarEstatusPago = (e) => {
        estatusPago = e.target.value;
        setEstatusPago(e.target.value);
        filtrarPorFecha();
        
    }

    const montoTotalFunc = () => {

        fetchConToken(`Facturacion/Facturas/MontoTotalFacturasFiltro?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}`).then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setMontoTotal(response.montoTotal);
        });

    }
 

    const columns = [
        {renderCell: RenderButtons ,headerName: '', maxWidth: 20 , path: path,opciones: "viewPdf,pdf,xml,MarcarPagado,cancelacionCfdi",viewPdf : 'Facturacion/DescargarPDF', viewPdfOnClick: verFactura, xml: 'Facturacion/DescargarXML', marcarpagado: marcarpagado, marcarpagadoUrl: "Facturacion/MarcarPagado"},
        { field: 'id', headerName: 'Id', width: 70, hide: true },
        { field: 'fecha_Timbrado', headerName: 'Fecha', flex: 1, renderCell: ({ value }) => value?.substring(0,10)},
        { field: 'folio', headerName: 'Folio', flex: 1 },
        { field: 'rfc_Receptor', headerName: 'RFC', flex: 1},
        { field: 'nombre_Receptor', headerName: 'Nombre' , flex: 1},
        { field: 'uso_Cfdi', headerName: 'Uso CFDI', flex: 1 },
        { field: 'total', headerName: 'Total', flex: 1 },
        { field: 'moneda', headerName: 'Moneda', flex: 1 },
        { field: 'estatus_Pago', headerName: 'Estatus Pago', flex: 1 },
        { field: 'fecha_Pago', headerName: 'Fecha Pago', flex: 1 },
        { field: 'estatus_Uuid_Cancelacion', headerName: 'Estatus Cancelacion', flex: 1 },
        { field: 'mensaje_Cancelacion', headerName: 'Mensaje Cancelacion', flex: 1 }
        
    ];


    function marcarpagado(url){

        Swal.fire({
             icon: 'warning',
             html:'Esta seguro de registrar como pagada la factura<br/><input id="datetimepicker" type="date" class="form-control" autofocus>',
             showCancelButton: true,
             confirmButtonText: "Registrar Pago",
             cancelButtonText: 'Cancelar'
         }).then((result) => {
             if (result.isConfirmed) {
                  if($('#datetimepicker').val() !== undefined && $('#datetimepicker').val().length > 0 ){                    
                       fetchConToken(
                           url+"&fecha="+$('#datetimepicker').val(),
                            null,
                            'POST'
                       ).then(function (response) {
                            if (response.hasOwnProperty('status') && response.status === 400) {
                            Object.entries(response.errors).map(([key, value]) => {
                                 Swal.fire('Error', value[0], 'error');
                            });
               
                            return;
                            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                    
                            return;
                            }
                   
                            Swal.fire('Exitoso', 'Orden Actualizada', 'success');
                       });
                  }
                  else
                  {
                       Swal.fire('Info', 'Seleccione una fecha de pago', 'info');
                  }
   }
   })
   }

   const handleChangeFiltroStatus = (event, newValue) => {
    setStatusFiltro(newValue);
};

    return (
        <>
            <div className='container-fluid'>
           
                <div className='col-md-12 row '>
                    <div className='col-md-3'>
                    <div className='d-flex'>
                        <label className='me-1'>Ejercicio</label>
                        <select className='selectCustomTwo m-2 w-100' onChange={onChangeEjercicio}>
                            {
                                años.map((item,i)=>(
                                    <option key={i} value={item}>{item}</option>
                                ))
                            }
                        </select> 
                    </div>
                    <div className='d-flex'>
                        <label className='me-1'>Periodo</label>
                        <select className='selectCustomTwo m-2 w-100' onChange={onChangeMes}>
                            <option value=''>Todos</option>
                            <option value='01'>Enero</option>
                            <option value='02'>Febrero</option>
                            <option value='03'>Marzo</option>
                            <option value='04'>Abril</option>
                            <option value='05'>Mayo</option>
                            <option value='06'>Junio</option>
                            <option value='07'>Julio</option>
                            <option value='08'>Agosto</option>
                            <option value='09'>Septiembre</option>
                            <option value='10'>Octubre</option>
                            <option value='11'>Noviembre</option>
                            <option value='12'>Diciembre</option>
                        </select>
                    </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='d-flex'>
                            <label className='me-1'>Estatus SAT:</label>
                            <select className='selectCustomEstatusSat m-2 w-100' onChange={filtrarEstatusSat}>
                                <option value='T'>Todos</option>
                                <option value='V'>Vigente</option>
                                <option value='C'>Cancelado</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='d-flex'>
                            <label className='me-1'>Estatus Pago:</label>
                            <select className='selectCustomEstatusSat m-2 w-100' onChange={filtrarEstatusPago}>
                                <option value='T'>Todos</option>
                                <option value='P'>Pendiente Pago</option>
                                <option value='F'>Pagado</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-md-12' >
                    <Tabs value={statusFiltro} onChange={handleChangeFiltroStatus} textColor="secondary" indicatorColor="secondary">
                        <Tab value="" label={<CustomTab label="Todos" count={totales?.todos} color='black' />} />
                        <Tab value="P" label={<CustomTab label="Pendiente Pago" count={totales?.pendientes} color='blue' />} />
                        <Tab value="F" label={<CustomTab label="Pagadas" count={totales?.pagados} color='yellowgreen' />} />
                    </Tabs>
                    <ListTable
                        columns = {columns}
                        path = {path}
                        url={url}
                    />

                        {/*<Table 
                        columns = {['Fecha','Folio','RFC','Nombre','Uso CFDI','Total','Moneda','Estatus Pago','Fecha Pago','Estatus Cancelacion','Mensaje Cancelacion','Acciones']}
                        rows = {[
                                ['fecha','fecha_Timbrado'],
                                ['text','folio'],
                                ['text','rfc_Receptor'],
                                ['text','nombre_Receptor'],
                                ['text','uso_Cfdi'],
                                ['moneda','total'],
                                ['text','moneda'],
                                ['text','estatus_Pago'],
                                ['fecha','fecha_Pago'],
                                ['text','estatus_Uuid_Cancelacion'],
                                ['text','mensaje_Cancelacion'],
                                ['opciones','id'] 
                                
                        ]}
                        opciones={"viewPdf,pdf,xml,MarcarPagado,cancelacionCfdi,estatusCfdi"}
                        ruta = "Facturacion"
                        path = {path}
                        url={`/Facturacion/Facturas/List?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E`}>
                        </Table>*/}
                </div>
                <div className='col-md-12 text-center' style={{paddingBotton: "50px;"}}>
                    <label>Total: {montoTotal}</label>
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                <> 
                    <div className="row" style={{padding: '21px'}}>
                    <embed
                        src={PDF}
                        type="application/pdf"
                        height={800}
                        width={500}
                    />
                    </div>
                </>
            </Modal>
        </>
    );
};

List.propTypes = {
    saludo: PropTypes.string
};

List.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { List };
