import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getDataSession } from 'views/Utils/Common';
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import AsyncSelect from 'react-select/async';
import { useGlobalState } from 'resources/GlobalStateContext';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import moment from 'moment';

import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';

const AddEdit = ({ history, match }) => {
    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;
    const { handleSubmit } = useForm();
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [isOpenModal, openModal, closeModal] = useModal(false);

    //select2 Agente
    const [initialAgente, setInitialAgente] = useState(true);
    //select2 Grupo Rutas
    const [initialGrupoRutas, setInitialGrupoRutas] = useState(true);
    //select2 Clientes
    const [initialClientes, setInitialClientes] = useState(true);

    let [detalles, setDetalles] = useState([]);
    let [detalle, setDetalle] = useState({
        id:0,
        cliente_Id: 0,
        fecha_Planeada: null,
        estatus: "P",
        observaciones: "",
        nombre_Cliente: ""
    });
    let [model, setModel] = useState({
        id: 0,
        agente_Id: 0,
        fecha_Inicial: null,
        fecha_Final: null,
        nombre: "",
        sucursal_Id: getDataSession('sucursal')
    });

    function onSubmit() {
        if(!(model.agente_Id > 0))
            {
                Swal.fire('Info', "Seleccione un agente valido", 'info');
                return;
            }
          
            if(detalles.length == 0)
                {
                        Swal.fire('Info', "Agrege al menos un detalle", 'info');
                        return;
                }
    
                model.Vx_Visitas = detalles;

        return isAddMode ? create(model) : update(id, model);
    }

    function create(data) {
        return fetchConToken('VxPlanesVisitas', data, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }
                Swal.fire('Exito', 'Guardado', 'success');
                history.push('.');
            })
            .catch(alertService.error);
    }

    function update(id, data) {
        return fetchConToken('VxPlanesVisitas', data, 'PUT')
        .then((response) => {
            if (response.hasOwnProperty('status')) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Guardado', 'success');
                history.push('..');
            
            })
            .catch(alertService.error);
    }

    useEffect(() => {
        if (!isAddMode) {
            GetById();
        }
    }, []);


    function GetById()
    {
        fetchConToken(`VxPlanesVisitas?id=${id}`).then(
            (clavel) => {
                console.log(clavel);
                /*
                clavel.fecha_Final = clavel.fecha_Final.substring(10);
                clavel.fecha_Inicial = clavel.fecha_Inicial.substring(10);
                clavel.fecha_Registro = clavel.fecha_Registro.substring(10);
                */
                setModel(clavel);     
                var detallesAux = [];        
                clavel.vx_Plan_Visita_Detalles.map(vx=>{
                    detallesAux.push(vx.vx_Visita);
                });
                setDetalles(detallesAux);
                CargarAgenteId(clavel.agente_Id);
            }
        );
    }

    const handleInputChange = ({ target }) => {
        setModel({
            ...model,
            [target.name]: target.type === 'checkbox' ? target.checked === true : target.value
        });
    }
    

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo:isAddMode ? 'Agregar Plan de Visita' : 'Editar Plan de Visita',
          subtitulo: ''
        });
      };
      React.useEffect(() => {actualizarTitulo();},[]);


      const loadDataAgentes = async (query) =>
        await fetchConToken(
            `Usuarios/GetList?empresa_id=${getDataSession('empresa')}&paginas=0&totalXpagina=50&busqueda=${query}&tipoUsuario=AGENTE&estatus=ACTIVOS&sucursal_Id=${getDataSession('sucursal')}`
        ).then((resp) => {
                return resp.data;
            })
            .catch((e) => {
                Swal.fire(
                    'Error',
                    `Ocurrio un error al obtener agentes ${query}`,
                    'error'
                );
            });


            const loadDataClientes = async (query) =>
                await fetchConToken(
                    `Clientes/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`
                ).then((resp) => {
                        return resp.data;
                    })
                    .catch((e) => {
                        Swal.fire(
                            'Error',
                            `Ocurrio un error al obtener clientes ${query}`,
                            'error'
                        );
                    });


     function CargarAgenteId(id){
        fetchConToken(
            `Usuarios?id=${id}&empresa_id=${getDataSession('empresa')}`
         ).then((resp) => {
            setInitialAgente(resp);
            })
     }


     function eliminar(item) {
        const detallesNuevos = detalles.filter(x => !(parseInt(x.id) === parseInt(item.id) && x.fecha_Planeada === item.fecha_Planeada && parseInt(x.cliente_Id) === parseInt(item.cliente_Id)));
        setDetalles(detallesNuevos);
     }

     function guardarDetalle() {

        if(!(detalle.cliente_Id > 0) )
            {
                Swal.fire("Info","Seleccione un cliente valido","info");
                return;
            }
            console.log(detalle);
            if(detalle.fecha_Planeada == null )
                {
                    Swal.fire("Info","Seleccione una fecha valida","info");
                    return;
                }

        if(detalles.filter(x=>x.fecha_Planeada == detalle.fecha_Planeada && x.cliente_Id == detalle.cliente_Id).length > 0)
        {
            Swal.fire("Info","Ya existe registrado el cliente con el dia asignado","info");
            return;
        }

        setDetalles([...detalles,detalle]);
     }
     

const handleInputChangeDetalle = ({ target }) => {

    setDetalle({
        ...detalle,
        [target.name]: target.type === 'checkbox' ? target.checked === 'true' : target.value
    });
}

function actualizaCliente( item ){
    setDetalle({
        ...detalle,
        nombre_Cliente: item.nombre,cliente_Id: item.id
    });

}

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className='mb-3'>
                        <div className='row' style={{ textAlign: 'left' }}>
                           
                        <div className='col-md-12' style={{ textAlign: 'left' }}>
                                <button className='btn btn-outline-success' type='submit'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-check-lg'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                    </svg>
                                    &nbsp; Guardar
                                </button>
                                &nbsp;
                                <Link
                                    to={isAddMode ? '.' : '..'}
                                    className='btn btn-outline-warning'
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                    &nbsp; Regresar &nbsp;
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>

                                    
                                    <label htmlFor='agente' className='form-label'>
                                        Agente
                                    </label>
                                    <AsyncSelect
                                                isClearable
                                                className='react-select-placeholder'
                                                getOptionLabel={(option) =>
                                                    option.nombre
                                                }
                                                getOptionValue={(option) => option.id}
                                                defaultOptions
                                                value={initialAgente}
                                                loadOptions={loadDataAgentes}
                                                onChange={(selectedItem) => {
                                                    const val =
                                                        selectedItem === null
                                                            ? ''
                                                            : selectedItem?.id;
                                                    model.agente_Id = val;
                                                    setInitialAgente(selectedItem);
                                                }}
                                            />
  
                                    <label htmlFor='nombre' className='form-label'>
                                        Nombre
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        required={true}
                                        maxLength={100}
                                        value={model.nombre}
                                        onChange={handleInputChange}
                                    />         

                                    </div>
                               
                            </div>
                        </div>
                        <div className='col-md-6' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                <label htmlFor='fechainicial' className='form-label'>
                                        Fecha Inicial
                                    </label>
                                    <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                        id="filled-number"
                                        type="date"
                                        name="fecha_Inicial"
                                        value={model.fecha_Inicial?.substring(0, 10)}
                                        onChange={handleInputChange}
                                        InputLabelProps={{ shrink: true, }}
                                        required={true}
                                    />
                                    <label htmlFor='fechafinal' className='form-label'>
                                        Fecha Final
                                    </label>
                                    <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                        id="filled-number"
                                        type="date"
                                        name="fecha_Final"
                                        value={model.fecha_Final?.substring(0, 10)}
                                        onChange={handleInputChange}
                                        InputLabelProps={{ shrink: true, }}
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='card mb-3'>
                                <div className='col-md-12 row'>
                                <div className='col-md-2 card-body '>
                                        <label htmlFor='fecha' className='form-label'>
                                                Fecha
                                        </label>
                                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                                id="filled-number"
                                                type="date"
                                                name="fecha_Planeada"
                                                value={detalle.fecha_Planeada?.substring(0, 10)}
                                                onChange={handleInputChangeDetalle}
                                                InputLabelProps={{ shrink: true, }}
                                            />
                                </div>
                                <div className='col-md-4 card-body '>
                                <label htmlFor='cliente' className='form-label'>
                                                    Cliente
                                                </label>
                                                <AsyncSelect
                                                        isClearable
                                                        className='react-select-placeholder'
                                                        getOptionLabel={(option) =>
                                                            option.nombre
                                                        }
                                                        getOptionValue={(option) => option.id}
                                                        defaultOptions
                                                        value={initialClientes}
                                                        loadOptions={loadDataClientes}
                                                        onChange={(selectedItem) => {
                                                            const val =
                                                                selectedItem === null
                                                                    ? ''
                                                                    : selectedItem?.id;
                                                           
                                                            actualizaCliente(selectedItem);
                                                            setInitialClientes(selectedItem);
                                                        }}
                                                    />     
                                </div>
                                <div className='col-md-3 card-body '>
                                        <label htmlFor='fecha' className='form-label'>
                                                Notas
                                        </label>
                                        <textarea className='form-control' name='observaciones'  onChange={handleInputChangeDetalle}>
                                                        {detalle.observaciones}
                                        </textarea>
                                </div>
                                <div className='col-md-3 card-body '>
                                <div className='espacio30px' />
                                        <button
                                                    className='btn btn-outline-success'
                                                    onClick={(e) => {
                                                        guardarDetalle()
                                                    }}
                                                    type='button'
                                                >
                                                    &nbsp; Agregar Cliente
                                        </button>
                                </div>
                                </div>
                            </div>
               
                            <div className='card-body text-start'>
                            <div style={{ overflowX: 'auto' }}>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-right'>Fecha</th>
                                                        <th className='text-left'>Cliente</th>
                                                        <th className='text-left'>Observaciones</th>
                                                        <th className='text-left'>Estatus</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {detalles?.map((item) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td className='text-right'>
                                                                    {item.fecha_Planeada?.substring(0, 10)}
                                                                </td>
                                                                <td className='text-left'>
                                                                    {item.nombre_Cliente}
                                                                </td>
                                                                <td className='text-left'>
                                                                    {item.observaciones}
                                                                </td>
                                                                <td className='text-left'>
                                                                    { item.estatus == "P" ? "Planeada" : "" }
                                                                    { item.estatus == "F" ? "Finalizada" : "" }
                                                                    { item.estatus == "E" ? "eliminado" : "" }
                                                                </td>
                                                                <td style={{ width: "50px"}} >
                                                                    <button
                                                                        type='button'
                                                                        className='btn  mr-1'
                                                                        onClick={() =>
                                                                            eliminar(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='25'
                                                                            height='25'
                                                                            fill='currentColor'
                                                                            className='bi bi-x-circle-fill'
                                                                            viewBox='0 0 16 16'
                                                                        >
                                                                            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z' />
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                
                                                </tbody>
                                            </table>
                                        </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
          
        </>
    );
};

export { AddEdit };
