import React, {useState} from 'react';
//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken,fetchConTokenFile,fetchSinToken } from 'helpers/fetch';
import  Pagination  from 'components/pagination/Pagination'
import { getDataSession, getFirstDay, getLastDay } from 'views/Utils/Common';
import * as FileSaver from "file-saver";
import TituloPagina from 'components/header/TituloPagina';
import { alertService } from '_services';
import { useGlobalState } from 'resources/GlobalStateContext';
import  ListTable from 'components/table/ListTable'
import RenderButtons from 'components/table/RenderButtons';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import { currency } from 'resources/utilities';

import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const List = ({ match}) => {

  const { globalTitulo, setGlobalTitulo } = useGlobalState();
  const { path } = match;

  
  let [cliente, setCliente] = useState({});
  let [saldoCliente, setSaldoCliente] = useState({});

  const [isOpenModal, openModal, closeModal] = useModal(false);
  let [conAdeudo, setConAdeudo] = useState(true);
  let [totales, setTotales] = useState([]);

  const verAdeudos = (cliente_id) => {
      fetchConToken(`clientes?id=${cliente_id}&sucursal_id=${getDataSession('sucursal')}`).then(resp=>{
          setCliente(resp);
      });
      fetchConToken(`clientes/Saldos?cliente_id=${cliente_id}`).then(resp=>{
          setSaldoCliente(resp);
      });        
      openModal();
  }

      const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "editar,verAdeudos",verAdeudos: verAdeudos },
        { field: 'nombre', headerName: 'Cliente', minWidth: 200 },
        { field: 'correo', headerName: 'Correo', minWidth: 100},
        { field: 'rfc', headerName: 'RFC', minWidth: 100},
        { field: 'telefono1', headerName: 'Telefono 1', minWidth: 100},
        { field: 'telefono2', headerName: 'Telefono 2', minWidth: 100},
        { field: 'limite_Credito', headerName: 'Limite de Credito', minWidth: 150, type: 'number', renderCell: ({ value }) => currency(value)  },
        { field: 'saldo_Cliente.saldo_Actual', headerName: 'Saldo Actual', minWidth: 150, type: 'number', renderCell: (params) => currency(params.row.saldo_Cliente.saldo_Actual)  },
        { field: 'saldo_Cliente.credito_Disponible', headerName: 'Credito Disponible', minWidth: 150, type: 'number', renderCell: (params) => currency(params.row.saldo_Cliente.credito_Disponible)  },

        
    ];
    
  const actualizarTitulo = () => {
      setGlobalTitulo({
        ...globalTitulo,
        titulo: 'Clientes registrados' ,
        subtitulo: 'Listado de clientes de su tienda.'
      });
    };
    React.useEffect(() => {actualizarTitulo(); getTotales();},[]);

    React.useEffect(() => {
        getTotales();
    }, [conAdeudo]);

    function getTotales() {
        fetchConToken(`Clientes/CantidadXEstatus?sucursal_Id=${getDataSession('sucursal')}`).then(function (response) { setTotales(response) });
    }
    const CustomTab = ({ value, label, count, color }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{label}</span>
            <span style={{
                marginLeft: 5,
                backgroundColor: color,
                color: 'white',
                padding: '2px 5px',
                borderRadius: 4,
            }}>
                {count}
            </span>
        </div>
    );
       
    return (
        <>

          <div className="container-fluid" >  
          
                        
                                              
          <div className='row'>
          <div className='col-sm-2'>
              <Link to={`${path}/add`} className='btn btn-outline-primary'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; Nuevo
                        </Link>
                &nbsp;
                </div>
            </div>
            <div className='col-md-12' >
                    <Tabs value={conAdeudo} onChange={(event, newValue)=>{setConAdeudo(newValue)}} textColor="secondary" indicatorColor="secondary">
                        <Tab value="true" label={<CustomTab label="Con Adeudo" count={totales?.conadeudo} color='blue' />} />
                        <Tab value="false" label={<CustomTab label="Todos" count={totales?.todos} color='yellowgreen' />} />
                    </Tabs>
              <ListTable 
                    columns = {columns}
                    path = {path}
                    url={`Clientes/GetList?sucursal_Id=${getDataSession('sucursal')}&conAdeudo=${conAdeudo}`}>
              </ListTable>

              <Modal isOpen={isOpenModal} closeModal={closeModal} >
                <div className='col-md-12 row'>
                    <div className='col-md-4'>
                        <h4>Cliente</h4>
                        <label>Cliente:</label><label>{cliente?.nombre}</label>
                        <label>Telefono:</label><p>{cliente?.telefono1}</p>
                        <label>Correo:</label><p>{cliente?.correo}</p>
                    </div>
                    <div className='col-md-4'>
                         <h4></h4>
                        <label>Rfc:</label><p>{cliente?.rfc}</p>
                        <label>Uso Cfdi:</label><p>{cliente?.uso_Cfdi}</p>
                        <label>Regimen Fiscal Receptor:</label><p>{cliente?.regimen_Fiscal_Receptor}</p>
                    </div>
                    <div className='col-md-4'>
                         <h4>Adeudo</h4>
                        <label>Limite Credito:</label><p>{currency(cliente?.saldo_Cliente?.limite_Credito)}</p>
                        <label>Saldo Actual:</label><p>{currency(cliente?.saldo_Cliente?.saldo_Actual)}</p>
                        <label>Credito Disponible</label><p>{currency(cliente?.saldo_Cliente?.credito_Disponible)}</p>
                    </div>
                </div>
                <div className='col-md-12'>
                    <h4>Adeudos</h4>
                   
                            <div className='row' style={{border: "1px solid black"}}>
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Folio Adeudo:</th>
                                    <th>Fecha Vencimiento:</th>
                                    <th className='text-right'>Saldo Cargo:</th>
                                    <th className='text-right'>Importe Acreditado:</th>                                   
                                    <th>Contado:</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                       saldoCliente?.facturas_Pendientes?.map(x=>{
                          return  ( 
                                    <tr>
                                    <td>{x?.folio}</td>
                                    <td>{x?.fecha_Vencimiento?.substring(0, 10)}</td>
                                    <td className='text-right'>{currency(x?.saldo_Cargo)}</td>
                                    <td className='text-right'>{currency(x?.importe_Acreditado)}</td>
                                    <td>{x?.concepto}</td>
                                    </tr>)})}
                                </tbody>
                            </table>
                            </div>
                      
                </div>
           </Modal>
            </div>
        </div>

        </>
    );    


}

export { List };