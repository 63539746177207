import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { MenuDescargas } from '../../Movimiento/MenuDescargas';
import { useGlobalState } from 'resources/GlobalStateContext';
import { currency } from 'resources/utilities';
import { Link } from 'react-router-dom';
import ListTable from 'components/table/ListTable';
import TextField from '@mui/material/TextField';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';

const List = ({ history, match }) => {
    const { path } = match;
    const { t } = useTranslation("common");
    const [fechaInicio, setFechaInicio] = useState(GetFecha(true));
    const [fechaFin, setFechaFin] = useState(GetFecha(false));
    const [actualizarTabla, setActualizarTabla] = useState(false);
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [dataMenu] = useState([{
        Titulo: 'Listado de Planes de Visita',
        Data: [
            { Titulo: 'Descargar Excel', Slug: 'EXCEL' },
            { Titulo: 'Descargar PDF', Slug: 'PDF' }
        ]
    }, {
        Titulo: 'Plan de Visita',
        Data: [
            { Titulo: 'Descargar Excel', Slug: 'EXCEL' },
            { Titulo: 'Descargar PDF', Slug: 'PDF' }
        ]
    }]);
    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "editar" },
        { field: 'nombre', headerName: 'Nombre', minWidth: 100 },
        { field: 'fecha_Inicial', headerName: 'Inicial', minWidth: 100},
        { field: 'fecha_Final', headerName: 'Final', minWidth: 100},
        { field: 'agente_Nombre', headerName: 'Agente', minWidth: 100 }
    ];

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Planes de Visita',
            subtitulo: 'Listado de Planes de Visita'
        });
    };

    const onClickButtonMenu = (slug) => {

    }

    const handleInputChangeFechaInicio = ({ target }) => {
        setFechaInicio(target.value);
    };

    const handleInputChangeFechaFin = ({ target }) => {
        setFechaFin(target.value);
    }

    function GetFecha(inicio) {
        var curr = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        if (!inicio) curr = new Date(curr.getFullYear(), curr.getMonth(), new Date(curr.getFullYear(), curr.getMonth() + 1, 0).getDate());

        curr.setDate(curr.getDate());
        return curr.toISOString().substring(0, 10);
    }

    useEffect(() => actualizarTitulo(), []);

    return (
        <>
            <MenuDescargas data={dataMenu} onClickButton={onClickButtonMenu} />
            <div className='espacio10px' />
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-2'>
                        <Link to={`${path}/add`} className='btn btn-outline-primary m-1' style={{ width: 'auto' }}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; {t('app.SwalFire.nuevo')}
                        </Link>
                    </div>
                    <div className='col-sm-5' />
                    {/*<div className='col-sm-5' style={{ display: 'flex' }}>
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={handleInputChangeFechaInicio}
                            InputLabelProps={{ shrink: true, }}
                        />
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Fin"
                            type="date"
                            value={fechaFin}
                            onChange={handleInputChangeFechaFin}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>*/}
                </div>
                <div className='col-md-12'>
                    <ListTable 
                        columns={columns} 
                        path={path} 
                        reload={actualizarTabla} 
                        url={`VxPlanesVisitas/GetList?sucursal_Id=${getDataSession('sucursal')}`} />
                </div>
            </div>
        </>
    );
}

export { List };