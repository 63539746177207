import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';
import { useGlobalState } from 'resources/GlobalStateContext';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [tipoCategoria, setTipoCategoria] = useState('P');
    const [actualizarTabla, setActualizarTabla] = useState(false);
    const columns = [
        {
            renderCell: RenderButtons, headerName: '', maxWidth: 20, path: path, opciones: "editar" + (validarSucursal() ? ",asignar,asignarmanual,desvincular" : ""),
            asignarAutomatico: asignar, desvincular: desvincular
        },
        getDataSession('tienda') === 'MERCADOLIBRE' ? { field: 'id', headerName: 'Id', maxWidth: 20 } :
        { field: 'id', headerName: 'Id', maxWidth: 20, renderCell: (params) => (<a href={`${path + '/edit/' + params.row.id}`}>{params.value}</a>) },
        getDataSession('tienda') === 'MERCADOLIBRE' ? { field: 'clave_Categoria', headerName: 'Clave categoria', flex: 1 } :
        { field: 'clave_Categoria', headerName: 'Clave categoria', flex: 1, renderCell: (params) => (<a href={`${path + '/edit/' + params.row.id}`}>{params.value}</a>) },
        { field: 'clave_Categoria_Padre', headerName: 'Clave Categoria Padre', flex: 1 },
        { field: 'clave_sat', headerName: 'Clave SAT', flex: 1 },
        { field: 'nombre', headerName: 'Nombre', flex: 1 },
        { field: 'tipo_Categoria', headerName: 'Tipo Categoria', flex: 1 },
        { field: 'publicado', headerName: 'Publicado', flex: 1, renderCell: (params) => (<span>{params.value ? 'S' : 'N'}</span>) }
    ];

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Categorias',
            subtitulo: 'Aqui se muestran todas las categorias por conexion'
        });
    };

    const FiltroTipoCategoria = () => {
        return (
            <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="tipo-categoria-checkbox-label">Tipo Categoria</InputLabel>
                <Select onChange={handleChange} value={tipoCategoria} label="Conexión">
                    <MenuItem key='T' value='T'><ListItemText primary='Todos' /></MenuItem>
                    <MenuItem key='P' value='P'><ListItemText primary='(P) Principal' /></MenuItem>
                    <MenuItem key='S' value='S'><ListItemText primary='(S) Secundario' /></MenuItem>
                    <MenuItem key='C' value='C'><ListItemText primary='(C) Final' /></MenuItem>
                </Select>
            </FormControl>);
    };

    function descargar() {
        var url = getDataSession('rol') === "ADMINISTRADOR" ? `categorias/DescargarCategorias?sucursal_id=${getDataSession('sucursal')}}` :
            `categorias/DescargarCategorias?sucursal_id=${getDataSession('sucursal')}&emrpesa_id=${getDataSession('sucursal')}&tienda=${getDataSession('tienda')}`;
        fetchConToken(url, null, 'POST').then((response) => {
            if (response.hasOwnProperty('status')) {
                Object.entries(response?.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo')) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return;
            }

            Swal.fire('Exito', 'Se inicio la descarga de  categorias', 'success');
        })
    }

    function handleChange(e) {
        setTipoCategoria(e.target.value);
    }

    function asignar(categoria_id) {
        Swal.fire({
            title: `¿Desea relacionar la categoria?`,
            showCancelButton: true,
            confirmButtonText: 'Relacionar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                var asignacion = {
                    sucursalOrg: getDataSession('sucursal'),
                    sucursalDes: getDataSession('sucursalPrincipal'),
                    categoriaOrg: categoria_id,
                    categoriaDes: 0
                };
                fetchConToken(`categorias/AsignarCategoria`, asignacion, 'PUT').then((response) => {
                    if (response.hasOwnProperty('status')) {
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }

                    Swal.fire('Asignación completa', 'Categoria Relacionado', 'success');
                    setActualizarTabla(true);
                    setActualizarTabla(false);
                });
            }
        });
    }

    function desvincular(categoria_id) {
        Swal.fire({
            title: `¿Desea desvincular la categoria?`,
            showCancelButton: true,
            confirmButtonText: 'Desvincular',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                var desvincular = {
                    sucursalOrg: getDataSession('sucursal'),
                    sucursalDes: getDataSession('sucursalPrincipal'),
                    categoriaOrg: categoria_id,
                    categoriaDes: 0
                };
                fetchConToken(`categorias/DesvincularCategoria`, desvincular, 'PUT')
                    .then((response) => {
                        if (response.hasOwnProperty('status')) {
                            Object.entries(response?.errors).map(([key, value]) => {
                                Swal.fire('Error', value[0], 'error');
                                return;
                            });
                            return false;
                        } else if (response.hasOwnProperty('codigo')) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                            return false;
                        }

                        Swal.fire('Exito', 'Categoria Desvinculado', 'success');
                        setActualizarTabla(true);
                        setActualizarTabla(false);
                    })
            }
        });
    }

    function validarSucursal() {
        return getDataSession('sucursal') !== getDataSession('sucursalPrincipal');
    }

    if (getDataSession('tienda') !== 'TIENDANUBE') {
        const indexToRemove = columns.findIndex(column => column.headerName === 'Publicado');
        if (indexToRemove !== -1) columns.splice(indexToRemove, 1);
    }

    if (getDataSession('tienda') === 'MERCADOLIBRE') {
        const indexToRemove = columns.findIndex(column => column.headerName === '');
        if (indexToRemove !== -1) columns.splice(indexToRemove, 1);
    }

    if (getDataSession('sucursal') !== getDataSession('sucursalPrincipal')) {
        columns.splice(1, 0, {
            field: 'padre.clave_Categoria', headerName: 'Cve. Orig.', width: 100, cellClassName: (params) => { return 'columna-art-padre' }, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.padre?.id}`}>{params.row.padre ? params.row.padre.clave_Categoria : ''}</a>)
        });
        columns.splice(2, 0, {
            field: 'padre.nombre', headerName: 'Nom. Orig.', width: 100, cellClassName: (params) => { return 'columna-art-padre' }, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.padre?.id}`}>{params.row.padre ? params.row.padre.nombre : ''}</a>)
        });
    }

    useEffect(() => actualizarTitulo(), []);

    return (
        <div className='container-fluid'>
            <div className='col-md-12'>
                <div className='col-md-12'>
                    {getDataSession('tienda') !== 'MERCADOLIBRE' &&
                        <Link to={`${path}/add`} className='btn btn-outline-primary'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; Nuevo
                        </Link>}
                    &nbsp; &nbsp;
                    {(getDataSession('rol') === "ADMINISTRADOR" || getDataSession('tienda') === 'TIENDANUBE') &&
                        <button className={getDataSession('tienda') === 'TIENDANUBE' ? 'btn btn-outline-success' : 'btn btn-outline-danger'} type='button' onClick={() => descargar()}>&nbsp;Descargar/Actualizar categorias</button>}
                </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12'>
                <ListTable
                    hideFiltroExtra
                    filtroExtra={FiltroTipoCategoria()}
                    columns={columns}
                    path={path}
                    url={`Categorias/CategoriasGetList?sucursal_Id=${getDataSession('sucursal')}&tipo_Categoria=${tipoCategoria}`}
                    reload={actualizarTabla}
                />
            </div>
        </div>
    );
};

export { List };
