import React, { useState } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { alertService } from '_services';
import { Link } from 'react-router-dom';
import { descargarFactura } from 'actions/archivos';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Pagination from 'components/pagination/Pagination';
import { useForm } from 'react-hook-form';
import { currency } from 'resources/utilities';
import TituloPagina from 'components/header/TituloPagina';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { useGlobalState } from 'resources/GlobalStateContext';
import Swal from 'sweetalert2';

import sinImagen from 'images/venta/SIN-IMAGEN.jpg'
import SLUGS from 'resources/slugs';

const View = ({ history, match, saludo, subtitulo }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [facturado, setFacturado] = useState(false);
    const { handleSubmit } = useForm();

    const { id } = match.params;
    console.log(id);

    const { document } = match.params;

    let [documento, setDocumento] = useState(document);
    let [TipoDocto,setTipoDocto] =  useState('C');
    let [Docto, setDocto]=  useState('C');


    const isAddMode = !id;

    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState(false);
    let [cfdi, setCfdi] = useState([]);

    let [cliente, setCliente] = useState([]);
    let [clientes, setClientes] = useState([]);
    let [direcciones, setDirecciones] = useState([]);
    let [direccionesList, setDireccionesList] = useState([]);
    let [venta, setVenta] = useState( {
        id: 0,
        empresa_Id: getDataSession('empresa'),
        sucursal_Id: getDataSession('sucursal'),
        cliente_Id: 0,
        direccion_Id: 0,
        no_Orden: '',
        folio: '',
        moneda: 'MXN',
        descuento_Importe: 0,
        estatus: 'P',
        importe_Total_Impuestos: 0,
        metodo_Pago: '',
        clave_Envio: '',
        forma_De_Pago: '',
        tipo_Documento: TipoDocto,
        descripcion: ''
    });
    let [ventaDetalles, setVentaDetalles] = useState([]);
    let [articulos, setArticulos] = useState([]);

    let [serie, setSerie] = useState('');
    let [sucursal_id, setSucursal_id] = useState(getDataSession('sucursal'));

    let [sucursales, setSucursales] = useState([]);
    let [series, setSeries] = useState([]);

    var direccion_id = 0;

    const totalxPagina = 10;

    let [error, setError] = useState(['']);
    let [erroresCliente, setErroresCliente] = useState({});
    let [erroresDirecciones, setErroresDirecciones] = useState({});

    let [erroresV, setErroresV] = useState({});
    let [erroresArticulos, setErroresArticulos] = useState({});
    let [requiereRevision, setRequiereRevision] = useState(false);

    let [load, setLoad] = useState(false);

    const [satFormaPago, setSatFormaPago] = useState(['']);
    const [satPais, setSatPais] = useState(['']);


    const actualizarTitulo = (titulo) => {
        setGlobalTitulo({
          ...globalTitulo,
          titulo: titulo,
          subtitulo: ''
        });
      };
    React.useEffect(() => {
        console.log("entro efect");
        setDocumento(match.params.documento);
        console.log(match.params.documento);
        switch(match.params.documento)
       { 
            case 'remisiones':
                setTipoDocto('R');
                setDocto('Remisión');
                actualizarTitulo('Remisión');
                break;
            case 'cotizaciones':
                setTipoDocto('C');
                setDocto('Cotización');
                actualizarTitulo('Cotización');
                break;
            case 'pedidos':
                setTipoDocto('P');
                setDocto('Pedido');
                actualizarTitulo('Pedido');
                break;
            default:
                setTipoDocto('P');
                setDocto('Pedido');
                actualizarTitulo('Pedido');
                break;
       }
 }, [match.params.documento]);

    async function getSatFormaPago() {
        const response = await fetchConToken(`CatalogosSat/ListFormaPago?pagina=1&totalXpagina=300`);
        setSatFormaPago(response);
    }
    async function getSatPais() {
        const response = await fetchConToken(`CatalogosSat/ListPais?pagina=1&totalXpagina=300`);
        setSatPais(response);
    }
    async function getSucursales() {
        const response = await fetchConToken(
            `Sucursales/GetList?empresa_id=${getDataSession('empresa')}&pagina=1&totalXpagina=50`
        );
        setSucursales(response);
    }

    if (getDataSession('sucursal') === '0') {
        Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
    }

    async function getSeries(sucursal_id) {
        const response = await fetchConToken(
            `Sucursales/GetFoliosSucursales?sucursal_Id=${sucursal_id}&tipo_Documento=F`
        );
        setSeries(response);
    }

    async function getDireccion(direccion_id) {
        const direccionAux = await fetchConToken(`DireccionesClientes?id=${direccion_id}`);
        setDirecciones(direccionAux);
        getCliente(direccionAux.cliente_Id);
    }

    async function getCliente(cliente_id) {
        const clienteAux = await fetchConToken(
            `Clientes?id=${cliente_id}&sucursal_Id=${venta.sucursal_Id}`
        );
        clienteAux.regimen_Fiscal_Receptor =
            clienteAux.regimen_Fiscal_Receptor.replace(' ') === ''
                ? '601'
                : clienteAux.regimen_Fiscal_Receptor;
        clienteAux.uso_Cfdi = clienteAux.uso_Cfdi.replace(' ') === '' ? 'G01' : clienteAux.uso_Cfdi;
        clienteAux.estatus = clienteAux.estatus.replace(' ') === '' ? 'A' : clienteAux.estatus;
        clienteAux.rfc = clienteAux.estatus.replace(' ') === '' ? '' : clienteAux.rfc;
        setCliente(clienteAux);
        console.log(clienteAux);
        console.log(clienteAux.clientes_Direcciones[0]);
    }

    const facturar = () => {
        saveCliente();
    };

    
    const cancelar = () => {

        Swal.fire({
            title: 'Cancelación',
            text: "Desea cancelar la venta",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Cancelar!',
            cancelButtonText: 'No!'
          }).then((result) => {
            if (result.isConfirmed) {


        fetchConToken(
            `Ventas/Cancelar?documento_id=${id}&sucursal_id=${getDataSession('sucursal')}`,
            null, 'POST'
        ).then((response) => {
            setErroresV({});
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresVenta = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
    
            Swal.fire('Exito', Docto + ' cancelada', 'success');
            venta.id > 0 ? history.push('..') : history.push('.');
            return true;
        });
    }
});
    }

    
    const DescargarDocumento = () => {
        setLoad(true);
        fetchConToken(`Ventas/DescargarPDF?id=${id}`).then(async (responseFile) =>  {
            try {
                if (responseFile.hasOwnProperty('status') && responseFile.status === 400) {
                    var erroresVenta = {};
                    Object.entries(responseFile.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (responseFile.hasOwnProperty('codigo') && responseFile.codigo !== 200) {
                    Swal.fire('Error', responseFile.mensajes[0], 'error');
                    return false;
                }
                  
                var win = window.open(responseFile.guiaEnvio, '_blank');
                win.focus();
                setLoad(false);
                
              } catch (error) {
                console.error('Error al descargar el archivo:', error);
              }
        });
    };

    const DescargarGuia= () => {
        setLoad(true);
        fetchConToken(`Ventas/DescargarEtiquetaEnvio?venta_id=${id}`).then(async (responseFile) =>  {
            try {
                if (responseFile.hasOwnProperty('status') && responseFile.status === 400) {
                    var erroresVenta = {};
                    Object.entries(responseFile.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (responseFile.hasOwnProperty('codigo') && responseFile.codigo !== 200) {
                    Swal.fire('Error', responseFile.mensajes[0], 'error');
                    return false;
                }
                  
                var win = window.open(responseFile.guiaEnvio, '_blank');
                win.focus();
                setLoad(false);
                
              } catch (error) {
                console.error('Error al descargar el archivo:', error);
              }
        });
    };

    const terminar = () => {

        Swal.fire({
            title: 'Terminar',
            text: "Desea terminar la venta",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Terminar!',
            cancelButtonText: 'No!'
          }).then((result) => {
            if (result.isConfirmed) {


        fetchConToken(
            `Ventas/Terminar?documento_id=${id}&sucursal_id=${getDataSession('sucursal')}`,
            null, 'POST'
        ).then((response) => {
            setErroresV({});
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresVenta = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
    
            Swal.fire('Exito', Docto + ' Terminada', 'success');
            venta.id > 0 ? history.push('..') : history.push('.');
            return true;
        });
    }
});
    }

    const saveCliente = () => {
        cliente.empresa_Id = getDataSession('empresa');
        cliente.sucursal_Id = getDataSession('sucursal');
        if (!cliente.hasOwnProperty('uso_Cfdi')) cliente.uso_Cfdi = 'G01';
        if (!cliente.hasOwnProperty('regimen_Fiscal_Receptor'))
            cliente.regimen_Fiscal_Receptor = '601';

        if (!cliente.hasOwnProperty('no_Cliente')) cliente.no_Cliente = 'local';

        cliente.sucursal_Id = getDataSession('sucursal');
        fetchConToken(
            `Clientes?sucursal_Id=${getDataSession('sucursal')}`,
            cliente,
            cliente.hasOwnProperty('id') && cliente.id !== null ? 'PUT' : 'POST'
        ).then((response) => {
            setError(['']);
            setErroresV({});
            setErroresCliente('');
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresC = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setCliente([]);
            setCliente(response);
            saveDireccion(response.id);

            return true;
        });
        return true;
        //.catch(alertService.error("91"));
    };

    const saveDireccion = (id) => {
        console.log(id);
        direcciones.cliente_Id = id;
        console.log(direcciones);

        if (!direcciones.hasOwnProperty('no_Direccion')) direcciones.no_Direccion = 'local';

        fetchConToken(
            `DireccionesClientes`,
            direcciones,
            direcciones.hasOwnProperty('id') && direcciones.id !== null ? 'PUT' : 'POST'
        ).then((response) => {
            setError(['']);
            setErroresV({});
            setErroresDirecciones('');
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresC = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                setError('Revisar errores');
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setDirecciones([]);
            setDirecciones(response);
            direccion_id = response.id;
            guardarVenta(response.id);

            return true;
        });
        return true;
        //.catch(alertService.error("107"));
    };

    ////////Guardar Venta
    ///////////////////////////////////////////
    const guardarVenta = (id) => {

        console.log('entro guardar venta');
        venta.sucursal_Id = getDataSession('sucursal');
        venta.direccion_Id = id;
        if (venta.direccion_Id === 0) {
            Swal.fire('Error', 'Seleccione una direccion', 'error');
            return false;
        }
        if (ventaDetalles.length === 0) {
            Swal.fire('Error', 'Seleccione por los menos un articulo', 'error');
            return false;
        }

        if (venta.forma_De_Pago.length === 0 || !venta.hasOwnProperty('forma_De_Pago'))
            venta.forma_De_Pago = '01';

        if (venta.metodo_Pago.length === 0 || !venta.hasOwnProperty('metodo_Pago'))
            venta.metodo_Pago = 'PUE';

        if (venta.length === 0 || !venta.hasOwnProperty('moneda')) venta.moneda = 'MXN';

        venta.venta_Detalles = ventaDetalles;

        fetchConToken(
            `Ventas?sucursal_Id=${getDataSession('sucursal')}`,
            venta,
            venta.hasOwnProperty('id') && venta.id > 0 ? 'PUT' : 'POST'
        ).then((response) => {
            setErroresV({});
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresVenta = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            //alertService.success('Venta guardada', { keepAfterRouteChange: true });
            Swal.fire('Exito', 'Venta guardada', 'success');
            venta.id > 0 ? history.push('..') : history.push('.');
            
            setError('');
            setVenta(response);

            setVentaDetalles([]);
            setVentaDetalles(response.venta_Detalles);
            setFacturado(true);
            return true;
        });
        return true;
        //.catch(alertService.error("131"));
    };
    ///////////////////////////////////////////
/*
    const saveArticulos = () => {
        setErroresArticulos({});
        setError('');
        ventaDetalles.map((art) => {
            art.articulo.descripcion =
                art.articulo.descripcion.replace(' ') === ' ' ? '' : art.articulo.descripcion;
            fetchConToken(
                `Articulos?sucursal_Id=${getDataSession('sucursal')}`,
                art.articulo,
                'PUT'
            ).then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0] + ' Clave: ' + art.articulo.clave, 'error');
                        return;
                    });
                    setError('Revisar errores');
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
            });
            return true;
            //.catch(alertService.error("153"));
        });

        if (Object.entries(erroresArticulos).length > 0) return false;
        if (erroresArticulos[0] !== undefined) return false;

        return true;
    };*/


    async function getVenta() {
        const ventaAux = await fetchConToken(
            `Ventas?id=${id}&sucursal_Id=${getDataSession('sucursal')}`
        );

       

        ventaAux.forma_De_Pago =
            ventaAux.forma_De_Pago.replace(' ') === '' ? '01' : ventaAux.forma_De_Pago;
        ventaAux.metodo_Pago =
            ventaAux.metodo_Pago.replace(' ') === '' ? 'PUE' : ventaAux.metodo_Pago;

             
        setVenta(ventaAux);
        getDireccion(ventaAux.direccion_Id);


        /*console.log('get direccion');
        getDireccion(ventaAux.direccion_Id);
        articulos.length = 0;
        ventaAux.venta_Detalles?.map((item) => {
            articulos.push(item?.articulo);
        });
        console.log(ventaAux.venta_Detalles); 

        const newTodos = [...ventaDetalles];
        ventaAux.venta_Detalles.map(venDet=>{      
            
            var artAux = ventaAux.venta_Detalles.filter((d) => d.id == venDet.id);

            console.log(artAux)
            artAux[0]['impuesto'] = artAux[0]['impuesto_Total'] / artAux[0]['cantidad'];
            artAux[0]['precioSinImpuesto'] = artAux[0]['precio_Unitario'];
            
            artAux[0]['subtotal'] = artAux[0]['precio_Unitario'] * artAux[0]['cantidad'];
            artAux[0]['iva'] = artAux[0]['impuesto'] * artAux[0]['cantidad'];
            artAux[0]['precio_Unitario'] = artAux[0]['precio_Unitario'];
            artAux[0]['precio_Total'] = artAux[0]['iva'] + artAux[0]['subtotal'];

            newTodos.push(artAux[0]);
        });
        setVentaDetalles(newTodos);
        setRequiereRevision(ventaAux.venta_Detalles.filter(e => e.requiere_Revision === true).length > 0);
*/
       console.log(ventaAux.venta_Detalles);
       setVentaDetalles( ventaAux.venta_Detalles );
        return ventaAux.venta_Detalles.filter(e => e.requiere_Revision === true).length > 0;
    }


    React.useEffect(() => {
        console.log("consola 1");

        getSucursales();
        getSatFormaPago();
        getSatPais();
        getSeries(getDataSession('sucursal'));

        getVenta();
      
    },[]);

    const handleInputChangeCliente = ({ target }) => {

        setCliente({
            ...cliente,
            [target.name]: target.value
        });

    };

    const handleInputChangeDireccion = ({ target }) => {
        setDirecciones({
            ...direcciones,
            [target.name]: target.value
        });
    };
    const handleInputChangeVenta = ({ target }) => {
        setVenta({
            ...venta,
            [target.name]: target.value
        });
    };

    const handleInputChangeSerie = ({ target }) => {
        setSerie(target.value);
    };

    const handleInputChangeSucursal = ({ target }) => {
        venta.sucursal_id = target.value;
        setSucursal_id(target.value);
        getSeries(target.value);
    };

    const handleInputChangeArticulos = (event, key, campo) => {
        let detalleV = ventaDetalles.filter((det) => det.articulo_Id === key);
        detalleV[0].articulo[campo] = event.target.value;

        setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === key ? detalleV[0] : det)));
    };

    async function eliminar(id) {
        //setVentaDetalles([]);

        if(isAddMode)
            setVentaDetalles(ventaDetalles.filter((item) => item.articulo_Id !== id));
        else
        {
            var det = ventaDetalles.filter((d) => d.articulo_Id == id);
            console.log(det)
            det.map(detV=>{
                console.log(detV)
                if(detV?.id > 0)
                {
                    console.log("entro cantidad 0");
                    detV.cantidad = 0;
                    setVentaDetalles(ventaDetalles.map((d) => (d.articulo_Id === id ? detV : d)));
                }
                else
                {
                    console.log("entro eliminar");
                    setVentaDetalles(ventaDetalles.filter((item) => item.articulo_Id !== id));
                }
           })
        }
        console.log(ventaDetalles)
    }

    ////Clientes
    /////////////////////////////////////////////
    async function getClientes(page) {
        setModalValor('cliente');
        const response = await fetchConToken(
            `clientes/GetList?sucursal_id=${getDataSession('sucursal')}&empresa_id=${getDataSession(
                'empresa'
            )}&paginas=${page}&totalXpagina=${totalxPagina}`
        );
        setClientes(response.data);
        console.log(response);
        console.log(clientes);
        console.log(clientes.length);
        //getDirecciones();
    }

    async function getCliente(cliente_id) {
        const clienteAux = await fetchConToken(
            `Clientes?id=${cliente_id}&sucursal_Id=${getDataSession('sucursal')}`
        );
        clienteAux.regimen_Fiscal_Receptor =
            clienteAux.regimen_Fiscal_Receptor.replace(' ') === ''
                ? '601'
                : clienteAux.regimen_Fiscal_Receptor;
        clienteAux.uso_Cfdi = clienteAux.uso_Cfdi.replace(' ') === '' ? 'G01' : clienteAux.uso_Cfdi;
        clienteAux.estatus = clienteAux.estatus.replace(' ') === '' ? 'A' : clienteAux.estatus;
        clienteAux.rfc = clienteAux.estatus.replace(' ') === '' ? '' : clienteAux.rfc;
        setCliente(clienteAux);
        setDirecciones(clienteAux.clientes_Direcciones[0]);
        venta.direccion_Id = clienteAux.clientes_Direcciones[0].id;
        console.log(clienteAux.clientes_Direcciones[0].id);
    }
    ////////////////////////////////////////////

    /////////Direcciones
    ///////////////////////////////////////////
    async function getDirecciones(page) {
        console.log(cliente);
        if (cliente.length === 0) {
            setError('Seleccione un cliente');
            return;
        }
        setModalValor('direccion');
        const direccionAux = await fetchConToken(
            `DireccionesClientes/GetList?paginas=${page}&totalXpagina=${totalxPagina}&busqueda=${cliente.id}`
        );

        console.log(direccionAux);
        setDireccionesList(direccionAux);
        getCliente(direccionAux.cliente_Id);
        openModal();
    }

    async function getDireccion(direccion_id) {
        console.log(direccion_id);
        venta.direccion_Id = direccion_id;
        const direccionAux = await fetchConToken(`DireccionesClientes?id=${direccion_id}`);
        setDirecciones(direccionAux);
        console.log(direccionAux);
        getCliente(direccionAux.cliente_Id);
        console.log(direccionAux.cliente_Id);
    }
    ///////////////////////////////////////////

    ///////////Articulos
    ///////////////////////////////////////////
    async function getArticulos(page) {
        //busqueda.value='';
        setModalValor('articulo');
        console.log(totalxPagina);
        const articulosAux = await fetchConToken(
            `Articulos/GetList?paginas=${page}&totalXpagina=${totalxPagina}&sucursal_Id=${getDataSession('sucursal')}`
        );
        setArticulos(articulosAux?.data);
    }
    async function getArticulo(id) {

        if (!Number.isInteger(id)) return;

        var artAux = ventaDetalles.filter((d) => d.articulo_Id == id);
        if (artAux.length > 0) {
            artAux[0].cantidad = artAux[0].cantidad + 1;

            setVentaDetalles(
                ventaDetalles.map((det) => (det.articulo_Id === id ? artAux[0] : det))
            );

            ImpuestosArticulo(ventaDetalles.filter(x=> x.articulo_Id === id ),false);

            return;
        }

        const articulosAux = await fetchConToken(
            `Articulos?id=${id}&sucursal_Id=${getDataSession('sucursal')}`
        );

        const detalleVenta = {
            id: 0,
            venta_Id: 0,
            articulo_Id: articulosAux.id,
            no_Detalle: 'local',
            posicion: 0,
            cantidad: 1,
            clave_Unidad: articulosAux.clave_Unidad,
            precio_Unitario: articulosAux.precio_Sin_Impuesto ,
            descuento_Total: 0,
            precio_Total: articulosAux.costo_Unidad,
            impuesto_Total: 0,
            estatus: 'A',
            articulo: articulosAux,
            nombre_Articulo: articulosAux.nombre,
            impuesto: 0,
            precioSinImpuesto: articulosAux.costo_Unidad
        };

     
        ImpuestosArticulo(detalleVenta,true);
    }

    ///////////////////////////////////////////

    async function ImpuestosArticulo(ventaDet, nuevaLinea)
    {
        console.log(ventaDet);
        if(nuevaLinea)
        {
            console.log("entro nueva linea");
            await fetchConToken(`ventas/CalculaDetalle?sucursal_Id=` + getDataSession('sucursal'),ventaDet,'POST' ).then((detVentaResp) =>{
                setVentaDetalles([...ventaDetalles,detVentaResp]);
            });
        }
        else
        {
            console.log("entro linea registrada");
            await fetchConToken(`ventas/CalculaDetalle?sucursal_Id=` + getDataSession('sucursal'),ventaDet[0],'POST' ).then((detVentaResp) =>{
                setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === detVentaResp.articulo_Id ? detVentaResp : det)));
            });
        }
    }
    
    /////////// Detalles venta
    //////////////////////////////////////////
    const handleInputChangeDetallesVenta = (event, key, campo) => {

        let detalleV = ventaDetalles.filter((det) => det.articulo_Id === key);

        switch(campo)
        {
            case "cantidad":
                detalleV[0][campo] = parseFloat(event.target.value);
                break;
            case "precio_Unitario":
                detalleV[0]["precio_Unitario"] = parseFloat(event.target.value);
                break;
            default:
                detalleV[0][campo] = event.target.value;
                break;
        }
        setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === key ? detalleV[0] : det)));

        if(campo !== "nombre_Articulo")
            ImpuestosArticulo(ventaDetalles.filter(x=> x.articulo_Id === key ),false);
/*
        let detalleV = ventaDetalles.filter((det) => det.articulo_Id === key);
        detalleV[0][campo] = event.target.value;

        if (campo === 'cantidad') {
            console.log(detalleV[0]);
            console.log('entro cantidad');
            detalleV[0]['iva'] = detalleV[0]['impuesto'] * detalleV[0]['cantidad'];
            detalleV[0]['subtotal'] = detalleV[0]['precioSinImpuesto'] * detalleV[0]['cantidad'];

            detalleV[0]['precio_Total'] = detalleV[0]['iva'] + detalleV[0]['subtotal'];
        }

        setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === key ? detalleV[0] : det)));*/
    };
    //////////

    const loadClientes = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `clientes/GetList?sucursal_id=${getDataSession('sucursal')}&empresa_id=${getDataSession(
                'empresa'
            )}&paginas=0&totalXpagina=50&busqueda=${query}`
        );
        return resp.data;
    };

    const loadArticulos = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `Articulos/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession(
                'sucursal'
            )}`
        );
        return resp?.data;
    };

    /*const loadDireccion = async (query) => {
        var resp = await fetchConToken(
            `DireccionesClientes/GetList?paginas=1&totalXpagina=50&busqueda=${cliente.id}`
        );
        return resp; 
    };*/


    async function descripcionDetalle(id) {
        //setVentaDetalles([]);
        var detAux = ventaDetalles.filter((d) => d.articulo_Id == id);

           Swal.fire({
            title: 'Agregar descripción al detalle',
            input: 'textarea',
            inputValue: detAux[0]?.descripcion,
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Guardar',
            showLoaderOnConfirm: true,

          }).then((result) => {
            if (result.isConfirmed) {
                
                detAux[0].descripcion = result.value;
                console.log(detAux)
                setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === id ? detAux[0] : det)));
            }
          })
        
        console.log(ventaDetalles)
    }

    const convertir = () => {

        var url = "";
        if(TipoDocto === 'C')
            url = `Cotizaciones/ConvertirAPedido?documento_id=${id}&sucursal_id=${getDataSession('sucursal')}`;
        if(TipoDocto === 'P')
            url = `Pedidos/ConvertirARemision?documento_id=${id}&sucursal_id=${getDataSession('sucursal')}`;


        fetchConToken(url, null, 'post').then(async (resp) => {
            try {
                if (resp.hasOwnProperty('status') && resp.status === 400) {
                    var erroresVenta = {};
                    Object.entries(resp.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (resp.hasOwnProperty('codigo') && resp.codigo !== 200) {
                    Swal.fire('Error', resp.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'El documento se convirtio correctamente', 'success');
            } catch (error) {
                console.error('Error al convertir el documento:', error);
            }
        });
    }

    return (
        <>
            <div className='container-fluid'>
            <TituloPagina
                    descripcion={'#No.Orden: ' + venta?.no_Orden } 
                />
            <TituloPagina
                    descripcion={'#Folio: ' + venta?.folio } 
                />
                <form onSubmit={handleSubmit(facturar)} className='facturar' /*onReset=""*/>
                    <div className='col-md-12 text-right'>
                    <button
                            className='btn btn-outline-success'
                            onClick={ (e) => 
                                descargarFactura(
                                    id,
                                    'ventas/DescargarPDF',
                                    venta?.folio + '.pdf'
                                )
                             }
                            type='button'
                            disabled={load}
                        >
                            &nbsp; Descargar PDF
                        </button> 
                        &nbsp; 
                        {(TipoDocto === 'C' || TipoDocto === 'P') &&  (venta.estatus !== 'T' && venta.estatus !== 'C') ? <>
                            <button
                                className='btn btn-outline-success'
                                onClick={ (e) => convertir() }
                                type='button'
                            >
                                &nbsp; Convertir a {TipoDocto == 'C' ? "Pedido" : "Remision"}
                            </button>
                            &nbsp; 
                        </>
                        : ""}
                    { venta.tipo_Documento === 'R' && getDataSession('tienda') === 'MERCADOLIBRE' ? 
                    <button
                            className='btn btn-outline-success'
                            onClick={ (e) => DescargarGuia() }
                            type='button'
                            disabled={load}
                        >
                            &nbsp; Descargar Guia
                        </button> : '' }
                        &nbsp; 

                        { (venta.id > 0 && venta.folio_venta == null && venta.estatus !== 'C' && venta.estatus !== 'T' ) ?
                        <>
                            <button
                            className='btn btn-outline-success'
                            onClick={ (e) => terminar() }
                            type='button'
                        >
                            &nbsp; Terminar {Docto}
                        </button>
                        &nbsp; 
                        </>
                    : ""
                    }

                        &nbsp;
                        <button
                            hidden={ facturado || venta.estatus === 'CANCELADA' || venta.id == 0 }
                            className='btn btn-outline-success'
                            onClick={ (e) => cancelar() }
                            type='button'
                        >
                            &nbsp; Cancelar {Docto}
                        </button>
                        &nbsp;
                        <Link to={ isAddMode ? '.' : '..'} className='btn btn-outline-warning'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='15'
                                height='15'
                                fill='currentColor'
                                className='bi bi-reply'
                                viewBox='0 0 16 16'
                            >
                                <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                            </svg>
                            &nbsp; Regresar
                        </Link>
                    </div>

                    <div className='espacio10px'/>
                    <div className='row'>
                        <div className='col-md-8'> 
                            <div className='card mb-2 '>
                                <div className='card-title text-start'>
                                   <h5><b>Detalles</b></h5>
                                   <label>{venta.descripcion}</label>
                                </div>
                            
                                {ventaDetalles.filter(i=>i.cantidad > 0)?.map((item) => {
                                                        return (<>
                                <hr style={{border:"1px dotted black", width:"100%"}} />
                                <a href={SLUGS.articulos+"/edit/"+item.articulo_Id} target='_blank'>
                                    <div className='card-body text-start row'>
                                        <div className='col-md-4 col-sm-3 col-3'>
                                            {item.imagen == null ?
                                            <img src={sinImagen} width="100%" height="auto" /> :
                                            <img src={item.imagen} width="100%" height="auto" />
                                            }
                                        </div>
                                        <div className='col-md-6 col-sm-7 col-7'>
                                            <b><p>{item.nombre_Articulo}</p></b>
                                            <p>{item.clave_Articulo} &emsp; {item?.clave_Variante}</p>
                                            <div className='row'>
                                                <div className='col-md-7 col-sm-8 col-8 text-right'>
                                                    <p><small>Importe:</small></p>
                                                    <p><small>Impuesto:</small></p>
                                                    <p><small>SubTotal:</small></p>
                                                    <p><small>Utilidad:</small></p>
                                                </div>
                                                <div className='col-md-5 col-sm-4 col-4 text-right'>
                                                    <p><small>{currency(item.precio_Total)}</small></p>
                                                    <p><small>{currency(item.impuesto_Total)}</small></p>
                                                    <p><small>{currency(item.impuesto_Total+item.precio_Total)}</small></p>
                                                    <p><small>{currency(item.utilidad)}</small></p>
                                                </div>
                                            
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-2'>
                                            <span>x{item.cantidad}</span>
                                        </div>
                                    </div>
                                </a>
                                </>)})}
                                <hr style={{border:"1px dotted black", width:"100%"}} />
                                <div className='card-body text-start row'>
                                    <div className='col-md-10 col-sm-8 col-8 text-right'>
                                    <b><p>Subtotal:</p></b>
                                    <b><p>IVA:</p></b>
                                    <b><p>Total:</p></b>
                                    <b><p>Ganancia Aprox:</p></b>
                                    </div>
                                    <div className='col-md-2 col-sm-4 col-4 text-right'>
                                        <p>{currency(ventaDetalles.reduce((a, v) => (a = a + ( v.precio_Unitario * v.cantidad )),0))}</p>
                                        <p>{currency(ventaDetalles.reduce((a, v) => (a = a + v.impuesto_Total),0))}</p>
                                        <p>{currency(ventaDetalles.reduce((a, v) =>(a = a + v.precio_Total + v.impuesto_Total),0))}</p>
                                        <p>{currency(venta?.pago_Neto || 0)}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='card mb-2 '>
                                <div className='card-title text-start'>
                                   <h5><b>Historial</b></h5>
                                </div>
                                <hr style={{border:"1px dotted black", width:"100%"}} />
                                <div className='card-body text-start row'>
                                { venta.envios?.map(envio =>{
                                    return (<><label>Guia: {envio.num_Guia}</label>
                                         <Stepper activeStep={true} orientation="vertical">
                                        { envio?.historial_Envio?.map(historial => {
                                        return (
                                        <Step key={historial.id}>
                                            <StepLabel
                                            optional={
                                                <label>{historial.estatus}</label>
                                            }
                                            >
                                            {historial.fecha} --- {historial.subestatus}
                                            </StepLabel>          
                                        </Step>)})}
                                    </Stepper></>)})}
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='card mb-2 '>
                                <div className='card-title text-start'>
                                   <h5><b>Cliente</b></h5>
                                </div>
                                <div className='card-body text-start row'>
                                    <small>{cliente.nombre}</small>
                                    <small>{cliente.no_Cliente}</small>
                                    <small>{cliente.correo}</small>
                                    <small>{cliente.rfc}</small>
                                    <small>{cliente.telefono1}</small>
                                    <small>{cliente.telefono2}</small>
                                </div>

                                <hr style={{border:"1px dotted black", width:"100%"}} />
                                <div className='card-title text-start'>
                                   <h5><b>Entrega</b></h5>
                                </div>
                                { venta.envios?.map(envio => {
                                 return (<div className='card-body text-start row'>
                                    <div className='col-md-4'><small><b>Empresa:</b></small></div>
                                    <div className='col-md-8'><small>{envio.empresa_Envio}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Codigo Postal:</b></small></div>
                                    <div className='col-md-8'><small>{envio.codigo_Postal}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Num. Guia:</b></small></div>
                                    <div className='col-md-8'><small>{envio.num_Guia}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Direccion:</b></small></div>
                                    <div className='col-md-8'><small>
                                                                {envio.pais}
                                                                {envio.estado}
                                                                {envio.ciudad}
                                                                {envio.municipio}
                                                                {envio.colonia}
                                                                {envio.calle}
                                                            </small>
                                    </div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Referencia:</b></small></div>
                                    <div className='col-md-8'><small>{envio.referencia}</small></div>

                                </div>)
                                })}

                                <hr style={{border:"1px dotted black", width:"100%"}} />
                                <div className='card-title text-start'>
                                   <h5><b>Pagos</b></h5>
                                </div>
                                 { venta.pago_Ecommerce?.map(pago => {
                                    return (<div className='card-body text-start row'>
                                    <div className='col-md-4'><small><b>Tipo Pago:</b></small></div>
                                    <div className='col-md-8'><small>{pago.tipo_pago}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Metodo Pago:</b></small></div>
                                    <div className='col-md-8'><small>{pago.metodo_pago}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Tipo Operacion:</b></small></div>
                                    <div className='col-md-8'><small>{pago.tipo_operacion}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>No. Pago:</b></small></div>
                                    <div className='col-md-8'><small>{pago.no_pago}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Fecha Pago:</b></small></div>
                                    <div className='col-md-8'><small>{pago.fecha_pago}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Fecha Aprov:</b></small></div>
                                    <div className='col-md-8'><small>{pago.fecha_aprovacion}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Monto:</b></small></div>
                                    <div className='col-md-8'><small>{currency(pago.monto)}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Costo Envio:</b></small></div>
                                    <div className='col-md-8'><small>{currency(pago.costo_envio)}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Tarifa Ecommerce:</b></small></div>
                                    <div className='col-md-8'><small>{currency(pago.tarifa_ecommerce)}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Estatus:</b></small></div>
                                    <div className='col-md-8'><small>{pago.estatus}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Detalle estatus:</b></small></div>
                                    <div className='col-md-8'><small>{pago.detalle_estatus}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Referencia Transf.:</b></small></div>
                                    <div className='col-md-8'><small>{pago.referencia_transferencia}</small></div>
                                    <hr/>
                                    <div className='col-md-4'><small><b>Descripcion:</b></small></div>
                                    <div className='col-md-8'><small>{pago.descripcion}</small></div>

                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                    <br/><br/><br/>
                </form>
            </div>
        </>
    );
};


export { View };
