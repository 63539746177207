import React, { useState, useEffect } from 'react';
import MapComponent from 'components/maps/MapComponent';
import { useGlobalState } from 'resources/GlobalStateContext';
import AsyncSelect from 'react-select/async';
import { fetchConToken } from 'helpers/fetch';
import Swal from 'sweetalert2';
import { getDataSession } from 'views/Utils/Common';
import TextField from '@mui/material/TextField';

const VxRecorrido = ({ history, match }) => {

    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let [agente, setAgente] = useState(null);
    let [cliente, setCliente] = useState(null);
    const [locations, setLocations] = useState([]);

    const [fechaInicio, setFechaInicio] = useState(GetFecha(true));
    const [fechaFin, setFechaFin] = useState(GetFecha(false));
    

    function GetFecha(inicio) {
        var curr = new Date();
        return curr.toISOString().substring(0, 10);
    }
    

  const actualizarTitulo = () => {
    setGlobalTitulo({
        ...globalTitulo,
        titulo: 'Recorrido De Agente'
    });
};

const loadDataClientes = async (query) =>
    await fetchConToken(
        `Clientes/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`
    ).then((resp) => {
            return resp.data;
        })
        .catch((e) => {
            Swal.fire(
                'Error',
                `Ocurrio un error al obtener clientes ${query}`,
                'error'
            );
 });

        
 const loadDataAgentes = async (query) =>
        await fetchConToken(
            `Usuarios/GetList?empresa_id=${getDataSession('empresa')}&paginas=0&totalXpagina=50&busqueda=${query}&tipoUsuario=AGENTE&estatus=ACTIVOS&sucursal_Id=${getDataSession('sucursal')}`
        ).then((resp) => {
                return resp.data;
            })
            .catch((e) => {
                Swal.fire(
                    'Error',
                    `Ocurrio un error al obtener agentes ${query}`,
                    'error'
                );
 });

  useEffect(() => actualizarTitulo(), []);
  useEffect(() => ubicacionAgente(), [agente,fechaInicio,fechaFin]);

  
  const handleInputChangeFechaInicio = ({ target }) => {
    setFechaInicio(target.value);
};

const handleInputChangeFechaFin = ({ target }) => {
    setFechaFin(target.value);
}


  function ubicacionAgente(){
        if(!agente)
            return;

        var ubicacion = fetchConToken(
                `VxLogUbicacion/GetList?paginas=0&totalXpagina=0&fecha_Inicio=${fechaInicio}&fecha_Fin=${fechaFin}&sucursal_Id=${getDataSession('sucursal')}&agente_id=`+agente.id
            ).then((resp) => {

                if(resp.data?.length > 0)
                {

                    let arrayLoc = [];
                    resp.data.forEach(element => {

                        const newMarker = {
                            id: arrayLoc.length + 1,
                            name: "Agente:" + agente?.nombre,
                            date:  element?.fecha_Registro,
                            position: [Number(element?.lat), Number(element?.lon)], 
                        };
                        arrayLoc.push(newMarker);
                    });
                    setLocations(arrayLoc);
                   
                }
                    else
                    {
                        Swal.fire('Info',`No se encontrraron registros de la ubicación de la fecha asignada`,'info')
                    }
                })
                .catch((e) => {
                    Swal.fire(
                    'Error',
                    `Ocurrio un error al obtener la ubicacion`,
                    'error'
            )})          
            
}

 
function limpiarMapa(){
    setLocations([]);
}

  return (
    <>
        <div className='container-fluid'>
            <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-md-4 '>
                                <label htmlFor='cliente' className='form-label'>
                                                    Agente
                                </label>
                                <AsyncSelect
                                                isClearable
                                                className='react-select-placeholder'
                                                getOptionLabel={(option) =>
                                                    option.nombre
                                                }
                                                getOptionValue={(option) => option.id}
                                                defaultOptions
                                                //value={initialAgente}
                                                loadOptions={loadDataAgentes}
                                                onChange={(selectedItem) => {
                                                    setAgente(selectedItem);
                                                }}
                                            />
                    </div>
                    <div className='col-md-3 '>

                       <div className='espacio40px'/>
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={handleInputChangeFechaInicio}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>
                    <div className='col-md-3 '>
                        <div className='espacio40px'/>
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Fin"
                            type="date"
                            value={fechaFin}
                            onChange={handleInputChangeFechaFin}
                            InputLabelProps={{ shrink: true, }}
                        />

                    </div>
                    <div className='col-md-2'>
                        <div className='espacio40px'/>
                        <button className='btn btn-outline-success' type='button' onClick={()=>limpiarMapa()}>
                                        &nbsp; Limpiar Mapa
                                    </button>
                        </div>
                <div className='col-md-12'>
                    <div className='espacio10px'/>
                    <MapComponent locations={locations} />
                </div>
            </div>
        </div>
    </>
  );
};

export {VxRecorrido};
