import React, { useState, useEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemButton from '@mui/material/ListItemButton';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PrintIcon from '@mui/icons-material/Print';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { descargarFactura } from 'actions/archivos';
import { fetchConToken } from 'helpers/fetch';
import Swal from 'sweetalert2';

const RenderButtons = (props) => {
  const path = props.colDef.path;
  const [clikId, setClikId] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setClikId(event.currentTarget.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const DescargarGuia = (id) => {
    handleClose();
    fetchConToken(`Ventas/DescargarEtiquetaEnvio?venta_id=${id}`).then(async (responseFile) => {
      try {
        if (responseFile.hasOwnProperty('status') && responseFile.status === 400) {
          var erroresVenta = {};
          Object.entries(responseFile.errors).map(([key, value]) => {
            Swal.fire('Error', value[0], 'error');
            return;
          });
          return false;
        } else if (responseFile.hasOwnProperty('codigo') && responseFile.codigo !== 200) {
          Swal.fire('Error', responseFile.mensajes[0], 'error');
          return false;
        }

        var win = window.open(responseFile.guiaEnvio, '_blank');
        win.focus();
      } catch (error) { console.error('Error al descargar el archivo:', error); }
    });
  };

  return <div>
    <MoreVertIcon id={props.id} variant="contained" onClick={handleClick} />
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      MenuListProps={{ 'aria-labelledby': 'long-button', }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
    >
      {props.colDef.opciones?.split(',').map((opcion) => {
        switch (opcion) {
          case "editar":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton href={path + "/edit/" + clikId}  >
                <EditIcon />&nbsp; Editar
              </ListItemButton>
            </MenuItem>
            break;
          case "vista":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton href={path + "/view/" + clikId} >
                <VisibilityIcon />&nbsp; Vista
              </ListItemButton>
            </MenuItem>
            break;
          case "URLeditar":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton href={props.colDef.UrlEditar + clikId} >
                <EditIcon />&nbsp; Editar
              </ListItemButton>
            </MenuItem>
            break;
          case "viewPdf":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.viewPdfOnClick(props.colDef.viewPdf + "?id=" + clikId); handleClose(); }} >
                <EditIcon />&nbsp; Ver factura
              </ListItemButton>
            </MenuItem>
            break;
          case "pdf":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { descargarFactura(clikId, props.colDef.viewPdf, clikId + ".pdf"); handleClose(); }} >
                <EditIcon />&nbsp; Descargar PDF
              </ListItemButton>
            </MenuItem>
            break;
          case "xml":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { descargarFactura(clikId, props.colDef.xml, clikId + ".xml"); handleClose(); }} >
                <EditIcon />&nbsp; Descargar XML
              </ListItemButton>
            </MenuItem>
            break;
          case "MarcarPagado":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.marcarpagado(props.colDef.marcarpagadoUrl + "?fac_id=" + clikId); handleClose(); }} >
                <EditIcon />&nbsp; Marcar como Pagado
              </ListItemButton>
            </MenuItem>
            break;
          case "asignar":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.asignarAutomatico(clikId); handleClose(); }} >
                <CompareArrowsIcon />&nbsp; Asignacion automatico
              </ListItemButton>
            </MenuItem>
            break;
          case "asignarmanual":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton href={props.colDef.path + '/asignacion/' + clikId} >
                <CompareArrowsIcon />&nbsp; Asignacion Manual
              </ListItemButton>
            </MenuItem>
            break;
          case "verVentasArticulo":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.verVentasArticulo(clikId); handleClose(); }} >
                <LoyaltyIcon />&nbsp; Ver ventas
              </ListItemButton>
            </MenuItem>
            break;
          case "kardex":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.verMovimientoArticulo(clikId); handleClose(); }} >
                <LoyaltyIcon />&nbsp; Kardex
              </ListItemButton>
            </MenuItem>
            break;
          case "publicarExistencia":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.publicarExistencia(clikId); handleClose(); }} >
                <LoyaltyIcon />&nbsp; Publicar Existencia
              </ListItemButton>
            </MenuItem>
            break;
          case "publicarPrecio":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.publicarPrecio(clikId); handleClose(); }} >
                <LoyaltyIcon />&nbsp; Publicar Precio
              </ListItemButton>
            </MenuItem>
            break;
          case "verVariantesArticulo":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.verVariantesArticulo(clikId); handleClose(); }} >
                <LoyaltyIcon />&nbsp; Ver Variantes
              </ListItemButton>
            </MenuItem>
            break;
          case "descargarguia":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { DescargarGuia(clikId); handleClose(); }} >
                <PrintIcon />&nbsp; Imprimir Etiqueta
              </ListItemButton>
            </MenuItem>
            break;
          case "actualizararticulo":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.actualizararticulo(clikId); handleClose(); }} >
                <SystemUpdateAltIcon />&nbsp; Actualizar
              </ListItemButton>
            </MenuItem>
            break;
          case "duplicarArticulo":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.duplicarArticulo(clikId); handleClose(); }} >
                <WebStoriesIcon />&nbsp; Duplicar
              </ListItemButton>
            </MenuItem>
            break;
          case "deshabilitar":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.deshabilitar(clikId); handleClose(); }}  >
                <BlockIcon />&nbsp; Deshabilitar
              </ListItemButton>
            </MenuItem>
            break;
          case "eliminar":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.eliminar(clikId); handleClose(); }}  >
                <DeleteIcon />&nbsp; Eliminar
              </ListItemButton>
            </MenuItem>
            break;
          case "desvincular":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.desvincular(clikId); handleClose(); }}  >
                <MobiledataOffIcon />&nbsp; Desvincular
              </ListItemButton>
            </MenuItem>
            break;
          case "generarCopia":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.generarCopia(clikId); handleClose(); }} >
                <WebStoriesIcon />&nbsp; Registrar a principal
              </ListItemButton>
            </MenuItem>
            break;
          case "verEntregas":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { handleClose(); }} >
                <LocalShippingIcon />&nbsp; Ver Entregas
              </ListItemButton>
            </MenuItem>
            break;
          case "asignarRepartidor":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { handleClose(); }} >
                <AssignmentIndIcon />&nbsp; Asignar Repartidor
              </ListItemButton>
            </MenuItem>
            break;
          case "asignarDatosExtraFactura":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.asignarDatosExtraFactura(clikId); handleClose(); }} >
                <AssignmentIcon />&nbsp; Asignar Datos Extra
              </ListItemButton>
            </MenuItem>
            break;
          case "verNotasCredito":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.verNotasCredito(clikId); handleClose(); }} >
                <RequestQuoteIcon />&nbsp; Ver Notas de credito
              </ListItemButton>
            </MenuItem>
            break;
          case "verDetallePago":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.verDetallePago(clikId); handleClose(); }} >
                <RequestQuoteIcon />&nbsp; Ver Detalle de Pago
              </ListItemButton>
            </MenuItem>
            break;
          case "verVisitas":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.verVisitas(clikId); handleClose(); }} >
                <RequestQuoteIcon />&nbsp; Ver Visitas
              </ListItemButton>
            </MenuItem>
            break;
          case "verAdeudos":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.verAdeudos(clikId); handleClose(); }} >
                <RequestQuoteIcon />&nbsp; Ver Adeudos
              </ListItemButton>
            </MenuItem>
            break;
          case "convertirPedido":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.convertirPedido(clikId); handleClose(); }} >
                <RequestQuoteIcon />&nbsp; Convertir a Pedido
              </ListItemButton>
            </MenuItem>
            break;
          case "convertirRemision":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.convertirRemision(clikId); handleClose(); }} >
                <RequestQuoteIcon />&nbsp; Convertir a Remision
              </ListItemButton>
            </MenuItem>
            break;
          case "verPagos":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.verPagos(clikId); handleClose(); }} >
                <RequestQuoteIcon />&nbsp; Ver Pagos
              </ListItemButton>
            </MenuItem>
            break;
          case "asignarAgente":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.asignarAgente(clikId); handleClose(); }} >
                <AssignmentIcon />&nbsp; Asignar Agente
              </ListItemButton>
            </MenuItem>
            break;
          case "verVxEntregas":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.verVxEntregas(clikId); handleClose(); }} >
                <AssignmentIcon />&nbsp; Ver Entregas
              </ListItemButton>
            </MenuItem>
            break;
          case "verEntregasPedido":
            return <MenuItem style={{ fontSize: "13px !important", padding: "0" }}>
              <ListItemButton onClick={() => { props.colDef.verEntregasPedido(clikId); handleClose(); }} >
                <AssignmentIcon />&nbsp; Ver Entregas
              </ListItemButton>
            </MenuItem>
            break;
        }
      })}
    </Menu>
  </div>;
}

export default RenderButtons;
