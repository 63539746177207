import React, { useState, useEffect, useRef } from 'react';
import { useGlobalState } from 'resources/GlobalStateContext';
import { getDataSession } from 'views/Utils/Common';
import { useTranslation } from 'react-i18next';
import { fetchConToken } from 'helpers/fetch';
import Modal from 'components/modal/Modal';
import { useForm } from 'react-hook-form';
import { useModal } from 'hooks/useModal';
import SLUGS from 'resources/slugs';
import Swal from 'sweetalert2';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const AddEdit = ({ history, match }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const { id } = match.params;
    const path = match.url;
    const { t } = useTranslation('common');
    const isAddMode = !id;
    const button_submit = useRef(null);
    const { register, handleSubmit, reset } = useForm();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [atributoValue, setAtributoValue] = useState({});
    const [atributoValues, setAtributoValues] = useState([]);
    let [atributo, setAtributos] = useState({
        sucursal_Id: getDataSession('sucursal'),
        tienda: getDataSession('tienda'),
        clave_Atributo: '',
        clave_Categoria: '',
        nombre: '',
        value_Type: '',
        value_Max_Length: 0,
        atributo_Padre_Id: 0,
        jerarquia: '',
        relevancia: 0,
        required: true,
        readonly: false,
        tiene_Values: false,
        es_Variante: false,
        es_Combinacion: false,
        values: []
    });

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: isAddMode ? 'Agregar Atributo' : 'Editar Atributo',
            subtitulo: ''
        });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickButtonSubmit = () => {
        if (validar()) button_submit.current.click();
    };

    const validar = () => {
        if (!atributo.nombre || atributo.nombre === '') {
            Swal.fire('', 'Complete el campo de Nombre', 'warning');
            return false;
        }

        if (atributo.tiene_Values && !(atributoValues && atributoValues.length > 0)) {
            Swal.fire('', 'Favor de ingresar los valores', 'warning');
            return false;
        }
        else if (!atributo.tiene_Values) atributo.values = [];
        else atributoValues.forEach(x => {
            x.clave_Atributo = atributo.clave_Atributo;
            x.valor = x.nombre;
            var temp = atributo.values.filter(y => y.clave_Value === x.clave_Value);
            if (!(temp && temp.length > 0)) atributo.values.push(x);
        });

        setAtributos({ ...atributo, values: !atributo.tiene_Values ? [] : atributoValues });
        return true;
    };

    function regresar() {
        history.push(`${SLUGS.atributos}`);
    }

    function obtenerAtributo() {
        fetchConToken('Categorias/Atributo?id=' + id + '&sucursal_id=' + getDataSession('sucursal')).then(res => {
            if (res.hasOwnProperty('status')) {
                Object.entries(res?.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (res.hasOwnProperty('codigo')) {
                Swal.fire('Error', res.mensajes[0], 'error');
                return;
            }

            res.values = res.values;
            setAtributos(res);
            setAtributoValues(res.values);
        });
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setAtributos({
            ...atributo,
            [name]: value
        });
    }

    function handleInputModalChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setAtributoValue({
            ...atributoValue,
            [name]: value
        });
    }

    function postAtributo() {
        if (getDataSession('tienda') === 'WOOCOMMERCE') {
            Swal.fire({
                title: `Desea publicar o solo guardar el atributo`,
                showCancelButton: true,
                confirmButtonText: 'Guardar y Publicar',
                cancelButtonText: 'Guardar'
            }).then((result) => PostAtributo(result.isConfirmed));
        }
        else PostAtributo(false);
    }

    function PostAtributo(isConfirmed) {
        var url = !isConfirmed ? `Categorias/Atributos?empresa_id=${getDataSession('empresa')}` :
            `Categorias/Atributos/Publicar?empresa_id=${getDataSession('empresa')}&sucursal_id=${getDataSession('sucursal')}&tienda=${getDataSession('tienda')}`;
        fetchConToken(url, atributo, 'POST').then((response) => {
            console.log(response);
            if (response.hasOwnProperty('status')) {
                Object.entries(response?.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo')) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }

            Swal.fire('Exito', !isAddMode ? 'Atributo actualizado con exito' : 'Atributo agregada con exito', 'success');
            history.push(isAddMode ? '.' : '..');
        })
    }

    function eliminar() {
        if (getDataSession('tienda') !== 'WOOCOMMERCE') {
            eliminarItem(false);
            return;
        }

        Swal.fire({
            title: '¿Desea eliminar el atributo de la Ecommerce?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.botones.eliminar') + ' de la Ecommerce',
            cancelButtonText: t('app.botones.eliminar') + ' del sistema'
        }).then((r) => eliminarItem(r.isConfirmed));
    }

    function eliminarItem(isConfirmed) {
        Swal.fire({
            title: '¿Desea eliminar el atributo?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.botones.eliminar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                var url = `Categorias/Atributo?id=${id}&emrpesa_id=${getDataSession('empresa')}&sucursal_id=${getDataSession('sucursal')}&eliminarmk=${isConfirmed}`;
                fetchConToken(url, null, 'DELETE').then((response) => {
                    if (response.hasOwnProperty('status')) {
                        Object.entries(response?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return;
                    }

                    Swal.fire('Exito', 'Atributo eliminado correctamente', 'success');
                    history.push('..');
                });
            }
        });
    }

    function onSubmit() {
        if (isAddMode && getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        if (isAddMode) {
            fetchConToken('Categorias/GetAtributoByClave?clave=' + atributo.clave_Atributo + '&emrpesa_id=' + getDataSession('empresa') + '&sucursal_id=' + getDataSession('sucursal')).then(x => {
                if (x.hasOwnProperty('mensajes')) {
                    var aux = x?.mensajes?.find((e) => e === 'No se encontro el atributo');
                    if (aux) return postAtributo();

                    Object.entries(x?.mensajes).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return;
                } else if (x.hasOwnProperty('status')) {
                    Object.entries(x?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return;
                } else if (x.hasOwnProperty('codigo')) {
                    Swal.fire('Error', x.mensajes[0], 'error');
                    return;
                }

                if (x?.clave_Atributo === atributo.clave_Atributo) {
                    Swal.fire('Error', 'Ya existe un atributo con la clave ' + x.clave_Atributo, 'error');
                    return;
                }
            });
        }
        else return postAtributo();
    }

    function ClaveDisable() {
        if (getDataSession('tienda') === 'WOOCOMMERCE') return true;

        return !isAddMode;
    }

    function AgregarValor() {
        openModal();
        setAtributoValue({
            sucursal_Id: getDataSession('sucursal'),
            clave_Atributo: atributo.clave_Atributo,
            clave_Value: GetClave(),
            atributo_Valor_Padre_Id: 0,
            clave_Categoria: '',
            nombre: '',
            valor: '',
        });
    }

    function AgregarValue() {
        if (!(atributoValue.clave_Value && atributoValue.clave_Value.length > 0) || !(atributoValue.nombre && atributoValue.nombre.length > 0)) {
            Swal.fire('', 'Complete los campos', 'warning');
            return false;
        }

        var temp = atributoValues.find(x => x.nombre.toUpperCase() === atributoValue.nombre.toUpperCase());
        if (!(temp && temp?.nombre)) setAtributoValues([...atributoValues, atributoValue]);

        closeModal();
    }

    function EliminarValue(nombre, clave) {
        Swal.fire({
            title: '¿Desea eliminar ' + nombre + '?',
            showCancelButton: true,
            confirmButtonText: t('app.botones.eliminar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                var temp = atributoValues.find(x => x.clave_Value.toUpperCase() === clave.toUpperCase());
                if (id > 0 && temp && temp.id > 0) {
                    var url = `Categorias/Atributo?id=${id}&emrpesa_id=${getDataSession('empresa')}&sucursal_id=${getDataSession('sucursal')}&clave_Value=${clave}`;
                    fetchConToken(url, null, 'DELETE').then((response) => {
                        if (response.hasOwnProperty('status')) {
                            Object.entries(response?.errors).map(([key, value]) => {
                                Swal.fire('Error', value[0], 'error');
                                return;
                            });
                            return;
                        } else if (response.hasOwnProperty('codigo')) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                            return;
                        }

                        atributo.values = atributo.values.filter(y => y.clave_Value === clave);
                        var temps = atributoValues.filter(x => x.clave_Value.toUpperCase() !== clave.toUpperCase());
                        setAtributoValues(temps);
                    });
                }
                else {
                    var temps = atributoValues.filter(x => x.clave_Value.toUpperCase() !== clave.toUpperCase());
                    setAtributoValues(temps);
                }
            }
        });
    }

    function GetClave() {
        const fecha = new Date();
        return `${fecha.getFullYear()}${fecha.getMonth() + 1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}${fecha.getMilliseconds()}`;
    }

    useEffect(() => {
        actualizarTitulo();
        if (!isAddMode) obtenerAtributo();
        else {
            if (getDataSession('sucursal') === '0') {
                Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
                return;
            }

            setAtributos({ ...atributo, clave_Atributo: GetClave() });
            setAtributoValues([]);
        }
    }, []);

    return (
        <div className='container-fluid'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-6  col-md-6'>
                        <div className='espacio5px' />
                    </div>
                    <div className='col-sm-2' />
                    <div className='col-sm-4 text-right'>
                        <button
                            className='input_file_hidden'
                            type='submit'
                            ref={button_submit}
                        >
                            {t('app.botones.guardar')}
                        </button>
                        <button
                            type='button'
                            className='btn btn-outline-primary m-1'
                            style={{ width: 'auto' }}
                            onClick={handleClick}
                        >
                            <TuneIcon />&nbsp; Acciones <ArrowDropDownIcon />
                        </button>
                        <Menu
                            id='basic-menu'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                        >
                            {getDataSession('tienda') !== 'MERCADOLIBRE' ? (<MenuItem
                                onClick={() => {
                                    handleClickButtonSubmit();
                                    handleClose();
                                }}
                            >
                                <ListItemIcon>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-check-lg'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                    </svg>
                                </ListItemIcon>
                                {t('app.botones.guardar')}
                            </MenuItem>) : ('')}
                            {getDataSession('tienda') !== 'MERCADOLIBRE' && !isAddMode ? (
                                <MenuItem
                                    onClick={() => {
                                        eliminar();
                                        handleClose();
                                    }}
                                >
                                    <ListItemIcon>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-x-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
                                        </svg>
                                    </ListItemIcon>
                                    {t('app.botones.eliminar')}
                                </MenuItem>
                            ) : ('')}
                            <MenuItem
                                onClick={() => {
                                    regresar();
                                    handleClose();
                                }}
                            >
                                <ListItemIcon>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                </ListItemIcon>
                                {t('app.botones.regresar')}
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                <div className='espacio10px' />
                <div className='card'>
                    <div className='col-12 col-sm-12 col-md-12 row'>
                        <div className='col-sm-1' />
                        <div className='col-sm-10' style={{ display: 'grid' }}>
                            <div className='card-body text-start row'>
                                <label htmlFor='clave' className='form-label'>Clave</label>
                                <input
                                    type='text'
                                    className={ClaveDisable() ? 'form-control form-control-disabled' : 'form-control'}
                                    name='clave_Atributo'
                                    maxLength={100}
                                    value={atributo.clave_Atributo || ''}
                                    disabled={ClaveDisable()}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor='nombre' className='form-label'>Nombre</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='nombre'
                                    maxLength={100}
                                    value={atributo.nombre || ''}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className='espacio20px' />
                                <div className='col-md-13 row'>
                                    <div className='col-sm-3'>
                                        <div className='form-check form-switch margin_left_switch'>
                                            <input
                                                type='checkbox'
                                                className='form-check-input'
                                                name='tiene_Values'
                                                role='switch'
                                                onChange={handleInputChange}
                                                checked={atributo.tiene_Values}
                                            />
                                            <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Tiene Valores</label>{' '}&nbsp;&nbsp;
                                        </div>
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className='form-check form-switch margin_left_switch'>
                                            <input
                                                type='checkbox'
                                                className='form-check-input'
                                                name='es_Variante'
                                                role='switch'
                                                onChange={handleInputChange}
                                                checked={atributo.es_Variante}
                                            />
                                            <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Es Variante</label>{' '}&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div className='espacio15px' />
                                {atributo.tiene_Values ? (<div>
                                    <Divider variant="middle" color='indigo' />
                                    <div className='espacio5px' />
                                    <div className='card-body'>
                                        <div style={{ overflowX: 'auto' }}>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>Clave</th>
                                                        <th className='text-center'>Valor</th>
                                                        <th className='text-center' width='100'></th>
                                                        <th className='text-center' width='150'><button className='btn btn-outline-primary' type='button' onClick={() => AgregarValor()}>&nbsp;Agregar Valor</button></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {atributoValues?.map((item) => {
                                                        return (
                                                            <tr key={item.clave_Value}>
                                                                <td className='text-center'>{item?.clave_Value}</td>
                                                                <td className='text-center'>{item?.nombre}</td>
                                                                <td className='text-center'></td>
                                                                <td className='text-center'>
                                                                    <button
                                                                        type='button'
                                                                        className='btn'
                                                                        style={{ color: 'red' }}
                                                                        onClick={(e) => EliminarValue(item?.nombre, item?.clave_Value)}
                                                                    >
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='25'
                                                                            height='20'
                                                                            fill='currentColor'
                                                                            className='bi bi-x-circle-fill'
                                                                            viewBox='0 0 16 16'
                                                                        >
                                                                            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'></path>
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>) : ''}
                            </div>
                        </div>
                        <div className='col-sm-1' />
                    </div>
                </div>
            </form>
            <Modal isOpen={isOpenModal} closeModal={closeModal} min={true}>
                <div className='card'>
                    <div className='col-12 col-sm-12 col-md-12 row'>
                        <div className='col-sm-1' />
                        <div className='col-sm-10' style={{ display: 'grid' }}>
                            <div className='card-body text-start row'>
                                <label htmlFor='clave' className='form-label'>Clave</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='clave_Value'
                                    maxLength={100}
                                    value={atributoValue.clave_Value || ''}
                                    onChange={handleInputModalChange}
                                    required
                                />
                                <label htmlFor='nombre' className='form-label'>Valor</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='nombre'
                                    maxLength={100}
                                    value={atributoValue.nombre || ''}
                                    onChange={handleInputModalChange}
                                    required
                                />
                                <div className='espacio20px' />
                                <div className='col-12 col-sm-12 col-md-12 row'>
                                    <div className='col-sm-5' />
                                    <div className='col-sm-3'>
                                        <button className='btn btn-outline-primary' type='button' onClick={() => AgregarValue()}>&nbsp;Aceptar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-1' />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export { AddEdit };